/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
// import { Avatar } from '@material-ui/core';
import _ from 'lodash';
import {
  change, Field, getFormSyncErrors, getFormValues, reduxForm,
} from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@material-ui/core';
import TimezoneSelect from 'react-timezone-select';
import UserAccountCard from './UserAccountCard';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import {
  COLORS, COUNTRIES, COUNTRIES_ENUM, ROLE_ACCESSES, TRUCK_NUM_VALIDATION_REGEX,
} from '../../utils/consts';
import SimpleText from '../Text/SimpleText';
import UserProfilePicture from './UserProfilePicture';
import EditableLabel from '../Forms/FormComponents/ComplexLabels/EditableLabel';
import { updateDefaultTruckNumber, updateUserInfo, updateDefaultTimezone } from '../../redux/actions/users.actions';
import { hasUserAccessSelector, userInfoSelector } from '../../redux/selectors/user.selectors';
import MDAutoComplete from '../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import { showModal } from '../../redux/actions/modals.actions';
import { getBrowserTimezone } from '../../utils/helpers';

const headerHeight = 155;
const imgSize = headerHeight / 1.6;
const profileMargins = (headerHeight - imgSize) / 2;

const formName = 'UserInfo';
const fields = {
  defaultTruckNum: 'defaultTruckNumber',
  zipcode: 'zipcode',
  country: 'country',
  timezone: 'tz',
};

const validate = (values) => {
  const errors = {};

  if (values[fields.defaultTruckNum]) {
    if (!TRUCK_NUM_VALIDATION_REGEX.test(values[fields.defaultTruckNum])) {
      errors[fields.defaultTruckNum] = 'Incorrect format';
    }
  }

  if (!values[fields.country]) {
    errors[fields.country] = 'Required to save';
  }

  if (!values[fields.zipcode]) {
    errors[fields.zipcode] = 'Required';
  }

  if (values[fields.country] && values[fields.zipcode]) {
    if (values[fields.country].code === COUNTRIES_ENUM.USA && !/^\d{5}$/.test(values[fields.zipcode])) {
      errors[fields.zipcode] = 'Incorrect format';
    } else if (values[fields.country].code === COUNTRIES_ENUM.CAN && !/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(values[fields.zipcode])) {
      errors[fields.zipcode] = 'Incorrect format';
    }
  }

  return errors;
};

const UserContactInfo = ({
  user, values, errors, minHeight,
}) => {
  const [showCountryInput, setShowCountryInput] = React.useState(false);
  const showMyTciProfile = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile]));
  const dispatch = useDispatch();
  const [selectedTimezone, setSelectedTimezone] = React.useState((!user[fields.timezone] || _.isEmpty(user[fields.timezone])) ? getBrowserTimezone() : user[fields.timezone]);
  // const userTerritories = (user.zip_codes ?? []).reduce((reduced, zipcode) => `${reduced}${reduced ? ',' : ''}${zipcode}`, '').replaceAll(',', ', ');

  React.useEffect(() => {
    if (user) {
      if (user[fields.zipcode]) {
        dispatch(change(formName, fields.zipcode, user[fields.zipcode]));
      }
      if (user[fields.defaultTruckNum]) {
        dispatch(change(formName, fields.defaultTruckNum, user[fields.defaultTruckNum]));
      }
      if (user[fields.country]) {
        const selected = COUNTRIES.find((c) => c.code === user[fields.country]);
        dispatch(change(formName, fields.country, selected));
      }
      if (user[fields.timezone]) {
        dispatch(change(formName, fields.timezone, user[fields.timezone]));
      }
    }
  }, [user[fields.zipcode], user[fields.defaultTruckNum], user[fields.country], user[fields.timezone]]);

  // eslint-disable-next-line no-unused-vars
  const onSaveDefaultTruckNum = (val) => {
    dispatch(updateDefaultTruckNumber(val ?? ''));
  };

  const showToast = (success, msg) => {
    dispatch(showModal('USER_UPDATE_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: msg ?? '',
      },
    }));
  };

  const onSaveHomeZipcode = (val) => {
    const newUserInfo = { ...(user ?? {}), [fields.zipcode]: val, [fields.country]: (values[fields.country] ?? {}).code };
    dispatch(updateUserInfo(newUserInfo));
  };

  const onTimezoneChange = (tz) => {
    setSelectedTimezone(tz);
    dispatch(change(formName, fields.timezone, tz));
  };

  const onSaveDefaultTimezone = () => {
    dispatch(updateDefaultTimezone(selectedTimezone));
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, style, ...rest }) => <ContainerItem {...(rest ?? {})} style={{ ...(style ?? {}), wordWrap: 'break-word', wordBreak: 'break-all' }}>{children}</ContainerItem>;

  const cardHeader = () => item({
    flex: 12,
    style: {
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      maxHeight: headerHeight,
      backgroundImage: `linear-gradient(to bottom right, ${COLORS.CINTAS_BLUE}, ${COLORS.CINTAS_BLUE_GRADIENT})`,
    },
    children: <UserProfilePicture user={user} size={imgSize} marginTop={profileMargins} marginBottom={profileMargins} />,
  });

  const labelTitle = (label) => <SimpleText txt={label} fontSize={16} style={{ color: COLORS.CINTAS_DARK_GRAY }} />;
  const valueDisplay = (val) => <SimpleText txt={`${val ?? 'N/A'}`} fontSize={18} style={{ color: 'black', fontWeight: 'bold' }} />;

  const infoCell = (label, value, inputProps) => container({
    spacing: 0,
    children: [
      item({ flex: 12, children: labelTitle(label) }),
      item({ flex: 12, children: inputProps ? inputProps.input : valueDisplay(value) }),
    ],
  });

  const truckNumberInput = () => (
    <EditableLabel
      id={fields.defaultTruckNum}
      path={fields.defaultTruckNum}
      values={values}
      allowEditing={showMyTciProfile}
      disabled={!showMyTciProfile}
      labelFlex={4}
      error={(errors ?? {})[fields.defaultTruckNum]}
      textStyle={{ color: 'black', fontWeight: 'bold', fontSize: 18 }}
      inputTextStyle={{}}
      disableSave={Boolean((errors ?? {})[fields.defaultTruckNum])}
      onSave={onSaveDefaultTruckNum}
    />
  );

  const zipcodeInput = () => (
    <EditableLabel
      id={fields.zipcode}
      path={fields.zipcode}
      values={values}
      allowEditing={showMyTciProfile}
      disabled={!showMyTciProfile}
      labelFlex={4}
      noHelperText
      error={(errors ?? {})[fields.zipcode]}
      textStyle={{ color: 'black', fontWeight: 'bold', fontSize: 18 }}
      inputTextStyle={{}}
      validate={() => {
        if (!values[fields.country]) {
          showToast(false, 'Please select a country to validate and save the Postal Code');
          return false;
        }

        if ((errors ?? {})[fields.zipcode]) {
          showToast(false, 'Invalid Postal Code format for the selected country');
          return false;
        }
        return true;
      }}
      onEditingChanged={(editing) => setShowCountryInput(editing)}
      disableSave={Boolean(!values[fields.zipcode])}
      onSave={onSaveHomeZipcode}
    />
  );

  const countryInput = () => (
    <Field
      variant="outlined"
      placeholder="Country"
      id={fields.country}
      name={fields.country}
      component={MDAutoComplete}
      displayEmpty
      options={COUNTRIES.filter((c) => c.code !== COUNTRIES_ENUM.MEX)}
      style={{ textAlign: 'start', borderRadius: 0 }}
      getLabel={(option) => `${option.name ?? ''}`}
      inputProps={{
        style: {
          maxHeight: 40,
          padding: 0,
          paddingLeft: 6,
          maxWidth: '90%',
          borderRadius: 0,
        },
      }}
    />
  );

  const timezoneSelect = () => (
    <EditableLabel
      id={fields.timezone}
      path={`${fields.timezone}.label`}
      values={values}
      allowEditing={showMyTciProfile}
      disabled={!showMyTciProfile}
      labelFlex={selectedTimezone?.value ? 9 : 4}
      inputFlex={9}
      noHelperText
      error={(errors ?? {})[fields.timezone]}
      textStyle={{ color: 'black', fontWeight: 'bold', fontSize: 18 }}
      inputTextStyle={{}}
      disableSave={Boolean((errors ?? {})[fields.timezone])}
      onSave={onSaveDefaultTimezone}
      getInputField={() => (
        <TimezoneSelect
          value={selectedTimezone?.value ?? ''}
          onChange={onTimezoneChange}
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: 'transparent',
              borderRadius: 0,
            }),
          }}
        />
      )}
    />
  );

  const userAccountInfo = () => item({
    flex: 12,
    children: container({
      spacing: 0,
      style: { justifyContent: 'space-between' },
      children: [
        item({ flex: 2, style: { paddingRight: 12 }, children: infoCell('Role', (user.displayRole ?? '').toUpperCase()) }),
        item({ flex: 4, children: infoCell('Partner #', user.pernr) }),
        item({ flex: 6, children: infoCell('User ID', user.usrid) }),
      ],
    }),
  });

  const tciForm = () => item({
    flex: 12,
    children: container({
      spacing: 5,
      style: { justifyContent: 'space-between' },
      children: [
        item({ flex: 12, children: divider() }),
        item({
          flex: 7,
          children: infoCell('Home Postal Code', user[fields.zipcode], { input: zipcodeInput() }),
        }),
        item({
          flex: 5,
          children: infoCell('Country', user[fields.country], showCountryInput ? { input: countryInput() } : undefined),
        }),
        item({
          flex: 7,
          children: infoCell(
            'Default Truck Number',
            '',
            { input: truckNumberInput() },
          ),
        }),

        item({
          flex: 5,
          style: {
            textAlign: 'start',
            width: '100%',
          },
          children: infoCell(
            'Default Timezone',
            '',
            { input: timezoneSelect() },
          ),
        }),
        // item({ flex: 12, children: infoCell('Territories', userTerritories === '' ? undefined : userTerritories) }),
      ],
    }),
  });

  const divider = () => <Divider style={{ margin: 0 }} />;

  const infoForm = () => container({
    spacing: 5,
    style: { justifyContent: 'space-between', padding: '20px 30px' },
    children: [
      userAccountInfo(),
      item({ flex: 12, children: divider() }),
      item({ flex: 5, children: infoCell('First Name', user.firstName) }),
      item({ flex: 5, children: infoCell('Last Name', user.lastName) }),
      item({ flex: 12, children: infoCell('Email', (user.email ?? '').toLowerCase()) }),
      // item({ flex: 5, children: infoCell('Phone', formatPhone(user.phone ?? '')) }),
      showMyTciProfile ? tciForm() : <></>,
      item({ flex: 12, children: <Box height={20} /> }),
    ],
  });

  return (
    <UserAccountCard title="Contact Info" minHeight={minHeight}>
      {container({
        style: { justifyContent: 'start', width: '100%' },
        spacing: 0,
        // direction: 'column',
        children: [
          cardHeader(),
          infoForm(),
        ],
      })}
    </UserAccountCard>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state) ?? {},
    user: userInfoSelector(state) ?? {},
    errors: getFormSyncErrors(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
])(UserContactInfo);
