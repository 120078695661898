/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// import { IconButton } from '@material-ui/core';
import _ from 'lodash';
// import AddIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import XIcon from '@material-ui/icons/Close';
// import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import {
  getFormValues, initialize, reduxForm,
} from 'redux-form';
import { getAdminMatrix, getRoleMatrix, updateRolesMatrix } from '../../../../redux/actions/settings.actions';
import SettingsFrame from '../SettingsFrame';
import { hideModal, showModal } from '../../../../redux/actions/modals.actions';
import MatrixRolesView from './MatrixRolesView';
import {
  ADMIN_ACCESSES, ORG_STRUCTURE_ROLES,
} from '../../../../utils/consts/index';
import DeleteConfirmAlert from '../../../../components/Calendar/CalendarComponents/EventPopper/DeleteConfirmAlert';
import Spinner from '../../../../components/SpinnerOverlay/Spinner';
import { hasAdminAccessSelector } from '../../../../redux/selectors/user.selectors';
import AdminMatrix, { adminMatrixFormName } from './AdminMatrix';
import OrgRolesRelations from './OrgRolesRelations';

const rolesFormName = 'AccessMatrix';

const AccessMatrix = ({
  orgMatrix,
  rMatrix,
  match: { path },
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const canEditAdminMatrix = useSelector((state) => hasAdminAccessSelector(state, [ADMIN_ACCESSES.matrix]));

  const loadMatrix = async () => {
    try {
      setLoading(true);
      await dispatch(getRoleMatrix(rolesFormName));
      await dispatch(getAdminMatrix(adminMatrixFormName));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadMatrix();
    return () => { };
  }, []);

  const onSave = async (roleKey, newVals, shouldDelete) => {
    const rolesMtrx = { ...(rMatrix ?? {}) };
    const orgMtrx = { ...(orgMatrix ?? {}) };
    if (Boolean(roleKey) && shouldDelete) {
      delete rolesMtrx[roleKey];
    }
    if (Boolean(roleKey) && Boolean(newVals)) {
      if (orgMtrx.hasOwnProperty(roleKey)) {
        orgMtrx[roleKey] = newVals;
      } else {
        rolesMtrx[roleKey] = newVals;
      }
    }
    if (Object.values(rolesMtrx).filter((val) => !val.role).length > 0) return;
    let success = false;
    try {
      let updatedRoleAccesses = Object.keys(rolesMtrx).reduce((red, oldKey) => {
        const roleMatrix = rolesMtrx[oldKey];
        const newKey = `${roleMatrix.role}${roleMatrix.appRole ? `--${roleMatrix.appRole}` : ''}`;
        return ({ ...red, [newKey]: roleMatrix });
      }, {});
      updatedRoleAccesses = { ...updatedRoleAccesses, ...orgMtrx };
      success = await dispatch(updateRolesMatrix(updatedRoleAccesses, rolesFormName));
    } finally {
      showToast(success, success ? 'Access Matrix updated successfully!' : 'Something went wrong updating the matrix');
    }
    return success;
  };

  const onDeleteRole = (toDelete, callback) => {
    const matrix = { ...rMatrix, ...orgMatrix };
    const roleName = matrix[toDelete]?.role ?? '';
    const modalName = 'DELETE_AERLT';
    dispatch(showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        confirmText: 'confirm',
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'DELETE ROLE',
        content: <DeleteConfirmAlert itemTypeName={`role (${roleName})`} />,
        onConfirm: () => {
          dispatch(hideModal(modalName));
          const newValues = { ...matrix };
          delete newValues[toDelete];
          dispatch(initialize(rolesFormName, newValues));
          if (callback) callback(toDelete, newValues);
        },
      },
    }));
  };

  const showToast = (success, msg) => {
    dispatch(showModal('UPDATE_MATRIX_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: msg,
      },
    }));
  };

  const rolesMatrix = () => (
    <MatrixRolesView
      titleText="ROLES MATRIX"
      matrix={rMatrix}
      formName={rolesFormName}
      uneditableRoles={[`Z&FAS_COE--${ORG_STRUCTURE_ROLES.ADMIN}`]}
      saveChanges={onSave}
      onDeleteRole={onDeleteRole}
      csvFilename="Roles_Matrix"
      // startOpen
      allowDownload
    />
  );

  // const orgRoleMatrix = () => (
  //   <MatrixRolesView
  //     titleText="ORG ROLES MATRIX"
  //     excludeAppRole
  //     collapsed
  //     noRoleInput
  //     noNewRole
  //     noDelete
  //     allowDownload
  //     csvFilename="Org_Roles_Matrix"
  //     matrix={orgMatrix}
  //     formName={rolesFormName}
  //     uneditableRoles={[ORG_STRUCTURE_ROLES.ADMIN]}
  //     saveChanges={onSave}
  //   />
  // );

  const spinner = () => (
    <Spinner
      customStyle={{
        marginTop: '10%',
      }}
    />
  );

  return (
    <SettingsFrame
      path={path}
    >
      {loading ? spinner() : [
        rolesMatrix(),
      ]}
      {loading || !canEditAdminMatrix ? <></> : <AdminMatrix />}
      {loading ? <></> : <OrgRolesRelations />}
    </SettingsFrame>
  );
};

export default _.flow([
  connect((state) => {
    const matrix = getFormValues(rolesFormName)(state) ?? {};
    const orgMatrix = Object.keys(matrix).filter((k) => Object.values(ORG_STRUCTURE_ROLES).includes(k)).reduce((red, k) => ({ ...red, [k]: matrix[k] }), {});
    const rMatrix = Object.keys(matrix).filter((k) => !Object.values(ORG_STRUCTURE_ROLES).includes(k)).reduce((red, k) => ({ ...red, [k]: matrix[k] }), {});
    return ({
      orgMatrix,
      rMatrix,
    });
  }),
  reduxForm({
    form: rolesFormName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(AccessMatrix);
