import React from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { COLORS } from '../../utils/consts';
import { ccyFormat } from '../../utils/helpers';
import { dayTotalRevenueSelector } from '../../redux/selectors/events.selectors';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';

const DayRevenueLabel = ({ date, tz, alignment }) => {
  const revenue = useSelector((state) => (!date ? 0.0 : dayTotalRevenueSelector(state, date, tz)));

  return (revenue
    ? (
      <Typography style={{
        color: COLORS.CINTAS_MEDIUM_GRAY,
        textAlign: alignment ?? 'start',
        fontSize: 12,
        paddingTop: '5%',
      }}
      >
        {ccyFormat(revenue)}
      </Typography>
    ) : <></>
  );
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
  })),
])(DayRevenueLabel);
