/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  Button, Grid, Typography, styled,
} from '@material-ui/core';
import React from 'react';

import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';
import {
  COLORS,
  EMAIL_VALIDATION_ERRORS, EMAIL_VALIDATION_SUCCESS, ENVIRONMENTS_WITH_EMAIL_LOGIN, SIGNIN_ERRORS,
} from '../../../utils/consts';
import { verifyEmail } from '../../../redux/actions/auth.actions';
import { showModal } from '../../../redux/actions/modals.actions';
import { ReactComponent as MicrosoftIcon } from '../../../assets/images/microsoft_logo.svg';
import ForgotPasswordBtn from '../../../routes/MainViews/Login/ForgotPasswordBtn';

export const formName = 'LoginForm';

const MicrosoftSingInButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 15,
  color: COLORS.CINTAS_BLUE,
  background: '#FFFFFF',
  height: 41,
  margin: 'auto',
  border: `1px solid ${COLORS.CINTAS_BLUE}`,
  padding: 12,
  '&:hover': {
    cursor: 'pointer',
  },
});

const LoginForm = ({
  onSubmit, classes, values,
  // onForgotPassword,
  showSignInErrorDialog, onSignInWithAzureID,
}) => {
  const [showPassField, setShowPassField] = React.useState(false);
  const dispatch = useDispatch();

  const showError = (msg) => {
    dispatch(showModal('SIGNIN_ERROR_MODAL', {
      modalType: 'ERROR_ALERT',
      modalProps: { message: msg || 'Something happened during the sign in. Please, try again.' },
    }));
  };

  const verify = async () => {
    if (!values) return;
    const { email } = values;
    const verifyResp = await dispatch(verifyEmail(email));
    if (Object.values(EMAIL_VALIDATION_ERRORS).includes(verifyResp)) {
      showError(SIGNIN_ERRORS[verifyResp]);
    }

    switch (verifyResp) {
      case EMAIL_VALIDATION_ERRORS.SIGN_UP:
      case EMAIL_VALIDATION_ERRORS.UNAUTHORIZED:
        showSignInErrorDialog();
        break;
      case EMAIL_VALIDATION_SUCCESS.AUTHORIZED:
        setShowPassField(true);
        break;
      default:
        break;
    }
  };

  // const signUpAlert = () => (
  //   <div style={{
  //     maxWidth: 400,
  //     maxHeight: 200,
  //     padding: 24,
  //     textAlign: 'start',
  //   }}
  //   >
  //     <SimpleText
  //       txt={SignUpText.emailSent.body}
  //       color="black"
  //       style={{
  //         fontSize: 16,
  //         textAlign: 'start',
  //       }}
  //     />
  //   </div>
  // );

  const azureLoginButton = () => (
    <MicrosoftSingInButton
      type="button"
      onClick={() => onSignInWithAzureID()}
    >
      <MicrosoftIcon style={{ marginRight: 12 }} />
      Sign in with Microsoft
    </MicrosoftSingInButton>
  );

  return (
    <>
      {ENVIRONMENTS_WITH_EMAIL_LOGIN.includes(process.env.REACT_APP_ENV) ? (
        <>
          <Grid container spacing={2} style={{ width: '100%', marginTop: 50, padding: 0 }}>
            <Grid item xs={12}>
              <Field
                id="email"
                name="email"
                variant="outlined"
                component={MDTextInputField}
                placeholder="Email Address"
                label="Email Address"
                required
                autoComplete="email"
                autoFocus
              />
            </Grid>
            {showPassField ? (
              <Grid item xs={12}>
                <Field
                  id="password"
                  name="password"
                  component={MDTextInputField}
                  type="password"
                  variant="outlined"
                  placeholder="Password"
                  label="Password"
                  required
                  autoComplete="current-password"
                />
              </Grid>
            ) : <></>}
          </Grid>
          <div style={{ margin: 8, width: '100%' }}>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => (showPassField ? onSubmit(values) : verify())}
              >
                {showPassField ? 'Sign In' : 'Next'}
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Typography className={classes.link} variant="body2" onClick={() => onForgotPassword()}>
                    Forgot password?
                  </Typography>
                </Grid>
              </Grid> */}
              <ForgotPasswordBtn dispatch={dispatch} />
            </Grid>
            <div style={{ textAlign: 'center' }}>
              <Typography style={{ padding: 5 }} variant="body2">
                Or instead you can:
              </Typography>
              {azureLoginButton()}
            </div>
          </div>
        </>
      ) : (
        <div style={{ paddingTop: 40 }}>
          {azureLoginButton()}
        </div>
      )}
    </>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
  }),
])(LoginForm);
