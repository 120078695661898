import _ from 'lodash';
import Immutable from 'seamless-immutable';

import {
  SAVE_UPCOMING_ORDERS, SET_ORDER_DELIVERY_STATUS, SAVE_ORDERS, UPDATE_UPCOMING_ORDER,
} from '../actions/actionTypes/orders';

const initialState = Immutable({
  data: [],
  upcoming: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORDERS:
      return state.set('data', action.payload);
    case SAVE_UPCOMING_ORDERS:
      return state.set('upcoming', action.payload);
    case SET_ORDER_DELIVERY_STATUS:
      return state.set('upcoming', _.map(state.upcoming, (order) => {
        if (order.id !== action.payload.orderId) return order;
        return { ...order, delivery: action.payload.delivery };
      }));
    case UPDATE_UPCOMING_ORDER:
      return state.set('upcoming', _.map((state.upcoming ?? []), (order) => {
        if (order.id !== action.payload.data.id) return order;
        return action.payload.data;
      }));
    default:
      return state;
  }
};
