/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import {
  makeStyles, Avatar, Box, Tooltip,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import NoAccountsSharpIcon from '@mui/icons-material/NoAccountsSharp';
import { COLORS } from '../../../../../utils/consts';
import CheckboxField from '../../../../Forms/FormComponents/Checkbox/CheckboxField';
import ShapeFactory from '../../../../Shapes/ShapeFactory';
import SimpleText from '../../../../Text/SimpleText';
import BoldTitle from '../../../../Text/BoldTitle';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import StyledButton from '../../../../Button/StyledButton';
import { showModal } from '../../../../../redux/actions/modals.actions';
import SimpleListSelectionDialog from './SimpleListSelectionDialog';

// const tileHeight = 90;
const imgSize = 50;
const numLocationsShown = 2;

const useStyles = makeStyles({
  root: {
    width: '100%',
    // height: tileHeight,
    padding: 16,
    backgroundColor: ({ selected }) => (selected ? COLORS.CINTAS_LIGHT_BLUE : null),
  },
  avatar: {
    maxHeight: imgSize,
    height: imgSize,
    width: imgSize,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    border: `0.01em solid ${COLORS.CINTAS_BLUE}`,
  },
  checkbox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const InstructorTile = ({
  instructorId, disableSelect, onSelectInstructor, instructor, locations, fields, isPrimaryTCI = false,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({ selected: instructor[fields.selected] });
  const uniqueLocations = (locations ?? []).map((l) => ({ label: l.label, id: l.id }));
  const uniqueLocationsLabels = Array.from(new Set(uniqueLocations.map((l) => l.label)));
  const notRegistered = !instructor.uid;

  React.useEffect(() => {
    if (instructor[fields.selected] && isPrimaryTCI) {
      onSelectInstructor(instructorId, instructor[fields.selected], undefined, !isPrimaryTCI);
    }
  }, [instructor[fields.selected]]);

  const onViewMoreLocations = () => {
    const modalName = 'TCI_LOC_INFO';
    dispatch(showModal(
      modalName,
      {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: false,
          maxWidth: 'md',
          title: _.startCase(`${instructor[fields.firstName] ? `${instructor[fields.firstName].toLowerCase()}'s` : ''} locations`),
          content: <SimpleListSelectionDialog options={uniqueLocations} />,
        },
      },
    ));
  };

  const checkbox = () => (
    <CheckboxField
      withoutForm
      disabled={disableSelect || notRegistered}
      style={{ color: (disableSelect || notRegistered) && !instructor[fields.selected] ? COLORS.CINTAS_GRAY : COLORS.CINTAS_BLUE }}
      className={classes.checkbox}
      value={instructor[fields.selected] ?? false}
      onChange={(__, v) => onSelectInstructor(instructorId, v, undefined, !isPrimaryTCI)}
    />
  );

  const initials = () => (
    <ShapeFactory shape="circle" color={COLORS.CINTAS_BLUE} style={{ alignItems: 'center', justifyContent: 'center' }}>
      <SimpleText color="white" txt={`${(instructor[fields.firstName] ?? ' ')[0]}${(instructor[fields.lastName] ?? ' ')[0]}`} />
    </ShapeFactory>
  );

  const avatar = () => (
    <Avatar className={classes.avatar} src={instructor.imgUrl}>
      {instructor.imgUrl ? <></> : initials()}
    </Avatar>
  );

  const title = () => container({
    children: [
      item({
        flex: 11,
        children: <BoldTitle style={{ fontStyle: notRegistered ? 'italic' : 'normal' }} color={notRegistered ? COLORS.CINTAS_DARK_GRAY : COLORS.CINTAS_RED} title={`${instructor[fields.firstName] ?? ''}${instructor[fields.firstName] ? ' ' : ''}${instructor[fields.lastName] ?? ''}`} />,
      }),
      notRegistered && item({
        flex: 1,
        children: <NoAccountsSharpIcon
          style={{ fontSize: 14, color: COLORS.CINTAS_RED }}
        />,
      }),
    ],
  });

  const info = () => container({
    spacing: 0,
    children: [
      item({
        flex: 12,
        children: title(),
      }),
      item({
        flex: 12,
        children: <SimpleText color={notRegistered ? COLORS.CINTAS_GRAY : null} txt={(uniqueLocationsLabels.length > 2 ? uniqueLocationsLabels.slice(0, numLocationsShown) : uniqueLocationsLabels).reduce((reduced, lbl, i, arr) => `${reduced}${lbl}${(i === arr.length - 1) ? '' : '\n'}`, '')} />,
      }),
      uniqueLocationsLabels.length > numLocationsShown ? item({
        flex: 12,
        children: viewMoreBtn(),
      }) : <></>,
    ],
  });

  const viewMoreBtn = () => (
    <StyledButton
      handleButton={onViewMoreLocations}
      variant="text"
      color="primary"
      buttonContent="view more..."
      disableCaps
      style={{ padding: 0 }}
    />
  );

  const body = () => container({
    spacing: 1,
    className: classes.root,
    style: { justifyContent: 'start', margin: 0 },
    children: [
      item({
        children: notRegistered ? <Box /> : checkbox(), flex: 'auto', lg: 1, padding: 0,
      }),
      item({
        children: avatar(), flex: 3, lg: 4, style: { textAlign: 'center', justifyContent: 'center', alignItems: 'center' },
      }),
      item({
        children: info(), flex: 12, lg: 7,
      }),
    ],
  });

  const notRegisteredTooltip = ({ child }) => (
    <Tooltip
      arrow
      placement="bottom"
      title="User not registered"
    >
      <span>
        {child}
      </span>
    </Tooltip>
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  return notRegistered ? notRegisteredTooltip({ child: body() }) : body();
};

export default InstructorTile;
