/* eslint-disable no-use-before-define */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Field, change, getFormValues, initialize, reduxForm,
} from 'redux-form';
import { COLORS } from '../../utils/consts';
import StyledButton from '../Button/StyledButton';
import MDTextInputField from '../Forms/FormComponents/MDTextInput/MDTextInputField';
import Spinner from '../SpinnerOverlay/Spinner';
import SimpleText from '../Text/SimpleText';
import { fetchNationalAccountById } from '../../redux/actions/nationalAccount.actions';
import { showModal } from '../../redux/actions/modals.actions';

const formName = 'NationalAccountSearchForm';
const fieldName = 'nationalAccount';

const NationalAccountSearch = ({ label, nationalAccount, onAccountSelected }) => {
  const dispatch = useDispatch();
  const [higherAccount, setHigherAccount] = React.useState(nationalAccount);
  const [loading, setLoading] = React.useState(false);

  const showToast = (msg) => {
    dispatch(showModal('NATIONAL_ACCOUNT_ERROR', {
      modalType: 'ERROR_ALERT',
      modalProps: {
        message: msg ?? '',
      },
    }));
  };

  const onSubmit = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const nationalAccountData = await fetchNationalAccountById(nationalAccount);
      if (nationalAccountData) {
        dispatch(initialize('NationalAccountForm', nationalAccountData));
        onAccountSelected(nationalAccountData);
      } else {
        dispatch(change(formName, fieldName, ''));
        setHigherAccount('');
        showToast('Invalid Higher Level Account, type the full number and try again');
      }
    } finally {
      setLoading(false);
    }
  };

  const header = () => (
    <SimpleText
      txt={label ?? ''}
      fontSize={22}
      style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, paddingTop: 10 }}
    />
  );

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20, width: 20, padding: 2, margin: 0, color: COLORS.CINTAS_WHITE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const input = () => (
    <div>
      <Field
        id={fieldName}
        name={fieldName}
        component={MDTextInputField}
        type="text"
        size="small"
        variant="outlined"
        placeholder="Higher Level Account"
        label="Higher Level Account"
        noBorderRadius
        withEnterTrigger
        style={{ width: 220, marginTop: 12 }}
        onHandleChange={(v) => setHigherAccount(v)}
      />
    </div>
  );

  const submitButton = () => (
    <StyledButton
      variant="contained"
      color="primary"
      buttonContent={!loading ? 'SEARCH' : spinner()}
      disabled={!higherAccount || _.isEmpty(higherAccount)}
      handleButton={onSubmit}
      style={{ minWidth: 220 }}
    />
  );

  return (
    <div>
      {header()}
      {input()}
      {submitButton()}
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    nationalAccount: getFormValues(formName)(state)?.nationalAccount,
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(NationalAccountSearch);
