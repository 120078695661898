/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import Notification from '../models/Notification';
import { NOTIFICATION_RECEIVER_TYPES, NOTIFICATION_TYPE } from './consts/notifications.consts';

export default class NotificationsManager {
  constructor({ user, tz, notificationSettings }) {
    this.user = user;
    this.tz = tz;
    this.userNotificationConfigs = user?.notificationSettings ?? {};
    this.notificationSettings = notificationSettings ?? {};
  }

  phoenixOfType(type) {
    const instance = { ...this };
    instance.type = type;
    instance.calculateEffectiveDate = this.calculateEffectiveDate;
    instance.getHoursOffset = this.getHoursOffset;
    switch (type) {
      case NOTIFICATION_TYPE.BOOKED_EVENT:
      case NOTIFICATION_TYPE.UPCOMING_EVENT:
      case NOTIFICATION_TYPE.COMPLETED_EVENTS:
      case NOTIFICATION_TYPE.CONFIRMATION_SIGN:
      case NOTIFICATION_TYPE.EVENT_NOT_STARTED:
      case NOTIFICATION_TYPE.SECONDARY_ADDED:
      case NOTIFICATION_TYPE.CANCELED_EVENT:
      case NOTIFICATION_TYPE.RESCHEDULED_EVENT:
      case NOTIFICATION_TYPE.EDITED_EVENTS:
      case NOTIFICATION_TYPE.E_OUTSIDE_LOCATION:
        return new NotificationPhoenix(this.orderNotificationBirther.bind(instance));
      case NOTIFICATION_TYPE.ESCALATED_EVENT:
        return new NotificationPhoenix(this.escalationBirther.bind(instance));
      case NOTIFICATION_TYPE.RECURRING_FAILED:
      case NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED:
      case NOTIFICATION_TYPE.COE_REVIEW_LISTING:
      case NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK:
      default:
        return undefined;
    }
  }

  // The effective date of a notification is the date the notification starts becoming available to the user
  calculateEffectiveDate(type, orderSummary) {
    const hoursOffset = this.getHoursOffset(type);
    const orderStart = orderSummary?.events?.[0]?.startTime;
    switch (type) {
      case NOTIFICATION_TYPE.UPCOMING_EVENT:
        return moment.utc(orderStart).add(-hoursOffset, 'hours');
      case NOTIFICATION_TYPE.EVENT_NOT_STARTED:
        return moment.utc(orderStart).add(hoursOffset, 'hours');
      default:
        return moment.utc().add(hoursOffset, 'hours');
    }
  }

  getHoursOffset(type) {
    const settings = this.notificationSettings[type] ?? {};
    return Number(settings.whenSent ?? 0);
  }

  receiversOfOrderNotificationType(type, orderCreator, orderInstructor, manager) {
    const {
      instructor: instrNtfSetting,
      manager: mngrNotifSetting,
      createdBy: crtrNotifSetting,
    } = this.notificationSettings?.[type] ?? {};
    const info = {
      [NOTIFICATION_RECEIVER_TYPES.INSTRUCTOR]: {
        uid: orderInstructor,
        notify: Boolean(instrNtfSetting),
      },
      [NOTIFICATION_RECEIVER_TYPES.CREATOR]: {
        uid: orderCreator,
        notify: Boolean(crtrNotifSetting),
      },
      [NOTIFICATION_RECEIVER_TYPES.MANAGER]: {
        uid: manager,
        notify: Boolean(mngrNotifSetting),
      },
    };

    return _.uniq([...Object.keys(info)].filter((k) => info[k].notify && !_.isEmpty(info[k].uid ?? '')).map((k) => info[k].uid));
  }

  // HELPERS:

  orderNotificationBirther(orderSummary, actionInfo) {
    const effectiveDate = this.calculateEffectiveDate(this.type, orderSummary);
    return Notification.fromOrderDetails(this.type, effectiveDate, this.user, this.tz, orderSummary, actionInfo);
  }

  // eslint-disable-next-line no-unused-vars
  failedEventCreationBirther(eventsFailed) {
    // This notification type is currently created backend side and dispatched backend side
    // return Notification.parseFailedOrders(this.tz, eventsFailed);
    return new Notification();
  }

  // eslint-disable-next-line no-unused-vars
  escalationBirther(task, resolution, orderSummary) {
    const effectiveDate = this.calculateEffectiveDate(this.type);
    return Notification.fromTask(task, resolution, effectiveDate, this.user, this.tz, orderSummary);
  }
}

class NotificationPhoenix {
  constructor(birther) {
    this.giveBirth = birther;
  }
}
