/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import {
  ClickAwayListener, Divider, IconButton, Popper, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useSelector } from 'react-redux';
import { hasUserAccessSelector } from '../../../../redux/selectors/user.selectors';
import { COLORS, ROLE_ACCESSES } from '../../../../utils/consts';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import BaseEventsListTile from './BaseEventsListTile';

const BaseEventsListPopper = ({
  eventsListEntries,
  onClosePopper,
  open,
  anchorEl,
  headerClasses,
  classes,
  title,
  titleIcon,
  customListRender,
  header,
  hidePrice,
  onEventEdited,
}) => {
  const [arrowRef, setArrowRef] = React.useState(null);
  const hasSubcontractorEditingAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.subcontractorEvents]));

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const clickAwayListener = ({ child }) => (
    <ClickAwayListener
      style={{
        height: '100%', width: '100%', position: 'absolute', top: 0, right: 0, left: 0, bottom: 0,
      }}
      onClickAway={(e) => {
        e.stopPropagation();
        onClosePopper();
      }}
    >
      {child}
    </ClickAwayListener>
  );

  const popover = ({ child }) => (
    <Popper
      className={classes.popper}
      placement="right"
      open={open}
      anchorEl={anchorEl}
      onClose={onClosePopper}
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      {child}
    </Popper>
  );

  const content = () => (
    <div style={{
      width: '100%',
      padding: 16,
    }}
    >
      {popperHeader()}
      {header}
      {divider()}
      {eventsList()}
    </div>
  );

  const eventsList = () => (
    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
      {customListRender ? customListRender() : (eventsListEntries ? eventsListEntries.map((entry) => <BaseEventsListTile hidePrice={hidePrice} entry={entry} clickCallback={onClosePopper} onEventEdited={onEventEdited} allowEditing={hasSubcontractorEditingAccess && entry.isTimeBlock} />) : <></>)}
    </div>
  );

  const divider = () => (
    <Divider
      style={{
        marginLeft: 12,
        marginRight: 12,
        marginTop: 4,
        marginBottom: 4,
      }}
    />
  );

  const closeIcon = () => (
    <IconButton
      aria-label="close"
      edge="end"
      className={headerClasses.closeButton}
      onClick={onClosePopper}
      style={{
        height: 10,
        width: 10,
        margin: 'auto',
        marginTop: 6,
      }}
    >
      <CloseIcon style={{ color: COLORS.CINTAS_BLUE }} />
    </IconButton>
  );

  const titleText = () => (
    <Typography variant="h6" style={{ marginLeft: titleIcon ? 4 : 0, textAlign: 'start' }} className={headerClasses.title}>
      {title}
    </Typography>
  );

  const popperHeader = () => container({
    style: {
      justifyContent: 'space-between',
      verticalAlign: 'middle',
      width: 350,
      textAlign: 'center',
      paddingLeft: 12,
    },
    children: [
      item({
        flex: 10,
        children: [
          container({
            style: { justifyContent: 'flex-start', alignItems: 'center' },
            children: [
              ...(titleIcon ? [item({ children: [titleIcon], style: { height: 25 } })] : []),
              item({ flex: 8, children: [titleText()] }),
            ],
          }),
        ],
      }),
      item({ flex: 2, style: { verticalAlign: 'middle', height: '100%' }, children: [closeIcon()] }),
    ],
  });

  return open && (
    clickAwayListener({
      child: popover({
        child: content(),
      }),
    })
  );
};

export default BaseEventsListPopper;
