import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import { TIME_FORMAT } from '../../../utils/consts';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    color: '#454545',
  },
}));

const renderFromHelper = ({ error }) => {
  if (error) {
    return <FormHelperText style={{ color: '#BA0C2F', margin: '0', height: '0' }}>{error}</FormHelperText>;
  }
  return '';
};
const TimeText = (props) => {
  const { title, ...rest } = props;
  const classes = useStyles();
  return (
    <Typography className={classes.title} {...rest} variant="body2" color="textSecondary" component="p">
      {title}
    </Typography>
  );
};

const FASTimeText = ({ input, meta: { error }, ...props }) => {
  // formate with date, MM/DD/YYYY, h:mm a
  const selectedTimezone = useSelector((state) => state.utils.tz.value);
  return (
    <>
      <TimeText
        title={input.value ? moment.utc(input.value).tz(selectedTimezone).format('LT') : ''}
        {...props}
        onChange={(value) => (input.onChange(moment.utc(value).tz(selectedTimezone).format(`MM/DD/YYYY, ${TIME_FORMAT}`)))}
      />
      {renderFromHelper({ error })}
    </>
  );
};

export default FASTimeText;
