import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import StyledButton from '../../Button/StyledButton';
import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const newLocationButtonStyle = {
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  width: '25%',
};

const searchOptions = {
  filter: true,
  filterType: 'dropdown',
  selectableRows: 'none',
  sortOrder: {
    name: 'Name',
    direction: 'asc',
  },
  downloadOptions: {
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
};

const columns = [
  'FAS Location',
  'Name',
  'Address',
  'City',
  'State',
  'ZIP',
  {
    name: 'Status',
    options: {
      filter: true,
      filterList: ['Active'],
    },
  },
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
];

const LocationsTable = ({ locations }) => {
  const classes = useStyles();

  const history = useHistory();

  const editButton = (id) => (
    <IconButton
      aria-label="edit"
      onClick={() => history.push(`/admin/sites/${id}`)}
    >
      <EditIcon />
    </IconButton>
  );

  const rows = _.map(locations, (location) => {
    const activeDisplay = location.active ? 'Active' : 'Inactive';
    return [
      location.territory,
      location.name,
      `${location.address.address1},${location.address.address2 ? ` ${location.address.address2}` : ' '}`,
      location.address.city,
      location.address.state,
      location.address.zip,
      activeDisplay,
      editButton(location.id),
    ];
  });

  return (
    <div className={classes.root}>
      <FASMUIDataTable
        data={rows}
        columns={columns}
        options={searchOptions}
        root={{
          padding: '5px 15px 5px',
          '&:nth-child(1)': {
            width: '15%',
          },
          '&:nth-child(2)': {
            width: '15%',
          },
          '&:nth-child(3)': {
            width: '30%',
          },
          '&:nth-child(4)': {
            width: '20%',
          },
          '&:nth-child(5)': {
            width: '10%',
          },
          '&:nth-child(6)': {
            width: '10%',
          },
        }}
      />
      <StyledButton
        variant="contained"
        color="primary"
        type="submit"
        width="160px"
        buttonContent="Create New Training Site"
        handleButton={() => history.push('/admin/sites/newLocation')}
        style={newLocationButtonStyle}
      />
    </div>
  );
};

export default LocationsTable;
