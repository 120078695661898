/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import React from 'react';
import SettingsFrame from '../SettingsFrame';
import NotificationsAdminSettings from './NotificationsAdminSettings';
import TasksAdminSettings from './TasksAdminSettings';

const NotificationsAdminSettingsView = ({
  match: { path },
}) => (
  <SettingsFrame path={path}>
    <TasksAdminSettings />
    <NotificationsAdminSettings />
  </SettingsFrame>
);

export default NotificationsAdminSettingsView;
