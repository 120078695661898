/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getAllLocations = () => (
  baseInstance.get('/locations')
);

export const createLocation = (payload) => (
  baseInstance.post('/locations', payload)
);

export const updateLocation = (locationId, payload) => (
  baseInstance.put(`/locations/${locationId}`, payload)
);
