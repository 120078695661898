/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import {
  Typography, Container, Grid, Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import * as LocationsActions from '../../../redux/actions/locations.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import StyledButton from '../../../components/Button/StyledButton';
import LocationSettingsForm from '../../../components/Forms/SettingsForms/LocationSettingsForm';

const titleStyle = {
  fontFamily: 'proxima-nova',
  fontWeight: 'Bold',
  display: 'flex',
  paddingBottom: '24px',
  paddingTop: '24px',
};

const submitButtonStyle = {
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
};

class LocationDetailsView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(LocationsActions.fetchLocations());
  }

  async onSubmitLocation(values) {
    const {
      dispatch,
      history,
      match: { params: { site } },
    } = this.props;

    const creatingNewLocation = site === 'newLocation';

    // Modify fields if needed
    try {
      if (creatingNewLocation) {
        await dispatch(LocationsActions.createLocation(values));
      } else {
        await dispatch(LocationsActions.updateLocationById(values.id, values));
      }
      dispatch(ModalsActions.showModal('UPDATE_LOCATION_SUCCESS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: { message: 'Successfully modified location' },
      }));
      history.push('/admin/sites');
    } catch (error) {
      const message = 'Error submitting location';
      dispatch(ModalsActions.showModal('UPDATE_LOCATION_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { message },
      }));
    }
  }

  render() {
    const {
      dispatch,
      history,
      match: { params: { site } },
      locations: { data: locationsData },
    } = this.props;
    const selectedLocation = _.find(locationsData, { id: site });
    return (
      <Container maxWidth="lg" style={{ paddingBottom: 64 }}>
        <Button
          // variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push('/admin/sites')}
        >
          Back to Locations
        </Button>
        <Grid container spacing={4} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography style={titleStyle} color="primary" variant="h4" gutterBottom>
              LOCATION SETTINGS
            </Typography>
          </Grid>
        </Grid>
        <LocationSettingsForm
          onSubmit={(values) => this.onSubmitLocation(values)}
          initialValues={selectedLocation}
        />
        <StyledButton
          variant="contained"
          color="primary"
          type="submit"
          width="160px"
          buttonContent="Submit"
          handleButton={() => dispatch(submit('LocationSettingsForm'))}
          style={submitButtonStyle}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.locations,
});

export default _.flow([
  withRouter,
  connect(mapStateToProps),
])(LocationDetailsView);
