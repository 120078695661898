/* eslint-disable max-len */
import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/consts/index';

const useStyles = makeStyles(() => ({
  unselectedTab: {
    color: COLORS.CINTAS_BLUE,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  selectedTab: {
    color: COLORS.CINTAS_BLUE,
    fontWeight: 'bold',
    fontSize: '19px',
  },
  underlined: {
    borderBottom: `3px solid ${COLORS.CINTAS_RED}`,
  },
}));

/*
    @param props : {
        tabs: [String],
        tabsContent: [JSX.Element],
    }
*/
const TabView = (props) => {
  const classes = useStyles();
  const {
    tabs, tabsContent, initialTab, onTabChange, alignment, spacing,
  } = props;
  const [tabIdx, setTabIdx] = useState(initialTab ?? 0);

  const onTabClick = (i) => {
    setTabIdx(i);
    if (onTabChange) {
      onTabChange(i);
    }
  };

  // Components Builders:
  const buildTab = (tabName, i) => {
    const isSelected = (tabIdx === i);
    return (
      <div key={`tab-${tabName}`} className={isSelected ? classes.underlined : ''} style={{ cursor: 'pointer', marginLeft: spacing, marginRight: spacing }}>
        <Typography onClick={() => onTabClick(i)} className={isSelected ? classes.selectedTab : classes.unselectedTab}>
          {tabName}
        </Typography>
      </div>
    );
  };

  return (
    <div>
      {(tabs.length > 1)
        && (
          <Grid container spacing={3} alignItems="stretch" justify={alignment ?? 'space-around'} style={{ paddingBottom: 20, paddingTop: 14, cursor: 'pointer' }}>
            {tabs.map((tabName, i) => buildTab(tabName, i))}
          </Grid>
        )}
      {tabsContent.map((tabCont, i) => ((tabIdx === i) ? tabCont : undefined))}
    </div>
  );
};

export default TabView;
