/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const fetchSAPMaterials = () => (
  baseInstance.get('/sap/materials')
);

export const fetchSAPListings = (customer, salesOrg, dc) => (
  baseInstance.get(`/sap/listing?customer=${customer}&sales_org=${salesOrg}&distribution_channel=${dc}`)
);
