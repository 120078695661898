/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {
  Field,
  reduxForm,
  submit,
  getFormValues,
  change,
} from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import CheckboxField from '../../../../Forms/FormComponents/Checkbox/CheckboxField';
import FASTitle from '../../../../Forms/CustomFormComponents/FASTtle';
import FASTimePickerField from '../../../../Forms/CustomFormComponents/FASTimePickerField/FASTimePickerField';
import {
  BOOKING_MODE, DATE_PICKER_FOMAT, EVENT_TYPE, ROLE_ACCESSES, TIME_BLOCK_INTERVALS, TIME_BLOCK_REASONS,
} from '../../../../../utils/consts';
import { timezoneSelector } from '../../../../../redux/selectors/utils.selectors';
import { hasUserAccessSelector } from '../../../../../redux/selectors/user.selectors';
// import { copyLocalDateToTimezone } from '../../../../../utils/dateUtils';

const formName = 'AddEventDialog';

export const useTimePickerStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    borderLeft: '2px solid #e5e5e5',
    // borderRight: '2px solid #e5e5e5',
    // whiteSpace: 'normal',
    // marginBottom: theme.spacing(1),
  },
  picker: {
    margin: '10px 0px',
  },
  allDayKeyword: {
    width: '80px',
    alignContent: 'center',
    textAlign: 'center',
    color: '#454545',
  },
  keyword: {
    verticalAlign: 'middle',
    textAlign: 'center',
    marginTop: '20px',
    color: '#454545',
  },
  title4: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    // fontWeight: '450',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000000',
    // margin: '20px 0px',
  },
}));

const TimePickForm = ({
  curEvent,
  isConfirmationPage,
  initialDateValues,
  dispatch,
  mode,
  tz,
}) => {
  const classes = useTimePickerStyles();
  const isTimeBlock = curEvent && curEvent.eventType === EVENT_TYPE.TIME_BLOCK;
  const hasDate = curEvent && curEvent.date;
  const hasEndDate = curEvent && curEvent.endDate;
  const eventStartTime = curEvent?.startTime && moment.utc(curEvent?.startTime);
  const [prevEventStartTime, setPrevEventStartTime] = React.useState(eventStartTime);
  const eventEndTime = curEvent?.endTime && moment.utc(curEvent?.endTime);
  const hasEndTime = eventEndTime && eventEndTime.isValid();
  const eventDate = curEvent?.date ?? null;
  const isAllDay = curEvent?.allDay ?? null;
  const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [!isTimeBlock || mode === BOOKING_MODE.booking ? ROLE_ACCESSES.bookEvent : ROLE_ACCESSES.editTimeblock]));
  const recurringSeries = curEvent?.recurringSeries && curEvent?.recurringSeries.interval !== TIME_BLOCK_INTERVALS.NO_REPEAT;

  const updateEndTimeToDefault = () => {
    const defaultEnd = eventStartTime?.clone().add(60, 'minutes');
    dispatch(change(formName, 'endTime', defaultEnd));
  };

  // Set default PTO length to 1 hour
  useEffect(() => {
    if (isTimeBlock && !eventEndTime && eventStartTime) {
      updateEndTimeToDefault();
    }
  }, [eventStartTime]);

  // Set default startTime to 7 AM
  useEffect(() => {
    if (hasDate && !eventStartTime && !initialDateValues?.start) {
      const defaultStartTime = moment.tz(curEvent.date, tz).set({ hour: 7, minute: 0 }).utc();
      dispatch(change(formName, 'startTime', defaultStartTime));
    }
  }, [eventDate]);

  useEffect(() => {
    if (initialDateValues && initialDateValues.start) {
      const startMoment = initialDateValues.start;
      dispatch(change(
        formName, 'date',
        `${startMoment.format(DATE_PICKER_FOMAT)}`,
      ));
      dispatch(change(formName, 'startTime', startMoment));
    } else if (mode === BOOKING_MODE.booking) {
      dispatch(change(
        formName, 'date',
        `${moment().utc().format(DATE_PICKER_FOMAT)}`,
      ));
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (initialDateValues && initialDateValues.end) {
      dispatch(change(formName, 'endTime', initialDateValues.end));
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (hasDate && !hasEndDate && isAllDay) {
      dispatch(change(formName, 'endDate', eventDate || ''));
    }

    if (!isAllDay && eventStartTime) {
      if (!hasEndTime) {
        dispatch(change(formName, 'endTime', eventStartTime.clone().add(60, 'minutes')));
      } else if (hasEndTime && mode !== BOOKING_MODE.booking && isTimeBlock) {
        const prevDuration = eventEndTime.toDate() - (prevEventStartTime ?? eventStartTime).toDate();
        const newEnd = eventStartTime.clone().add(prevDuration, 'ms');
        dispatch(change(formName, 'endTime', newEnd));
      }
    }
    setPrevEventStartTime(eventStartTime);
  }, [isAllDay, eventDate]);

  useEffect(() => {
    if (recurringSeries && hasEndTime) {
      const curStartTime = moment.utc(curEvent.startTime);
      if (eventEndTime.isAfter(curStartTime.clone().endOf('day'))) {
        dispatch(change(formName, 'endTime', eventEndTime.subtract(1, 'day')));
      }
    }
  }, [recurringSeries]);

  return (
    <Grid item xs={12}>
      <FASTitle title="Time" />
      <Grid item xs={12}>
        <Field
          className={classes.picker}
          id="date"
          name="date"
          component={MDTextInputField}
          type="date"
          size="small"
          variant="outlined"
          placeholder=""
          // label="Date"
          onChange={(d) => {
            if (!d) return;
            if (mode === BOOKING_MODE.editing) {
              dispatch(change(formName, 'bookingMode', BOOKING_MODE.rescheduling));
            }
            const parts = d.split('-');
            if (parts.length < 3) return;
            const year = Number(parts[0]);
            const month = Number(parts[1]) - 1;
            const day = Number(parts[2]);
            if (year < new Date().getFullYear()) return;
            if (curEvent.startTime) {
              const prevDate = moment.utc(curEvent.startTime);
              dispatch(change(formName, 'startTime', prevDate.clone().set({
                year, month, date: day, hour: prevDate.hour(),
              }).utc()));
              if (mode === BOOKING_MODE.booking && hasEndTime) {
                const duration = moment.utc(curEvent.endTime).diff(prevDate, 'hours');
                dispatch(change(formName, 'endTime', prevDate.clone().set({
                  year, month, date: day, hour: prevDate.hour(),
                }).add(duration, 'hours').utc()));
              }
            }
          }}
          required
          autoComplete="date"
          noBorderRadius
          inputProps={{ min: moment.tz(Date.now(), tz).format(DATE_PICKER_FOMAT) }}
          disabled={!hasEditAccess || (isConfirmationPage && !isTimeBlock) || recurringSeries}
          formatDate={() => {
            let d = eventDate;
            const parsed = Date.parse(d);

            if (d && (Number.isNaN(parsed) || parsed < 0)) {
              return d;
            }

            const splitDate = d.split('-');
            if (splitDate.length < 3) return d;

            if (splitDate[0].length > 4) {
              d = `${splitDate[0].substring(0, 4)}-${splitDate[1]}-${splitDate[2]}`;
            }

            if (!d && eventStartTime) {
              return moment.utc(eventStartTime).tz(tz).format(DATE_PICKER_FOMAT);
            }

            return moment.tz(d, tz).format(DATE_PICKER_FOMAT);
          }}
        />
      </Grid>
      {curEvent && curEvent.eventType === EVENT_TYPE.TIME_BLOCK
        && curEvent.reason === TIME_BLOCK_REASONS.PTO
        && curEvent.allDay
        ? (
          <Grid container>
            <Grid item xs={12} className={classes.allDayKeyword}>to</Grid>
            <Grid item xs={12}>
              <Field
                className={classes.picker}
                id="endDate"
                name="endDate"
                component={MDTextInputField}
                type="date"
                size="small"
                variant="outlined"
                placeholder=""
                required
                autoComplete="date"
                noBorderRadius
                disabled={(isConfirmationPage && !isTimeBlock) || recurringSeries}
              />
            </Grid>
          </Grid>
        )
        : (
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={5}>
              <Field
                className={classes.picker}
                id="startTime"
                name="startTime"
                initialFocusedDate={curEvent && curEvent.date && moment.utc(curEvent.date).startOf('day')}
                component={FASTimePickerField}
                onChange={(value) => {
                  if (mode === BOOKING_MODE.editing) {
                    dispatch(change(formName, 'bookingMode', BOOKING_MODE.rescheduling));
                  }
                  if (isTimeBlock && !recurringSeries && curEvent.endTime.isAfter(moment(curEvent.date).endOf('day').utc())
                    && moment.utc(curEvent.endTime).clone().subtract(1, 'day').isAfter(value)) {
                    dispatch(change(formName, 'endTime', moment.utc(curEvent.endTime).subtract(1, 'day')));
                  }
                }}
                normalize={(v) => v?.utc()}
                disabled={!hasDate || (isConfirmationPage && !isTimeBlock)}
                required
                noBorderRadius
              />
            </Grid>
            <Grid item xs={12} md={2} className={classes.keyword}>to</Grid>
            <Grid item xs={12} md={5}>
              <Field
                className={classes.picker}
                id="endTime"
                name="endTime"
                component={FASTimePickerField}
                onChange={(value) => {
                  if (!value) return;
                  const curStartTime = moment.utc(curEvent.startTime);
                  if (isTimeBlock && !recurringSeries && curStartTime.isSameOrAfter(value)) {
                    dispatch(change(formName, 'endTime', value.add(1, 'day')));
                  } else if (isTimeBlock && value.isAfter(curStartTime.clone().endOf('day'))
                    && curStartTime.isBefore(value.clone().subtract(1, 'day'))) {
                    dispatch(change(formName, 'endTime', value.subtract(1, 'day')));
                  }
                }}
                initialFocusedDate={curEvent && curEvent.date && moment.utc(curEvent.date).startOf('day')}
                normalize={(v) => v?.utc()}
                disabled={!isTimeBlock || (isConfirmationPage && !isTimeBlock) || !hasDate}
                noBorderRadius
              />
            </Grid>
          </Grid>
        )}
      {curEvent
        && curEvent.eventType === EVENT_TYPE.TIME_BLOCK
        && curEvent.reason === TIME_BLOCK_REASONS.PTO
        && (
          <Grid item xs={12}>
            <Field
              name="allDay"
              component={CheckboxField}
              onChange={(val) => {
                if (!val && eventStartTime) {
                  updateEndTimeToDefault();
                }
              }}
              label="All Day"
            />
          </Grid>
        )}
    </Grid>
  );
};

export default _.flow([
  connect((state) => {
    const curEvent = getFormValues(formName)(state);
    return {
      curEvent,
      tz: timezoneSelector(state),
    };
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
    },
    onSubmit: submit,
  }),
])(TimePickForm);
