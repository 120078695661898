import { Box } from '@material-ui/core';
import React from 'react';

const BrowserIcon = ({
  name,
  version,
  date,
  children,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
    }}
  >
    {children}
    <Box height={10} />
    <span>
      <strong>{`${name} ${version}`}</strong>
    </span>
    <span>{date}</span>
  </div>
);

export default BrowserIcon;
