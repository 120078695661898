import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: '#012169',
    margin: '20px 0px',
  },
}));

const FASTitle = (props) => {
  const {
    title, className, customStyle, ...rest
  } = props;
  const classes = useStyles();

  return (
    <Typography className={`${classes.title} ${className}`} style={customStyle} {...rest}>
      {title}
    </Typography>
  );
};

export default FASTitle;
