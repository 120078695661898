/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import _ from 'lodash';
import { FormControlLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import RowLayout from '../../LayoutBuilders/RowLayout';
import RowCell from '../../LayoutBuilders/RowCell';
import BoldTitle from '../../Text/BoldTitle';
import FASDatePickerField from '../../Forms/CustomFormComponents/FASDatePickerField/FASDatePickerField';
import MDRadioGroupField from '../../Forms/FormComponents/MDRadioGroup/MDRadioGroupField';
import FASRadio from '../../Forms/CustomFormComponents/FASRadio';
import StyledButton from '../../Button/StyledButton';
import Spinner from '../../SpinnerOverlay/Spinner';
import { COLORS } from '../../../utils/consts';
import FasRegionLocationInputs from './FasRegionLocationInputs';
import { currentUserLocationsSelector } from '../../../redux/selectors/user.selectors';

const OrdersFilters = (props) => {
  const {
    loading,
    regionLocationData,
    getOrders,
    userLocIds,
    higherLevelAccount,
    nationalAccountLocationData,
  } = props;

  const [region, setRegion] = React.useState(false);
  const [location, setLocation] = React.useState(false);
  const [startDate, setStartDate] = React.useState(moment().utc().startOf('week').toDate());
  const [endDate, setEndDate] = React.useState(moment().utc().toDate());
  const [filterValue, setFilterValue] = React.useState('');

  const filterOptions = [
    {
      value: 'cancelled',
      label: 'Cancelled Orders',
    },
    {
      value: 'tempContact',
      label: 'Has Temporary Contact',
    },
    {
      value: 'unsigned',
      label: 'Unsigned Orders',
    },
    {
      value: '',
      label: 'None',
    },
  ];

  const row = ({
    children, withPadding = false, style, key, ...rest
  }) => (
    <RowLayout key={key} {...rest} style={{ paddingTop: (withPadding ? 14 : 0), ...(style ?? {}) }}>
      {children}
    </RowLayout>
  );

  const rowCell = ({
    child, flex, key, ...rest
  }) => (
    <RowCell key={key} flex={flex} {...(rest ?? {})}>{child}</RowCell>
  );

  const header = (h) => <BoldTitle title={h} fontSize={18} />;
  // const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
    const startDateObj = Date.parse(newDate);
    const endDateObj = Date.parse(endDate);
    if (startDateObj > endDateObj) { setEndDate(newDate); }
  };

  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
    const startDateObj = Date.parse(startDate);
    const endDateObj = Date.parse(newDate);
    if (endDateObj < startDateObj) { setStartDate(newDate); }
  };

  const submitHandler = () => {
    // Set start and end to start of day and end of day
    const startOfDay = new Date(startDate.toDateString());
    const endOfDay = new Date(endDate.toDateString());
    endOfDay.setDate(endOfDay.getDate() + 1);

    let requestObj = {
      startTime: startOfDay,
      endTime: endOfDay,
      cancelled: filterValue === 'cancelled',
      tempContact: filterValue === 'tempContact',
      unsigned: filterValue === 'unsigned',
    };

    if (regionLocationData) {
      const regionObj = _.find(regionLocationData, { region_name: region });
      const locationObj = _.find(regionLocationData, { loc_name: location });
      let defaultLocs = regionObj?.region_code ? null : userLocIds;
      if (_.isEmpty(defaultLocs)) {
        defaultLocs = null;
      }
      requestObj.region = regionObj?.region_code;
      requestObj.locations = (locationObj?.loc_id !== 'All' && Boolean(locationObj?.loc_id)) ? [locationObj?.loc_id] : defaultLocs;
    } else if (higherLevelAccount) {
      const locationObj = _.find(nationalAccountLocationData, { label: location });
      requestObj = locationObj;
    }

    getOrders(requestObj);
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20, width: 20, padding: 0, margin: 0, color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const filterRadioGroup = () => (
    <MDRadioGroupField
      input={{
        value: filterValue,
        onChange: handleFilterChange,
        onBlur: () => { },
      }}
      meta
      row
      withHelperText={false}
    >
      {filterOptions.map(
        (option) => (
          <FormControlLabel
            value={option.value}
            control={<FASRadio />}
            label={option.label}
            disabled={loading}
          />
        ),
      )}
    </MDRadioGroupField>
  );

  const startDatePicker = () => (
    <FASDatePickerField
      selectedDate={startDate}
      handleDateChange={handleStartDateChange}
      disabled={loading}
    />
  );

  const endDatePicker = () => (
    <FASDatePickerField
      selectedDate={endDate}
      handleDateChange={handleEndDateChange}
      disabled={loading}
    />
  );

  const submitButton = () => (
    <StyledButton
      style={{
        width: '100%', height: higherLevelAccount ? 40 : '100%', fontSize: 18,
      }}
      variant="contained"
      color="primary"
      buttonContent="Fetch Orders"
      handleButton={submitHandler}
      disabled={loading || (!region && !higherLevelAccount)}
      endIcon={loading && spinner()}
    />
  );

  const rowOneHeaders = () => row({
    withPadding: true,
    children: [
      rowCell({ key: 0, child: header(higherLevelAccount ? 'Higher Level Account' : 'Region'), flex: 4 }),
      rowCell({ key: 1, child: header('Location'), flex: 4 }),
      rowCell({ key: 2, child: header(regionLocationData && 'Filter'), flex: 4 }),
    ],
  });

  const rowTwoHeaders = () => row({
    withPadding: false,
    children: [
      rowCell({ key: 0, child: header('Start Date'), flex: 4 }),
      rowCell({ key: 1, child: header('End Date'), flex: 4 }),
      // Submit Button - no header
    ],
  });

  const rowOne = () => (
    <FasRegionLocationInputs
      regionLocationData={regionLocationData || nationalAccountLocationData}
      higherLevelAccount={higherLevelAccount}
      loading={loading}
      trailing={rowCell({ key: 2, child: higherLevelAccount ? submitButton() : filterRadioGroup() })}
      onChange={(reg, loc) => {
        setRegion(reg);
        setLocation(loc);
      }}
    />
  );

  const rowTwo = () => row({
    style: { paddingBottom: 25 },
    children: [
      // Start date picker
      rowCell({ key: 0, child: startDatePicker() }),
      // End date Picker
      rowCell({ key: 1, child: endDatePicker() }),
      // Submit button
      rowCell({ key: 2, child: submitButton() }),
    ],
  });

  return (
    <div style={{ paddingLeft: 16, paddingRight: 16, width: '98%' }}>
      {rowOneHeaders()}
      {rowOne()}
      {regionLocationData && rowTwoHeaders()}
      {regionLocationData && rowTwo()}
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    userLocIds: currentUserLocationsSelector(state),
  })),
])(OrdersFilters);
