/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Divider } from '@material-ui/core';
import { getInstructName } from '../../redux/actions/users.actions';
import { TASKS_LABELS, TASK_SUBTYPE_LABELS } from '../../utils/consts/tasks.consts';
import Spinner from '../SpinnerOverlay/Spinner';
import FASTitle from '../Forms/CustomFormComponents/FASTtle';
import { DATE_TIME_YYYY_SHORT_FORMAT_HISTORY } from '../../utils/consts';
import SimpleText from '../Text/SimpleText';
import EventSummaryButton from '../Button/EventSummaryButton';
import { getStatusLabel } from '../../utils/tasks.utils';

const EscalationSummary = ({
  task, resolutionDate, order, // materialsInfo,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [history, setHistory] = React.useState({});

  const {
    type, subType, assigneeID, creationDate,
    resolutionValue: status,
    body: { createdBy: creatorName, tz },
  } = task;

  const formatName = (name) => _.startCase((name ?? '').toLowerCase());

  const getInitiationLabel = () => `initiated a ${(TASKS_LABELS[type] ?? '').toUpperCase()} escalation.`;

  const loadUsers = async () => {
    try {
      setLoading(true);
      const receiverName = await dispatch(getInstructName(assigneeID));
      // const orderTotal = order.cancellationFee ?? order?.events?.reduce((sum, ev) => sum + calculateEventTotalPrice(
      //   ev,
      //   [...(ev.addOns ?? []), ...(ev.additional_participants ?? [])],
      //   materialsInfo,
      // ), 0.0);
      const newHistory = [
        {
          user: formatName(creatorName),
          action: getInitiationLabel(),
          datetime: creationDate,
        },
        {
          user: formatName(receiverName),
          action: `resolved escalation.\nEscalation Type: ${(TASKS_LABELS[type] ?? '').toUpperCase()} ${subType ? `(${TASK_SUBTYPE_LABELS[subType]})` : ''}\nEscalation Status: ${getStatusLabel(status, type)}`,
          datetime: resolutionDate,
        },
      ];
      setHistory(newHistory);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadUsers();
    return () => ({});
  }, []);

  const text = (txt, style) => <SimpleText txt={txt} divStyle={{ padding: '6px 0px' }} style={{ textAlign: 'start', ...(style ?? {}) }} />;

  const historyEntry = (entry) => (
    <div style={{ marginBottom: 16 }}>
      <FASTitle
        title={moment.utc(entry.datetime).tz(tz?.value).format(DATE_TIME_YYYY_SHORT_FORMAT_HISTORY)}
        style={{ padding: 0, margin: 0 }}
      />
      {text(`${entry.user} ${entry.action}`)}
    </div>
  );

  const spinner = () => <Spinner customStyle={{ margin: 0, padding: 0 }} />;

  return (
    <div style={{
      minWidth: 400, padding: 18, maxHeight: 270, overflowY: 'auto',
    }}
    >
      {loading ? spinner() : (
        <>
          {history?.map((entry) => historyEntry(entry))}
          <Divider style={{ margin: '10px 0px' }} />
          <EventSummaryButton
            orderId={order?.id}
            hideRepeatBookingButton
            tzOverride={tz}
          />
        </>
      )}
    </div>
  );
};

export default _.flow([
  connect(
    //   (state) => ({
    //   materialsInfo: materialsDataSelector(state),
    // })
  ),
])(EscalationSummary);
