/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import Task from '../models/Task';

export default class TasksManager {
  constructor({ user, tz, taskConfigs }) {
    this.user = user;
    this.tz = tz;
    this.taskConfigs = taskConfigs ?? {};
  }

  phoenixOfType(type, subType) {
    const instance = { ...this };
    instance.type = type;
    instance.subType = subType;
    instance.calculateEffectiveDate = this.calculateEffectiveDate;
    instance.getHoursOffset = this.getHoursOffset;

    return new TasksPhoenix(this.orderTaskBirther.bind(instance));
  }

  // The effective date of a task is the date the task starts becoming available to the user
  calculateEffectiveDate(type) {
    const hoursOffset = this.getHoursOffset(type);
    return moment.utc().add(hoursOffset, 'hours');
  }

  getHoursOffset(type) {
    const settings = this.taskConfigs[type] ?? {};
    return Number(settings.whenSent ?? 0);
  }

  receiversOfOrderTaskType(type, managerID) {
    const {
      manager: mngrNotifSetting,
    } = this.taskConfigs?.[type] ?? {};
    const notify = Boolean(mngrNotifSetting);
    return (notify && !_.isEmpty(managerID ?? '')) ? [managerID] : [];
  }

  // HELPERS:

  orderTaskBirther(orderSummary, actionInfo) {
    const effectiveDate = this.calculateEffectiveDate(this.type, orderSummary);
    return Task.fromOrderDetails(this.type, this.subType, effectiveDate, this.user, this.tz, orderSummary, actionInfo);
  }
}

class TasksPhoenix {
  constructor(birther) {
    this.giveBirth = birther;
  }
}
