/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { NOTIFICATION_DATETIME_FORMAT } from './consts';
import { NOTIFICATION_TYPE, NOTIFICATIONS_HEADERS } from './consts/notifications.consts';
import { getBrowserTimezone, removeLeadingZeros } from './helpers';
import { timezoneAbbrevFromLabel } from './dateUtils';

const getDateTimeLabel = (bodyData, date, withDataTz) => {
  if (!date) return '';
  const browserTz = getBrowserTimezone();
  const tz = withDataTz ? (bodyData.tz ?? browserTz) : browserTz;
  const datetimeMoment = (withDataTz && tz?.value) ? moment.utc(date).tz(tz.value) : moment(date);
  const datetimeFormatted = datetimeMoment.format(NOTIFICATION_DATETIME_FORMAT);
  const tzAbbrv = withDataTz ? timezoneAbbrevFromLabel(bodyData?.tz?.value, datetimeMoment) : tz?.abbrev;
  return `${datetimeFormatted} ${tzAbbrv ?? ''}`;
};

const getCreatedByLabel = (bodyData) => {
  if (!bodyData.createdBy) return '';
  return `by ${bodyData.createdBy}`;
};

const buildBodyLabel = (bodyData, effectiveDate, withExtraText, excludeCreator, withEventDate) => {
  let extraInfo = '';
  if (withExtraText && bodyData.text) {
    extraInfo = `\n${bodyData.text}`;
  }
  return `${bodyData.title ?? ''}\n${getDateTimeLabel(bodyData, effectiveDate)}\n${excludeCreator ? '' : getCreatedByLabel(bodyData)}${extraInfo}\n${(!withEventDate || !bodyData.datetime) ? '' : `Failed Date: ${getDateTimeLabel(bodyData, bodyData.datetime, true)}`}`;
};

export const buildNotificationBody = (notificationType, bodyData = {}, effectiveDate) => {
  switch (notificationType) {
    case NOTIFICATION_TYPE.BOOKED_EVENT:
    case NOTIFICATION_TYPE.CANCELED_EVENT:
    case NOTIFICATION_TYPE.RESCHEDULED_EVENT:
    case NOTIFICATION_TYPE.UPCOMING_EVENT:
    case NOTIFICATION_TYPE.COMPLETED_EVENTS:
    case NOTIFICATION_TYPE.CONFIRMATION_SIGN:
    case NOTIFICATION_TYPE.E_OUTSIDE_LOCATION:
    case NOTIFICATION_TYPE.EVENT_NOT_STARTED:
    case NOTIFICATION_TYPE.SECONDARY_ADDED:
      return buildBodyLabel(bodyData, effectiveDate);
    case NOTIFICATION_TYPE.RECURRING_FAILED:
      return buildBodyLabel(bodyData, effectiveDate, false, false, true);
    case NOTIFICATION_TYPE.EDITED_EVENTS:
    case NOTIFICATION_TYPE.ESCALATED_EVENT:
      return buildBodyLabel(bodyData, effectiveDate, true);
    case NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED:
    case NOTIFICATION_TYPE.COE_REVIEW_LISTING:
    case NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK:
      return buildBodyLabel(bodyData, effectiveDate, true, true);
    default:
      return (bodyData.title ?? '');
  }
};

const includeAllDataTemplate = {
  location: true,
  customerNum: true,
  contact: true,
  description: true,
};
const messageTemplateRules = {
  message1: includeAllDataTemplate,
  message2: includeAllDataTemplate,
  message3: includeAllDataTemplate,
  message4: includeAllDataTemplate,
  message5: includeAllDataTemplate,
  message6: includeAllDataTemplate,
  message7: {
    notificationType: true,
    description: true,
  },
};

const buildNotificationDetailsBlocks = (notification, template) => {
  const blocks = [];
  const addLine = (blockId, line) => { blocks[blockId] = `${blocks?.[blockId] ?? ''}${line}\n`; };
  if (template.notificationType && notification?.type) {
    addLine(0, `${NOTIFICATIONS_HEADERS[notification?.type]}`);
  }

  if (template.location && notification?.details?.location) {
    addLine(0, `${notification?.details?.location?.city ?? ''}, ${notification?.details?.location?.zip ?? ''}`); // addLine(0, `Location: ${notification?.details?.location?.city ?? ''}, ${notification?.details?.location?.zip ?? ''}`);
  }

  if (template.customerNum && notification?.details?.customer) {
    // addLine(1, `Customer Name: ${notification?.details?.customer?.name}`);
    // addLine(1, `Customer Sold to #: ${removeLeadingZeros(notification?.details?.customer?.soldTo)}`);
    addLine(1, `${notification?.details?.customer?.name}`);
    addLine(1, `${removeLeadingZeros(notification?.details?.customer?.soldTo)}`);
  }

  if (template.contact && notification?.details?.contact) {
    // addLine(2, 'Contact Name/Phone/Email:');
    addLine(2, `${notification?.details?.contact?.name}`);
    if (notification?.details?.contact?.phone) addLine(2, `${notification?.details?.contact?.phone}`);
    addLine(2, `${notification?.details?.contact?.email}`);
  }

  if (template.description && notification?.details?.description) {
    addLine(3, `${notification?.details?.description}`.replaceAll('\\n', '\n'));
  }

  return (blocks).filter((b) => Boolean(b));
};

export const buildNotificationDetails = (notification) => {
  switch (notification.type) {
    case NOTIFICATION_TYPE.BOOKED_EVENT:
    case NOTIFICATION_TYPE.UPCOMING_EVENT:
    case NOTIFICATION_TYPE.COMPLETED_EVENTS:
    case NOTIFICATION_TYPE.CONFIRMATION_SIGN:
    case NOTIFICATION_TYPE.ESCALATED_EVENT:
    case NOTIFICATION_TYPE.EVENT_NOT_STARTED:
    case NOTIFICATION_TYPE.SECONDARY_ADDED:
    case NOTIFICATION_TYPE.CANCELED_EVENT:
      return buildNotificationDetailsBlocks(notification, messageTemplateRules.message2);
    case NOTIFICATION_TYPE.RESCHEDULED_EVENT:
      return buildNotificationDetailsBlocks(notification, messageTemplateRules.message3);
    case NOTIFICATION_TYPE.EDITED_EVENTS:
      return buildNotificationDetailsBlocks(notification, messageTemplateRules.message4);
    case NOTIFICATION_TYPE.E_OUTSIDE_LOCATION:
      return buildNotificationDetailsBlocks(notification, messageTemplateRules.message5);
    case NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED:
    case NOTIFICATION_TYPE.COE_REVIEW_LISTING:
    case NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK:
      return buildNotificationDetailsBlocks(notification, messageTemplateRules.message6);
    case NOTIFICATION_TYPE.RECURRING_FAILED:
    default:
      return undefined;
  }
};
