import Immutable from 'seamless-immutable';

import {
  SAVE_ADDONS_PRICES,
  RESET_ADDONS_PRICES,
  SAVE_ADDITIONAL_PARTICIPANTS_PRICES,
  RESET_ADDITIONAL_PARTICIPANTS_PRICES,
} from '../actions/actionTypes/pricing';

const initialState = Immutable({
  addons: [],
  additionalParticipants: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ADDONS_PRICES:
      return state.set('addons', [...state.addons, { material: action.id, pricing: action.payload }]);
    case RESET_ADDONS_PRICES:
      return state.set('addons', []);
    case SAVE_ADDITIONAL_PARTICIPANTS_PRICES:
      return state.set('additionalParticipants', [...state.additionalParticipants, { material: action.id, pricing: action.payload }]);
    case RESET_ADDITIONAL_PARTICIPANTS_PRICES:
      return state.set('additionalParticipants', []);
    default:
      return state;
  }
};
