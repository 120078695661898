/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { connect, useSelector } from 'react-redux';
import {
  reduxForm, submit, getFormValues, change,
} from 'redux-form';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Box, Grid } from '@material-ui/core';
import CoursePriceDialogWrapper from './CoursePriceDialogWrapper';
import Spinner from '../../../SpinnerOverlay/Spinner';
import CoursePriceComponent from './CoursePriceComponent';
import { fetchPricing } from '../../../../redux/actions/pricing.actions';
import { showModal, hideModal } from '../../../../redux/actions/modals.actions';
import { getPricing } from '../EventDialog/EventDialogControl/CourseSelect';
import StyledButton from '../../../Button/StyledButton';
import { calcCLevels } from '../../../../utils/helpers';
import { materialSelectorOfMultipleTypes } from '../../../../redux/selectors/materials.selector';
import { BELOW_MIN_PRICE_CL, MATERIAL_TYPE } from '../../../../utils/consts';

const formName = 'CoursePriceDialog';

const validate = (val, waiveCost) => parseFloat(BELOW_MIN_PRICE_CL.min) <= Number(val ?? 0) || (waiveCost && Number(val) === 0);

const CoursePriceDialog = ({
  course, coursesNum, customer, dispatch, values, commissionsPercents, onSubmit, modalName,
  addonSpecific, // if the addonSpecific variable has a value, it will be the addon object and it means that the dialog is meant to be setting the price for the addon instead of the course
}) => {
  const [loading, setLoading] = useState(true);
  const [coursePricingInfo, setCoursePricingInfo] = useState({});
  // const skillcheckObject = _.find(materials, { id: course.courseObj.skillcheckID });
  // const skillcheckSAPCode = skillcheckObject?.code ?? '';
  const addon = addonSpecific;
  const makeDayDiscount = course?.price?.makeDayDiscount;
  const initialCustomerPrice = (addonSpecific !== undefined) ? Number(addon.price?.amount ?? '0') : (Number(course?.price?.amount ?? '0') + Number(makeDayDiscount ?? '0'));
  const mat = useSelector(
    (state) => materialSelectorOfMultipleTypes(
      {
        state,
        types: [MATERIAL_TYPE.ADD_ON, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS, MATERIAL_TYPE.COURSE],
        id: addonSpecific !== undefined ? addon.id : course.courseObj.id,
      },
    ),
  );
  // materials.find((v) => v.id === ((addonSpecific !== undefined) ? addon.id : course.courseObj.id)) ?? {};
  const isValid = !loading ? validate(values.price.amount, mat.waiveCost) : false;

  const calcPricing = async () => {
    try {
      if (!mat.code) throw Error();
      const pricingInfo = await dispatch(fetchPricing(customer, mat.code));
      const marketPricing = _.find(pricingInfo, { Customer: '-1' });
      const customerPricing = _.find(pricingInfo, { Customer: customer.sold_to });
      const marketPrice = getPricing(marketPricing, customer.distribution_channel);
      const customerPrice = customerPricing
        ? getPricing(customerPricing, customer.distribution_channel)
        : marketPrice;
      const commissionLevels = calcCLevels(commissionsPercents, (marketPrice ?? 0));
      commissionLevels.sort((a, b) => a.id > b.id);
      return {
        marketPrice,
        commissionLevels,
        disableCustomerPricing: Boolean(customerPricing),
        customerPricing: customerPrice,
      };
    } catch (e) {
      throw e;
    }
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const pricing = await calcPricing();
      setCoursePricingInfo({ ...pricing });
      onPriceChanged(initialCustomerPrice ?? pricing.customerPricing);
      setLoading(false);
      return;
    } catch (error) {
      setCoursePricingInfo({
        marketPrice: 0.0,
        disableCustomerPricing: true,
        commissionLevels: [],
        addonPricing: {},
        customerPricing: 0.0,
        skillcheckPricing: 0.0,
      });
      dispatch(showModal('FETCH_PRICING_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: 'Something happened while fetching pricing. Please, try again!' },
      }));
      onPriceChanged(0.0);
    }
  };

  useEffect(async () => {
    await loadData();
  }, []);

  const dismissDialog = () => dispatch(hideModal(modalName));

  const onPriceChanged = (newVal, discountInfo) => {
    dispatch(change(formName, 'price', ({ ...(course?.price ?? {}), amount: Number(newVal) })));
    dispatch(change(formName, 'discountInfo', discountInfo));
  };

  const onSubmitChange = () => {
    const withDiscount = values.discountInfo?.isMakeDayDiscount;
    const { amount, currency, option } = values.price ?? {};
    const value = withDiscount ? (amount ?? 0) - (values.discountInfo?.makeDayDiscount ?? 0) : (amount ?? 0);
    onSubmit({
      option,
      currency,
      makeDayDiscount: !withDiscount ? undefined : values.discountInfo?.makeDayDiscount,
      amount: value,
    });
    dismissDialog();
  };

  const grid = ({ children, ...rest }) => <Grid {...(rest ?? {})}>{children}</Grid>;
  const container = ({ children, ...rest }) => grid({ children, container: true, ...(rest ?? {}) });
  const btn = ({
    label, disabled, onClick, ...rest
  }) => (
    <StyledButton
      variant="contained"
      buttonContent={label}
      handleButton={onClick}
      disabled={disabled}
      {...(rest ?? {})}
    />
  );

  const footer = () => container({
    style: { justifyContent: 'end' },
    children: [
      btn({
        label: 'CANCEL',
        onClick: dismissDialog,
        variant: 'outlined',
      }),
      <Box width="5%" />,
      btn({
        label: 'APPLY',
        disabled: !isValid,
        color: 'primary',
        onClick: onSubmitChange,
      }),
    ],
  });

  const spinner = () => <Spinner customStyle={{ margin: '30%' }} />;

  return (
    <>
      <CoursePriceDialogWrapper>
        {
          loading
            ? spinner()
            : (
              <div style={{ margin: 0, marginTop: '3%', marginBottom: 4 }}>
                <CoursePriceComponent
                  marketPrice={coursePricingInfo.marketPrice}
                  commissionLevels={coursePricingInfo.commissionLevels}
                  validator={validate}
                  course={course}
                  customer={customer}
                  coursesNum={coursesNum}
                  initialCustomerPrice={initialCustomerPrice}
                  allowEditingCustomer={!coursePricingInfo.disableCustomerPricing}
                  onCustomerPriceChanged={onPriceChanged}
                  material={mat}
                />
                <Box height={30} />
                {footer()}
              </div>
            )
        }
      </CoursePriceDialogWrapper>
    </>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state),
    commissionsPercents: state.cl.data.levels,
  })),
  withRouter,
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: () => { },
    onSubmit: submit,
  }),
])(CoursePriceDialog);
