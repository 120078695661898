import React, { useState, useEffect } from 'react';

import MDTextInputView from './MDTextInputView';

const normalizeInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }

  return value;
};

const MDTextInputField = (props) => {
  const {
    input,
    value,
    type,
    defaultinput,
    helperText,
    noHelperText,
    meta: { touched, invalid, error },
    formatDate,
    onHandleChange,
    max,
  } = props;

  const val = value ?? (input?.value === 0 && type === 'number' ? '0' : (input?.value || defaultinput || ''));
  const [inputValue, setInputValue] = useState(val);

  useEffect(() => {
    setInputValue(val);
  }, [val]);

  useEffect(() => {
    if (String(input?.value)) setInputValue(input?.value);
  }, [input?.value]);

  const handleChange = (newValue, onBlur = false) => {
    // Type 'date' is hanlded different because if input works different
    if (type === 'date' && input) {
      if (onBlur) {
        input.onChange(val);
        input.onBlur(val);
      } else {
        input.onChange(newValue);
        input.onBlur(newValue);
      }
    } else if (onBlur && input) { // Only change redux state when onBlur event happends
      input.onChange(type === 'phone' ? normalizeInput(newValue) : newValue);
      input.onBlur(type === 'phone' ? normalizeInput(newValue) : newValue);
    } else setInputValue(type === 'phone' ? normalizeInput(newValue) : newValue);
    if (onHandleChange) onHandleChange(newValue);
  };

  const getInputValue = () => {
    if (formatDate && val) return formatDate();
    // in case it's number and it's empty, return max
    if (type === 'number' && !String(input?.value) && max) return parseInt(max, 10);
    return inputValue;
  };

  return (
    <MDTextInputView
      {...props}
      error={touched && invalid}
      helperText={((touched && error) || helperText) && !noHelperText}
      value={getInputValue()}
      onChange={(newValue) => handleChange(newValue)}
      onBlur={(newValue) => handleChange(newValue, true)}
    />
  );
};

export default MDTextInputField;
