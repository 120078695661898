/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import DrawerSection from '../DrawerSection';
import TaskTile from './TaskTile';
import ViewMoreButton from '../../Button/ViewMoreButton';
import { fetchUnsolvedTasksofType } from '../../../redux/actions/tasks.actions';

const TaskPanelContentSection = ({ tasksType, maxNumRecordsPerPage, pagedTasks }) => {
  const dispatch = useDispatch();
  const [currentPage, setPage] = React.useState(1);
  const [loadingPage, setLoadingPage] = React.useState(false);
  const pagesLoaded = Array(currentPage).fill(0).map((__, i) => i + 1);
  const data = _.flatten((pagesLoaded ?? []).map((p) => (pagedTasks?.[p] ?? [])));
  const toResolve = data.filter((t) => !t.resolved);
  const [noMorePages, setNoMorePages] = React.useState(false);

  const loadPage = async (page) => {
    let success = false;
    try {
      setLoadingPage(true);
      const newPageData = await dispatch(fetchUnsolvedTasksofType(page, tasksType));
      if (_.isEmpty(newPageData) || newPageData?.length < maxNumRecordsPerPage) {
        setNoMorePages(true);
      }
      success = true;
    } catch (e) {
      success = false;
    } finally {
      setLoadingPage(false);
    }
    return success;
  };

  const increasePage = async () => {
    const newPage = currentPage + 1;
    const pageLoaded = await loadPage(newPage);
    if (pageLoaded) {
      setPage(newPage);
    }
  };

  const viewMoreBtn = () => (
    <ViewMoreButton onClick={increasePage} loading={loadingPage} noMorePages={noMorePages} />
  );

  const tasksList = () => (
    <div>
      {
        data
          .sort((a, b) => moment.utc(b.effectiveDate).valueOf() - moment.utc(a.effectiveDate).valueOf())
          .map((t) => <TaskTile task={t} />)
      }
    </div>
  );

  return (
    <DrawerSection
      header={_.startCase(tasksType.toLowerCase())}
      title={`${toResolve.length}${noMorePages || (toResolve.length === 0) ? '' : '+ '} to resolve`}
      content={[
        tasksList(),
        !_.isEmpty(data) && viewMoreBtn(),
      ]}
    />
  );
};

export default _.flow([connect()])(TaskPanelContentSection);
