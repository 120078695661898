/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';
import Papa from 'papaparse';
import _ from 'lodash';
import { COLORS } from '../../utils/consts';
import SimpleText from '../Text/SimpleText';
import StyledButton from '../Button/StyledButton';
import RowLayout from '../LayoutBuilders/RowLayout';
import * as ModalsActions from '../../redux/actions/modals.actions';

const useStyles = makeStyles({
  container: {
    width: ({ width }) => width ?? 380,
    minWidth: ({ width }) => width ?? 380,
  },
  dropContainer: {
    width: ({ width }) => width ?? 380,
    height: 200,
    minHeight: 200,
    maxHeight: 200,
    textAlign: 'center',
    padding: 16,
  },
  errorText: {
    color: COLORS.CINTAS_RED,
    paddingLeft: 30,
  },
  warningText: {
    color: COLORS.CINTAS_ORANGE,
    paddingLeft: 30,
  },
});

const errorMsgs = {
  genericParsing: 'Something went wrong parsing the file',
  headersMismatch: 'The file headers are incorrect',
  maxRecordsExceeded: 'Warning:\nNumber of rows has been truncated to fit',
};

const warnings = [errorMsgs.maxRecordsExceeded];

const FileImportDialog = ({
  modalName, maxRecords, message, onData, requiredHeaders, templateSampleEntries, width, dispatch,
}) => {
  const classes = useStyles({ width });
  const [errorMsg, setErrorMsg] = useState();
  const [importedData, setImportedData] = useState();

  // it maps the data to a list of objects with headers as keys to values
  const prepareReturnData = (fileData) => {
    const headersIndex = {};
    const headers = fileData[0].data;
    let truncated = false;
    let values = ((fileData.length > 1) ? fileData.slice(1) : []).map((v) => v.data);

    if (maxRecords !== undefined && values.length > maxRecords) {
      values = values.slice(0, maxRecords);
      truncated = true;
    }

    headers.forEach((h, i) => {
      headersIndex[h] = i;
    });

    return {
      truncated,
      data: _.isEmpty(values) ? [] : values.map(
        (csvRow) => headers.reduce(
          (reduced, h) => ({
            ...reduced,
            [h]: csvRow[headersIndex[h]],
          }),
          {},
        ),
      ),
    };
  };

  const onDataReceived = (data) => {
    if (!data || data.length === 0) {
      setErrorMsg(errorMsgs.genericParsing);
      return;
    }

    const headersInfo = data[0];
    if (!headersInfo || !headersInfo.data || headersInfo.data.length === 0) {
      setErrorMsg(errorMsgs.headersMismatch);
      return;
    }

    const headers = headersInfo.data;
    let missingHeader = false;
    (requiredHeaders ?? []).forEach((h) => {
      if (!headers.includes(h)) {
        missingHeader = true;
      }
    });

    if (missingHeader) {
      setErrorMsg(errorMsgs.headersMismatch);
      return;
    }

    const returnData = prepareReturnData(data);
    setErrorMsg(returnData.truncated ? errorMsgs.maxRecordsExceeded : undefined);
    setImportedData(returnData.data);
  };

  const closeAndSendData = () => {
    if (importedData) {
      onData(importedData);
      dispatch(ModalsActions.hideModal(modalName));
    }
  };

  const downloadTemplate = () => {
    const headers = [...requiredHeaders];
    const headersIndex = {};
    headers.forEach((h, i) => {
      headersIndex[h] = i;
    });
    const sampleData = templateSampleEntries.map((entry) => {
      const row = Array(headers.length).fill('');
      headers.forEach((h) => { row[headersIndex[h]] = (entry[h] ?? ''); });
      return row;
    });
    sampleData.unshift(headers);
    const csvString = Papa.unparse(JSON.stringify(sampleData));
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvString)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Template.csv';
    hiddenElement.click();
  };

  const onError = (e) => {
    setImportedData(undefined);
    setErrorMsg(e);
  };

  const onRemoveFile = () => {
    setImportedData(undefined);
    setErrorMsg(undefined);
  };

  const dragDrop = () => (
    <div className={classes.dropContainer}>
      <CSVReader
        onDrop={onDataReceived}
        onError={onError}
        addRemoveButton
        removeButtonColor={COLORS.RED}
        onRemoveFile={onRemoveFile}
      >
        <span>{message}</span>
      </CSVReader>
    </div>
  );

  const error = () => (
    <SimpleText
      className={warnings.includes(errorMsg) ? classes.warningText : classes.errorText}
      txt={errorMsg}
    />
  );

  const button = (label, onClick, { disabled, variant = 'contained' }) => (
    <StyledButton
      buttonContent={label}
      variant={variant}
      color="primary"
      disabled={disabled}
      handleButton={onClick}
    />
  );

  const footer = () => {
    const padding = 30;
    return (
      <RowLayout style={{
        justifyContent: 'space-between',
        marginTop: padding,
        paddingRight: padding,
        paddingLeft: padding,
        paddingBottom: padding,
      }}
      >
        {button('DOWNLOAD TEMPLATE', downloadTemplate, { variant: 'outlined' })}
        {button('IMPORT', closeAndSendData, { disabled: ((errorMsg && !warnings.includes(errorMsg)) || !importedData) })}
      </RowLayout>
    );
  };

  return (
    <div className={classes.container}>
      {dragDrop()}
      {errorMsg ? error() : <></>}
      {footer()}
    </div>
  );
};

export default FileImportDialog;
