import _ from 'lodash';
import { CardContent, Divider, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import { COLORS } from '../../../../utils/consts';
import EventDetailsDialog from '../EventDetailsDialog/EventDetailsDialog';
import StyledButton from '../../../Button/StyledButton';

import * as ModalsActions from '../../../../redux/actions/modals.actions';
import * as OrderActions from '../../../../redux/actions/orders.actions';
import UpcomingOrder from './UpcomingOrder';
import { allUpcomingEventsListSelector } from '../../../../redux/selectors/events.selectors';

const useStyles = makeStyles({
  root: {
    height: '87vh',
    display: 'block',
    alignContent: 'center',
  },
  boxStyle: {
    backgroundColor: '#012169',
    color: COLORS.CINTAS_WHITE,
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '0 0 0',
  },
  smalltitle: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '0 0 0',
    fontSize: '0.85rem',
  },
  daytitle: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    fontSize: '1.2em',
  },
  classinfo: {
    color: '#000000',
  },
  line: {
    color: '#858585',
    backgroundColor: '#858585',
    height: 1,
  },
});

const ScheduleCard = (props) => {
  const classes = useStyles();
  const {
    upcomingEvents,
    materialsInfo,
    dispatch,
    upcomingOrders,
    utils: { tz: { value: selectedTimezone } },
  } = props;

  // Note: upcomingOrders is now full order objects instead of list of ID's.
  // previous mapping shown below in comment
  const expandedOrders = _.map(upcomingOrders, (order) => {
    // const coursesDetails = _.map(order.courses, (course) => (_.find(events, { id: course })));
    const coursesDetails = order.events;
    const startTimes = _.map(coursesDetails, (course) => course?.startTime);
    return { ...order, courses: coursesDetails, start: _.min(startTimes) };
  });
  const ordersByDay = _.groupBy(expandedOrders, (order) => moment.utc(order.start).tz(selectedTimezone).format('YYYYMMDD'));
  const [, setAnchorEl] = React.useState(null);

  const shrinkTitle = useMediaQuery('(min-width:965px)');
  const isToday = (date) => moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

  const popperCloseHandler = () => {
    setAnchorEl(null);
  };

  const showEventDetailsDialog = (order, fromStartDelivery) => {
    popperCloseHandler();
    const modalName = 'EVENT_DETAILS_DIALOG';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_EVENT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'EVENT DETAILS',
        content: <EventDetailsDialog
          openedFromStartDelivery={fromStartDelivery}
          modalName={modalName}
          orderId={order}
          dispatch={dispatch}
        />,
      },
    }));
  };

  const startCourseHander = async (orderId) => {
    // console.log(courseIdx, orderId);
    /**
     * TODO:
     * When the button is pressed, the course goes into “delivery mode”. That means,
     * the participants roster switches to the present/not present visual
     * and the customer signature can be collected.
     * A course started flag will be saved in the event object
    */
    try {
      await dispatch(OrderActions.updateOrderDelivery(orderId));
      showEventDetailsDialog(orderId, true);
      dispatch(ModalsActions.showModal('CREATE_EVENT_SUCCESS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: { message: 'Event successfully started' },
      }));
    } catch (error) {
      dispatch(ModalsActions.showModal('DELIVERY_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: 'Something happened while moving to delivery. Please, try again!' },
      }));
    }
  };

  return (
    <Card className={classes.root}>
      <Box className={classes.boxStyle}>
        <Typography className={shrinkTitle ? classes.title : classes.smalltitle} gutterBottom variant="h6">
          Upcoming Classes
        </Typography>
      </Box>
      <div style={{ overflow: 'scroll', height: '100%' }}>
        {upcomingEvents.length > 0 ? (
          _.map(ordersByDay, (orders, day) => (
            <React.Fragment key={day}>
              <CardContent style={{ paddingBottom: 0, background: isToday(day) ? '#eaf6ff' : 'white' }}>
                <Typography className={classes.daytitle} variant="body2" color="textSecondary" component="p">
                  {moment(day).format('dddd, MMMM D')}
                </Typography>
              </CardContent>
              {_.map(orders, (order) => {
                const selectedCustomer = order.customerObj;
                return (
                  <Grid container key={order.id} style={{ background: isToday(day) ? '#eaf6ff' : 'white' }}>
                    <Grid item xs={12} xl={8}>
                      <UpcomingOrder
                        checkConflict={false}
                        key={order.id}
                        order={order}
                        customer={selectedCustomer}
                        materialsInfo={materialsInfo}
                      />
                    </Grid>
                    {moment().isAfter(moment(order.start).subtract(1, 'hour')) && (
                      <Grid item xs={12} xl={4}>
                        <CardContent style={{ textAlign: 'end' }}>
                          {order && !order.delivery && (
                            <StyledButton style={{ fontSize: 12 }} buttonContent="Start" variant="contained" color="primary" handleButton={() => startCourseHander(order.id)} />
                          )}
                          {order && order.delivery && (
                            <StyledButton
                              buttonContent={(order.deliverySignature) ? 'Completed' : 'In Progress'}
                              variant={(order.deliverySignature) ? 'outlined' : 'contained'}
                              disabled={(order.deliverySignature)}
                              color="primary"
                              style={{ fontSize: 12 }}
                              handleButton={() => showEventDetailsDialog(order.id)}
                            />
                          )}
                        </CardContent>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <CardContent style={{ width: '100%', textAlign: 'center' }}>
            <Typography className={classes.title} variant="body2" component="p">
              No Upcoming Classes
            </Typography>
          </CardContent>
        )}
        <CardContent style={{ width: '100%', textAlign: 'center' }} />
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  utils: state.utils,
  upcomingOrders: state.orders.upcoming,
  upcomingEvents: allUpcomingEventsListSelector(state),
});

export default connect(mapStateToProps)(ScheduleCard);
