import {

  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import DialogHeader from '../DialogHeader/DialogHeader';
import StyledButton from '../Button/StyledButton';

const styles = () => ({
  innerContainer: {
    // padding: 24,
    paddingTop: 0,
  },
  actionsContainer: {
    position: 'sticky',
    top: 0,
    width: '100%',
    background: 'white',
    zIndex: 999,
  },
  bodyText: {
    margin: 0,
    fontWeight: '100',
    fontSize: 18,
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class FASInputDialog extends React.Component {
  onClose() {
    const { onCancel, hideModal, onClosePopup } = this.props;
    if (onClosePopup) {
      onClosePopup();
    }
    if (onCancel) {
      onCancel();
    } else {
      hideModal();
    }
  }

  onConfirm() {
    const { onConfirm, hideModal } = this.props;
    if (onConfirm) {
      onConfirm();
    } else {
      hideModal();
    }
  }

  render() {
    const {
      title,
      bodyText,
      fullWidth,
      fullScreen,
      cancelText,
      content,
      confirmText,
      onConfirm,
      bodyTextStyle,
      hideCancel,
      classes,
      disableBackdropClick,
      maxWidth,
      padding,
    } = this.props;

    const renderDialogActions = () => (
      (
        <DialogActions className={classes.actionsContainer}>
          {!hideCancel && (
          // <MDButton
          //   title={cancelText || 'Close'}
          //   onClick={() => this.onClose()}
          //   containerStyle={{ margin: 0, marginRight: 20 }}
          //   secondary
          // />
          <StyledButton
            variant="outlined"
            width="160px"
            handleButton={() => this.onClose()}
            buttonContent={cancelText || 'Back'}
          />
          )}
          {onConfirm && (
          // <MDButton
          //   title={confirmText || 'Confirm'}
          //   onClick={() => this.onConfirm()}
          //   containerStyle={{ margin: 0 }}
          // />
          <StyledButton
            variant="contained"
            color="primary"
            // onClick={handleNext}
            width="160px"
            handleButton={() => this.onConfirm()}
            buttonContent={confirmText || 'Confirm'}
          />
          )}
        </DialogActions>
      )
    );

    return (
      <Dialog
        PaperProps={{
          style: {
            borderRadius: '0px',
          },
        }}
        open
        disableBackdropClick={disableBackdropClick}
        fullWidth={fullWidth}
        TransitionComponent={Transition}
        maxWidth={maxWidth || 'lg'}
        fullScreen={fullScreen}
        keepMounted
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ position: 'relative' }}>
          <DialogHeader id="customized-dialog-title" onClose={() => this.onClose()}>{title}</DialogHeader>
          {(!hideCancel || onConfirm) && renderDialogActions()}
          <div className={classes.innerContainer} style={{ padding: padding ?? 24, paddingTop: padding ? '' : 0 }}>
            <div id="alert-dialog-slide-description">
              <h5 className={classes.bodyText} style={{ ...bodyTextStyle }}>{bodyText || ''}</h5>
              {content || ''}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FASInputDialog);
