import React from 'react';
import NO_IMAGE_AVAILABLE from '../../../../../assets/images/no_image_available.png';

const AddonItemImage = React.memo(({ addonItem, classes }) => (
  <img
    key={`${addonItem.image}`}
    className={classes.image}
    alt={`${addonItem.id}`}
    src={addonItem.image ?? NO_IMAGE_AVAILABLE}
  />
), () => true);

export default AddonItemImage;
