import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  daytitle: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: ({ color }) => color ?? '#000000',
    fontSize: ({ fontSize }) => (fontSize || '1.2em'),
  },

}));

const BoldTitle = ({
  title, fontSize, color, ...rest
}) => {
  const classes = useStyles({ fontSize, color });

  return (
    <Typography className={classes.daytitle} variant="body2" color="textSecondary" component="p" {...rest}>
      {title}
    </Typography>
  );
};

export default BoldTitle;
