/* eslint-disable arrow-body-style */
/* eslint-disable max-len */

import _ from 'lodash';
import { MATERIAL_TYPE } from '../../utils/consts';
import { customersListSelector } from './customers.selectors';
import { eventsFromOrderSelector } from './events.selectors';
import { materialsDataSelector, materialsOfMultipleTypes } from './materials.selector';

/* eslint-disable no-unused-vars */
export const orderEventsSelector = (state, orderId) => [
  ...(eventsFromOrderSelector(state, orderId)),
].sort((a, b) => Date.parse(a.startTime) > Date.parse(b.startTime));

// If you have not loaded the events for the order yet, you can pass undefined for 'loadedOrders'
// and it will pull the order events from store by default.
export const orderCoursesSelector = (state, orderId, loadedEvents, noSkillchecks = false) => {
  const events = [...(loadedEvents ?? orderEventsSelector(state, orderId))];
  const courses = noSkillchecks ? Object.values(materialsDataSelector(state)[MATERIAL_TYPE.COURSE] ?? {}) : materialsOfMultipleTypes({
    state,
    types: [MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK],
  });
  const toReturn = [];

  events.forEach((event) => {
    const course = courses.find((c) => event.course === c.id);
    if (course && event) {
      toReturn.push({
        ...course,
        event: {
          ...(event ?? {}),
        },
      });
    }
  });

  return toReturn;
};

// If you have not loaded the events for the order yet, you can pass undefined for 'loadedOrders'
// and it will pull the order events from store by default.
export const orderCustomerSelector = (state, soldTo, salesOrg, dc) => {
  return _.find(customersListSelector(state), {
    sold_to: soldTo,
    sales_organization: salesOrg,
    distribution_channel: dc,
  });
};
