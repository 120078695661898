/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { materialCategoriesSelector } from '../../../redux/selectors/settings.selectors';
import SettingsDetailsFrame, { settingsDetailsFrameAction } from './SettingsDetailsFrame';
import * as SettingsActions from '../../../redux/actions/settings.actions';
import { hideModal, showModal } from '../../../redux/actions/modals.actions';
import ClassificationSettingsForm from '../../../components/Forms/SettingsForms/ClassificationSettingsForm';
import DeleteConfirmAlert from '../../../components/Calendar/CalendarComponents/EventPopper/DeleteConfirmAlert';

const AddonClassificationsDetails = ({
  dispatch,
  history,
  classificationData,
  match: { params: { categoryID } },
}) => {
  const [submitting, setSubmitting] = React.useState();
  const creatingNewCategory = categoryID === 'newClassification';
  const selectedCategory = _.find(classificationData, { id: categoryID });

  const navToClassifications = () => history.push('/admin/addonClassifications');

  const onSubmitCategory = async (values) => {
    // Modify fields if needed
    try {
      setSubmitting(true);
      let success = true;
      if (creatingNewCategory) {
        success = await dispatch(SettingsActions.createCategory(values, Boolean(values.imageObj)));
      } else {
        success = await dispatch(SettingsActions.updateCategory(values.id, values, Boolean(values.imageObj)));
      }
      if (success) {
        dispatch(showModal('UPDATE_CLASSIFICATION_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Successfully modified classification' },
        }));
        navToClassifications();
      } else {
        throw Error();
      }
    } catch (error) {
      dispatch(showModal('UPDATE_CLASSIFICATION_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: 'Error submitting classification' },
      }));
    } finally {
      setSubmitting(false);
    }
  };

  const requestDelete = () => {
    const modalName = 'DELETE_CLASSIFICATION';
    dispatch(showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        confirmText: 'confirm',
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'DELETE CLASSIFICATION',
        content: <DeleteConfirmAlert itemTypeName="classification" />,
        onConfirm: async () => {
          dispatch(hideModal(modalName));
          try {
            await dispatch(SettingsActions.deleteCategory(categoryID));
            dispatch(showModal('UPDATE_CLASSIFICATION_SUCCESS', {
              modalType: 'SUCCESS_ALERT',
              modalProps: { message: 'Successfully deleted classification' },
            }));
            navToClassifications();
          } catch (e) {
            dispatch(showModal('UPDATE_CLASSIFICATION_ERROR', {
              modalType: 'ERROR_ALERT',
              modalProps: { message: 'Error deleting classification' },
            }));
          }
        },
      },
    }));
  };

  return (
    <SettingsDetailsFrame
      title="CLASSIFICATION SETTINGS"
      backBtnTitle="Back to Classifications"
      backPath="/admin/addonClassifications"
      formName="ClassificationSettingsForm"
      submitting={submitting}
      onDelete={requestDelete}
      actions={[settingsDetailsFrameAction.SUBMIT]}
    >
      <ClassificationSettingsForm
        dispatch={dispatch}
        onSubmit={(values) => onSubmitCategory(values)}
        initialValues={selectedCategory}
      />
    </SettingsDetailsFrame>
  );
};

export default _.flow([
  connect((state) => ({
    classificationData: materialCategoriesSelector(state),
  })),
  withRouter,
])(AddonClassificationsDetails);
