/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { makeContactDisplay } from '../components/Settings/Orders/OrdersTable';
import SimpleText from '../components/Text/SimpleText';
import { materialSelectorOfMultipleTypes } from '../redux/selectors/materials.selector';
import { DATE_STANDARD_SHORT_FORMAT, MATERIAL_TYPE, QUOTES_STATUS } from '../utils/consts';
import { ccyFormat, removeLeadingZeros } from '../utils/helpers';
import { calculateEventTotalPrice } from '../utils/event.utils';
import Quote from './Quote';

/* eslint-disable class-methods-use-this */
export default class QuotesTableData {
  constructor(props, actionsBuilders) {
    this.props = props ?? {};
    this.actionsBuilders = actionsBuilders ?? {};
    this.coursesAvailable = new Set();
    this.quotes = Object.values(props.quotesInfo);
    this.rows = this.calcRows();
    this.columns = this.calcColumns();
  }

  buildCoursesLabels(courses) {
    const { materialsData } = this.props;
    return (courses ?? []).map((event) => {
      const materialID = event.course ?? '';
      const material = materialSelectorOfMultipleTypes({ materialsInfo: materialsData, types: [MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK], id: materialID });
      if (material) this.coursesAvailable.add(material.title);
      return `${courses.length === 1 ? '' : '\u2022'} ${removeLeadingZeros(material?.code ?? 'N/A')} - ${material?.title ?? 'Missing Title'}`;
    });
  }

  calcRows() {
    const { tz, materialsData } = this.props;
    const { book, copy, view } = this.actionsBuilders;

    return _.map(this.quotes, (quote) => {
      const quoteStatus = quote?.status ?? QUOTES_STATUS.active;

      const quoteData = { ...(quote ?? {}) };
      delete quoteData.data;
      const displayCreationDate = quoteData.creationDate ? moment.utc(quoteData.creationDate).tz(tz).format(DATE_STANDARD_SHORT_FORMAT) : 'Missing Date';
      const displayExpirationDate = quoteData.expirationDate ? moment.utc(quoteData.expirationDate).tz(tz).format(DATE_STANDARD_SHORT_FORMAT) : 'Missing Date';
      const isExpired = moment.utc().isAfter(moment.utc(quoteData.expirationDate));
      const revenue = ccyFormat((quote.data ?? []).reduce((sum, event) => (sum + calculateEventTotalPrice(event, event.addOns ?? [], materialsData)), 0.0));
      const contactDisplay = makeContactDisplay(quoteData.contact, quoteData.contact?.isTemporary);
      const materialsDisplay = this.buildCoursesLabels(quote.data ?? []).join('\n');

      return [
        quoteData.quoteID ?? 'Missing ID',
        displayCreationDate,
        displayExpirationDate,
        quoteData.createdBy ?? 'Missing Name',
        materialsDisplay,
        contactDisplay,
        revenue,
        quoteStatus,
        quoteData.quoteID && !isExpired && book && book(quoteData.quoteID, quote),
        quoteData.quoteID && copy && copy(quoteData.quoteID, quote),
        quoteData.quoteID && view && view(quoteData.quoteID),
      ];
    });
  }

  calcColumns() {
    const { filtersList } = this.props;
    const { switchStatus, expiredLabel } = this.actionsBuilders;
    return [
      'Quote ID',
      {
        name: 'Creation Date',
        options: {
          display: true,
          filter: true,
          searchable: true,
          sort: true,
          viewColumns: true,
          download: true,
          setCellProps: () => ({ style: { textAlign: 'left' } }),
          sortCompare: (order) => (obj1, obj2) => {
            const val1 = moment.utc(obj1.data, DATE_STANDARD_SHORT_FORMAT, true);
            const val2 = moment.utc(obj2.data, DATE_STANDARD_SHORT_FORMAT, true);
            const isAfter = val2.isAfter(val1);
            const sortVal = (isAfter ? 1 : -1) * (order === 'desc' ? 1 : -1);
            return sortVal;
          },
        },
      },
      {
        name: 'Expiration Date',
        options: {
          display: true,
          filter: true,
          searchable: true,
          sort: true,
          viewColumns: true,
          download: true,
          sortCompare: (order) => (obj1, obj2) => {
            const val1 = moment.utc(obj1.data, DATE_STANDARD_SHORT_FORMAT, true);
            const val2 = moment.utc(obj2.data, DATE_STANDARD_SHORT_FORMAT, true);
            const isAfter = val2.isAfter(val1);
            const sortVal = (isAfter ? 1 : -1) * (order === 'desc' ? 1 : -1);
            return sortVal;
          },
        },
      },
      'Created By',
      {
        name: 'COURSE',
        options: {
          display: true,
          filter: true,
          searchable: true,
          print: false,
          sort: true,
          viewColumns: false,
          download: true,
          customBodyRenderLite: (dataIndex) => {
            const q = this.quotes?.[dataIndex];
            if (!q) return '';
            const labels = this.buildCoursesLabels(q.data);
            return <SimpleText txt={labels.join('\n')} />;
          },
          filterOptions: {
            names: Array.from(this.coursesAvailable),
            logic(value, filters) {
              if (_.isEmpty(filters ?? [])) return false;
              return !filters.some((fVal) => (value ?? '').toLowerCase().includes((fVal ?? '').toLowerCase()));
            },
          },
        },
      },
      {
        name: 'CONTACT',
        options: {
          display: true,
          filter: false,
          searchable: true,
          sort: true,
          viewColumns: true,
          download: false,
        },
      },
      'Subtotal',
      {
        name: 'STATUS',
        options: {
          display: true,
          filter: true,
          searchable: true,
          print: false,
          sort: true,
          viewColumns: false,
          download: false,
          filterType: 'multiselect',
          filterList: filtersList?.[7], // inited ? undefined : [QUOTES_STATUS.active],
          filterOptions: {
            names: [...Object.values(QUOTES_STATUS), 'expired'],
            logic(value, filters, row) {
              if (_.isEmpty(filters ?? [])) return false;
              return !filters.some((fVal) => {
                const isExpirationStatusCheck = (fVal === 'expired');
                if (isExpirationStatusCheck) {
                  const expired = moment.utc(row[2], DATE_STANDARD_SHORT_FORMAT, true).isBefore(moment.utc());
                  return expired;
                }
                return (value ?? '').toLowerCase() === ((fVal ?? '').toLowerCase());
              });
            },
          },
          customBodyRenderLite: (dataIndex) => {
            const q = new Quote(this.quotes?.[dataIndex]);
            return (
              <div style={{ textAlign: 'center' }}>
                {(!q.isExpired) && switchStatus && switchStatus(q)}
                {q.isExpired && expiredLabel && expiredLabel(q)}
              </div>
            );
          },
        },
      },
      {
        name: '',
        options: {
          filter: false,
          searchable: false,
          print: false,
          sort: false,
          viewColumns: false,
          download: false,
        },
      }, // This is for the Link to Book Quote
      {
        name: '',
        options: {
          filter: false,
          searchable: false,
          print: false,
          sort: false,
          viewColumns: false,
          download: false,
        },
      }, // This is for the Link to Copy Quote
      {
        name: '',
        options: {
          filter: false,
          searchable: false,
          print: false,
          sort: false,
          viewColumns: false,
          download: false,
        },
      }, // This is for the Link to Quote Confirmation

      // Invisible Columns used for filter functionalities
      // ...
    ];
  }
}
