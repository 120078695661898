/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import _ from 'lodash';
import { Field, getFormValues } from 'redux-form';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import ShapeFactory from '../../../Shapes/ShapeFactory';
import { COLORS, ROLE_ACCESSES } from '../../../../utils/consts';
import MDTextInputField from '../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import InstructorTile from '../EventDetailsDialog/DialogSubcomponents/InstructorTile';
import { instuctorSearchQuerySelector, subcontractorsSelector } from '../../../../redux/selectors/instuctors.selectors';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../Text/SimpleText';
import * as UserActions from '../../../../redux/actions/users.actions';
import { showModal } from '../../../../redux/actions/modals.actions';
import LocationsCalendarTriggerButton from '../../LocationsCalendar/LocationsCalendarTriggerButton';
import { hasUserAccessSelector, reportingTciLocations } from '../../../../redux/selectors/user.selectors';
import { initialDataLoadedSelector } from '../../../../redux/selectors/utils.selectors';
import { groupByZip } from '../../../../utils/instructors.helpers';

const headerHeight = 36;
const headerIconsSize = 20;
const borderStyle = `1px solid ${COLORS.CINTAS_LIGHT_GRAY}`;
const heightSetter = ({ height }) => height;

const useStyles = makeStyles({
  searchBar: {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderLeft: borderStyle,
    marginLeft: 24,
  },
  BLTborder: {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderLeft: borderStyle,
    height: heightSetter,
  },
  BLborder: {
    borderBottom: borderStyle,
    borderLeft: borderStyle,
  },
  BTborder: {
    borderTop: borderStyle,
    borderBottom: borderStyle,
  },
  Bborder: {
    borderBottom: borderStyle,
  },
  Rborder: {
    borderRight: borderStyle,
  },
  Lborder: {
    borderLeft: borderStyle,
  },
  BRborder: {
    borderRight: borderStyle,
    borderBottom: borderStyle,
  },
  verticalDivider: {
    borderLeft: borderStyle,
  },
  borderedBox: {
    border: borderStyle,
  },
});

const fields = {
  firstName: 'first_name',
  lastName: 'last_name',
  location: {
    locationId: 'loc_id',
    locationLabel: 'location_desc',
  },
  partnerNum: 'pernr',
  userId: 'usrid_long',
  selected: 'selected',
};

const InstructorsCards = ({
  height,
  tcis,
  zipcodeQuery,
  loadingData,
  initialDataLoaded,
  selectedTci,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({ height });
  const [instructors, setInstructorsData] = useState([]);
  const searchQuery = useSelector(instuctorSearchQuerySelector);
  const [TCISelected, setTCISelected] = useState(true);
  const hasLocCalendarAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.locCalendar]));
  const subcontractors = useSelector((state) => subcontractorsSelector(state));

  const setInstructors = (dt) => {
    const data = [...(dt ?? []), ...(subcontractors ?? [])];
    setInstructorsData(data);
  };

  React.useEffect(() => {
    // default to first tci selected when tcis load
    if (instructors.length === 0 || (instructors.length - subcontractors.length) === 0) {
      if (tcis.length > 0) {
        if (initialDataLoaded && selectedTci) {
          handleSelectInstructor(buildInstructorId(selectedTci), true, tcis, false, true);
        } else {
          setInstructors(tcis);
        }
      } else {
        setInstructors(tcis);
      }
    }
  }, [tcis, subcontractors]);

  React.useEffect(() => {
    if (zipcodeQuery) {
      setTCISelected(true);
    }
  }, [zipcodeQuery]);

  React.useEffect(() => { }, [zipcodeQuery?.zipcode ?? '']);

  const normalizeAndMatch = (a, b) => a.toLowerCase().includes(b.toLowerCase());
  const buildInstructorId = (instructor) => instructor[fields.userId];

  const sortAndShrink = (data) => {
    const uniqueIds = _.filter(Array.from(new Set(data.map((t) => t.pernr))), (e) => e !== '');
    const shrinkedData = [];
    const locations = {};
    _.filter(uniqueIds, (e) => e.pernr !== '').forEach((uid) => {
      const uidData = data.filter((t) => t.pernr === uid)?.[0];
      shrinkedData.push(uidData);
      locations[uid] = reportingTciLocations(
        { users: { data: { reportingTcis: tcis } } },
        uid,
        uidData.subcontractor ? [uidData] : undefined,
      );
    });

    shrinkedData.sort((a, b) => {
      if (!b.uid) { return -1; }
      if (!a.uid) { return 1; }
      return (a[fields.firstName] ?? '').localeCompare(b[fields.firstName] ?? '');
    });

    // shrinkedData.push(...subcontractors);
    // subcontractors.forEach((sub) => locations[sub.uid] = reportingTciLocations(
    //   reportingTcisLocsPayload,
    //   sub.uid,
    //   [sub],
    // ));

    return ({ shrinkedData, locations });
  };

  const filterInstructors = (data) => {
    if (!searchQuery) return [...data];
    return data.filter((inst) => {
      // CODE SNIPPET TO SEARCH IN ALL FIELDS EXCLUDING PROFILE PICTURE
      // const instructorProps = Object.values(inst).filter(
      //   (instProp) => typeof instProp === 'string' && !(instProp ?? '').includes('http'),n
      // );
      const instructorProps = [...(Object.keys(inst.locations ?? {})), inst.first_name, inst.last_name];
      let isMatch = false;
      instructorProps.forEach(
        (instProp) => isMatch = isMatch || normalizeAndMatch(instProp, searchQuery),
      );
      return isMatch;
    });
  };

  const handleSelectInstructor = async (selectionId, value, data, override = false, restoreSelection = false) => {
    const instrs = [...(data ?? instructors)].map((inst) => ({
      ...(inst ?? {}),
      selected: buildInstructorId(inst) === selectionId ? value : false,
    }));
    const selected = (data ?? instructors).find((instr) => buildInstructorId(instr) === selectionId);
    setInstructors(instrs);
    setTCISelected(!override);
    if (value) {
      if (!restoreSelection) {
        const success = await dispatch(UserActions.setSelectedTCI(selected));
        showToast(success, selected?.uid ?? '');
      }
    } else {
      dispatch(UserActions.resetSelectedTCI());
    }
  };

  const showToast = (success, uid) => {
    const modalMessage = 'This partner has not logged in to Training Central';
    dispatch(showModal('PULL_CALENDAR_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: success ? 'Events successfully loaded!' : uid ? 'Something went wrong loading the TCI events. Please, try again!' : modalMessage,
      },
    }));
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const locationCalendarBtn = () => item({
    flex: 'auto',
    style: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: COLORS.CINTAS_BLUE,
    },
    children: <LocationsCalendarTriggerButton
      dispatch={dispatch}
      iconSize={headerIconsSize}
    />,
  });

  const searchBar = () => (
    container({
      spacing: 0,
      style: { justifyContent: 'space-between', height: headerHeight },
      children: [
        hasLocCalendarAccess && locationCalendarBtn(),
        item({
          flex: 9,
          style: { padding: '4px 2px 2px 12px', height: headerHeight },
          children: <Field
            id="instructorSearchQuery"
            name="instructorSearchQuery"
            component={MDTextInputField}
            withEnterTrigger
            size="small"
            type="text"
            variant="standard"
            margin="none"
            InputProps={{ disableUnderline: true, margin: 0 }}
            noBorderRadius
            placeholder="Search by name or location"
          />,
        }),
        item({
          flex: 1,
          style: { alignItems: 'center', justifyContent: 'center' },
          children: <SearchIcon style={{ width: headerIconsSize, marginTop: '20%' }} />,
        }),
      ],
    })
  );

  const displayInstructors = (data, isPrimaryTCI = false) => {
    const { shrinkedData, locations } = sortAndShrink(data);
    return shrinkedData.map((instr) => (
      <InstructorTile
        fields={fields}
        disableSelect={Boolean(loadingData)}
        locations={_.uniq(locations[instr.pernr])}
        instructorId={buildInstructorId(instr)}
        onSelectInstructor={handleSelectInstructor}
        instructor={instr}
        isPrimaryTCI={isPrimaryTCI}
      />
    ));
  };

  const groupingHeader = (h, color) => (
    <SimpleText
      txt={h}
      divStyle={{
        width: '100%',
        backgroundColor: color,
        textAlign: 'start',
        padding: '5px 12px',
        zIndex: 500,
      }}
      style={{ color: COLORS.CINTAS_WHITE }}
    />
  );

  const noTCIsTile = () => (
    <SimpleText
      txt="No TCIs for this location"
      divStyle={{ padding: '5px 12px' }}
    />
  );

  const zipcodeDisplayInstructors = () => {
    const { primary, secondary } = groupByZip(instructors, zipcodeQuery.locationId, zipcodeQuery.zipcode, TCISelected);
    if (!primary) {
      return displayInstructors(filterInstructors(secondary));
    }

    return [
      groupingHeader('Primary TCI', COLORS.CINTAS_BLUE),
      ...(displayInstructors([primary], true)),
      groupingHeader('Location TCIs', COLORS.CINTAS_GRAY),
      ...(displayInstructors(filterInstructors(secondary))),
      (secondary.length === 0) ? noTCIsTile() : <></>,
    ];
  };

  return (
    <>
      <div className={classes.searchBar}>
        {searchBar()}
      </div>
      <ShapeFactory style={{ marginLeft: 24, overflow: 'scroll' }} className={classes.BLTborder} shape="rectangle">
        {zipcodeQuery ? zipcodeDisplayInstructors() : displayInstructors(filterInstructors(instructors))}
      </ShapeFactory>
    </>
  );
};

export default _.flow([
  connect((state) => ({
    zipcodeQuery: (getFormValues('CalendarTable')(state) ?? {}).zipcodeQuery,
    tcis: state.users?.data?.reportingTcis || [],
    selectedTci: state.users?.data?.selectedTci,
    initialDataLoaded: initialDataLoadedSelector(state),
  })),
])(InstructorsCards);
