import { baseInstance } from './instances/baseInstance';

export const getAllCertifications = () => (
  baseInstance.get('/certifications')
);

export const createCertification = (payload) => (
  baseInstance.post('/certifications', payload)
);

export const updateCertification = (certId, payload) => (
  baseInstance.put(`/certifications/${certId}`, payload)
);
