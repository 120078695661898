/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { connect } from 'react-redux';
// import { Typography, Grid } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';

// import { COLORS } from '../../../utils/consts';
// import AdminNav from '../../../components/AdminNav/AdminNav';
import CertificationsTable from '../../../components/Settings/Certifications/CertificationsTable';
import * as CertificationsActions from '../../../redux/actions/certifications.actions';
import * as MaterialsActions from '../../../redux/actions/materials.actions';
import StyledButton from '../../../components/Button/StyledButton';
import FASTitle from '../../../components/Forms/CustomFormComponents/FASTtle';
import SettingsFrame from './SettingsFrame';

export const newCertButtonStyle = {
  marginLeft: 'auto',
  marginRight: 4,
  display: 'flex',
};

class CertificationsView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(MaterialsActions.fetchMaterials());
    await dispatch(CertificationsActions.fetchCertifications());
  }

  render() {
    const {
      certifications: { data: certifications },
      match: { path },
      history,
    } = this.props;

    const onlyCertifications = _.filter(
      certifications,
      (c) => c.options === 'certification',
    );

    const recertifications = _.filter(
      certifications,
      (c) => c.options === 'recertification',
    );

    const families = _.filter(
      certifications,
      (c) => c.options === 'family',
    );

    return (
      <SettingsFrame
        path={path}
        actions={[
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            width="160px"
            buttonContent="Create New"
            handleButton={() => history.push('/admin/certifications/newCertification')}
            style={newCertButtonStyle}
          />,
        ]}
      >
        <FASTitle title="Certifications" />
        <CertificationsTable certifications={onlyCertifications} type="Certification" />
        <FASTitle title="Recertifications" />
        <CertificationsTable certifications={recertifications} type="Recertification" />
        <FASTitle title="Family" />
        <CertificationsTable certifications={families} type="Family" />
      </SettingsFrame>
    );
  }
}

const mapStateToProps = (state) => ({
  certifications: state.certifications,
});

export default _.flow([
  connect(mapStateToProps),
  withRouter,
])(CertificationsView);
