/* eslint-disable max-len */
import React from 'react';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import ArrowUpwardIcon from '@mui/icons-material/North';
import ArrowDownwardIcon from '@mui/icons-material/South';
import IconButton from '@material-ui/core/IconButton';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment/moment';
import { Typography } from '@material-ui/core';
import { getDaysBetweenDates } from '../../utils/dateUtils';
import { CALENDAR_VIEWS, COLORS, DATE_PICKER_FOMAT } from '../../utils/consts';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { dayTotalRevenueSelector } from '../../redux/selectors/events.selectors';
import { ccyFormat, hasEarlyMorningEvents, hasLateNightEvents } from '../../utils/helpers';

const CustomToolbar = ({
  onNavigate, label, onView, view, eventStartTimes, // loading,
  date,
  showRevenue,
}) => {
  const totalMonthRevenue = useSelector((state) => {
    const tz = timezoneSelector(state);
    const firstOfInterval = moment(date).startOf(`${view}`.toLowerCase());
    const lastOfInteval = moment(date).endOf(`${view}`.toLowerCase());
    if (!showRevenue) return 0;
    const intervalDates = getDaysBetweenDates(firstOfInterval, lastOfInteval, DATE_PICKER_FOMAT);
    return intervalDates
      .map((d) => dayTotalRevenueSelector(state, d, tz))
      .reduce((red, rev) => red + rev, 0.0);
  });

  const isDayView = view === CALENDAR_VIEWS.DAY;
  const showEarlyMorning = hasEarlyMorningEvents(label, eventStartTimes);
  const showLateNight = hasLateNightEvents(label, eventStartTimes);

  return (
    <div
      className="rbc-toolbar"
      style={{
        justifyContent: 'space-between', flexWrap: 'nowrap', margin: '0px', border: '1px solid #e5e5e5',
      }}
    >
      <span className="rbc-btn-group">
        <span
          className="rbc-toolbar-label"
          style={{
            fontWeight: 700,
            color: '#012169',
            fontFamily: 'proxima-nova, sans-serif',
            textTransform: 'uppercase',
            display: showRevenue ? 'flex' : 'inline-block',
          }}
        >
          {label}
          {(showRevenue && totalMonthRevenue > 0) && (
            <Typography
              style={{
                color: COLORS.CINTAS_MEDIUM_GRAY,
                marginLeft: 10,
              }}
            >
              {ccyFormat(totalMonthRevenue)}
            </Typography>

          )}
        </span>
        {(isDayView && showEarlyMorning && (
          <span
            className="rbc-toolbar-label"
            style={{
              fontWeight: 700,
              color: '#012169',
              fontFamily: 'proxima-nova, sans-serif',
            }}
          >
            <ArrowUpwardIcon style={{ color: COLORS.CINTAS_BLUE, fontSize: 16, paddingTop: 1 }} />
          </span>
        ))}
        {(isDayView && showLateNight && (
          <span
            className="rbc-toolbar-label"
            style={{
              fontWeight: 700,
              color: '#012169',
              fontFamily: 'proxima-nova, sans-serif',
            }}
          >
            <ArrowDownwardIcon style={{ color: COLORS.CINTAS_BLUE, fontSize: 16, paddingTop: 1 }} />
          </span>
        ))}
      </span>
      <div>
        <div className="rbc-btn-group">
          <button type="button" onClick={() => onView(CALENDAR_VIEWS.DAY)} style={{ border: 'hidden', fontWeight: 'bold', background: view === CALENDAR_VIEWS.DAY && '#E8E8E8' }}>Day</button>
          <button type="button" onClick={() => onView(CALENDAR_VIEWS.WEEK)} style={{ border: 'hidden', fontWeight: 'bold', background: view === CALENDAR_VIEWS.WEEK && '#E8E8E8' }}>Week</button>
          <button type="button" onClick={() => onView(CALENDAR_VIEWS.MONTH)} style={{ border: 'hidden', fontWeight: 'bold', background: view === CALENDAR_VIEWS.MONTH && '#E8E8E8' }}>Month</button>
          <button type="button" onClick={() => onView(CALENDAR_VIEWS.YEAR)} style={{ border: 'hidden', fontWeight: 'bold', background: view === CALENDAR_VIEWS.YEAR && '#E8E8E8' }}>Year</button>
        </div>
      </div>

      <span className="rbc-btn-group">
        <IconButton
          style={{
            fontWeight: 'bold', marginRight: '20px', border: 'hidden',
          }}
          // disabled={loading}
          onClick={() => onNavigate('PREV')}
        >
          <LeftArrowIcon style={{ border: '1px solid #E1E1E1', color: '#012169' }} />
        </IconButton>
        <IconButton
          style={{ border: 'hidden' }}
          onClick={() => onNavigate('TODAY')}
        >
          <span className="rbc-toolbar-label" style={{ fontSize: '16px' }}>
            <strong> Today </strong>
          </span>
        </IconButton>
        <IconButton
          // disabled={loading}
          style={{
            fontWeight: 'bold', marginLeft: '20px', border: 'hidden',
          }}
          onClick={() => onNavigate('NEXT')}
        >
          <RightArrowIcon style={{ border: '1px solid #E1E1E1', color: '#012169' }} />
        </IconButton>
      </span>
    </div>
  );
};

export default _.flow([
  connect(),
])(CustomToolbar);
