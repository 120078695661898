/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Field, change, getFormValues, initialize, reduxForm,
} from 'redux-form';
import { IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { holidaysSelector } from '../../../redux/selectors/settings.selectors';
import { deleteHolidayDefault, updateHolidayDefault } from '../../../redux/actions/settings.actions';
import SettingsTile from './SettingsTile';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import StyledButton from '../../Button/StyledButton';
import { DATE_PICKER_FOMAT, DATE_SHORT_FORMAT } from '../../../utils/consts';
import { useTimePickerStyles } from '../../Calendar/CalendarComponents/EventDialog/EventDialogForm/TimePickForm';
import { hideModal, showModal } from '../../../redux/actions/modals.actions';
import DeleteConfirmAlert from '../../Calendar/CalendarComponents/EventPopper/DeleteConfirmAlert';

const formName = 'HolidaysDefaultsForm';
const initialState = {
  label: '',
  date: moment(Date.now()).format(DATE_PICKER_FOMAT),
};

const checkHolidayDate = (val, defaultVals) => {
  const errors = {};
  const date = defaultVals?.date;
  if (!date || (date ?? '').trim() === '') {
    errors[val] = {
      ...(errors[val] ?? {}),
      date: 'Invalid',
    };
  }
  return errors;
};

const checkLabel = (id, defaultVals) => {
  const errors = {};
  const label = defaultVals?.label;
  const isInvalid = _.isEmpty(label ?? '');
  if (isInvalid) {
    errors[id] = {
      ...(errors[id] ?? {}),
      label: 'Invalid',
    };
  }

  return errors;
};

const validate = (vals) => {
  let errors = {};

  Object.keys(vals).forEach((val) => {
    if (val === 'new') {
      const dateErrors = checkHolidayDate(val, vals[val]);
      errors = { ...errors, ...dateErrors };
    }
    const labelErrors = checkLabel(val, vals[val]);
    errors = { ...errors, ...labelErrors };
  });

  return errors;
};

const HolidaysDefault = ({ data, formData }) => {
  const dispatch = useDispatch();
  const classes = useTimePickerStyles();
  const [addingNew, setAddingNew] = React.useState(false);
  const [holidayName, setHolidayName] = React.useState(false);

  React.useEffect(async () => {
    dispatch(initialize(formName, data));
    return () => { };
  }, []);

  const updateHoliday = async (date, newData, isNew) => {
    const dt = await dispatch(updateHolidayDefault(date, newData));
    dispatch(initialize(formName, dt?.holidays ?? {}));
    if (isNew) {
      setAddingNew(false);
    }
  };

  const onDefaultChanged = async (date, field, val) => {
    const defData = { ...formData[date] };
    defData[field] = val;
    const errors = validate({ [date]: defData });
    if (_.isEmpty(errors)) {
      await updateHoliday(date, defData);
    }
  };

  const onSaveNew = async (id) => {
    const newData = formData?.[id];
    const { date } = newData;
    delete newData.date;
    const errors = validate({ [date]: { ...newData } });
    if (_.isEmpty(errors)) {
      const success = await updateHoliday(date, newData, true);
      if (success) {
        setAddingNew(false);
        dispatch(change(formName, 'new', undefined));
      }
    }
  };

  const handleRequestDelete = (id) => {
    if (addingNew) {
      setAddingNew(false);
      dispatch(change(formName, 'new', undefined));
    } else {
      const modalName = 'CONFIRM_DELETE_DEFAULT';
      dispatch(showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'confirm',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'DELETE HOLIDAY',
          content: <DeleteConfirmAlert itemTypeName="holiday" />,
          onConfirm: () => {
            dispatch(hideModal(modalName));
            dispatch(deleteHolidayDefault(id));
          },
        },
      }));
    }
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt) => (
    <Typography
      style={{}}
    >
      {txt}
    </Typography>
  );

  const labelInput = (id, isNew) => (
    <Field
      name={`${id}.label`}
      component={MDTextInputField}
      noHelperText
      noErrorLabel
      withEnterTrigger
      label="Holiday Name"
      required
      type="text"
      size="small"
      margin="none"
      style={{ margin: 0, padding: 0 }}
      onHandleChange={((v) => {
        if (isNew) setHolidayName(v);
        else onDefaultChanged(id, 'label', v);
      })}
    />
  );

  const datePicker = (id) => (
    <Field
      className={classes.picker}
      id={`${id}.date`}
      name={`${id}.date`}
      component={MDTextInputField}
      type="date"
      size="small"
      variant="outlined"
      placeholder=""
      required
      autoComplete="date"
      noHelperText
      noErrorLabel
      inputProps={{ min: moment(Date.now()).set('year', 2000).format(DATE_PICKER_FOMAT) }}
      formatDate={() => {
        const newData = formData?.[id];
        const { date } = newData;
        let d = moment(date).format(DATE_PICKER_FOMAT);
        const parsed = Date.parse(d);

        if (d && (Number.isNaN(parsed) || parsed < 0)) {
          return d;
        }

        const splitDate = d.split('-');
        if (splitDate.length < 3) return d;

        if (splitDate[0].length > 4) {
          d = `${splitDate[0].substring(0, 4)}-${splitDate[1]}-${splitDate[2]}`;
        }

        return moment(d).format(DATE_PICKER_FOMAT);
      }}
    />
  );

  const holidayEntry = (id, isNew) => container({
    style: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    spacing: 1,
    children: [
      item({ flex: isNew ? 'auto' : 1, children: isNew ? datePicker(id) : text(moment(id).format(DATE_SHORT_FORMAT)) }),
      item({ flex: 3, children: labelInput(id, isNew) }),
      item({ flex: 3, children: [isNew && saveDefaultBtn(id), deleteDefaultBtn(id)] }),
    ],
  });

  const deleteDefaultBtn = (id) => (
    <IconButton
      onClick={() => handleRequestDelete(id)}
    >
      <ClearIcon />
    </IconButton>
  );

  const saveDefaultBtn = (id) => (
    <StyledButton
      color="primary"
      variant="contained"
      handleButton={_.isEmpty(validate({ [id]: formData?.[id] })) ? () => onSaveNew(id) : null}
      buttonContent="SAVE"
      disabled={!_.isEmpty(validate({ [id]: addingNew ? { ...formData?.[id], label: holidayName } : formData?.[id] }))}
    />
  );

  const addDefaultBtn = () => (
    <StyledButton
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      buttonContent="ADD"
      handleButton={() => {
        dispatch(change(formName, 'new', initialState));
        setAddingNew(true);
      }}
    />
  );

  return (
    <SettingsTile
      title="Holidays"
    >
      {Object.keys(data).map((dateKey) => holidayEntry(dateKey))}
      {addingNew ? holidayEntry('new', true) : addDefaultBtn()}
    </SettingsTile>
  );
};

export default _.flow([
  connect((state) => ({
    data: holidaysSelector(state),
    formData: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
])(HolidaysDefault);
