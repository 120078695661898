/* eslint-disable max-len */
import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';

const searchOptions = {
  filter: true,
  filterType: 'dropdown',
  selectableRows: 'none',
  sortOrder: {
    name: 'Title',
    direction: 'asc',
  },
  downloadOptions: {
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
};

// const boolIcon = (value) => (value ? <CheckIcon color="primary" /> : <ClearIcon color="secondary" />);

const columns = [
  {
    name: 'Title',
  },
  {
    name: 'Status',
    options: {
      filter: true,
    },
  },
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
];

const CertificationsTable = ({ certifications }) => {
  const history = useHistory();

  const editButton = (id) => (
    <IconButton
      aria-label="edit"
      onClick={() => history.push(`/admin/certifications/${id}`)}
    >
      <EditIcon />
    </IconButton>
  );

  const rows = _.map(certifications, (certification) => {
    const activeDisplay = certification.enabled ? 'Active' : 'Inactive';
    return [
      certification.title,
      activeDisplay,
      editButton(certification.id),
    ];
  });

  return (
    <FASMUIDataTable
      data={rows}
      columns={columns}
      options={searchOptions}
      root={{
        padding: '5px 15px 5px',
        '&:nth-child(1)': {
          width: '45%',
        },
        '&:nth-child(2)': {
          width: '45%',
        },
        '&:nth-child(3)': {
          width: '10%',
        },
        '&:nth-child(4)': {
          width: '15%',
        },
        '&:nth-child(5)': {
          width: '10%',
        },
        '&:nth-child(6)': {
          width: '10%',
        },
      }}
    />
  );
};

export default CertificationsTable;
