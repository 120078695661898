import React from 'react';
import StyledButton from '../Button/StyledButton';

const ActionsFooter = ({ actionTitle, onClick }) => (
  <div style={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 90,
    zIndex: 1,
    backgroundColor: 'white',
    textAlign: 'end',
    padding: 20,
    boxShadow: '0px 0px 4px 2px rgba(0,0,0,.2)',
  }}
  >
    <StyledButton
      handleButton={onClick}
      variant="contained"
      color="primary"
      buttonContent={actionTitle}
    />
  </div>
);

export default ActionsFooter;
