/* eslint-disable max-len */
import React from 'react';
import { Schedule } from '@material-ui/icons';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { cancelOrder, fetchOrderSummary } from '../../redux/actions/orders.actions';
import CancelOrderAlert from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/Alerts/CancelOrderAlert/CancelOrderAlert';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import StyledButton from './StyledButton';
import { cleanRows } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import { getCancelPrice } from '../../utils/event.utils';
import { listingsSelector } from '../../redux/selectors/sap.selectors';
import SimpleText from '../Text/SimpleText';
import { orderMaterialExclusionCheck } from '../../utils/helpers';

const CancelOrderButton = ({
  orderId, curEvent, listings, onCancelled, clickCallback,
}) => {
  const dispatch = useDispatch();

  const onSubmitCancelOrder = async (eventId, isWaived, cost, currency, reason, notes, noTask) => {
    try {
      const success = await dispatch(
        cancelOrder(
          orderId,
          eventId,
          {
            amount: Number(cost && !isWaived ? cost : 0.0),
            isWaived,
            currency,
            reason,
            notes,
            option: 'cancellationFee',
          },
          (cost ?? 0.0),
          noTask,
        ),
      );
      if (success) {
        cleanRows();
        dispatch(hideModal('CANCEL_ORDER_ALERT'));
        dispatch(hideModal('EDIT_EVENT_DIALOG'));
        dispatch(hideModal('SUBC_CANCEL_WARNING'));
        dispatch(showModal('CANCEL_EVENT_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: 'Successfully Cancelled Order.',
          },
        }));
        onCancelled?.call();
      } else {
        throw Error();
      }
    } catch (error) {
      const errMessage = 'Error Cancelling Event. Please contact an Admin';
      dispatch(showModal('CANCEL_EVENT_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { errMessage },
      }));
    }
  };

  const showSubcontractorCancellationWarning = (ord) => {
    const modalName = 'SUBC_CANCEL_WARNING';
    dispatch(showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'xs',
        title: 'CANCEL ORDER',
        confirmText: 'CANCEL ORDER',
        content: <SimpleText divStyle={{ margin: 12 }} txt={'Subcontracted events will not automatically invoice any cancellation fees through Training Central.\nIf a cancellation fee applies, it must be invoiced locally in SAP.'} />,
        onConfirm: () => onSubmitCancelOrder(curEvent?.id, false, 0, ord?.events?.[0]?.price?.currency, null, null, true),
      },
    }));
  };

  const showCancelOrderAlert = async () => {
    if (clickCallback) {
      const proceed = await clickCallback();
      if (!proceed) return;
    }
    const curOrder = orderId && await dispatch(fetchOrderSummary(orderId));
    if (curOrder?.subcontracted) {
      showSubcontractorCancellationWarning(curOrder);
      return;
    }
    const currency = curOrder && curOrder.events && curOrder.events.length > 0 ? (curOrder.events[0]?.price?.currency ?? '') : '';
    const cancelCost = await getCancelPrice(curOrder?.events ?? {}, curOrder?.customerObj, dispatch, listings);
    const isSigned = curOrder?.signature ?? false;
    const hasExcludedMaterial = orderMaterialExclusionCheck(curOrder ?? {}, listings);
    const modalName = 'CANCEL_ORDER_ALERT';

    await dispatch(showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'CANCEL ORDER',
        content: <CancelOrderAlert
          confirmText="Cancel Order"
          modalName={modalName}
          cancelCost={cancelCost}
          curEvent={curEvent}
          isSigned={isSigned}
          hasExcludedMaterial={hasExcludedMaterial}
          onSubmit={(isWeived, reason, notes, systemForced) => onSubmitCancelOrder(curEvent?.id, isWeived, cancelCost, currency, reason, notes, systemForced)}
        />,
      },
    }));
  };

  return (
    <StyledButton
      variant="contained"
      color="primary"
      width="160px"
      onClick={showCancelOrderAlert}
      buttonContent="Cancel"
      startIcon={<Schedule />}
    />
  );
};

export default _.flow([
  connect((state) => ({
    listings: listingsSelector(state),
  })),
])(CancelOrderButton);
