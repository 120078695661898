/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import { ENTER_KEY_CODE } from '../../../../utils/consts';
import EditBoxButton from '../../../Calendar/CalendarComponents/EventDetailsDialog/DialogSubcomponents/EditBoxButton';
import RowCell from '../../../LayoutBuilders/RowCell';
import RowLayout from '../../../LayoutBuilders/RowLayout';
import SimpleText from '../../../Text/SimpleText';
import MDTextInputField from '../MDTextInput/MDTextInputField';

const EditableLabel = ({
  id,
  key,
  path,
  type,
  disableSave,
  onSave,
  onChange,
  values,
  disabled,
  allowEditing,
  placeholder,
  required,
  width,
  label,
  onBlur,
  onEnter,
  defaultVal,
  noHelperText,
  textStyle,
  inputTextStyle,
  inputProps,
  multiline,
  multilineRows,
  inputSize,
  labelFlex,
  inputFlex,
  spacing,
  borderRadius,
  onEditingChanged,
  charLimit,
  getInputField,
  validate,
  saveButton,
  hideEditIcon,
  initiallyEditing,
  ...fieldRest
}) => {
  const [editing, setEditing] = React.useState(initiallyEditing);
  const value = _.get((values ?? {}), path) ?? defaultVal;

  const onEditing = async () => {
    const proceed = () => {
      if (onEditingChanged) onEditingChanged(!editing);
      if (editing) {
        onSave(value);
      }
      setEditing(!editing);
    };

    if (!validate || !editing) {
      proceed();
    } else if (validate(value)) {
      proceed();
    }
  };

  // Components builders:
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} style={textStyle} />;
  const row = ({
    children, withPadding = false, style, k, ...rest
  }) => (
    <RowLayout key={k} {...rest} style={{ paddingTop: (withPadding ? 14 : 0), ...(style ?? {}) }}>
      {children}
    </RowLayout>
  );

  const rowCell = ({
    child, flex, k, ...rest
  }) => (
    <RowCell key={k} flex={flex} {...(rest ?? {})}>{child}</RowCell>
  );

  const inputField = () => (
    <Field
      {...fieldRest}
      id={id}
      name={id}
      component={MDTextInputField}
      size={inputSize ?? 'small'}
      type={type ?? 'text'}
      disabled={disabled}
      inputProps={{
        width: (width ?? '100%'),
        maxLength: (charLimit ?? Infinity),
        style: {
          textAlign: 'start', borderRadius, marginLeft: 5, ...((inputTextStyle ?? textStyle) ?? {}),
        },
        ...(inputProps ?? {}),
      }}
      multiline={multiline}
      rows={multilineRows}
      variant="outlined"
      required={required}
      label={label}
      noBorderRadius
      noHelperText={noHelperText}
      style={{ width: (width ?? '100%') }}
      placeholder={placeholder}
      onChange={onChange}
      onBlurCapture={() => (onBlur ? onBlur() : null)}
      onKeyDown={(k) => (k.code === ENTER_KEY_CODE && onEnter ? onEnter() : null)}
    />
  );

  const getLabelValue = () => {
    const normalized = (value ?? '').trim();
    if (normalized) {
      return normalized;
    }

    return (defaultVal ?? 'N/A');
  };

  const leftFlexing = (editing || !labelFlex) ? (editing && (inputFlex ?? 'auto')) : labelFlex;

  return row({
    key,
    style: { justifyContent: 'start' },
    spacing: (spacing ?? 1),
    children: [
      rowCell({
        key: 3,
        flex: (editing || !labelFlex) ? (editing && (inputFlex ?? 'auto')) : labelFlex,
        child: editing ? (getInputField ? getInputField() : inputField()) : text(getLabelValue()),
      }),
      allowEditing && rowCell({
        key: 4,
        flex: (leftFlexing ? 12 - leftFlexing : 'auto'),
        child: <EditBoxButton
          size={editing ? 40 : (textStyle?.fontSize ?? 20)}
          noBorder={!editing}
          borderRadius={borderRadius}
          isEditing={editing}
          disableSave={disableSave}
          onClick={onEditing}
          saveButton={saveButton}
          hideEditIcon={hideEditIcon}
        />,
      }),
    ],
  });
};

export default EditableLabel;
