/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { Divider } from '@material-ui/core';
import React from 'react';
import AddonTable from '../../routes/MainViews/EventSummaryView/AddOnTable';
import EventOverview from '../Calendar/CalendarComponents/EventDetailsDialog/DialogSubcomponents/EventOverview';
import { useStyles } from '../../routes/MainViews/EventSummaryView/EventSummaryView';

const OrderSummary = ({
  order, showOrderId,
  customerFacing, allowEditing,
  showMaterialNumbers, history,
  dispatch, formName, isBilling,
  isQuote, tzOverride,
}) => {
  const classes = useStyles();

  const divider = (color) => <Divider style={{ margin: '30px 0px', background: color }} />;
  const contact = order.contact
    ? order.contact
    : (order.temporaryContact
      ? { ...order.temporaryContact, isTemporary: true }
      : {});

  return (
    <>
      <EventOverview
        hideCourses
        hideStatus
        hideNotes
        hideInstructor
        isQuote={isQuote}
        alreadyLoaded
        showOrderId={showOrderId}
        order={order}
        contact={contact}
        history={history}
        dispatch={dispatch}
        isBilling={isBilling}
        customerFacing={customerFacing}
        tzOverride={tzOverride}
      />
      {divider()}
      {
        order.events.map((e, i, arr) => (
          <AddonTable
            i={i}
            isQuote={isQuote}
            classes={classes}
            formName={formName}
            allowEditing={allowEditing && !isQuote}
            showInstructor={!isQuote}
            showMaterialNumbers={showMaterialNumbers}
            event={e}
            customer={order.customerObj}
            dispatch={dispatch}
            customerFacing={customerFacing}
            withDivider={i < (arr.length - 1)}
            tzOverride={tzOverride}
            isBilling={isBilling}
          />
        ))
      }
    </>
  );
};

export default OrderSummary;
