import { Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modals.actions';
import { COLORS } from '../../utils/consts';
import EventDetailsDialog from '../Calendar/CalendarComponents/EventDetailsDialog/EventDetailsDialog';

const EventSummaryButton = ({
  orderId, marginLeft, clickCallback, tzOverride, hideRepeatBookingButton, fontSize,
}) => {
  const dispatch = useDispatch();

  const showEventDetailsDialog = () => {
    const modalName = 'EVENT_DETAILS_DIALOG';
    dispatch(showModal(modalName, {
      modalType: 'FAS_EVENT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'EVENT DETAILS',
        content: <EventDetailsDialog
          modalName={modalName}
          dispatch={dispatch}
          orderId={orderId}
          tzOverride={tzOverride}
          hideRepeatBookingButton={hideRepeatBookingButton}
        />,
      },
    }));

    if (clickCallback) clickCallback();
  };

  return (
    <Typography
      variant="outlined"
      style={{
        font: 'Proxima Nova',
        marginLeft,
        color: COLORS.CINTAS_BLUE,
        fontWeight: 'bold',
        fontSize: fontSize ?? '1.2em',
        cursor: 'pointer',
      }}
      onClick={showEventDetailsDialog}
    >
      Event Summary
    </Typography>
  );
};

export default EventSummaryButton;
