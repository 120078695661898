/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';

import {
  Field,
  reduxForm,
  submit,
  getFormValues,
} from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SelectedDayEvents from './FormComponent/SelectedDayEvents';
import { removeLeadingZeros } from '../../../../../utils/helpers';
import { EVENT_TYPE } from '../../../../../utils/consts';
import { reportingTciById, selectedTciSelector } from '../../../../../redux/selectors/user.selectors';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    fontWeight: 'bold',
    color: '#000000',
    textAlign: 'start',
  },
  body: {
    color: '#404040',
    textAlign: 'start',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
});
const EventSummaryForm = ({
  activeStep, curEvent, mode, presetDate,
}) => {
  const classes = useStyles();
  const contact = curEvent.customerContact ?? (curEvent.temporaryContact ?? {});
  const selectedTci = useSelector((state) => ((curEvent.instructorID && reportingTciById(state, curEvent.instructorID)) ? reportingTciById(state, curEvent.instructorID) : selectedTciSelector(state)));
  const secondaryTci = useSelector((state) => (!curEvent?.secondaryTci ? null : reportingTciById(state, curEvent?.secondaryTci)));
  const getCustomerZREG = () => _.startCase(_.toLower(curEvent?.customer?.regional_customer ?? '')) ?? '';
  const getCustomerZNAT = () => _.startCase(_.toLower(curEvent?.customer?.national_customer ?? '')) ?? '';
  const getCustomerZLOC = () => _.startCase(_.toLower(curEvent?.customer?.Local_Customer ?? '')) ?? '';

  const zLocationDisplay = () => {
    const localCustomer = curEvent?.customer?.Local_Customer ?? '';
    const regionalCustomer = curEvent?.customer?.regional_customer ?? '';
    const nationalCustomer = curEvent?.customer?.national_customer ?? '';

    return (
      <>
        {localCustomer && (<Typography className={classes.body} variant="body2" color="textSecondary" component="p" gutterBottom>{`\nZLOC: ${getCustomerZLOC()}`}</Typography>)}
        {regionalCustomer && (<Typography className={classes.body} variant="body2" color="textSecondary" component="p" gutterBottom>{`\nZREG: ${getCustomerZREG()}`}</Typography>)}
        {nationalCustomer && (<Typography className={classes.body} variant="body2" color="textSecondary" component="p" gutterBottom>{`\nZNAT: ${getCustomerZNAT()}`}</Typography>)}
      </>
    );
  };

  const entry = (label, value) => (
    <div>
      <Typography className={classes.title} variant="body2" color="textSecondary" component="p">{label}</Typography>
      <Typography className={classes.body} variant="body2" color="textSecondary" component="p" gutterBottom>{value}</Typography>
    </div>
  );

  const formatName = (txt) => _.startCase((txt ?? '').toLowerCase());

  return (
    <Grid item xs={12}>
      {activeStep === 0 && (
        <Grid item xs={12}>
          <Field
            id="selectedDayEvents"
            name="selectedDayEvents"
            component={SelectedDayEvents}
            selectedDay={curEvent?.date ?? presetDate?.start}
            selectedEnd={curEvent && curEvent.allDay && curEvent.endDate}
            label="Agenda"
            selectedEvent={curEvent}
            mode={mode}
          // value={eventConflict}
          />
        </Grid>
      )}
      {activeStep === 1 && (
        <Grid item xs={12}>
          {[EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(curEvent.eventType) && (
            <>
              {[EVENT_TYPE.ON_SITE].includes(curEvent.eventType) ? [
                entry('Event Type', 'On Site'),
                selectedTci && entry('Primary Instructor', formatName(`${selectedTci?.first_name} ${selectedTci?.last_name}`)),
                secondaryTci && entry('Secondary Instructor', formatName(`${secondaryTci?.first_name} ${secondaryTci?.last_name}`)),
              ] : []}
              {entry('Customer', `${removeLeadingZeros(curEvent.customer?.sold_to)}  ${curEvent.customer?.name}`)}
              {zLocationDisplay()}
              {entry('Customer Contact', `${_.capitalize(contact.FirstName)} ${_.capitalize(contact.LastName)}`)}
              {entry(null, `${_.toLower(contact.Email)}`)}
              {entry('Courses')}
              {_.map(curEvent.courses, (onSiteCourse, i) => (
                <React.Fragment key={`${onSiteCourse.course.title}${i}`}>
                  {entry(null, onSiteCourse.course.title)}
                  {entry(null, `${onSiteCourse.participants} ${onSiteCourse.participants > 1 ? 'participants' : 'participant'} ${onSiteCourse.priceLevel?.name ? `- ${onSiteCourse.priceLevel?.name}` : ''}`)}
                  {onSiteCourse.additional_participants && onSiteCourse.additional_participants?.[0]?.qty > 0 && entry(null, `${onSiteCourse.additional_participants?.[0]?.qty} additional ${onSiteCourse.additional_participants?.[0]?.qty > 1 ? 'participants' : 'participant'}`)}
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

// export default EventSummaryForm;
export default _.flow([
  connect((state) => {
    const curEvent = getFormValues('AddEventDialog')(state);
    // const selectedDay = curEvent && curEvent.date;
    return {
      curEvent,
      //   selectedDay,
    };
  }),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
    },
    onSubmit: submit,
  }),
])(EventSummaryForm);
