import React from 'react';
import Typography from '@material-ui/core/Typography';

const ConfirmSeriesAlert = ({ fontSize }) => (
  <Typography
    color="textSecondary"
    style={{
      verticalAlign: 'middle', padding: '20px', fontSize: fontSize ?? 18, color: 'black',
    }}
  >
    Do you want to apply the changes to only
    <br />
    this event or all the events in this series?
  </Typography>
);

export default ConfirmSeriesAlert;
