/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
import {
  Field, change, reduxForm,
  submit, getFormValues,
} from 'redux-form';
import _ from 'lodash';
import { Divider, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, connect } from 'react-redux';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import ArrowRightAltSharp from '@material-ui/icons/ArrowRightAltSharp';
import SimpleText from '../../../../Text/SimpleText';
import { calcCLevels, ccyFormat } from '../../../../../utils/helpers';
import { BELOW_MIN_PRICE_CL, BOOKING_MODE, COLORS, ENTER_KEY_CODE, EVENT_TYPE, MATERIAL_TYPE } from '../../../../../utils/consts';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import FASCheckboxField from '../../../../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import StyledButton from '../../../../Button/StyledButton';
import CenteredContainer from '../../../../LayoutBuilders/CenteredContainer';
import RefreshPriceBtn from '../../../../../routes/MainViews/EventSummaryView/RefreshPriceBtn';
import { materialsDataSelector } from '../../../../../redux/selectors/materials.selector';
import { makeDayDiscountCalculator } from '../../../../../utils/event.utils';

const inputHeight = 40;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal',
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: COLORS.CINTAS_ORANGE,
    border: 'hidden',
    color: 'white',
    fontSize: 12,
    whiteSpace: 'nowrap',
    borderRadius: '0px',
    fontFamily: 'proxima-nova, sans-serif',
    '&:hover': {
      background: COLORS.CINTAS_ORANGE,
    },
  },
  formControl: {
    width: '100%',
  },
  chips: {
    borderRadius: '0px',
    margin: '1px',
    color: 'white',
    minWidth: '40px',
    fontWeight: 'bold',
  },
  level: {
    display: 'inline-block',
    fontSize: '12px',
  },
  blueText: {
    color: COLORS.CINTAS_BLUE,
    fontSize: 14,
  },
}));

const CoursePrice = (props) => {
  const classes = useStyles();
  const {
    member, i, idx, dispatch, curEvent, hideDivider, mode, isSkillcheck, canEdit,
  } = props;
  const materialsInfo = useSelector(materialsDataSelector);
  // eslint-disable-next-line no-unused-vars
  const isOnSite = curEvent.eventType === EVENT_TYPE.ON_SITE || isSkillcheck || mode === BOOKING_MODE.quote;
  const marketPrice = curEvent.courses && i < curEvent.courses.length && (isSkillcheck ? (curEvent.courses ?? [])[i]?.skillcheckPrice?.marketPrice : curEvent.courses[i].marketPrice);
  const customerPriceVal = curEvent.courses && (isSkillcheck ? (curEvent.courses ?? [])[i]?.skillcheckPrice : curEvent.courses[i])?.customerPrice;
  const hasMarketPrice = curEvent.courses && curEvent.courses[i] && marketPrice;
  const { isMakeDayDiscount, adjustedPrice, makeDayPercentage } = (curEvent.courses && curEvent.courses[i] && !isSkillcheck) ? makeDayDiscountCalculator(curEvent.courses[i], curEvent.courses?.length ?? 1, curEvent.customer) : {};
  const commissionPercentages = useSelector((state) => state.cl.data.levels);
  const commissionLevels = isOnSite && calcCLevels(commissionPercentages, marketPrice);
  const minCommissionLevel = commissionLevels?.[commissionLevels?.length - 1] ?? {};
  const disabledCustomerPrice = curEvent.courses && curEvent.courses[i] && (isSkillcheck ? curEvent.courses[i].skillcheckPrice : curEvent.courses[i])?.disableCustomerPrice;
  const minCLName = isOnSite && commissionLevels && minCommissionLevel.name;
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedLevel, setSelectedLevels] = useState(
    isOnSite
    && commissionLevels && commissionLevels[0].name,
  );
  // eslint-disable-next-line no-nested-ternary
  const materialNum = curEvent?.courses && i < curEvent.courses.length
    ? (isSkillcheck
      ? (materialsInfo[MATERIAL_TYPE.SKILL_CHECK][curEvent.courses[i].skillcheck?.id] ?? {}).code
      : curEvent.courses[i].course?.code)
    : '';
  const material = Object.values(materialsInfo[isSkillcheck ? MATERIAL_TYPE.SKILL_CHECK : MATERIAL_TYPE.COURSE]).find((item) => materialNum === item.code);
  const listingsPath = `${member}.priceListings`;

  const renderFromHelper = ({ touched, error }) => {
    if (touched || error) {
      return <FormHelperText>{touched && error}</FormHelperText>;
    }
    return '';
  };

  const priceToLevel = (price) => {
    if (!commissionLevels) {
      return;
    }
    if (price < Number(minCommissionLevel.min)) {
      return BELOW_MIN_PRICE_CL;
    }
    if (price >= Number(commissionLevels[0].min)) {
      return commissionLevels[0];
    }
    const cl = _.find(commissionLevels,
      (level) => price >= Number(level.min) && price <= Number(level.max));
    return cl ?? minCommissionLevel;
  };

  const onPriceChange = (price) => {
    const level = priceToLevel(price);
    setActiveIndex(level.id);
    setSelectedLevels(level.name);
    dispatch(change('AddEventDialog', `${member}.priceLevel`, level));
  };

  React.useEffect(() => {
    if (curEvent.courses && i < curEvent.courses.length && customerPriceVal) {
      onPriceChange(Number(customerPriceVal));
    }
    return () => { };
  }, [(curEvent.courses && i < curEvent.courses.length ? customerPriceVal : 0), disabledCustomerPrice]);

  const onEnterCustomerPrice = () => {
    if (disabledCustomerPrice || activeIndex === BELOW_MIN_PRICE_CL.id) return;
    commissionLevels.sort((a, b) => a.id > b.id);
    let minLevel = parseFloat(minCommissionLevel.min);
    minLevel = Number.isNaN(minLevel) ? 0 : minLevel;
    if (minLevel > (Number(customerPriceVal ?? 0))) {
      dispatch(change('AddEventDialog', `${member}.customerPrice`, minLevel));
    }
  };

  // eslint-disable-next-line no-unused-vars
  const ChipGroup = ({
    input, label, meta: { touched, error }, ...rest
  }) => {
    const addLevel = () => {
      if (activeIndex >= 0 && activeIndex < commissionPercentages.length) {
        const curLevel = priceToLevel(Number(customerPriceVal ?? 0));
        setActiveIndex(curLevel.id - 1);
        setSelectedLevels(commissionLevels[curLevel.id - 1].name);
        input.onChange(commissionLevels[curLevel.id - 1].name);
        input.onBlur(commissionLevels[curLevel.id - 1].name);
        dispatch(change('AddEventDialog', `${member}.customerPrice`, commissionLevels[curLevel.id - 1].min));
        onPriceChange(commissionLevels[curLevel.id - 1].min);
      } else if (activeIndex === -1) {
        const curLevel = minCommissionLevel;
        setActiveIndex(curLevel.id);
        setSelectedLevels(curLevel.name);
        input.onChange(curLevel.name);
        input.onBlur(curLevel.name);
        dispatch(change('AddEventDialog', `${member}.customerPrice`, curLevel.min));
        onPriceChange(curLevel.min);
      }
    };

    return (
      <>
        <FormControl
          error={!!(touched && error)}
          variant="outlined"
          className={classes.formControl}
        >
          <Grid container spacing={0} style={{ alignItems: 'center', paddingBottom: 10 }}>
            <Grid item xs={6}>
              {commissionLevels && commissionLevels.map((level, index) => (
                <div key={level.id}>
                  <Chip
                    size="small"
                    label={level.name}
                    name={level.name}
                    id={level.id}
                    {...rest}
                    className={classes.chips}
                    style={{
                      backgroundColor: index === activeIndex ? '#012169' : '#DEDEDE',
                      color: index === activeIndex ? 'white' : 'black',
                    }}
                    clickable={false}
                  />
                  {index !== 0
                    ? <div className={classes.level}>{`-  $${level.min} - $${level.max}`}</div>
                    : <div className={classes.level}>{`-  $${level.min} +`}</div>}
                  <br />
                </div>
              ))}
            </Grid>
            {!disabledCustomerPrice
              && (
                <Grid item xs={4}>
                  <StyledButton
                    variant="contained"
                    buttonContent="NEXT LEVEL"
                    className={classes.button}
                    handleButton={addLevel}
                    disabled={
                      (activeIndex === 0) || !canEdit
                    }
                    startIcon={<ArrowUpwardIcon />}
                  />
                </Grid>
              )}
          </Grid>
          {renderFromHelper({ touched, error })}
        </FormControl>
      </>
    );
  };

  const grid = ({ children, ...rest }) => <Grid {...(rest ?? {})}>{children}</Grid>;
  const container = ({ children, ...rest }) => grid({ children, container: true, ...(rest ?? {}) });
  const item = ({ children, ...rest }) => grid({ children, item: true, ...(rest ?? {}) });
  const title = (txt, rest) => <FASFieldTitle title={txt} {...(rest ?? {})} />;
  const text = (txt, fontSize, ...rest) => (
    <SimpleText
      txt={txt}
      fontSize={fontSize ?? 16}
      {...(rest ?? {})}
    />
  );

  const textField = (txt, rest) => (
    <CenteredContainer {...(rest ?? {})} height={inputHeight}>
      {text(txt)}
    </CenteredContainer>
  );

  const makeDayDiscountSubtitle = () => subheader(
    'Subtotal with Make It A Day discount: ',
    ccyFormat(adjustedPrice, curEvent?.courses[i]?.currency),
    true,
  );

  const makeDayDiscountPercentageSubtitle = () => subheader(
    'Make It A Day discount: ',
    `${makeDayPercentage}%`,
    true,
  );

  const marketPriceField = () => textField(ccyFormat(marketPrice ?? 0, curEvent?.courses[i]?.currency, true), { verticalOnly: true });

  const customerPriceField = () => (
    <Field
      id={`customerPrice${i}`}
      name={`${member}.customerPrice`}
      component={MDTextInputField}
      noBorderRadius
      disabled={(disabledCustomerPrice || !curEvent.courses[i].course) || !canEdit}
      size="small"
      type="number"
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      key={`field${idx}`}
      required
      min={Number(`${minCommissionLevel.min ?? 0}`)}
      onHandleChange={(value) => onPriceChange(value)}
      onKeyDown={(k) => (k.code === ENTER_KEY_CODE ? onEnterCustomerPrice() : null)}
      // onBlurCapture={() => onEnterCustomerPrice()}
      autoComplete="customerPrice"
    />
  );

  const subheader = (txt, value, ignoreMargin) => (
    <div style={{ fontWeight: 'bold', marginBottom: ignoreMargin ? 0 : 6 }}>
      <span style={{ fontWeight: 'normal' }}>{txt}</span>
      <span style={{ fontWeight: 'bold' }}>{value}</span>
    </div>
  );

  const currentPriceLevel = () => (
    <Field
      id={`priceLevel${i}`}
      name={`${member}.priceLevel`}
      component={ChipGroup}
      required
      commissionLevels={commissionLevels}
    />
  );

  const updateCoursePrice = ({ amount }) => {
    dispatch(change('AddEventDialog', `${member}.customerPrice`, amount));
  };

  const refreshPriceBtn = () => (
    <RefreshPriceBtn
      formName="AddEventDialog"
      customer={curEvent.customer}
      classes={classes}
      listingsPath={listingsPath}
      materialNum={materialNum}
      updatePriceFn={updateCoursePrice}
      width={disabledCustomerPrice
        || !(curEvent.courses[i].course)
        || !marketPrice ? 100 : 80}
      height={40}
    />
  );

  const setCustomerPrice = (value) => {
    if (value) {
      updateCoursePrice({ amount: 0 });
    } else {
      updateCoursePrice({ amount: marketPrice });
    }
  };

  const waiveCostCheckbox = () => (
    <Field
      name={`${member}.waiveCost`}
      component={FASCheckboxField}
      label={`Change price to ${ccyFormat(0, curEvent?.courses[i]?.currency, true)}`}
      onChange={setCustomerPrice}
    />
  );

  const customerPrice = () => item({
    xs: 8,
    style: {
      paddingTop: 0,
    },
    children: [
      title('Customer Price'),
      item({
        style: { display: 'flex' },
        xs: disabledCustomerPrice
          || !(curEvent.courses[i].course)
          || !marketPrice ? 5 : 10,
        children: disabledCustomerPrice
          || !(curEvent.courses[i].course)
          || !marketPrice
          ? [textField(ccyFormat(Number(customerPriceVal ?? 0), curEvent?.courses[i]?.currency, true), { verticalOnly: true }), mode !== BOOKING_MODE.booking && refreshPriceBtn()]
          : [customerPriceField(), mode !== BOOKING_MODE.booking && customerPriceVal !== 0 && refreshPriceBtn()],
      }),
      material?.waiveCost && waiveCostCheckbox(),
      isMakeDayDiscount && makeDayDiscountSubtitle(),
      isMakeDayDiscount && makeDayDiscountPercentageSubtitle(),
      hasMarketPrice && (
        <>
          {subheader('Commission level: ', selectedLevel)}
          {item({ xs: 12, children: currentPriceLevel() })}
        </>
      ),
      (disabledCustomerPrice && activeIndex === -1) && text(`Existing Price is below ${minCLName}`, 14),
    ],
  });

  const arrow = () => item({
    xs: 1,
    style: { padding: 0, margin: 0, paddingTop: 42 },
    children: <CenteredContainer height={inputHeight}><ArrowRightAltSharp /></CenteredContainer>,
  });

  const divider = () => <Divider style={{ margin: '2px 12px' }} />;

  return (
    container({
      spacing: 3,
      children: [
        !hideDivider && item({ xs: 12, style: { padding: 0 }, children: divider() }),
        item({
          style: { margin: 0, paddingTop: 0 },
          children: [
            (!curEvent.eventType || isOnSite) && title('Market Price'),
            curEvent.eventType === EVENT_TYPE.OPEN_ENROLLMENT && title('Market Price (CL1)'),
            isOnSite && marketPriceField(),
          ],
        }),
        isOnSite && arrow(),
        isOnSite && customerPrice(),
      ],
    })
  );
};

// export default CoursePrice;
export default _.flow([
  connect((state) => {
    const curEvent = getFormValues('AddEventDialog')(state);
    return {
      curEvent,
    };
  }),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(CoursePrice);
