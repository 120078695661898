/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textStyle: {
    display: 'flex',
    fontSize: ({ fontSize }) => (fontSize || '1em'),
    color: ({ color }) => color,
    fontWeight: ({ fontWeight }) => fontWeight,
  },
  subtitleStyle: {
    display: 'flex',
    fontSize: ({ subtitleFontSize }) => (subtitleFontSize || '1em'),
    color: ({ color }) => color,
  },
}));

const SimpleText = ({
  txt, fontSize, fontWeight, lineBreak, withSubtitle, subtitleFontSize,
  subtitleStyle, divStyle, color, getLineProps, align, ...rest
}) => {
  const classes = useStyles({
    fontSize, fontWeight, subtitleFontSize, color,
  });
  const lines = (txt ?? '').split(lineBreak ?? '\n');

  return (
    <div style={divStyle}>
      {lines.map((line, i) => (
        <Typography
          key={`${i}-${line}`}
          align={align ?? 'left'}
          className={withSubtitle && i > 0 ? classes.subtitleStyle : classes.textStyle}
          component="p"
          style={withSubtitle && i > 0 ? subtitleStyle : null}
          {...rest}
          {...(getLineProps ? getLineProps(line, i) : {})}
        >
          {line}
        </Typography>
      ))}
    </div>
  );
};

export default SimpleText;
