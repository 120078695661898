/* eslint-disable no-nested-ternary */
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import NO_IMAGE_AVAILABLE from '../../../assets/images/no_image_available.png';

const FASImage = ({
  input, src, alt, material, height, ...rest
}) => (
  <img
    src={src || (input.value ? (material ? input.value : `${input.value}?i=${uuidv4()}`) : NO_IMAGE_AVAILABLE)}
    alt={alt ?? 'material'}
    style={{
      height: height ?? 200,
      objectFit: 'contain',
    }}
    {...rest}
  />
);

export default FASImage;
