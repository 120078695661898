import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import { connect } from 'react-redux';
// import _ from 'lodash';

const CancelOrderAlertContent = withStyles((theme) => ({
  root: {
    minWidth: 580,
    maxWidth: 580,
    minHeight: 450,
    // maxHeight: 400,
    textAlign: 'center',
    overflowY: 'none',
    [theme.breakpoints.down('lg')]: {
      minHeight: 450,
    },
  },
}))(MuiDialogContent);

const CancelOrderAlertWrapper = ({ children }) => (
  <CancelOrderAlertContent>
    {children}
  </CancelOrderAlertContent>
);

export default CancelOrderAlertWrapper;
