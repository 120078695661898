/* eslint-disable no-use-before-define */
import React from 'react';
import { useDispatch } from 'react-redux';
import NationalAccountDetails from '../../components/NationalAccountDashboard/NationalAccountDetails';
import NationalAccountSearch from '../../components/NationalAccountDashboard/NationalAccountSearch';
import SimpleText from '../../components/Text/SimpleText';
import { fetchCommissionLevels } from '../../redux/actions/commissionLevels.actions';
import { fetchMaterials } from '../../redux/actions/materials.actions';
import { fetchUnreadNotifications } from '../../redux/actions/notifications.actions';
import { fetchSAPMaterials } from '../../redux/actions/sap.actions';
import { COLORS } from '../../utils/consts';
import { DashboardStep } from './CustomersDashboardView';

const NationalAccountView = () => {
  const dispatch = useDispatch();
  const [nationalAccount, setNationalAccount] = React.useState();

  React.useEffect(() => {
    dispatch(fetchMaterials());
    dispatch(fetchSAPMaterials());
    dispatch(fetchCommissionLevels());
    dispatch(fetchUnreadNotifications(1));
    return () => { };
  }, []);

  const onAccountSelected = (nationalAccountData) => {
    if (!nationalAccountData) {
      setNationalAccount();
      prevStep();
    } else {
      setNationalAccount(nationalAccountData);
      nextStep();
    }
  };

  const STEPS = [
    new DashboardStep('Search Higher Level Account', (args) => <NationalAccountSearch {...args} />, { onAccountSelected }),
    new DashboardStep('National Account Details', (args) => <NationalAccountDetails {...args} />, { nationalAccount, onPrevStep: () => { prevStep(); setNationalAccount(); } }),
  ];
  const [currentStep, setCurrentStep] = React.useState(0);

  const nextStep = () => {
    if (currentStep === (STEPS.length - 1)) return;
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };

  const header = () => (
    <SimpleText
      txt="National Account Dashboard"
      fontSize={30}
      style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, paddingTop: 10 }}
    />
  );

  const stepsView = () => {
    const { label, widget, args } = STEPS[currentStep];
    return widget({ label, ...args });
  };

  return (
    <>
      {header()}
      {stepsView()}
    </>
  );
};

export default NationalAccountView;
