/* eslint-disable max-len */
import { IconButton } from '@material-ui/core';
import React from 'react';
import ArrowExpand from '@material-ui/icons/ExpandMore';
import ArrowCollapse from '@material-ui/icons/ChevronRight';
import FASFieldTitle from '../../../../components/Forms/CustomFormComponents/FASFieldTitle';
import Container from '../../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../../components/LayoutBuilders/ContainerItem';
import { COLORS } from '../../../../utils/consts';

const CollapsableHeader = ({
  title, actions, boundActionToContent, content, startOpen,
}) => {
  const [open, setOpen] = React.useState(Boolean(startOpen));
  const border = `1px solid ${COLORS.CINTAS_GRAY_OPAQUE_RGBA}`;
  const titleWidget = (txt) => <FASFieldTitle title={txt} style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, fontSize: 18 }} />;
  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const expansionIcon = () => (
    <IconButton
      color="primary"
      onClick={() => setOpen(!open)}
    >
      {open ? <ArrowExpand /> : <ArrowCollapse />}
    </IconButton>
  );

  const header = () => container({
    onClick: open ? null : () => setOpen(!open),
    style: {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 28,
      borderTop: border,
      borderBottom: open ? undefined : border,
      cursor: open ? null : 'pointer',
    },
    children: [
      item({
        flex: 8,
        children: [
          titleWidget(title ?? ''),
        ],
      }),
      item({
        flex: 4,
        children: [
          container({
            style: {
              justifyContent: 'flex-end', alignItems: 'center', margin: 0, padding: 0,
            },
            children: [...((boundActionToContent && open) || (!boundActionToContent) ? (actions ?? []) : []), expansionIcon()],
          }),
        ],
      }),
    ],
  });

  return (
    <>
      {header()}
      {open ? content : <></>}
    </>
  );
};

export default CollapsableHeader;
