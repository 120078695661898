/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {
  Field, submit, reduxForm, getFormValues, change,
} from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  MenuItem,
  Grid,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FASRadio from '../../../../Forms/CustomFormComponents/FASRadio';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import {
  BOOKING_MODE,
  DATE_NAME_FORMAT,
  DATE_PICKER_FOMAT,
  TIME_BLOCK_INTERVALS,
  TIME_BLOCK_REPETITION_RULES,
} from '../../../../../utils/consts';
import FASSelectField from '../../../../Forms/CustomFormComponents/FASSelectField';
import { timezoneSelector } from '../../../../../redux/selectors/utils.selectors';

const formName = 'AddEventDialog';
const fieldName = 'recurringSeries';
const weekDayNames = _.map(
  [...Array(7).keys()], (day) => moment().day(day).format(DATE_NAME_FORMAT),
);

const IntervalForm = ({
  interval,
  recurringSeries,
  date,
  allDay,
  bookingMode,
  endDate,
}) => {
  const dispatch = useDispatch();
  const tz = useSelector(timezoneSelector);
  const [days, setDays] = useState((interval === TIME_BLOCK_INTERVALS.WEEK && recurringSeries?.weekdays) ?? []);
  const [repetition, setRepetition] = useState(
    recurringSeries?.offset?.weekNumber ? TIME_BLOCK_INTERVALS.WEEK : TIME_BLOCK_INTERVALS.DAY,
  );

  useEffect(() => {
    if (bookingMode !== BOOKING_MODE.editing) dispatch(change(formName, `${fieldName}.startDate`, date ?? moment.tz(Date.now(), tz).format(DATE_PICKER_FOMAT)));
  }, []);

  useEffect(() => {
    if (bookingMode !== BOOKING_MODE.editing) {
      dispatch(change(formName, 'date', recurringSeries.startDate));
    }
    dispatch(change(formName, `${fieldName}.endDate`, moment.utc(recurringSeries.startDate).add(1, interval.toLowerCase()).format(DATE_PICKER_FOMAT)));
    if (allDay && !endDate) {
      dispatch(change(formName, 'endDate', recurringSeries.startDate));
    }
  }, [recurringSeries.startDate]);

  // update addEventDialod endDate when full day PTO, when changing recurring series endDate
  useEffect(() => {
    if (allDay) {
      dispatch(change(formName, 'endDate', recurringSeries.endDate));
    }
  }, [recurringSeries.endDate]);

  useEffect(() => {
    if (repetition && interval) {
      const offset = (interval !== TIME_BLOCK_INTERVALS.DAY && interval !== TIME_BLOCK_INTERVALS.WEEK) ? {
        ...(repetition === TIME_BLOCK_INTERVALS.DAY && { monthDate: recurringSeries?.offset?.monthDate ?? 1 }),
        ...(interval === TIME_BLOCK_INTERVALS.YEAR && { month: recurringSeries?.offset?.month ?? 1 }),
        ...(repetition === TIME_BLOCK_INTERVALS.WEEK && { dayNumber: recurringSeries?.offset?.dayNumber ?? 0 }),
        ...(repetition === TIME_BLOCK_INTERVALS.WEEK && { weekNumber: recurringSeries?.offset?.weekNumber ?? 1 }),
      } : undefined;
      dispatch(change(formName, `${fieldName}.offset`, offset));
    }
    if (interval !== TIME_BLOCK_INTERVALS.WEEK) dispatch(change(formName, `${fieldName}.weekdays`, undefined));
    if (interval !== TIME_BLOCK_INTERVALS.NO_REPEAT && bookingMode !== BOOKING_MODE.editing) {
      dispatch(change(formName, 'date', recurringSeries.startDate ?? date));
      dispatch(change(formName, `${fieldName}.endDate`, moment.utc(recurringSeries.startDate ?? date).add(1, interval.toLowerCase()).format(DATE_PICKER_FOMAT)));
    }
  }, [repetition, interval]);

  const handleWeekSelect = (values) => {
    setDays(values);
    dispatch(change(formName, `${fieldName}.weekdays`, values));
  };

  const dayOfWeekSelector = () => (
    <ToggleButtonGroup
      color="primary"
      size="small"
      id={`${fieldName}.weekdays`}
      name={`${fieldName}.weekdays`}
      value={days}
      onChange={(__, values) => handleWeekSelect(values)}
      style={{ padding: 10 }}
    >
      {_.map(weekDayNames, (day, idx) => (
        <ToggleButton value={idx}>
          {day.substring(0, 3)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );

  const monthSelector = (field, repeat) => (
    <Grid item xs="auto">
      <Field
        name={field}
        id={field}
        component={FASSelectField}
        noErrorLabel
        noBorderRadius
        disabled={repetition !== repeat}
        normalize={(v) => parseInt(v, 10)}
      >
        {_.map(moment.monthsShort(), (month, idx) => (
          <MenuItem key={month} value={idx + 1}>{month}</MenuItem>
        ))}
      </Field>
    </Grid>
  );

  const offsetForm = () => (
    <>
      <Grid container spacing={1}>
        {interval === TIME_BLOCK_INTERVALS.YEAR && monthSelector(`${fieldName}.offset.month`, TIME_BLOCK_INTERVALS.DAY)}
        <Grid item xs={interval === TIME_BLOCK_INTERVALS.YEAR ? 3 : 5}>
          <Field
            id={`${fieldName}.offset.monthDate`}
            name={`${fieldName}.offset.monthDate`}
            component={MDTextInputField}
            noErrorLabel
            type="number"
            size="small"
            min={1}
            disabled={repetition !== TIME_BLOCK_INTERVALS.DAY}
            required
            noBorderRadius
            normalize={(v) => parseInt(v, 10)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <Field
            name={`${fieldName}.offset.weekNumber`}
            id={`${fieldName}.offset.weekNumber`}
            component={FASSelectField}
            noErrorLabel
            noBorderRadius
            disabled={repetition !== TIME_BLOCK_INTERVALS.WEEK}
            normalize={(v) => parseInt(v, 10)}
          >
            {_.map(TIME_BLOCK_REPETITION_RULES, (value, key) => (
              <MenuItem key={key} value={key}>{value}</MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs="auto">
          <Field
            name={`${fieldName}.offset.dayNumber`}
            id={`${fieldName}.offset.dayNumber`}
            component={FASSelectField}
            noErrorLabel
            noBorderRadius
            disabled={repetition !== TIME_BLOCK_INTERVALS.WEEK}
            normalize={(v) => parseInt(v, 10)}
          >
            {_.map(weekDayNames, (day, idx) => (
              <MenuItem key={day} value={idx}>{day.substring(0, 3)}</MenuItem>
            ))}
          </Field>
        </Grid>
        {interval === TIME_BLOCK_INTERVALS.YEAR && (
          <>
            <Grid item>
              <FASFieldTitle title="of" />
            </Grid>
            <Grid item xs="auto">
              {monthSelector(`${fieldName}.offset.month`, TIME_BLOCK_INTERVALS.WEEK)}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );

  const offsetOptions = () => (
    <Grid container>
      <RadioGroup value={repetition} onChange={(e) => setRepetition(e.target.value)}>
        <FormControlLabel value={TIME_BLOCK_INTERVALS.DAY} control={<FASRadio />} label={interval === TIME_BLOCK_INTERVALS.YEAR ? 'On' : 'On day'} />
        <FormControlLabel value={TIME_BLOCK_INTERVALS.WEEK} control={<FASRadio />} label="On the" />
      </RadioGroup>
    </Grid>
  );

  const intervalForm = () => (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        {offsetOptions()}
      </Grid>
      <Grid item>
        {offsetForm()}
      </Grid>
    </Grid>
  );

  const renderInterval = () => {
    switch (interval) {
      case TIME_BLOCK_INTERVALS.WEEK:
        return dayOfWeekSelector();
      case TIME_BLOCK_INTERVALS.MONTH:
      case TIME_BLOCK_INTERVALS.YEAR:
        return intervalForm();
      default:
        return '';
    }
  };

  return (
    <>
      <Grid item>
        <FASFieldTitle title="Set recurrence" style={{ fontWeight: 'bold' }} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FASFieldTitle title="Start Date" />
        </Grid>
        <Grid item xs={4}>
          <Field
            id={`${fieldName}.startDate`}
            name={`${fieldName}.startDate`}
            component={MDTextInputField}
            inputProps={{
              min: moment.tz(Date.now(), tz).format(DATE_PICKER_FOMAT),
              max: moment.tz(Date.now(), tz).add(1, 'y').format(DATE_PICKER_FOMAT),
            }}
            noErrorLabel
            type="date"
            noBorderRadius
            size="small"
            required
            onChange={(v) => dispatch(change(formName, 'date', v))}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FASFieldTitle title="Repeat every" />
        </Grid>
        <Grid item xs={2}>
          <Field
            id={`${fieldName}.repeat`}
            name={`${fieldName}.repeat`}
            component={MDTextInputField}
            noErrorLabel
            type="number"
            size="small"
            min="1"
            required
            noBorderRadius
            normalize={(v) => parseInt(v, 10)}
          />
        </Grid>
        <Grid item xs={2}>
          <FASFieldTitle title={interval.charAt(0) + interval.slice(1).toLowerCase()} style={{ marginLeft: 5 }} />
        </Grid>
      </Grid>
      {renderInterval()}
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FASFieldTitle title="End Date" />
        </Grid>
        <Grid item xs={4}>
          <Field
            id={`${fieldName}.endDate`}
            name={`${fieldName}.endDate`}
            component={MDTextInputField}
            inputProps={{
              min: moment.utc(recurringSeries.startDate).add(1, interval.toLowerCase()).format(DATE_PICKER_FOMAT),
              max: moment.utc(recurringSeries.startDate).add(1, 'y').format(DATE_PICKER_FOMAT),
            }}
            noErrorLabel
            type="date"
            size="small"
            required
            noBorderRadius
          />
        </Grid>
      </Grid>
    </>
  );
};

export default _.flow([
  connect((state) => ({
    date: (getFormValues(formName)(state) ?? {}).date,
    allDay: (getFormValues(formName)(state) ?? {}).allDay,
    endTime: (getFormValues(formName)(state) ?? {}).endTime,
    recurringSeries: (getFormValues(formName)(state) ?? {}).recurringSeries,
    bookingMode: (getFormValues(formName)(state) ?? {}).bookingMode,
    endDate: (getFormValues(formName)(state) ?? {}).endDate,
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit, // a unique identifier for this form
  }),
])(IntervalForm);
