/* eslint-disable no-case-declarations */
import Immutable from 'seamless-immutable';

import {
  SAVE_EVENTS,
  RESET_EVENTS_STATE,
  ADD_EVENT,
  SAVE_UPCOMING_EVENTS,
  DELETE_TIMEBLOCK_EVENT,
  UPDATE_EVENT,
  DELETE_UPCOMING_TIMEBLOCK,
  UPDATE_UPCOMING_EVENT,
  SAVE_CANCELLED_EVENTS_COUNT,
  RESET_CANCELLED_EVENTS_COUNT,
} from '../actions/actionTypes/events';

const initialState = Immutable({
  data: {},
  upcoming: {},
  cancelledDailyCount: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_EVENTS:
      return state.set('data', action.events);
    case SAVE_UPCOMING_EVENTS:
      return state.set('upcoming', action.events);
    case UPDATE_EVENT:
      if (!action.event.id) return state;
      return state.setIn(
        ['data', (action.event.order ?? ''), action.event.id],
        action.event,
      );
    case UPDATE_UPCOMING_EVENT:
      if (!action.event.id) return state;
      return state.setIn(['upcoming', action.event.order ?? '', action.event.id], action.event);
    case RESET_EVENTS_STATE:
      return initialState;
    case ADD_EVENT:
      if (!action.payload.id) return state;
      return state.setIn(['data', action.payload.order ?? '', action.payload.id], action.payload);
    case DELETE_TIMEBLOCK_EVENT:
      const newTimeblocksData = { ...(state.data[''] ?? {}) };
      delete newTimeblocksData[action.id];
      return state.setIn(['data', ''], newTimeblocksData);
    case DELETE_UPCOMING_TIMEBLOCK:
      const newTimeblockData = { ...(state.upcoming[''] ?? {}) };
      delete newTimeblockData[action.id];
      return state.setIn(['upcoming', ''], newTimeblockData);
    case SAVE_CANCELLED_EVENTS_COUNT:
      return state.set('cancelledDailyCount', { ...state.cancelledDailyCount, ...action.payload });
    case RESET_CANCELLED_EVENTS_COUNT:
      return state.set('cancelledDailyCount', {});
    default:
      return state;
  }
};
