/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
// import AddToCalendar from 'react-add-to-calendar';
import { atcb_init } from 'add-to-calendar-button';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
// import '@culturehq/add-to-calendar/dist/styles.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { DATE_TIME_ISO, TIME_FORMAT } from '../../utils/consts';
import { calcMaxParticipants } from '../../utils/event.utils';
import { getNewDate } from '../../utils/dateUtils';
import { ccyFormat } from '../../utils/helpers';

const courseDetailsHeaders = [
  'Course Topic/Type',
  'Course Time',
  'Course Price',
  '# of expected participants',
];
const detailsHeaders = [
  'Customer Name',
  'Customer Address',
  'Contact Name/Phone/Email',
  'Customer Sold to #',
  'Route',
  // "ZNAT/ZREG", // ??
  'PO #',
  '', // header for courses section
  'Special onsite instructions',
];

const AddToCalendarBtn = ({ order /* , open */ }) => {
  const userTz = useSelector(timezoneSelector);

  React.useEffect(atcb_init, []);

  const courseDetailsInfo = (e) => {
    const title = e.title ?? (e.courseObj?.title ?? '');
    const courseDetails = [
      `${title} / ${(e.eventType ?? '')}`,
      `${e.allDay ? 'ALL DAY' : moment.utc(e.startTime).tz(userTz).format(TIME_FORMAT)} - ${moment.utc(e.endTime).tz(userTz).format(TIME_FORMAT)}`,
      ccyFormat(e.price?.amount ?? 0.0, e.price.curr),
      e.participants_count ?? calcMaxParticipants(e.courseObj, !_.isEmpty(e.secondaryTci ?? ''), 0),
    ];

    return courseDetailsHeaders.reduce((red, ch, x) => `${red}${ch ? `${ch}` : ''}: ${courseDetails[x]}\n`, '');
  };
  const getOnsiteLocation = () => {
    const city = _.startCase((order?.customerObj?.city ?? '').toLowerCase());
    const region = (order?.customerObj?.region ?? '').toUpperCase();
    return `${city}, ${region}`;
  };

  const getCustomerName = () => order?.customerObj?.name ?? 'N/A';
  const getCustomerSoldTo = () => order?.customerObj?.sold_to ?? 'N/A';
  const getCustomerRoute = () => order?.customerObj?.Route ?? 'N/A';
  const getCustomerAddress = () => {
    const street = order?.customerObj?.street ?? '';
    const cityRegion = getOnsiteLocation();
    const zip = order?.customerObj?.postal_code ?? '';
    return `\n${street}\n${cityRegion} ${zip}`;
  };
  const getPO = () => order?.poNumber ?? 'N/A';
  const getNotes = () => `\n${order?.notes ?? 'N/A'}`;
  const getContact = () => {
    const tempContact = order?.temporaryContact;
    const contact = order?.contact ?? tempContact;
    const first = contact?.FirstName;
    const last = contact?.LastName;
    const email = contact?.Email;
    const isTemp = !order?.contact;
    const phone = (isTemp ? contact?.Phone : (contact.Phones ?? [{}])[0].Phone) ?? '';
    return `\n${first} ${last}${phone ? `\n${phone}` : ''}\n${email}`;
  };

  const detailsInfo = [
    getCustomerName(),
    getCustomerAddress(),
    getContact(),
    getCustomerSoldTo(),
    getCustomerRoute(),
    getPO(),
    (order?.events ?? []).map((e) => courseDetailsInfo(e)).reduce((red, entry) => `${red}\n${entry}`, ''),
    getNotes(),
  ];

  const getGlobalStartTime = () => {
    if (_.isEmpty(order?.events ?? [])) {
      return `${moment.utc().format(DATE_TIME_ISO)}`;
    }
    const firstEv = order?.events[0];
    const startdate = moment.tz(firstEv.startTime, userTz);
    return `${getNewDate(startdate).toISOString()}`;
  };

  const getGlobalEndTime = () => {
    if (_.isEmpty(order?.events ?? [])) {
      return `${moment.utc().format(DATE_TIME_ISO)}`;
    }

    const lastEv = order?.events[order.events.length - 1];
    const endDate = moment.tz(lastEv.endTime, userTz);
    return `${getNewDate(endDate).toISOString()}`;
  };

  const buildDetails = () => {
    const details = detailsHeaders.reduce((red, h, i) => `${red}\n${h ? `${h}` : ''}${h ? ':' : ''} ${detailsInfo[i]}\n`, '');
    return details;
  };

  return (
    <div className="atcb" style={{ display: 'none' }}>
      {JSON.stringify({
        name: getCustomerName(),
        description: buildDetails(),
        location: getOnsiteLocation(),
        startDate: getGlobalStartTime(),
        endDate: getGlobalEndTime(),
        timeZone: 'UTC',
        trigger: 'click',
        inline: true,
        // options: ['iCal|ics', 'Apple', 'Google', 'Outlook.com|Outlook', 'Microsoft365'],
        options: ['iCal|ics'],
      })}
    </div>
  );
};

export default AddToCalendarBtn;
