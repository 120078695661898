/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import informal from 'spacetime-informal';
import spacetime from 'spacetime';
import {
  CURRENCY, CURRENCY_SYMBOLS, DEFAULT_CURRENCY, SESSION_TIMEOUT,
} from './consts';
import { logout } from '../redux/actions/auth.actions';
import * as Firebase from './firebase';

export const ccyFormat = (num, curr, withSpace) => {
  const currency = curr ?? DEFAULT_CURRENCY;
  const number = Number.isNaN(Number(num)) ? 0 : Number(num);
  switch (currency) {
    case CURRENCY.CAD:
      return `${CURRENCY_SYMBOLS.CAD}${withSpace ? ' ' : ''}${number.toFixed(2)}`;
    case CURRENCY.USD:
      return `${CURRENCY_SYMBOLS.USD}${withSpace ? ' ' : ''}${number.toFixed(2)}`;
    default:
      return `${number.toFixed(2)}`;
  }
};

export function getBrowserTimezone() {
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = spacetime.now().goto(zone);
  const tzStrings = informal.display(zone);

  let abbrev = zone;

  if (tzStrings && tzStrings.daylight && tzStrings.standard) {
    abbrev = now.isDST()
      ? tzStrings.daylight.abbrev
      : tzStrings.standard.abbrev;
  }
  return ({ value: zone, abbrev });
}

export function normalizeDate(dateMoment, timezone, allDay = false) {
  const toFormat = (allDay ? (dateMoment.startOf('day').add(12, 'hours')) : dateMoment) ?? moment(undefined);
  const inTimezone = moment.utc(toFormat.format('YYYY-MM-DD HH:mm')).tz(timezone);
  return inTimezone;
}

export function prepareDateForBackend(dateMoment, timezone, allDay = false) {
  return normalizeDate(dateMoment, timezone, allDay).toISOString();
}

export function middleDate(a, b) {
  const diff = Math.abs(moment.utc(a).diff(b));
  const middle = diff / 2 + Math.min(moment.utc(a).valueOf(), moment.utc(b).valueOf());
  return moment.utc(middle);
}

export function removeLeadingZeros(str) {
  if (!str) return '';
  return `${str}`.replace(/^0+/, '');
}

export function showTwoDecimal(num) {
  return num && (Math.round(num * 100) / 100).toFixed(2);
}

export function saveDataToLocalStorage(fieldName, data) {
  localStorage[fieldName] = data;
}

export function formatLocation(address) {
  const formattedLocation = address && `${address.AddressLine1},${address.AddressLine2 ? ` ${address.AddressLine2}, ` : ' '}${address.City}, ${address.State} ${address.PostalCode}`;

  return formattedLocation;
}

export function formatLocationTwoLines(address) {
  const formattedLocation1 = address && `${address.AddressLine1},${address.AddressLine2 ? ` ${address.AddressLine2}` : ' '}`;
  const formattedLocation2 = address && `${address.City}, ${address.State} ${address.PostalCode}`;

  return [formattedLocation1, formattedLocation2];
}

// For calculate commission levels
export const calcCLevels = (commissionPercentages, marketPrice) => {
  if (!commissionPercentages) {
    return null;
  }
  const clLength = commissionPercentages.length;
  const commissionLevels = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < clLength; i++) {
    commissionLevels.push(
      {
        id: i,
        name: `CL${i + 1}`,
        min: (marketPrice * (100 - commissionPercentages[i]) * 0.01).toFixed(2),
        max: i === 0 ? '' : (marketPrice * (100 - commissionPercentages[i - 1]) * 0.01 - 0.01).toFixed(2),
      },
    );
  }
  return commissionLevels;
};

// for flatten addons list for each course
export const flattenList = (addonData, required) => addonData.reduce(
  (tot, course) => tot.concat(required
    ? course.course.addons.required || []
    : course.course.addons.optional || []), [],
);

export const capitalizeWordsFirstLetter = (toCap, { capFirst = true }) => {
  const rawWords = toCap.split(' ');

  const capWords = rawWords.map((word, i) => {
    if (!word) return '';
    if (word.length === 1) return word.toUpperCase();
    return `${(!capFirst && i === 0) ? word[0] : word[0].toUpperCase()}${word.split('').slice(1).join('').toLowerCase()}`;
  });

  return capWords.join(' ');
};

export const formatPhone = (phone) => {
  if (!phone || phone.length < 10) return phone;
  const withContryCode = phone.startsWith('+');
  const ph = withContryCode ? phone.slice(-10) : phone;
  const countryCode = withContryCode ? phone.replaceAll(ph, '') : '';
  const adjusted = ph.substring(0, 10);
  const prefix = adjusted.substring(0, 3);
  const p1 = adjusted.substring(3, 6);
  const p2 = adjusted.substring(6, 10);

  return `${withContryCode ? `${countryCode} ` : ''}(${prefix}) ${p1} - ${p2}`;
};

/*
* params:
*   label (string) - week header on react-big-calendar (ex: '19 Sep')
*   eventStartTimes (array) - array of eventStartTimes to compare
*
* return:
*  hasEarlyMorningEvents (boolean) - if date has events that start prior to 8 AM
*/
export const hasEarlyMorningEvents = (label, eventStartTimes) => {
  const parsedDay = parseInt(label.replace(/\D/g, ''), 10);
  const filteredStarts = _.filter(eventStartTimes, (e) => (moment(e).date() === parsedDay && moment(e).hour() < 8));

  return filteredStarts.length > 0;
};

/*
* params:
*   label (string) - week header on react-big-calendar (ex: '19 Sep')
*   eventStartTimes (array) - array of eventStartTimes to compare
*
* return:
*  hasLateNightEvents (boolean) - if date has events that start after 5 PM
*/
export const hasLateNightEvents = (label, eventStartTimes) => {
  const parsedDay = parseInt(label.replace(/\D/g, ''), 10);
  const filteredStarts = _.filter(eventStartTimes, (e) => (moment(e).date() === parsedDay && moment(e).hour() > 16));

  return filteredStarts.length > 0;
};

/*
* params:
*   order (object) - order containing events array
*   listings (object) - listings/exclusions
*
* return:
*  hasExcludedMaterial (boolean) - if order contains excluded course or skillcheck
*/
export const orderMaterialExclusionCheck = (order, listings) => {
  let hasExcludedMaterial = false;

  const events = order?.events ?? [];

  events.forEach((event) => {
    hasExcludedMaterial = !((_.find(listings ?? [], { Material: event?.courseObj?.code ?? '' }) ?? {}).inlcuded ?? true);
  });

  return hasExcludedMaterial;
};

const authWithMicrosoft = () => (Firebase?.auth?.currentUser?.providerData ?? []).find((d) => d?.providerId === 'microsoft.com') != null;

const calcSessionTimeoutTime = async (userAuth) => {
  const tokenInfo = await userAuth.getIdTokenResult();
  const loginDatetime = moment.utc(tokenInfo.authTime);
  const timeoutDatetime = loginDatetime.clone().add(SESSION_TIMEOUT.duration, SESSION_TIMEOUT.unit);
  return timeoutDatetime;
};

export const timeoutSession = async (userAuth) => {
  // Checks if there should be a session timeout for the user after a SESSION_TIMEOUT amount of time (login will be required again)
  // returns true if timeout is needed
  if (authWithMicrosoft()) {
    const timeoutDatetime = await calcSessionTimeoutTime(userAuth);
    if (timeoutDatetime.isBefore(moment.utc())) {
      return true;
    }
  }

  return false;
};

export const setupTimeoutListener = async (userAuth, dispatch, history) => {
  if (!authWithMicrosoft()) return;
  if (localStorage.sessionTimeout) {
    clearTimeout(localStorage.sessionTimeout);
    saveDataToLocalStorage('sessionTimeout', false);
  }
  const timeoutDatetime = await calcSessionTimeoutTime(userAuth);
  const timeUntilTimeout = timeoutDatetime.diff(moment.utc());
  const timeout = setTimeout(async () => {
    await dispatch(logout());
    history.push('/');
  }, timeUntilTimeout);

  saveDataToLocalStorage('sessionTimeout', timeout);
};
