/* eslint-disable import/prefer-default-export */
import { baseInstance } from './instances/baseInstance';

export const fetchConfigs = () => baseInstance.get('/configurations');
export const updateLocationDefault = (locId, entry) => baseInstance.put(`/updateLocationDefaults/${locId}`, entry);
export const updateHolidayDefault = (payload) => baseInstance.put('/updateHolidays', payload);
export const deleteHolidayDefault = (date) => baseInstance.post('/deleteHolidays', [date]);
export const getRoleMatrix = () => baseInstance.get('/rolesMatrix');
export const getAdminMatrix = () => baseInstance.get('/adminMatrix');
export const updateRoleMatrix = (matrix) => baseInstance.put('/updateRoleMatrix', matrix);
export const updateAdminMatrix = (matrix) => baseInstance.put('/updateAdminMatrix', matrix);
export const updateConfigs = (payload) => baseInstance.put('/configurations', payload);
export const updateCancelReasons = (payload) => baseInstance.put('/updateCancelReasons', payload);
export const createCategory = (payload) => baseInstance.post('/createMaterialCategory', payload);
export const updateCategory = (id, payload) => baseInstance.put(`/updateMaterialCategory?material=${id}`, payload);
export const uploadMaterialCategoryImage = (payload) => baseInstance.put('/uploadMaterialCategoryImage', payload);
export const updateAdminNotificationSettings = (payload) => baseInstance.put('/updateNotificationsConfigs', payload);
export const updateAdminTaskSettings = (payload) => baseInstance.put('/updateTasksConfigs', payload);
export const updateOrgRelations = (payload) => baseInstance.put('/updateOrgRelations', payload);
export const updateUserNotificationsSettings = (payload) => baseInstance.put('/userData/notificationSettings', payload);
