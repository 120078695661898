/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { ccyFormat } from '../../../../../../utils/helpers';
import FASBlackFont from '../../../../../Forms/CustomFormComponents/FASBlackFont';
import FASTitle from '../../../../../Forms/CustomFormComponents/FASTtle';

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    color: '#012169',
    borderBottom: '0px',
    padding: '10px 0px',
  },
  tableContent: {
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000000',
    borderBottom: '0px',
    padding: '10px 0px',
  },

  cellTitle: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontSize: '14px',
    color: '#000000',
    borderBottom: '0px',
    padding: '5px 0px',
  },
  cellContent: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#000000',
    borderBottom: '0px',
    padding: '5px 0px',
  },
  table: {
    minWidth: '270px',
    margin: '0px',
    padding: '10px 0px',
  },
  summary: {
    borderTop: ({ darkDivider }) => `1px solid ${darkDivider ? 'black' : '#DEDEDE'}`,
    borderBottom: '0px',
    padding: '5px 0px',
  },

}));

// const TAX_RATE = 0.07;

function saveRows() {
  localStorage.setItem('rows', JSON.stringify(rows));
  window.dispatchEvent(new Event('storage'));
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return {
    desc, qty, unit, price,
  };
}
function createRowWithId(pid, desc, qty, unit, course, currency) {
  const price = priceRow(qty, unit);
  return {
    pid, desc, qty, unit, price, course, currency,
  };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

let rows = [
  // createRowWithId('1', 'First Aid CPR AED BBPs', 100, 1.15, '', 'CAD'),
  //   createRow('Paper (Case)', 10, 45.99),
  //   createRow('Waste Basket', 2, 17.99),
];

// suppose all item only have 1
export function deleteRow(itemName) {
  const id = rows.findIndex((item) => item.desc === itemName);
  if (id >= 0) {
    rows.splice(id, 1);
  }
  saveRows();
}
export function deleteRowWithId(itemId) {
  rows = rows.filter((item) => item.pid !== itemId);
  saveRows();
}

export function addRow(desc, qty, unit) {
  const id = rows.findIndex((item) => item.desc === desc);
  if (id > 0) {
    rows[id].qty += 1;
  } else {
    const newRow = createRow(desc, +qty, +unit);
    rows = rows.concat(newRow);
  }
  saveRows();
}

export function adjustRowPrice({
  pid, desc, qty, course, currency, subtract, add,
}) {
  const id = rows.findIndex((item) => item.pid === pid);
  if (id === -1) {
    addRowWithId(pid, (desc ?? ''), Number(qty ?? 0), Number((subtract ?? add) ?? 0.0), (course ?? ''), currency);
  } else {
    let unit = Number(rows[id].unit ?? 0);
    if (subtract) {
      unit -= Number(subtract);
    }
    if (add) {
      unit += Number(add);
    }
    const newRow = createRowWithId(pid, rows[id].desc, Number(qty ?? rows[id].qty), Number(unit), rows[id].course, rows[id].currency);
    rows[id] = { ...newRow };
  }
  saveRows();
}

export function adjustRowQty({
  pid, desc, qty, unit, course, currency, subtract, add,
}) {
  const id = rows.findIndex((item) => item.pid === pid);
  if (id === -1) {
    addRowWithId(pid, (desc ?? ''), Number(qty ?? 0), Number(unit ?? 0.0), (course ?? ''), currency);
  } else {
    let quantity = Number(rows[id].qty ?? 0);
    if (subtract) {
      quantity -= Number(subtract);
    }
    if (add) {
      quantity += Number(add);
    }
    if (quantity === 0) {
      deleteRowWithId(pid);
    } else {
      const newRow = createRowWithId(pid, rows[id].desc, quantity, rows[id].unit, rows[id].course, rows[id].currency);
      rows[id] = { ...newRow };
    }
  }
  saveRows();
}

export function addRowWithId(pid, desc, qty, unit, course, currency) {
  const id = rows.findIndex((item) => item.pid === pid);
  if (id !== -1) {
    const curr = rows[id];
    const newQty = qty ? Number(curr.qty ?? 0) + Number(qty ?? 0) : Number(curr.qty ?? 0);
    const newPrice = priceRow(qty, Number(unit));
    const updated = {
      pid,
      desc: (desc ?? curr.desc),
      qty: newQty,
      unit: unit ?? curr.unit,
      price: Number(curr?.price ?? '0') + Number(newPrice ?? '0'),
      course: (course ?? curr.course),
      currency: (currency ?? curr.currency),
    };
    rows[id] = { ...updated };
  } else {
    const newRow = createRowWithId(pid, desc, Number(qty), Number(unit), course, currency);
    rows = rows.concat(newRow);
  }
  rows = rows.sort((a, b) => a.course.localeCompare(b.course));
  saveRows();
}

export function addAdditionalParticipantsRow(pid, desc, qty, unit, course, currency) {
  const id = rows.findIndex((item) => item.pid === pid && item.course === course);
  if (id !== -1) {
    const curr = rows[id];
    const newQty = Number(curr.qty ?? 0) + Number(qty ?? 0);
    const newPrice = priceRow(newQty, Number(unit));
    const updated = {
      pid,
      desc: `${desc} - ${course}`,
      qty: newQty,
      unit: unit ?? curr.unit,
      price: newPrice,
      course: (course ?? curr.course),
      currency: (currency ?? curr.currency),
    };
    rows[id] = { ...updated };
  } else {
    const newRow = createRowWithId(pid, `${desc} - ${course}`, Number(qty), Number(unit), course, currency);
    rows = rows.concat(newRow);
  }
  rows = rows.sort((a, b) => a.course.localeCompare(b.course));
  saveRows();
}

// export function addRowWithId(pid, desc, qty, unit, course) {
//   const id = rows.findIndex((item) => item.pid === pid);
//   if (id > 0) {
//     rows[id].qty += 1;
//     rows[id].price += rows[id].unit;
//   } else {
//     const newRow = createRowWithId(pid, desc, +qty, +unit, course);
//     rows = rows.concat(newRow);
//   }
//   rows = rows.sort((a, b) => a.course.localeCompare(b.course));
// }

export function cleanRows() {
  rows = [];
  saveRows();
}

// const invoiceSubtotal = subtotal(rows);
// const invoiceTaxes = TAX_RATE * invoiceSubtotal;
// const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const CartSummaryForm = (props) => {
  const {
    eventSummary,
    darkDivider,
    hideTitle,
    customerFacing,
    isQuote,
  } = props;
  const [rowsItems, setRows] = useState(JSON.parse(localStorage.getItem('rows')));
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [invoiceTaxes] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [currency, setCurrency] = useState('');
  // const formValues = useSelector((state) => state.form.AddEventDialog.values);

  // let rowItems = [];

  // if (eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
  //   rowItems = [
  //     createRow(formValues.openEnrollmentCourse.name, 1, formValues.openEnrollmentCourse.price),
  //   ];
  // }

  const storageEventHandler = () => {
    setRows(JSON.parse(localStorage.getItem('rows')));
  };

  useEffect(() => {
    storageEventHandler();
  }, [rows]);

  useEffect(() => {
    window.addEventListener('storage', () => storageEventHandler());

    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener('storage', () => storageEventHandler());
    };
  }, []);

  const countPrice = () => {
    setInvoiceSubtotal(subtotal(rows));
    // setInvoiceSubtotal(subtotal(rowItems));
    // setInvoiceTaxes(TAX_RATE * invoiceSubtotal);
    setInvoiceTotal(invoiceTaxes + invoiceSubtotal);
    return invoiceTotal;
  };

  useEffect(() => {
    // setInvoiceSubtotal(subtotal(rows));
    // setInvoiceTaxes(TAX_RATE * invoiceSubtotal);
    // setInvoiceTotal(invoiceTaxes + invoiceSubtotal);
    countPrice();
    if (rows && rows.length) {
      setCurrency(rows[0].currency);
    }
    // console.log('rows in useEffect:', rows);
    // console.log(rows);
    // console.log(invoiceSubtotal, invoiceTaxes, invoiceTotal);
  });
  // console.log('rows:', rows);
  const classes = useStyles({ darkDivider });
  return (
    eventSummary
      ? (
        <>
          <Grid item xs={12}>
            {!hideTitle && <FASBlackFont size="18px" weight={700} title={`${isQuote ? 'Quote' : 'Order'} Summary`} />}
            <Table className={classes.table} aria-label="spanning table">
              <TableBody>
                {rows.filter((row) => row.qty).map((row, i) => (
                  <TableRow key={`${row.pid}${row.desc}${i}`}>
                    <TableCell align="left" colSpan={3} className={classes.cellTitle}>
                      <FASBlackFont size="15px" weight={500} title={`${(row.desc ?? '')}`.toUpperCase()} />
                    </TableCell>
                    <TableCell align="right" colSpan={3} className={classes.cellContent}>{ccyFormat(row.price, row.currency)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="end" colSpan={5} className={classes.summary}>
                    <FASBlackFont size="18px" weight={700} title="Subtotal : " />
                  </TableCell>
                  <TableCell align="end" className={classes.summary}>
                    <FASBlackFont size="18px" weight={700} title={ccyFormat(invoiceTotal, currency)} customStyle={{ paddingRight: 0, textAlign: 'end' }} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {customerFacing
              ? (<Typography style={{ fontSize: 12, paddingLeft: 0, textAlign: 'start' }}>Tax not included</Typography>)
              : (<Typography style={{ fontSize: 12, paddingLeft: 0, textAlign: 'start' }}>Subtotal + total with taxes will be calculated by SAP</Typography>)}
          </Grid>
        </>
      )
      : (
        <Grid item xs={12}>
          <FASTitle title="Summary" />
          <Table className={classes.table} aria-label="spanning table">
            <TableBody>
              {rowsItems.filter((row) => row.qty).map((row, i) => (
                <TableRow key={`${row.pid}${row.desc}${i}`}>
                  <TableCell align="left" colSpan={3} className={classes.cellTitle}>{`${(row.desc ?? '')}`.toUpperCase()}</TableCell>
                  <TableCell align="center" colSpan={1} className={classes.cellTitle}>{row.qty}</TableCell>
                  <TableCell align="right" colSpan={3} className={classes.cellContent}>{ccyFormat(row.price ?? 0, row.currency)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="left" colSpan={4} className={classes.tableTitle}>Total</TableCell>
                <TableCell align="right" className={classes.tableContent}>{ccyFormat(invoiceTotal, currency)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      )

  );
};
export default CartSummaryForm;
