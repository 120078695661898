/* eslint-disable no-alert */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import classes from './styles.module.css';
import SelectedDate from './SelectedDate';

const createCalendar = (yearDate) => {
  const currentDate = yearDate ? moment(yearDate) : moment();

  const first = currentDate.clone().startOf('month');
  const last = currentDate.clone().endOf('month');
  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  _.each(_.range(weeksCount), (weekNumber) => {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    _.each(_.range(7 * weekNumber, 7 * (weekNumber + 1)), (day) => {
      const date = currentDate.clone().set('date', day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    });
  });

  return calendar;
};

const MonthInDay = ({ date, curDay, onClick }) => {
  const calendar = createCalendar(date);

  if (!calendar) {
    return null;
  }

  return (
    <div className={classes.month}>
      <div className={classes['month-name']}>
        {calendar.currentDate.format('MMMM').toUpperCase()}
      </div>
      {_.map(moment.weekdaysMin(), (day) => (
        <span key={day} className={classes.day}>
          {_.first(day)}
        </span>
      ))}
      {_.map(calendar, (week, index) => (
        <div key={index}>
          {week.map((day) => (
            <SelectedDate
              key={day.date()}
              curDay={curDay}
              dateToRender={day}
              dateOfMonth={calendar.currentDate}
              onClick={onClick}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MonthInDay;
