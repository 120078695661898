const API_VERSION = 'v1';

export const getDefaultAPIBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return `http://localhost:8395/api/${API_VERSION}`;
    case 'develop':
      return `https://fastrainingcentraldev.cintas.com/api/${API_VERSION}`;
    // `http://dev-firebase-backend-template-qci6r6vcva-uc.a.run.app/api/${API_VERSION}`;
    case 'release':
      return `https://fastrainingcentralqa.cintas.com/api/${API_VERSION}`;
    case 'production':
      return `https://fastrainingcentral.cintas.com/api/${API_VERSION}`;
    case 'sandbox':
      return `https://trainingcentralsandbox.cintas.com/api/${API_VERSION}`;
    case 'preprod':
      return `https://fastctrainingmode.cintas.com/api/${API_VERSION}`;
    default:
      return `https://trainingcentraldev.cintas.com/api/${API_VERSION}`;
    // `https://dev-firebase-backend-template-qci6r6vcva-uc.a.run.app/api/${API_VERSION}`;
  }
};

export const drawerWidth = 240;

export const companyName = 'FAS Training App';
