/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { saveAs } from 'file-saver';
// import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { pdf } from '@react-pdf/renderer';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import DownloadIcon from '../../assets/images/roster_pdf_download_icon.svg';
import Roster from '../PDF/Roster';
import { COLORS, DATE_PICKER_FOMAT } from '../../utils/consts';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { fetchOrderSummary } from '../../redux/actions/orders.actions';
import { fetchCertifications } from '../../redux/actions/certifications.actions';
import { certificationsDataSelector } from '../../redux/selectors/certifications.selectors';

const DownloadRosterPDFButton = ({
  preloaded, orderId, tz, certifications, size = 38,
}) => {
  const dispatch = useDispatch();

  const getCertifications = useCallback(async () => {
    if (Array.isArray(certifications) && certifications.length) {
      return certifications;
    }
    return dispatch(fetchCertifications());
  });

  const generatePDFDocument = useCallback(async () => {
    const order = preloaded ?? (await dispatch(fetchOrderSummary(orderId)));
    const certs = await getCertifications();
    const blob = await (await pdf(
      <Roster events={order?.events} customer={order?.customerObj ?? {}} certifications={certs} timezone={tz} />,
    )).toBlob();

    saveAs(blob, `${(order?.customerObj?.name ?? '').replaceAll(' ', '')}_${moment.utc(order?.events?.[0]?.startTime).tz(tz).format(DATE_PICKER_FOMAT)}.pdf`);
  });

  return (
    <IconButton
      color="primary"
      style={{ padding: 0, margin: 0, backgroundColor: COLORS.CINTAS_BLUE }}
      onClick={generatePDFDocument}
    >
      <img
        style={{
          height: size,
          width: size,
          fontSize: size,
        }}
        src={DownloadIcon}
        alt="PdfRosterDownloadIcon"
      />
    </IconButton>
  );
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
    certifications: certificationsDataSelector(state),
  })),
])(DownloadRosterPDFButton);
