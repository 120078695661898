/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from './Card';
import { COLORS } from '../../utils/consts/index';
import Container from './Container';
import ContainerItem from './ContainerItem';
import SimpleText from '../Text/SimpleText';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))((props) => ({
  borderBottom: props?.noBorder ? 0 : `1px solid ${COLORS.CINTAS_GRAY}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const ExpansionTile = ({
  expanded, onClick, onExpansionChange, noExpanding, headerActions, noContentPadding, elevated, bodyFlex, leading, padding, leadingFlex, leadingSpacing, noBorder, headerFlex, header, subheader, contentStr, children, headerStyle, subheaderStyle, ...rest
}) => {
  const simpleContainer = ({ child }) => (
    <div {...(rest ?? {})}>
      {child}
    </div>
  );

  const container = ({ child, ...rst }) => <Container {...(rst ?? {})}>{child}</Container>;
  const item = ({ child, ...rst }) => <ContainerItem {...(rst ?? {})}>{child}</ContainerItem>;

  const accordion = () => (
    <Accordion
      style={{ width: '100%', borderRadius: elevated ? 4 : 0 }}
      expanded={expanded}
      noBorder={noBorder}
      onClick={onClick}
      onChange={(e, val) => (onExpansionChange && !noExpanding ? onExpansionChange(val) : null)}
    >
      <AccordionSummary
        expandIcon={noExpanding ? <></> : <ExpandMoreIcon style={{ color: COLORS.CINTAS_BLUE }} />}
        id="panel-header"
        style={{
          width: '100%', paddingRight: 10, ...(padding ?? {}), cursor: noExpanding ? 'default' : 'pointer',
        }}
      >
        {container({
          spacing: leadingSpacing,
          style: { justifyContent: 'flex-start', ...(padding ?? {}) },
          child: [
            leading && item({ flex: leadingFlex ?? 'auto', child: [leading] }),
            item({
              flex: bodyFlex ?? 'auto',
              child: [
                <Typography sx={{ width: headerFlex ?? '33%', flexShrink: 0 }} style={headerStyle}>
                  {header ?? ''}
                </Typography>,
                <SimpleText txt={subheader ?? ''} style={{ color: COLORS.CINTAS_GRAY, ...(subheaderStyle ?? {}) }} />,
                ...(headerActions ?? []),
              ],
            }),
          ],
        })}
      </AccordionSummary>
      <AccordionDetails style={{ padding: noContentPadding ? 0 : null }}>
        {contentStr && <Typography>{contentStr}</Typography>}
        {children}
      </AccordionDetails>
    </Accordion>
  );

  return elevated ? <Card elevation={elevated} style={{ padding: 0, ...(rest ?? {}) }}>{accordion()}</Card> : simpleContainer({ child: accordion() });
};

export default ExpansionTile;
