/* eslint-disable no-use-before-define */
import React from 'react';
import { useDispatch } from 'react-redux';
import ActionsFooter from '../../components/CustomerDashboard/ActionsFooter';
import CustomerOrdersDashboardStep from '../../components/CustomerDashboard/CustomerOrdersDashboardStep';
import SearchCustomerDashboardStep from '../../components/CustomerDashboard/SearchCustomerDashboardStep';
import Spinner from '../../components/SpinnerOverlay/Spinner';
import SimpleText from '../../components/Text/SimpleText';
import { fetchCommissionLevels } from '../../redux/actions/commissionLevels.actions';
import { fetchMaterials } from '../../redux/actions/materials.actions';
import { fetchUnreadNotifications } from '../../redux/actions/notifications.actions';
import { fetchSAPMaterials } from '../../redux/actions/sap.actions';
import { fetchTasksData } from '../../redux/actions/tasks.actions';
import { getRegionsLocations } from '../../redux/actions/users.actions';
import { COLORS } from '../../utils/consts';

const CustomersDashboardView = () => {
  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedCustomer] = React.useState();
  const [showActionsBar, setShowActionsBar] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [regions, setRegions] = React.useState({});

  const loadCintasRegions = async () => {
    try {
      setLoading(true);
      const fetchedRegions = await dispatch(getRegionsLocations(true));
      setRegions(fetchedRegions);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    dispatch(fetchMaterials());
    dispatch(fetchSAPMaterials());
    dispatch(fetchCommissionLevels());
    dispatch(fetchUnreadNotifications(1));
    dispatch(fetchTasksData());
    loadCintasRegions();
    return () => { };
  }, []);

  const onCustomerSelected = (customer, forceSelection) => {
    if (!customer) {
      setSelectedCustomer();
      prevStep();
    } else {
      setSelectedCustomer(customer);
      if (forceSelection) {
        nextStep();
      } else {
        setShowActionsBar(true);
      }
    }
  };

  const STEPS = [
    new DashboardStep('Search Customer', (args) => <SearchCustomerDashboardStep {...args} />, { onCustomerSelected, regions }),
    new DashboardStep('Customer Orders', (args) => <CustomerOrdersDashboardStep {...args} />, { regions, customer: selectedCustomer, onPrevStep: () => { prevStep(); setSelectedCustomer(); } }),
  ];
  const [currentStep, setCurrentStep] = React.useState(0);

  const nextStep = () => {
    if (currentStep === (STEPS.length - 1)) return;
    setShowActionsBar(false);
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep === 0) return;
    setShowActionsBar(false);
    setCurrentStep(currentStep - 1);
  };

  const header = () => (
    <SimpleText
      txt="Customer Dashboard"
      fontSize={30}
      style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, paddingTop: 10 }}
    />
  );

  const stepsView = () => {
    const { label, widget, args } = STEPS[currentStep];
    return loading ? spinner() : widget({ label, ...args });
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{ color: COLORS.CINTAS_BLUE, marginTop: 20 }}
    />
  );

  const bottomActionsBar = () => (
    <ActionsFooter
      actionTitle="SELECT CUSTOMER"
      onClick={() => { if (selectedCustomer) nextStep(); }}
    />
  );

  return (
    <>
      {header()}
      {stepsView()}
      {showActionsBar && selectedCustomer ? bottomActionsBar() : <></>}
    </>
  );
};

export default CustomersDashboardView;

export class DashboardStep {
  constructor(label, widget, args) {
    this.label = label;
    this.widget = widget;
    this.args = args ?? {};
  }
}
