/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import {
  EVENT_TYPE, ROLE_ACCESSES,
} from '../../../../utils/consts';

import {
  removeLeadingZeros,
} from '../../../../utils/helpers';
import { hasUserAccessSelector, userInfoSelector } from '../../../../redux/selectors/user.selectors';
import { timezoneSelector } from '../../../../redux/selectors/utils.selectors';
import EditOrderButton from '../../../Button/EditOrderButton';

export const usePopperHeaderStyles = makeStyles(() => ({
  root: {
    margin: 0,
    maxWidth: 400,
    paddingBottom: '0px',
    borderRadius: '0',
    minWidth: '120px',
    display: 'flex',
  },
  buttonGroup: {
    color: '#012169',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  title: {
    fontWeight: 'bold',
    margin: 'auto',
  },
  titleContainer: {},
  closeButton: {
    color: '#012169',
  },
  header: {
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    justifyContent: 'space-between',
    color: 'white',
    borderRadius: '0',
  },
}));

const EventPopperHeader = (props) => {
  const {
    oeTitle,
    onClosePopper,
    selectedEvent,
    dispatch,
    withCloseButton,
    dayView,
    order,
    onEventEdited,
    disableEditing,
    userRole,
    userTz,
    ...other
  } = props;
  const classes = usePopperHeaderStyles();

  // Determine if course is editable i.e. not delivered yet.
  const subcontracted = order?.subcontracted || order?.events?.[0]?.subcontracted;
  const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [subcontracted ? ROLE_ACCESSES.subcontractorEvents : ROLE_ACCESSES.editEvent]));
  const allowedToEditTimeBlocks = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.editTimeblock]));
  const withEditButton = (selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK || (selectedEvent?.desc?.eventType === EVENT_TYPE.TIME_BLOCK && allowedToEditTimeBlocks)) && (selectedEvent?.desc?.eventType === EVENT_TYPE.TIME_BLOCK || (order && !order.delivery));
  const shouldDisableEditing = disableEditing && disableEditing(order);
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      style={{ padding: dayView && 8 }}
      {...other}
    >
      <div className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          {selectedEvent.desc.eventType === EVENT_TYPE.OPEN_ENROLLMENT
            ? oeTitle : selectedEvent.title}
        </Typography>
        {selectedEvent.desc.eventType === EVENT_TYPE.ON_SITE && (
          <Typography variant="body1" gutterBottom>
            {
              `Sold-to Number: ${removeLeadingZeros(order?.customerObj?.sold_to ?? '')}` // TODO: fix
            }
          </Typography>
        )}
      </div>
      <div className={classes.buttonGroup}>
        {!shouldDisableEditing && withEditButton && hasEditAccess && (
          <EditOrderButton orderId={selectedEvent.desc.order} selectedEvent={selectedEvent} callback={onClosePopper} onEventEdited={onEventEdited} />
        )}
        {withCloseButton && (
          <IconButton aria-label="close" edge="end" className={classes.closeButton} onClick={onClosePopper}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </MuiDialogTitle>
  );
};

export default _.flow([
  connect((state) => ({
    userRole: userInfoSelector(state).role,
    userTz: timezoneSelector(state),
  })),
])(EventPopperHeader);
