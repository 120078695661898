/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import Immutable from 'seamless-immutable';

import _ from 'lodash';
import {
  SAVE_OPEN_PANEL_FLAG,
  SAVE_SHOW_NOTICE_DOT,
  SAVE_NOTIFICATIONS_DATA,
  SAVE_UNREAD_NOTIFICATIONS,
  READ_NOTIFICATION,
  CLEAR_NOTIFICATIONS_DATA,
  SAVE_TASKS_DATA,
  CLEAR_TASKS_DATA,
  SAVE_UNSOLVED_TASKS,
  READ_TASK,
  RESOLVE_TASK,
  CLEAR_UNSOLVED_TASKS_DATA,
} from '../actions/actionTypes/notifications.tasks';
import { TASK_TYPE } from '../../utils/consts/tasks.consts';

const initialUnsolvedTasksState = Object.keys(TASK_TYPE).reduce((prev, type) => ({ ...prev, [type]: {} }), {});

const initialState = Immutable({
  notifications: {
    openPanel: false,
    showNoticeDot: false,
    unread: {},
    pastData: {},
  },
  tasks: {
    openPanel: false,
    showNoticeDot: false,
    unsolved: initialUnsolvedTasksState,
    solved: {},
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_OPEN_PANEL_FLAG:
      return state.setIn([action.panel, 'openPanel'], action.open);
    case SAVE_SHOW_NOTICE_DOT:
      return state.setIn([action.panel, 'showNoticeDot'], action.show);
    // notifications
    case SAVE_NOTIFICATIONS_DATA:
      return state.setIn(['notifications', 'pastData', (action.page ?? 0)], (action.data ?? []));
    case CLEAR_NOTIFICATIONS_DATA:
      return state.setIn(['notifications', 'pastData'], {});
    case SAVE_UNREAD_NOTIFICATIONS:
      return state.setIn(['notifications', 'unread', (action.page ?? 0)], (action.data ?? []));
    case READ_NOTIFICATION:
      const unreadNotific = state.notifications.unread;
      const unreadPage = _.cloneDeep(unreadNotific[`${action.page}`]);
      unreadPage[action.index] = { ...(unreadPage[action.index] ?? {}), read: true };
      return state.setIn(['notifications', 'unread', (action.page ?? 0)], (unreadPage ?? []));
    // tasks
    case SAVE_TASKS_DATA:
      return state.setIn(['tasks', 'solved', (action.page ?? 0)], (action.data ?? []));
    case CLEAR_TASKS_DATA:
      return state.setIn(['tasks', 'solved'], {});
    case CLEAR_UNSOLVED_TASKS_DATA:
      return state.setIn(['tasks', 'unsolved', action.taskType], {});
    case SAVE_UNSOLVED_TASKS:
      return state.setIn(['tasks', 'unsolved', action.taskType, (action.page ?? 0)], (action.data ?? []));
    case READ_TASK:
      return state.setIn(['tasks', 'unsolved', action.taskType, action.page, action.index, 'read'], true);
    case RESOLVE_TASK:
      return state.setIn(['tasks', 'unsolved', action.taskType, action.page, action.index, 'resolved'], true).setIn(['tasks', 'unsolved', action.taskType, action.page, action.index, 'resolutionValue'], action.value);
    default:
      return state;
  }
};
