/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Container,
  Grid,
  Box,
} from '@material-ui/core';
import { reduxForm, submit } from 'redux-form';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React, { useState } from 'react';

import {
  COLORS,
  EVENT_TYPE,
  MATERIAL_TYPE,
} from '../../../utils/consts';
import CartSummaryForm, { cleanRows, addRowWithId } from '../../../components/Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import EventEditForm from './EventEditForm';
import FASTitle from '../../../components/Forms/CustomFormComponents/FASTtle';
import StyledButton from '../../../components/Button/StyledButton';
import * as OrderActions from '../../../redux/actions/orders.actions';
import Spinner from '../../../components/SpinnerOverlay/Spinner';
import OrderSummary from '../../../components/OrderSummary/OrderSummary';
import { materialsDataSelector } from '../../../redux/selectors/materials.selector';
import { loadAddons } from '../../../utils/event.utils';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  title: {
    marginLeft: '10px',
  },
  orderSummary: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  date: {
    maxWidth: '100%',
  },
  paper: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

  },
  addOnTable: {
    maxWidth: '100%',
  },
  instructor: {
    marginBottom: theme.spacing(3),
  },
  courses: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '200px',
    height: '60px',
    marginTop: '30px',
  },
  blueText: {
    color: COLORS.CINTAS_BLUE,
    fontSize: 14,
  },
  box: {
    position: 'flex',
    flexDirection: 'column',
    border: ({ notAvailable }) => `1px solid ${notAvailable ? COLORS.CINTAS_RED : COLORS.CINTAS_GRAY_BORDER}`,
    textAlign: 'center',
    minHeight: '90px',
    margin: '5px',
    marginLeft: theme.spacing(2),
  },
  clickableBox: {
    display: 'inline-block',
    border: ({ notAvailable }) => `1px solid ${notAvailable ? COLORS.CINTAS_RED : COLORS.CINTAS_GRAY_BORDER}`,
  },
  image: {
    width: '80px',
    alignItems: 'center',
    height: '80px',
    objectFit: 'contain',
  },
  textRightAlign: {
    textAlign: 'right',
  },
  textCenterAlign: {
    textAlign: 'center',
  },
  itemcheckbox: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
  },
}));

// collect all information for on site course
export const getOSEventInfo = (events, materialsData, selectedTimezone, addOnsLoaded) => {
  let courseEvent;
  let courseTotal;
  let skillCheckTotal = 0;
  let addonsTotal = 0;
  let additionalParticipantsTotal = 0;
  let currency;
  if ((events ?? []).length === 0) {
    courseEvent = {};
  } if (events.length === 1) {
    const date = moment.utc(events[0].startTime).format('ll');
    const course = materialsData[MATERIAL_TYPE.COURSE][events[0].course] ?? {};
    const addonList = addOnsLoaded ? events[0].addOns : loadAddons(
      _.map(events[0].addOns, (addon) => addon.id),
      Object.values(materialsData[MATERIAL_TYPE.ADD_ON]),
    );
    const mappedAddOns = addOnsLoaded ? events[0].addOns : _.map(addonList, (addon) => {
      const addonPrice = events[0].addOns.find((add) => add.id === addon.id);
      return { ...addon, ...addonPrice };
    });
    const additionalParticipantsList = addOnsLoaded ? events[0].additional_participants : loadAddons(
      _.map(events[0].additional_participants, (item) => item.id),
      Object.values(materialsData[MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS]),
    );
    const mappedAdditionalParticipants = addOnsLoaded ? events[0].additional_participants : _.map(additionalParticipantsList, (item) => {
      const itemPrice = events[0].additional_participants.find((add) => add.id === item.id);
      return { ...item, ...itemPrice };
    });

    courseEvent = {
      ...events[0],
      date,
      startTime: moment.utc(events[0].startTime).format('LT'),
      endTime: moment.utc(events[0].endTime).format('LT'),
      courses: [{
        title: course.title,
        price: events[0].price,
        participants_count: events[0].participants_count,
        addOns: (mappedAddOns ?? []),
        additional_participants: (mappedAdditionalParticipants ?? []),
        startTime: events[0].startTime,
        endTime: events[0].endTime,
      }],
    };
    addonsTotal = (mappedAddOns ?? []).map(
      ({ price, qty }) => price.amount * (qty ?? events[0].participants_count),
    ).reduce((sum, i) => sum + i, 0);
    additionalParticipantsTotal = (mappedAdditionalParticipants ?? []).map(
      ({ price, qty }) => price.amount * qty,
    ).reduce((sum, i) => sum + i, 0);
    courseTotal = events[0].price.amount;
    currency = events[0].price.currency;
  } else {
    // Deal with time, startTime and endTime
    const sortedEvents = _.sortBy(events, 'startTime');
    const date = moment.utc(sortedEvents[0]?.startTime).format('ll');
    const startTime = moment.utc(sortedEvents[0]?.startTime).format('LT');
    const endTime = moment.utc(sortedEvents[sortedEvents.length - 1]?.endTime).format('LT');
    // Courses and Addons
    const courseList = _.map(events, (event) => {
      const addonList = addOnsLoaded ? events[0].addOns : loadAddons(_.map(event.addOns, (addon) => addon.id), Object.values(materialsData[MATERIAL_TYPE.ADD_ON]));
      const mappedAddOns = addOnsLoaded ? events[0].addOns : _.map(addonList, (addon) => {
        const addonPrice = event.addOns.find((add) => add.id === addon.id);
        return { ...addon, ...addonPrice };
      });
      const additionalParticipantsList = addOnsLoaded ? events[0].additional_participants : loadAddons(_.map(event.additional_participants, (item) => item.id), Object.values(materialsData[MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS]));
      const mappedAdditionalParticipants = addOnsLoaded ? events[0].additional_participants : _.map(additionalParticipantsList, (item) => {
        const itemPrice = event.additional_participants.find((add) => add.id === item.id);
        return { ...item, ...itemPrice };
      });
      const course = (materialsData[MATERIAL_TYPE.COURSE]?.[event.course]) ?? {};

      return {
        ...event,
        addOns: mappedAddOns,
        additional_participants: mappedAdditionalParticipants,
        title: course?.title ?? '',
      };
    });
    courseEvent = {
      date,
      startTime,
      endTime,
      courses: courseList,
      eventType: sortedEvents[0]?.eventType,
      order: sortedEvents[0]?.order,
    };
    _.forEach(events, (event) => {
      if (event.addOns) {
        addonsTotal += event.addOns.map(
          ({ price, qty }) => Number(price.amount ?? 0) * Number(qty ?? (event.participants_count ?? 0)),
        ).reduce((sum, i) => sum + i, 0);
      }
      if (event.additional_participants?.length > 0) {
        additionalParticipantsTotal += event.additional_participants.map(
          ({ price, qty }) => Number(price.amount ?? 0) * Number(qty),
        ).reduce((sum, i) => sum + i, 0);
      }
    });
    courseTotal = courseEvent.courses.filter((c) => c.courseObj.type !== MATERIAL_TYPE.SKILL_CHECK).map(
      ({ price }) => Number(price.amount),
    ).reduce((sum, i) => sum + i, 0);
    skillCheckTotal = courseEvent.courses.filter((c) => c.courseObj.type === MATERIAL_TYPE.SKILL_CHECK).map(
      ({ price, participants_count }) => Number(price.amount) * (participants_count ?? 0),
    ).reduce((sum, i) => sum + i, 0);
    currency = courseEvent.courses?.[0]?.price.currency;
  }

  const courseTot = Number(courseTotal ?? 0);
  const skTot = Number(skillCheckTotal ?? 0);
  const addonsTot = Number(addonsTotal ?? 0);
  const addPartTotal = Number(additionalParticipantsTotal ?? 0);
  addRowWithId(`course${events?.[0]?.order}`, 'Course Total', 1, courseTot, '', currency);
  if (skTot > 0) {
    addRowWithId(`skillchecks${events?.[0]?.order}`, 'Skillchecks Total', 1, skTot, '', currency);
  }
  if (addonsTot > 0) {
    addRowWithId(`addons${events?.[0]?.order}`, 'Course Materials Total', 1, addonsTot, '', currency);
  }
  if (addPartTotal > 0) {
    addRowWithId(`additionalParticipants${events?.[0]?.order}`, 'Course Additional Participants Total', 1, addPartTotal, '', currency);
  }
  return courseEvent;
};

const EventSummary = (props) => {
  const {
    loadedOrder,
    materialsInfo,
    utils: { tz: { value: selectedTimezone } },
    history,
    match: { params: { orderId } },
    dispatch,
  } = props;
  const classes = useStyles();
  const [editing, activateEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(loadedOrder);

  React.useEffect(async () => {
    cleanRows();
    if (!loadedOrder) {
      await loadOrder();
    } else {
      setLoading(false);
    }
    return () => { };
  }, []);

  const loadOrder = async () => {
    const ord = await dispatch(OrderActions.fetchOrderSummary(orderId));
    if (!ord) return;
    if (ord.events && ord.events.length > 0
      && ord.events[0].eventType === EVENT_TYPE.ON_SITE) {
      getOSEventInfo([...ord.events], materialsInfo, selectedTimezone, true);
    }
    setOrder({
      id: orderId,
      ...ord,
      events: (ord.events ?? []).map(
        (e) => ({ ...e, title: (e.courseObj ?? {}).title ?? '' }),
      ),
    });
    setLoading(false);
  };

  const goBack = () => {
    cleanRows();
    history.goBack();
  };

  const spinner = (style) => <Spinner customStyle={{ margin: '10%' }} {...(style ?? {})} />;

  const buildSummary = () => (loading ? spinner() : (
    <>
      <OrderSummary order={order} history={history} dispatch={dispatch} />
      <Box height={24} />
    </>
  ));

  return (
    <Container className={classes.root} maxWidth="md">
      <Paper className={classes.paper} elevation={3}>
        <Grid container justify="space-between">
          <Grid item>
            <FASTitle className={classes.title} title="Summary" />
          </Grid>
          <Grid item>
            <StyledButton
              variant={editing ? 'contained' : 'outlined'}
              width="38px"
              handleButton={() => activateEdit(!editing)}
              buttonContent={<EditIcon />}
            />
          </Grid>
        </Grid>
        <Container className={classes.root} maxWidth="lg" style={{ justifyContent: 'center', padding: 0 }}>
          {editing ? <EventEditForm /> : buildSummary()}
        </Container>
      </Paper>
      <Paper className={classes.paper} elevation={3} style={{ width: '100%', marginTop: 14 }}>
        <CartSummaryForm eventSummary />
      </Paper>
      <Grid container justify="center" spacing={3}>
        <Grid item>
          <StyledButton
            className={classes.button}
            variant="outlined"
            width="160px"
            handleButton={goBack}
            buttonContent="Back"
          />
        </Grid>
        <Grid item>
          <StyledButton
            className={classes.button}
            variant="contained"
            width="160px"
            handleButton={() => { }}
            buttonContent="Update"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  materialsInfo: materialsDataSelector(state),
  utils: state.utils,
});
export default _.flow([
  connect(
    mapStateToProps,
  ),
  withRouter,
  reduxForm({
    form: 'EventSummary',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(EventSummary);
