/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {
  clearScheduledNotification,
  fetchUserReadNotifications,
  fetchUserUnreadNotifications,
  generateOrderNotification,
  markAllNotificationsRead,
  markNotificationRead,
} from '../../api/notifications.api';
import { OK_STATUS_CODES, ROLE_ACCESSES } from '../../utils/consts';
import { NOTIFICATION_TYPE, SCHEDULED_NOTIFICATIONS_TYPES } from '../../utils/consts/notifications.consts';
import { buildNotificationDetails } from '../../utils/notification.utils';
import NotificationsManager from '../../utils/notificationsManager';
import { checkNotificationEnabledSelector, unreadNotificationsSelector, panelTypes } from '../selectors/notifications.tasks.selector';
import { notificationsConfigSelector } from '../selectors/settings.selectors';
import {
  hasUserAccessSelector, instructorRoleSelector, instructorZipcodesSelector, userInfoSelector,
} from '../selectors/user.selectors';
import { timezoneDetailsSelector } from '../selectors/utils.selectors';
import {
  SAVE_OPEN_PANEL_FLAG,
  SAVE_SHOW_NOTICE_DOT,
  SAVE_NOTIFICATIONS_DATA,
  SAVE_UNREAD_NOTIFICATIONS,
  READ_NOTIFICATION,
  CLEAR_NOTIFICATIONS_DATA,
} from './actionTypes/notifications.tasks';
import { fetchEventById } from './events.actions';
import { fetchOrderSummary } from './orders.actions';
import { getTciManagerUid } from './users.actions';

export const openPanelOfType = (type, open) => ({
  type: SAVE_OPEN_PANEL_FLAG,
  panel: type,
  open,
});

export const showNoticeDot = (type, show) => ({
  type: SAVE_SHOW_NOTICE_DOT,
  show,
  panel: type,
});

export const savePastNotifications = (page, data) => ({
  type: SAVE_NOTIFICATIONS_DATA,
  page,
  data,
});

const clearPastNotifications = () => ({
  type: CLEAR_NOTIFICATIONS_DATA,
});

const saveUnreadNotifications = (page, data) => ({
  type: SAVE_UNREAD_NOTIFICATIONS,
  page,
  data,
});

const readNotification = (page, index) => ({
  type: READ_NOTIFICATION,
  page,
  index,
});

export const changeNotificationToRead = (page, index, notificationID) => async (dispatch, getState) => {
  try {
    const result = await markNotificationRead(notificationID);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(readNotification(page ?? 1, index));
      const unread = _.flatten(Object.values(unreadNotificationsSelector(getState())));
      if (_.isEmpty(unread.filter((n) => !n.read))) {
        dispatch(showNoticeDot(panelTypes.notification, false));
      }
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const changeMultipleNotificationsToRead = (notificationIDs) => async (dispatch) => {
  try {
    const result = await markAllNotificationsRead(notificationIDs ?? []);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      await dispatch(fetchNotificationsData(null, null, true));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const buildOrderNotification = ({
  notificationType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
}) => async (dispatch, getState) => {
  // This function builds an order notification and its receivers
  // it returns an object with empty receivers if the notification type + order info do not meet criterias for the notification to be posted
  try {
    if ((!eventID || eventID === '') && (!loadAsOrder && !preloadedOrderSummary)) return undefined;
    let order;
    let event;
    if (loadAsOrder) {
      // fetch the order using the orderID or use the preloaded one
      order = preloadedOrderSummary ?? (await dispatch(fetchOrderSummary(eventID)));
      try {
        // get latest event-specific info (info not present in orderSummary)
        event = await dispatch(fetchEventById(order?.events?.[0]?.id));
      } catch (e) { event = {}; }
    } else {
      // get latest event-specific info (info not present in orderSummary)
      event = await dispatch(fetchEventById(eventID));
      // fetch the order linked to the event or use the preloaded one
      order = preloadedOrderSummary ?? (await dispatch(fetchOrderSummary(event.order)));
    }
    if (order?.subcontracted) return { receivers: [] };
    let { instructorID, createdBy } = event;
    const zip = (order?.customerObj?.postal_code ?? '').split('-')[0]; // zip used to calculate manager for TCI based on customer location
    const actionInfo = getActionInfo ? getActionInfo(order, event) : {};

    if ((notificationType === NOTIFICATION_TYPE.EDITED_EVENTS) && _.isEmpty(actionInfo?.changesLabels ?? [])) {
      // If there were no labeled changes for the edited event, NO EDITED_EVENTS notification needed ---> No editing labels to display.
      return { receivers: [] };
    }
    if ((notificationType === NOTIFICATION_TYPE.ESCALATED_EVENT) && !actionInfo?.task) {
      return { receivers: [] };
    }
    if (notificationType === NOTIFICATION_TYPE.E_OUTSIDE_LOCATION) {
      // Check if the order is outside the location. If its within location of the TCI, do not create the notification
      const instructorZipcodes = instructorZipcodesSelector(getState(), instructorID);
      const { roleMatrix: instructorRoleMatrix } = instructorRoleSelector(getState(), instructorID);
      const disableOutsideLoc = hasUserAccessSelector(null, [ROLE_ACCESSES.outsideTerritoryNotification], instructorRoleMatrix);
      if (instructorZipcodes.includes(zip) || disableOutsideLoc) { // if the TCI locations CONTAINS the zipcode of the customer, NO E_OUTSIDE_LOCATION notification needed ---> Event is within TCI location.
        return { receivers: [] };
      }
    }

    // create a notification manager instance
    const notificationsManager = new NotificationsManager({
      user: userInfoSelector(getState()),
      tz: timezoneDetailsSelector(getState()),
      notificationSettings: notificationsConfigSelector(getState()),
    });
    // use the notification manager to generate a notification specific to the notification type
    const phoenix = notificationsManager.phoenixOfType(notificationType);
    let notification;
    switch (notificationType) {
      case NOTIFICATION_TYPE.ESCALATED_EVENT:
        notification = phoenix.giveBirth(actionInfo.task, actionInfo.resolution, order);
        break;
      default:
        notification = phoenix.giveBirth(order, actionInfo);
    }
    // use the notification manager to calculate the receivers IDs according to the notification type and notification configurations
    const managerID = await dispatch(getTciManagerUid(instructorID, zip));
    const receivers = notificationsManager.receiversOfOrderNotificationType(notificationType, createdBy, instructorID, managerID);
    return {
      notification,
      receivers,
    };
  } catch (e) { return undefined; }
};

export const postOrderNotification = ({
  notificationType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
}) => async (dispatch, getState) => {
  try {
    const isNotificationEnabled = checkNotificationEnabledSelector(getState(), notificationType);
    if (!isNotificationEnabled) {
      // dont do anything if the notifications configurations have this type of notification disabled for inApp notification
      await dispatch(postDependentNotifications({
        notificationType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
      }));
      return true;
    }

    const notificationInfo = await dispatch(buildOrderNotification({
      notificationType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
    }));

    if (!notificationInfo) return false;
    const { receivers, notification } = notificationInfo;

    if (_.isEmpty(receivers)) return true;
    const response = await dispatch(createOrderNotification(receivers, notification));
    await dispatch(postDependentNotifications({
      notificationType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
    }));
    if (response && OK_STATUS_CODES.includes(response.stauts)) {
      return true;
    }
    return false;
  } catch (e) { return false; }
};

const postDependentNotifications = ({
  notificationType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
}) => async (dispatch, getState) => {
  // This function runs every time a notification is posted through @postOrderNotification() to ensure that other notifications dependent on the one just posted get dispatched correctly as well
  try {
    const shouldScheduleUpcomingEventNotification = [NOTIFICATION_TYPE.BOOKED_EVENT, NOTIFICATION_TYPE.RESCHEDULED_EVENT].includes(notificationType);
    const shouldScheduleEventNotStartedNotification = [NOTIFICATION_TYPE.BOOKED_EVENT, NOTIFICATION_TYPE.RESCHEDULED_EVENT].includes(notificationType);
    const shouldPostOutsideTerritoryNotification = [NOTIFICATION_TYPE.BOOKED_EVENT].includes(notificationType);
    const shouldClearAllScheduledNotifications = [NOTIFICATION_TYPE.CANCELED_EVENT].includes(notificationType);

    if (shouldClearAllScheduledNotifications) {
      const ordID = (preloadedOrderSummary?.id) ?? (loadAsOrder ? eventID : await dispatch(fetchEventById(eventID)));
      await Promise.allSettled(SCHEDULED_NOTIFICATIONS_TYPES.map((t) => dispatch(deleteScheduledNotifications(t, ordID))));
    }

    if (shouldScheduleUpcomingEventNotification && checkNotificationEnabledSelector(getState(), NOTIFICATION_TYPE.UPCOMING_EVENT)) {
      const upcomingEventReminderInfo = await dispatch(buildOrderNotification({
        notificationType: NOTIFICATION_TYPE.UPCOMING_EVENT, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
      }));
      if (upcomingEventReminderInfo) {
        const { receivers: upcomingEventReceivers, notification: upcomingEventNotification } = upcomingEventReminderInfo;
        if (!_.isEmpty(upcomingEventReceivers)) {
          await dispatch(createOrderNotification(upcomingEventReceivers, upcomingEventNotification));
        }
      }
    }

    if (shouldScheduleEventNotStartedNotification && checkNotificationEnabledSelector(getState(), NOTIFICATION_TYPE.EVENT_NOT_STARTED)) {
      const eventNotStartedReminderInfo = await dispatch(buildOrderNotification({
        notificationType: NOTIFICATION_TYPE.EVENT_NOT_STARTED, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
      }));
      if (eventNotStartedReminderInfo) {
        const { receivers: eventNotStartedReceivers, notification: eventNotStartedNotification } = eventNotStartedReminderInfo;
        if (!_.isEmpty(eventNotStartedReceivers)) {
          await dispatch(createOrderNotification(eventNotStartedReceivers, eventNotStartedNotification));
        }
      }
    }

    if (shouldPostOutsideTerritoryNotification && checkNotificationEnabledSelector(getState(), NOTIFICATION_TYPE.E_OUTSIDE_LOCATION)) {
      const outTettitoryNotifInfo = await dispatch(buildOrderNotification({
        notificationType: NOTIFICATION_TYPE.E_OUTSIDE_LOCATION, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
      }));
      if (outTettitoryNotifInfo) {
        const { receivers: outTettitoryNotifReceivers, notification: outTettitoryNotifNotification } = outTettitoryNotifInfo;
        if (!_.isEmpty(outTettitoryNotifReceivers)) {
          await dispatch(createOrderNotification(outTettitoryNotifReceivers, outTettitoryNotifNotification));
        }
      }
    }

    return true;
  } catch (e) {
    return false;
  }
};

const createOrderNotification = (receivers, notification, refreshUnread = true) => async (dispatch) => {
  try {
    if (SCHEDULED_NOTIFICATIONS_TYPES.includes(notification?.type)) {
      // this clears scheduled notifications such as UPCOMING_EVENT before creating a new version of it
      await dispatch(deleteScheduledNotifications(notification?.type, notification?.details?.orderID));
    }
    const emailBody = buildNotificationDetails(notification)?.join('\n\n');
    const result = await generateOrderNotification({ receivers, notification, emailBody });
    const success = result && OK_STATUS_CODES.includes(result.status);
    if (success && refreshUnread) {
      dispatch(fetchUnreadNotifications(1));
    }
    return success;
  } catch (e) {
    return false;
  }
};

export const deleteScheduledNotifications = (type, orderID) => async () => {
  try {
    const result = await clearScheduledNotification(type, orderID);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const fetchNotificationsData = (readPage, unreadPage, cleanState) => async (dispatch, getState) => {
  try {
    const user = userInfoSelector(getState());
    if (!user) throw Error('No user logged-in');
    const results = await Promise.allSettled([
      dispatch(fetchUnreadNotifications(unreadPage ?? 1, cleanState)),
      dispatch(fetchReadNotifications(readPage ?? 1, cleanState)),
    ]);
    return results;
  } catch (e) {
    return false;
  }
};

const fetchNotifications = ({ pageNumber, isRead, cleanState }) => async (dispatch, getState) => {
  try {
    const user = userInfoSelector(getState());
    if (!user) throw Error('No user logged-in');
    const page = (pageNumber ?? 1);
    const response = await (isRead ? fetchUserReadNotifications(user.uid, page) : fetchUserUnreadNotifications(user.uid, page));
    const data = response?.data ?? [];
    if (cleanState && isRead) dispatch(clearPastNotifications());
    dispatch(isRead ? savePastNotifications(page, data) : saveUnreadNotifications(page, data));
    return data;
  } catch (e) {
    throw e;
    // for testing:
    // await new Promise((resolve) => setTimeout(resolve, 1000)); // THIS IS FOR TESTING
    // if (cleanState) dispatch(clearPastNotifications());
    // dispatch(savePastNotifications(p, mockedData.read[p]));
    // return mockedData.read[p];
  }
};

export const fetchReadNotifications = (p, cleanState) => async (dispatch) => {
  try {
    const data = await dispatch(fetchNotifications({ pageNumber: p ?? 1, cleanState, isRead: true }));
    return data;
  } catch (e) {
    throw e;
    // for testing:
    // await new Promise((resolve) => setTimeout(resolve, 1000)); // THIS IS FOR TESTING
    // if (cleanState) dispatch(clearPastNotifications());
    // dispatch(savePastNotifications(p, mockedData.read[p]));
    // return mockedData.read[p];
  }
};

export const fetchUnreadNotifications = (p, cleanState) => async (dispatch, getState) => {
  try {
    const data = await dispatch(fetchNotifications({ pageNumber: p ?? 1, isRead: false, cleanState }));
    const unreadNotifications = _.flatten(Object.values(unreadNotificationsSelector(getState())));
    dispatch(showNoticeDot(panelTypes.notification, !_.isEmpty(unreadNotifications)));
    return data;
  } catch (e) {
    throw e;
    // for testing:
    // await dispatch(saveUnreadNotifications(p, mockedData.read[p]));
    // const unreadNotifications = _.flatten(Object.values(unreadNotificationsSelector(getState())));
    // dispatch(showNoticeDot(panelTypes.notification, !_.isEmpty(unreadNotifications)));
    // return mockedData.read[p];
  }
};

// const mockedData = {
//   unread: [
//     {
//       notificationID: '36248ea0-1ff9-11ed-861d-0242ac120we2',
//       assigneeID: 'userID123',
//       type: 'BOOKED_EVENT',
//       read: false,
//       creationDate: '2023-02-18T03:01:11Z',
//       expirationDate: '2023-02-18T03:01:11Z',
//       header: 'New event booked',
//       body: {
//         title: '1300 Lafayette East Coop',
//         createdBy: 'Shawn Ashworth',
//         datetime: '2023-02-18T03:01:10Z',
//         tz: {
//           value: 'America/Los_Angeles',
//           label: '(GMT-8:00) Pacific Time',
//           offset: -8,
//           abbrev: 'PST',
//           altname: 'Pacific Standard Time',
//         },
//       },
//       details: {
//         orderID: 'c70iw4usyXDF7vZ0jLbf',
//         location: {
//           city: 'Detroit, MI',
//           zip: '48207-2905',
//         },
//         description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//         contact: {
//           name: 'Emanuele Dalessandro',
//           phone: '(970)889-9416',
//           email: 'testemail@gmail.com',
//         },
//         customer: {
//           name: '1300 Lafayette East Coop',
//           customerID: '0010422829',
//           soldTo: '0010422829',
//         },
//       },
//     },
//     {
//       notificationID: '36248ea0-1ff9-11ed-861d-0242ac120003',
//       type: 'RESCHEDULED_EVENT',
//       read: false,
//       assigneeID: 'userID123',
//       creationDate: '2022-08-19T19:59:23+0000',
//       expirationDate: '2022-09-19T19:59:23+0000',
//       header: 'Event Rescheduled',
//       body: {
//         title: 'Evonik Corporation',
//         datetime: '2022-08-30T19:59:23+0000',
//         tz: {
//           abbrev: 'MDT',
//           altname: 'Mountain Daylight Time',
//           label: '(GMT-6:00) Mountain Time',
//           offset: -6,
//           value: 'America/Boise',
//         },
//         createdBy: 'Mike Renner',
//         text: null,
//       },
//       details: {
//         orderID: 'pShpnqyIbhb1QitQl6gr',
//         location: {
//           city: 'Murdock',
//           zip: '67111-8747',
//         },
//         description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//         contact: {
//           contactID: '79299ra0-1ff9-11ed-861d-0920',
//           name: 'Jay Lario',
//           phone: '620297-3222',
//           email: 'jayfsklario@pixius.net',
//         },
//         customer: {
//           name: 'LARIO OIL & GAS CO.',
//           customerID: '8992099ra0-1ff9-11ed-861d-33',
//           soldTo: '0010668789',
//         },
//       },
//     },
//   ],
//   read: {
//     1: [
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120004',
//         type: 'CANCELED_EVENT',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Canceled Event',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120005',
//         type: 'UPCOMING_EVENT',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Upcoming Event',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120007',
//         type: 'COMPLETED_EVENTS',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Completed Event',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120008',
//         type: 'EDITED_EVENTS',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Change notification',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//     ],
//     2: [
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120009',
//         type: 'RECURRING_FAILED',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Recurring event failed',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120010',
//         type: 'CONFIRMATION_SIGN',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Confirmation Signed',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },

//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120011',
//         type: 'E_OUTSIDE_LOCATION',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Event booked outside territory',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120012',
//         type: 'ESCALATED_EVENT',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Escalated Event',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//     ],
//     3: [
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120013',
//         type: 'EVENT_NOT_STARTED',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Event not started',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120014',
//         type: 'SECONDARY_ADDED',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Secondary instructor added',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120016',
//         type: 'COE_REVIEW_LISTING',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Course review required',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//     ],
//     4: [
//       {
//         notificationID: '36248ea0-1ff9-11ed-861d-0242ac120017',
//         type: 'COE_REVIEW_LISTING',
//         read: false,
//         assigneeID: 'userID123',
//         creationDate: '2022-08-19T19:59:23+0000',
//         expirationDate: '2022-09-19T19:59:23+0000',
//         header: 'Course review required',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: '2022-08-30T19:59:23+0000',
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//           createdBy: 'Mike Renner',
//           text: null,
//         },
//         details: {
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           location: {
//             city: 'Murdock',
//             zip: '67111-8747',
//           },
//           description: 'Date: Feb 24, 2023\nCourse(s):\n• CPR/FIRST AID/AED /STUDEN: 12:00pm - 12:10pm PST \n• FORKLIFT CERT (MIN): 12:10pm - 1:40pm PST \nTotal Revenue: $4552.62 ',
//           contact: {
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: {
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//     ],
//     5: [],
//     6: [],
//   },
// };
