/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-object-spread */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import {
  Field,
  change,
  submit,
  formValueSelector,
} from 'redux-form';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import Container from '../../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../../LayoutBuilders/ContainerItem';
import CustomerAdvancedSearch from './CustomerAdvancedSearch/CustomerAdvancedSearch';
import FASFieldTitle from '../../../../../Forms/CustomFormComponents/FASFieldTitle';
import MDAutoComplete from '../../../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import StyledButton from '../../../../../Button/StyledButton';
import * as ContactsActions from '../../../../../../redux/actions/contacts.actions';
import * as SAPActions from '../../../../../../redux/actions/sap.actions';
import * as ModalsActions from '../../../../../../redux/actions/modals.actions';
import { removeLeadingZeros } from '../../../../../../utils/helpers';
import { COLORS, ORDER_BLOCKED, ROLE_ACCESSES } from '../../../../../../utils/consts';
import { BookingLayoutRules } from '../../../../../../utils/consts/LayoutRules/BookingLayoutRules';
import { hasUserAccessSelector, userInfoSelector } from '../../../../../../redux/selectors/user.selectors';
import SimpleListSelectionDialog from '../../../EventDetailsDialog/DialogSubcomponents/SimpleListSelectionDialog';
import Spinner from '../../../../../SpinnerOverlay/Spinner';
import {
  fetchCustomers,
  saveCustomers,
} from '../../../../../../redux/actions/customers.actions';
import CustomerInfo, { buildAddress } from './CustomerAdvancedSearch/CustomerInfo';

const AddCustomerDialog = ({
  initialOptions, dispatch, customer, mode,
  needsLocSelection, locationsOptions, salesOffice,
}) => {
  const [customerOptions, setCustomerOptions] = useState(initialOptions ?? []);
  const [address, setAddress] = useState(customer ?? false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  // const localCustomer = customer?.Local_Customer ?? '';
  // const regionalCustomer = customer?.regional_customer ?? '';
  // const nationalCustomer = customer?.national_customer ?? '';
  const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  const shouldReloadCustomers = (office) => {
    // only load the customers if the data hasnt already been loaded or if the sales office has changed
    if ((customerOptions ?? []).length === 0) return true;
    if (office == null) return false;
    if (customerOptions?.[0]?.sales_office === office) return false;
    return true;
  };

  const clearCustomers = async () => {
    await dispatch(saveCustomers([]));
  };

  const loadCustomers = async (office) => {
    if (!shouldReloadCustomers(office)) {
      return customerOptions;
    }
    setLoadingCustomers(true);
    let customers = [];
    try {
      customers = await dispatch(fetchCustomers([office]));
      setCustomerOptions(customers);
    } finally {
      setLoadingCustomers(false);
    }
    return customers;
  };

  const onSelectCustomer = (dialogName, val) => {
    const selection = (val ?? {}).selected ?? customer;
    if (selection) {
      dispatch(change('AddEventDialog', 'customer', selection));
      checkCustomer(selection);
      dispatch(ModalsActions.hideModal(dialogName));
      clearCustomers();
    } else {
      showErrorAlert('Select a customer from the table before proceeding');
    }
  };

  const showErrorAlert = (message) => {
    dispatch(ModalsActions.showModal('CREATE_EVENT_ERROR', {
      modalType: 'ERROR_ALERT',
      modalProps: { message },
    }));
  };

  const showAdvancedSearchPanel = () => {
    if (needsLocSelection) {
      openLocationSelectionPopup();
    } else if (salesOffice) {
      loadCustomers(salesOffice).then((customers) => openAdvancedSearch(customers));
    } else {
      openAdvancedSearch(customerOptions);
    }
  };

  const openLocationSelectionPopup = () => {
    const dialogName = 'CUSTOMER_LOC_SELECT_DIALOG';
    dispatch(ModalsActions.showModal(dialogName, {
      modalType: 'FAS_INPUT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 16 },
        padding: 0,
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'sm',
        title: 'SELECT LOCATION',
        content: <SimpleListSelectionDialog
          options={locationsOptions}
          dialogName={dialogName}
          selectable
          onSelection={async (loc, setLoadingState) => {
            setLoadingState(true);
            setTimeout(() => { // this timeuout is to force the filtering of data into another thread so the UI doesnt freeze
              setLoadingState(false);
              dispatch(ModalsActions.hideModal(dialogName));
              if (!shouldReloadCustomers(loc.id)) {
                const locCustomers = (customerOptions ?? []).filter((c) => c.sales_office === loc.id);
                openAdvancedSearch(locCustomers);
              } else {
                loadCustomers(loc.id).then((customers) => openAdvancedSearch(customers));
              }
            }, 100);
          }}
        />,
      },
    }));
  };

  const openAdvancedSearch = (customersToShow) => {
    const dialogName = 'CUSTOMER_ADV_SEARCH_DIALOG';
    const formName = 'CustomerAdvancedSearch';
    dispatch(ModalsActions.showModal(dialogName, {
      modalType: 'FAS_INPUT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 16 },
        padding: 0,
        hideCancel: false,
        cancelText: 'cancel',
        fullWidth: true,
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'SEARCH CUSTOMER',
        content: <CustomerAdvancedSearch dialogName={dialogName} formName={formName} customers={customersToShow} initialSelection={customer} onSubmit={(val) => onSelectCustomer(dialogName, val)} />,
        onConfirm: () => dispatch(submit(formName)),
      },
    }));
  };

  const checkCustomer = (event) => {
    if (event) {
      dispatch(ContactsActions.fetchContactsByCustomerId(event.sold_to));
      setAddress(event);
      dispatch(SAPActions.fetchSAPListings(event.sold_to, event.sales_organization, event.distribution_channel));
      dispatch(change('AddEventDialog', 'courses', [{}]));
    } else {
      dispatch(change('AddEventDialog', 'courses', []));
      dispatch(change('AddEventDialog', 'customerContact', undefined));
      setAddress(undefined);
    }
  };

  const buildMainLabel = (option) => (option ? `${removeLeadingZeros(option.sold_to)}/${option.distribution_channel}${option.name ? ` - ${option.name}` : ''}` : '');
  const buildSubtitle = (option, args) => (option ? `${removeLeadingZeros(option.Route ?? '')}${option.Route ? ((args ?? {}).linebreak ? '\n' : ' - ') : ''}${buildAddress(option, args)}` : '');

  const title = (txt) => <FASFieldTitle title={txt} />;

  const addressLabelComponent = () => <CustomerInfo customer={address} boldLabels />;

  const customersDropdown = () => {
    const disable = !BookingLayoutRules.isEditable(BookingLayoutRules.rules.customer, mode) || !hasEditAccess;

    return (
      <div key="customer" onClick={disable ? null : () => showAdvancedSearchPanel()}>
        <Field
          options={customer ? [customer] : []}
          variant="outlined"
          id="customer"
          name="customer"
          required
          disabled
          component={MDAutoComplete}
          onChange={checkCustomer}
          getLabel={(option) => buildMainLabel(option)}
          getValue={(option) => option.sold_to}
          inputProps={{
            style: disable ? null : { color: 'black' },
          }}
          formHelperTextProps={{
            style: { color: COLORS.CINTAS_RED },
          }}
          renderOption={(option) => {
            const textColor = option.customer_blocked_for_orders === ORDER_BLOCKED.denied ? COLORS.CINTAS_RED : undefined;
            return (
              <div key={option.sold_to} style={{ color: textColor }}>
                {buildMainLabel(option)}
                <br style={{ lineHeight: 1 }} />
                <p style={{
                  fontSize: 12,
                  margin: 0,
                  padding: 0,
                }}
                >
                  {(buildSubtitle(option)).toUpperCase()}
                </p>
              </div>
            );
          }}
          size="small"
          // label="Customer"
          forcePopupIcon={false}
          noBorderRadius
        />
      </div>
    );
  };

  const searchBtn = () => (
    <StyledButton
      variant="contained"
      color="primary"
      width="40px"
      disabled={!BookingLayoutRules.isEditable(BookingLayoutRules.rules.customer, mode) || !hasEditAccess}
      handleButton={() => showAdvancedSearchPanel()}
      buttonContent={loadingCustomers ? spinner() : <SearchIcon />}
    />
  );

  const spinner = () => {
    const spinnerSize = 16;
    return (
      <Spinner
        spinnerStyle={{ height: spinnerSize, width: spinnerSize, color: 'white' }}
        customStyle={{
          maxHeight: spinnerSize,
          maxWidth: spinnerSize,
          marginTop: 4,
          marginBottom: 4,
        }}
      />
    );
  };

  return (
    <ContainerItem flex={12}>
      {title('Customer')}
      <Container spacing={3}>
        <ContainerItem flex={10}>
          {customersDropdown()}
        </ContainerItem>
        <ContainerItem flex={2}>
          {searchBtn()}
        </ContainerItem>
        <ContainerItem flex={12} style={{ paddingTop: 0, marginLeft: 4 }}>
          {address && addressLabelComponent()}
        </ContainerItem>
      </Container>
    </ContainerItem>
  );
};

const selector = formValueSelector('AddEventDialog'); // <-- same as form name

export default _.flow([
  connect((state) => {
    // can select values individually
    const customer = selector(state, 'customer');
    // const isUpdate = selector(state, 'order');
    // or together as a group
    return {
      customer,
      role: userInfoSelector(state).role,
      // isUpdate,
    };
  }),
])(AddCustomerDialog);
