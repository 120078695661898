import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { COLORS } from '../../../../utils/consts';

const CheckboxField = ({
  input, label, onChange, value, withoutForm, style, ...rest
}) => {
  const checkbox = () => (
    <Checkbox
      style={{ color: COLORS.CINTAS_BLUE, ...(style ?? {}) }}
      {...input}
      {...rest}
      onChange={withoutForm
        ? onChange
        : (event, val) => {
          input.onChange(val); input.onBlur(val);
        }}
      label={label}
      checked={withoutForm ? value : !!input.value}
    />
  );

  return (
    withoutForm ? checkbox() : (
      <FormControlLabel
        control={checkbox()}
        label={label}
      />
    )
  );
};

export default CheckboxField;
