import Immutable from 'seamless-immutable';

import {
  SAVE_CERTIFICATIONS, UPDATE_CERTIFICATION, ADD_CERTIFICATION,
} from '../actions/actionTypes/certifications';

const initialState = Immutable({
  data: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CERTIFICATIONS:
      return state.set('data', action.certifications);
    case UPDATE_CERTIFICATION:
      return state.set('data', state.data.map((certification) => ((certification.id === action.certification.id) ? action.certification : certification)));
    case ADD_CERTIFICATION:
      return state.set('data', [...state.data, action.payload]);
    default:
      return state;
  }
};
