import {
  Button,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = () => ({
  innerContainer: {
    padding: 24,
    paddingTop: 0,
  },
  actionsContainer: {
    margin: 24,
    padding: 0,
  },
  bodyText: {
    margin: 0,
    fontWeight: '100',
    fontSize: 18,
  },
});

const Transition = (props) => <Grow {...props} />;

class MDDialog extends React.Component {
  onClose() {
    const { onCancel, hideModal, onClosePopup } = this.props;
    if (onClosePopup) {
      onClosePopup();
    }
    if (onCancel) {
      onCancel();
    } else {
      hideModal();
    }
  }

  onConfirm() {
    const { onConfirm, hideModal } = this.props;
    if (onConfirm) {
      onConfirm();
    } else {
      hideModal();
    }
  }

  render() {
    const {
      title,
      bodyText,
      fullWidth,
      fullScreen,
      cancelText,
      content,
      confirmText,
      onConfirm,
      bodyTextStyle,
      hideCancel,
      disableClosing,
      classes,
      disableBackdropClick,
      maxWidth,
    } = this.props;

    return (
      <Dialog
        open
        disableBackdropClick={disableBackdropClick}
        fullWidth={fullWidth}
        TransitionComponent={Transition}
        maxWidth={maxWidth || 'lg'}
        fullScreen={fullScreen}
        keepMounted
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ position: 'relative' }}>
          {!disableClosing && (
            <IconButton onClick={() => this.onClose()} style={{ position: 'absolute', right: 5, top: 5 }}>
              <CloseIcon />
            </IconButton>
          )}
          <DialogTitle id="alert-dialog-slide-title" style={{ marginRight: 40, marginTop: -3 }}>
            <Typography variant="h5" component="p">
              {title}
            </Typography>
          </DialogTitle>
          <div className={classes.innerContainer}>
            <div id="alert-dialog-slide-description">
              <h5 className={classes.bodyText} style={{ ...bodyTextStyle }}>{bodyText || ''}</h5>
              {content || ''}
            </div>
          </div>
          {(!hideCancel || onConfirm) && (
            <DialogActions className={classes.actionsContainer}>
              {!hideCancel && (
                // <MDButton
                //   title={cancelText || 'Close'}
                //   onClick={() => this.onClose()}
                //   containerStyle={{ margin: 0, marginRight: 20 }}
                //   secondary
                // />
                <Button variant="contained" color="primary" onClick={() => this.onClose()}>
                  {cancelText || 'Close'}
                </Button>
              )}
              {onConfirm && (
                // <MDButton
                //   title={confirmText || 'Confirm'}
                //   onClick={() => this.onConfirm()}
                //   containerStyle={{ margin: 0 }}
                // />
                <Button variant="contained" color="primary" onClick={() => this.onConfirm()}>
                  {confirmText || 'Confirm'}
                </Button>
              )}
            </DialogActions>
          )}
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MDDialog);
