/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import EventPopperWrapper from './EventPopperWrapper';
import EventPopperForm from './EventPopperForm';
import * as OrdersActions from '../../../../redux/actions/orders.actions';
import * as ModalsActions from '../../../../redux/actions/modals.actions';
import { CALENDAR_TYPE, EVENT_TYPE } from '../../../../utils/consts';
import ConfirmationNavButton from '../../../Button/ConfirmationNavButton';
import DuplicateEventButton from '../../../Button/DuplicateEventButton';
import OrderStatusButton from '../../../Button/OrderStatusButton';
import NationalAccountNotesPopover from '../../../NationalAccountDashboard/NationalAccontNotesPopover';
import { fetchNationalAccountById } from '../../../../redux/actions/nationalAccount.actions';
import { getHigherAccountNumber } from '../../../../utils/event.utils';

const EventPopper = ({
  openPopper, anchorEl, onEventEdited, onClosePopper, selectedEvent, dispatch,
  forceInstructorDisplay, disableEditing, calendarType,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [selectedOrder, setSelectedOrder] = React.useState();
  const event = selectedEvent || {};
  const subcontractorGrouppedEvents = (calendarType === CALENDAR_TYPE.location) && selectedEvent?.desc?.subcontracted;

  const loadOrder = async () => {
    if (subcontractorGrouppedEvents) { setLoading(false); return; }

    setLoading(true);
    try {
      if (selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK) {
        const orderObj = await dispatch(OrdersActions.fetchOrderSummary(selectedEvent?.desc?.order ?? ''));
        const nationalAccount = await fetchNationalAccountById(getHigherAccountNumber(orderObj?.customerObj));
        setSelectedOrder({ ...orderObj, nationalAccount });
      } else {
        setSelectedOrder();
      }
    } catch (error) {
      const errMessage = 'Error loading full order details. Please contact an Admin';
      dispatch(ModalsActions.showModal('SHOW_EVENT_POPPER_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: errMessage },
      }));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(async () => {
    // check if selectedEvent && openPopper=true
    if (selectedEvent && openPopper) {
      await loadOrder();
    }
  }, [openPopper]);

  const nationalAccountNotesPopover = () => (
    <NationalAccountNotesPopover account={selectedOrder?.nationalAccount} noBackground style={{ marginLeft: 4 }} />
  );

  const confirmationNavBtn = () => (
    <ConfirmationNavButton orderId={selectedOrder?.id} />
  );

  return (
    openPopper
    && (
      <EventPopperWrapper
        openPopper={openPopper}
        anchorEl={anchorEl}
        loading={loading}
        onClosePopper={onClosePopper}
        selectedEvent={event}
        onEventEdited={onEventEdited}
        order={selectedOrder}
        disableEditing={disableEditing}
        subcontractorGrouppedEvents={subcontractorGrouppedEvents}
      >

        {
          (!subcontractorGrouppedEvents)
            ? (
              <>
                <EventPopperForm forceInstructorDisplay={forceInstructorDisplay} selectedEvent={event} order={selectedOrder} />
                <div style={{ width: '100%', padding: 8 }}>
                  {selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK && <DuplicateEventButton size={30} order={selectedOrder} selectedEvent={selectedEvent} callback={onClosePopper} />}
                  <OrderStatusButton selectedOrder={selectedOrder} selectedEvent={selectedEvent} disableEditing={disableEditing} loadOrder={loadOrder} closePopperHandler={onClosePopper} />
                  {selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK && nationalAccountNotesPopover()}
                  {selectedOrder && confirmationNavBtn()}
                </div>
              </>
            ) : <></>
        }
      </EventPopperWrapper>
    )
  );
};
export default connect()(EventPopper);
