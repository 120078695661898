/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import SwapIcon from '@material-ui/icons/SwapHorizRounded';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import { COLORS, QUOTES_STATUS } from '../../utils/consts';
import StyledButton from './StyledButton';
import SimpleText from '../Text/SimpleText';
import { updateCustomerQuoteStatus } from '../../redux/actions/customers.actions';

const warnings = {
  [QUOTES_STATUS.active]: 'Making a quote active will restore customers access to details.',
  [QUOTES_STATUS.inactive]: 'Making a quote inactive will prevent customers from accessing its details.',
};

const SwitchQuoteStatusButton = ({
  customerID, quoteID, status, onSwitched,
}) => {
  const dispatch = useDispatch();

  const onSwitchStatus = async (newStatus) => {
    try {
      const success = await dispatch(updateCustomerQuoteStatus({ customer: customerID, quote: quoteID, status: newStatus }));
      showToast(success);
      if (onSwitched) onSwitched();
    } catch (e) {
      showToast(false);
    }
  };

  const onRequestSwitchStatus = (newStatus) => {
    const modalName = 'CONFIRM_STATUS_SWITCH';
    dispatch(showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        confirmText: `make ${newStatus}`,
        cancelText: 'CANCEL',
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'CHANGE STATUS',
        content: <SimpleText divStyle={{ padding: 24 }} txt={`Are you sure you want to switch the status of this quote to '${newStatus}' ?\n${warnings[newStatus]}`} />,
        onConfirm: () => {
          dispatch(hideModal(modalName));
          onSwitchStatus(newStatus);
        },
      },
    }));
  };

  const showToast = (success) => {
    dispatch(showModal('QUOTE_UPDATE_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: success ? 'Status updated successfully!' : 'Something went wrong updating the staus. Please, try again!',
      },
    }));
  };

  return (
    <StyledButton
      variant="text"
      color={COLORS.CINTAS_BLUE}
      buttonContent={status}
      handleButton={() => onRequestSwitchStatus((status === QUOTES_STATUS.active) ? QUOTES_STATUS.inactive : QUOTES_STATUS.active)}
      startIcon={<SwapIcon />}
      style={{ margin: 0 }}
    />
  );
};

export default SwitchQuoteStatusButton;
