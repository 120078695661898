/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { instructorIdSelector, reportingInstructorsByLocation } from '../../../../../../redux/selectors/user.selectors';
import { BOOKING_MODE, COLORS } from '../../../../../../utils/consts';
import FASFieldTitle from '../../../../../Forms/CustomFormComponents/FASFieldTitle';
import MDAutoComplete from '../../../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import SimpleText from '../../../../../Text/SimpleText';

const SecondaryTciSelection = ({
  location, noMarginLeft, maxWidth, noLabel, excludedIds, conflictingIds = [], mode, initialValue,
}) => {
  const options = useSelector((state) => (!location ? [] : reportingInstructorsByLocation(state, location))).reduce((prev, tci) => ({ ...prev, [tci.uid]: { label: _.startCase(`${tci.first_name} ${tci.last_name}`.toLowerCase()), id: tci.uid } }), {});
  const primaryTciUids = useSelector((state) => excludedIds ?? [instructorIdSelector(state)]);
  const display = [BOOKING_MODE.booking, BOOKING_MODE.rebooking, BOOKING_MODE.duplicate].includes(mode) || !mode;
  const disable = !location || !display;
  const displayAsLabel = [BOOKING_MODE.editing, BOOKING_MODE.rescheduling].includes(mode) && initialValue;

  const title = (txt) => <FASFieldTitle title={txt} style={{ margin: 0, padding: 0 }} />;
  const text = (txt, style) => <SimpleText txt={txt} style={{ textAlign: 'start', ...(style ?? {}) }} />;
  const disableOptionCheck = (opt) => !opt || conflictingIds.includes(opt);

  return display || displayAsLabel
    ? (
      <div style={{
        width: '100%', marginLeft: noMarginLeft ? 0 : 12, marginBottom: 12,
      }}
      >
        {!noLabel && title('Secondary Instructor')}
        {displayAsLabel ? text(initialValue, {
          padding: 0, margin: 0, fontSize: 15, marginTop: 10,
        }) : (
          <Field
            variant="outlined"
            size="small"
            name="secondaryTci"
            getOptionDisabled={disableOptionCheck}
            renderOption={(opt) => text(options[opt]?.label, {
              color: disableOptionCheck(opt) ? COLORS.CINTAS_RED : null,
              fontStyle: disableOptionCheck(opt) ? 'italic' : 'normal',
            })}
            component={MDAutoComplete}
            options={Object.keys(options).filter((opt) => !primaryTciUids.includes(opt))}
            getLabel={(opt) => options[opt]?.label}
            displayEmpty
            disabled={disable}
            disa
            noBorderRadius
            style={{
              textAlign: 'start',
              borderRadius: 0,
              width: maxWidth ?? '80%',
            }}
          />
        )}
      </div>
    ) : <></>;
};

export default _.flow([
  connect(),
])(SecondaryTciSelection);
