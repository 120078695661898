/* eslint-disable max-len */
import _ from 'lodash';
import {
  Grid,
  Popper,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React, { useState } from 'react';

import { EVENT_TYPE } from '../../../../utils/consts';
import EventPopperHeader, { usePopperHeaderStyles } from './EventPopperHeader';
import Spinner from '../../../SpinnerOverlay/Spinner';
import EventSummaryButton from '../../../Button/EventSummaryButton';
import BaseEventsListPopper from './BaseEventsListPopper';
import { BaseEventsListEntry } from './BaseEventsListTile';

const spinnerSize = 30;

export const useStyles = (props) => makeStyles((theme) => ({
  paper: {
    maxWidth: '400px',
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 500,
    display: 'inline-block',
    maxWidth: props?.maxWidth ?? '450px',
    textAlign: 'start',
    border: '2px solid #e5e5e5',
    background: theme.palette.background.paper,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

const EventPopperWrapper = (props) => {
  const {
    loading, openPopper, disableEditing,
    anchorEl, onClosePopper, children, selectedEvent,
    onEventEdited, order, subcontractorGrouppedEvents,
  } = props;
  const classes = useStyles()();
  const [arrowRef, setArrowRef] = useState(null);
  const headerClasses = usePopperHeaderStyles();
  const shouldDisableEditing = disableEditing && disableEditing(order);

  // const materialsInfo = useSelector(materialsDataSelector);

  const handlePopperClose = () => {
    onClosePopper(anchorEl);
  };

  // TODO: Create title for OPEN_ENROLLMENT EVENTS
  // const oeCourse = materialsInfo && materialsInfo[MATERIAL_TYPE.COURSE][selectedEvent.desc.course];
  // const oeTitle = `Open Enrollment: ${(oeCourse && oeCourse.title) || 'No Courses Found'}`;

  const spinner = () => (
    <Spinner
      customStyle={{ maxHeight: spinnerSize, maxWidth: spinnerSize, margin: 50 }}
      spinnerStyle={{ height: spinnerSize, width: spinnerSize }}
    />
  );

  const regularEventPopper = () => (
    <Grid item xs={12}>
      {
        loading ? spinner() : (
          <>
            <EventPopperHeader
              selectedEvent={selectedEvent}
              onClosePopper={handlePopperClose}
              onEventEdited={onEventEdited}
              withCloseButton
              oeTitle=""
              order={order}
              disableEditing={disableEditing}
            />
            {!shouldDisableEditing && selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK && (
              <EventSummaryButton orderId={order?.id} marginLeft={24} />
            )}
            <MuiDialogContent className={classes.paper}>
              <Divider />
              <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                {children}
              </Grid>
            </MuiDialogContent>
          </>
        )
      }
    </Grid>
  );

  const subcontractedEventsPopper = () => (
    <Grid item xs={12}>
      {
        loading ? spinner() : (
          <BaseEventsListPopper
            title="Subcontracted"
            classes={classes}
            headerClasses={headerClasses}
            onClosePopper={onClosePopper}
            onEventEdited={onEventEdited}
            open={openPopper}
            anchorEl={anchorEl}
            hidePrice
            eventsListEntries={(selectedEvent?.desc?.data ?? []).map((ev) => BaseEventsListEntry.fromEvent(ev))}
          />
        )
      }
    </Grid>
  );

  return (
    <ClickAwayListener onClickAway={onClosePopper}>
      <Popper
        className={classes.popper}
        placement="right"
        open={openPopper}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        {!subcontractorGrouppedEvents ? regularEventPopper() : subcontractedEventsPopper()}
      </Popper>
    </ClickAwayListener>
  );
};

export default _.flow([
  withRouter,
  connect(),
])(EventPopperWrapper);
