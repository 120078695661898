import { Grid } from '@material-ui/core';
import React from 'react';

const Container = ({ children, flex, ...rest }) => {
  const grid = ({ ...r }) => <Grid {...(r ?? {})}>{children}</Grid>;

  return grid({
    container: true, xs: flex, ...(rest ?? {}),
  });
};

export default Container;
