import React from 'react';
import Typography from '@material-ui/core/Typography';

const DeleteConfirmAlert = ({ itemTypeName, action, fontSize }) => (

  <Typography
    color="textSecondary"
    style={{
      verticalAlign: 'middle', padding: '20px', fontSize: fontSize ?? 18, color: 'black',
    }}
  >
    {`Are you sure you want to ${action ?? 'permanently delete'} this ${itemTypeName ?? 'event'}?`}
  </Typography>
);

export default DeleteConfirmAlert;
