/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getTempContactOrders = (usersIds) => baseInstance.post('/orderList', usersIds);

export const getOrderById = (orderId) => baseInstance.get(`/orders/${orderId}`);
export const reassignOrder = (payload, tz, ignoreConflict) => baseInstance.put(`/user/modifyInstructorID?tz=${tz}&&ignoreConflict=${Boolean(ignoreConflict).toString().toLowerCase()}`, payload);

export const getConfirmationOrderById = (orderId) => baseInstance.get(`/orderConfirmation?orderID=${orderId}`);
export const getOrderSummaryById = (orderId) => baseInstance.get(`/orderSummary?orderID=${orderId}`);
export const getOrderSummariesById = (orderIds) => baseInstance.post('/orderSummaries', orderIds);
export const getFilteredOrdersCount = (payload) => baseInstance.post('/orderHistoryMiniCount', payload);
export const getFilteredOrders = (payload) => baseInstance.post('/orderHistoryMini', payload);
export const getOrdersSlow = (payload) => baseInstance.post('/orderHistoryMini', payload);
export const generateConfirmation = (orderId) => baseInstance.post('/order/confirmationurl', ({ orderID: orderId }));
export const requestOrdersCSV = (payload) => baseInstance.post('/orderHistoryMiniCSV', payload);

export const deleteSnapshot = (orderId, token) => baseInstance.delete(`/deleteSnapshot?orderID=${orderId}&token=${token}`);
export const getLastSnapshotToken = (orderId) => baseInstance.get(`/getSnapshotToken?orderID=${orderId}`);
export const getSignatureSnapshot = (orderId, snapshotToken) => baseInstance.get(`/getSignatureSnapshot?orderID=${orderId}&token=${snapshotToken}`);
export const confirmOrder = (orderId, payload) => baseInstance.post(`/orders/${orderId}/sign`, payload);
export const updateOrder = (payload, disableEmail, tz, ignoreConflict) => baseInstance.put(`/updateOrder?disableEmail=${Boolean(disableEmail).toString().toLowerCase()}&tz=${tz}&ignoreConflict=${ignoreConflict ?? Boolean(payload.subcontracted).toString().toLowerCase()}`, payload);
export const updatePoNum = (orderId, payload) => baseInstance.put(`/orders/${orderId}/PO`, payload);
export const updateNotes = (orderId, payload) => baseInstance.put(`/orders/${orderId}/updateNotes`, payload);
export const cancelOrder = (orderId, payload) => baseInstance.post(`/order/cancel/${orderId}`, payload);
export const postCancelledSalesOrder = (payload) => baseInstance.post('/createCancelledSalesOrder', payload);
export const rescheduleOrder = (orderId, payload, tz, reason, notes, fee, isWaived, subcontractor) => baseInstance.put(`/order/reschedule/${orderId}?tz=${tz}&ignoreConflict=${Boolean(subcontractor).toString().toLowerCase()}&fee=${fee ?? 0}&isWaived=${Boolean(isWaived).toString()}${reason ? `&reason=${reason}` : ''}${notes ? `&notes=${notes}` : ''}`, payload);

export const startOrderDelivery = (orderId) => baseInstance.put(`/orders/${orderId}/start`);
export const cancelOrderDelivery = (orderId) => baseInstance.put(`/orders/${orderId}/end`);
export const uploadSignature = (orderId, signature, forceFlag) => baseInstance.put(`/orders/${orderId}/deliverySignature?manual_invoice=${Boolean(forceFlag).toString().toLowerCase()}`, signature);
export const sendCommunication = (payload, type, token, confirmationEmail) => baseInstance.post(`/sendCommunication?confirmationEmail=${Boolean(confirmationEmail).toString().toLowerCase()}&type=${type}${token ? `&snapshotToken=${token}` : ''}`, payload);
export const sendConfirmation = (token, orderId) => baseInstance.post(`/sendConfirmation?snapToken=${token}&orderID=${orderId}`);
export const updateContact = (orderId, contactId) => baseInstance.put(`/order/updateContact/${orderId}?contactID=${contactId}`);
export const updateDeliveryStep = (orderId, deliveryStep) => baseInstance.put(`/orders/deliveryStep?orderID=${orderId}`, ({ deliveryStep }));

export const shouldRebook = (orderId) => baseInstance.get(`/shouldRebook/${orderId}`);
