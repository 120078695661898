import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React from 'react';

const NewMaterialModalContent = withStyles((theme) => ({
  root: {
    // minWidth: 800,
    [theme.breakpoints.down('sm')]: {
      minWidth: 50,
    },
  },
}))(MuiDialogContent);

const NewMaterialModalWrapper = ({ children }) => (
  <NewMaterialModalContent dividers>
    {children}
  </NewMaterialModalContent>
);

export default NewMaterialModalWrapper;
