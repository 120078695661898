import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { COLORS } from '../../../../utils/consts';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {

    borderRadius: 0,
    width: 20,
    height: 20,
    border: `1px solid ${COLORS.CINTAS_GRAY_BORDER}`,

    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    // backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      //   backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      //   background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    // backgroundColor: '#012169',
    backgroundColor: (props) => (props.disabled ? '#ACACAC' : '#012169'),
    // color:'blue',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,

      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='15' height='14' viewBox='-3 -3 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.57143L4.27273 10L13 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E \")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});
const FASCheckboxField = (props) => {
  const {
    input, value, onChange, label, touched, error, hideErrorHelper, isDelete, ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            className={classes.root}
            disableRipple
            color={isDelete ? 'primary' : 'default'}
            checkedIcon={isDelete ? <DeleteIcon />
              : <span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...(input ?? {})}
            {...rest}
            label={label}
            checked={input ? !!input.value : value}
            onChange={(event, val) => {
              if (input) {
                input.onChange(val); input.onBlur(val);
              }
              if (onChange) {
                onChange(val);
              }
            }}
          />
        )}
        label={label}
      />
      {!hideErrorHelper && <FormHelperText>{(touched && error) || ' '}</FormHelperText>}
    </>
  );
};

export default FASCheckboxField;
