import _ from 'lodash';
import { useSelector } from 'react-redux';

const Permission = (props) => {
  const { children, whitelist } = props;
  if (!whitelist) {
    return children;
  }

  const currentUserRole = useSelector((state) => state.auth.data.role);

  let isAllowed = false;
  if (_.includes(whitelist, currentUserRole)) {
    isAllowed = whitelist;
  }
  return (isAllowed ? children : null);
};

export default Permission;
