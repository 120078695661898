/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import CustomerInfo from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CustomerAdvancedSearch/CustomerInfo';
import FASFieldTitle from '../Forms/CustomFormComponents/FASFieldTitle';
import SimpleText from '../Text/SimpleText';
import CustomerContactsList from './CustomerContactsList';
import CustomerOrdersTabView from './CustomerOrdersTabView';
import AssignedCustomerTciCard from './AssignedCustomerTciCard';
import Card from '../LayoutBuilders/Card';
import CreateCustomerQuoteButton from '../Button/CreateCustomerQuoteButton';
import BackStepButton from '../Button/BackStepButton';

const CustomerOrdersDashboardStep = ({ customer, onPrevStep, regions }) => {
  const [reloadTable, setReloadTable] = useState();
  const title = (txt, style) => <FASFieldTitle title={txt} style={{ fontWeight: 'bold', ...(style ?? {}) }} />;
  const text = (txt, style) => (<SimpleText txt={txt} style={{ ...(style ?? {}) }} />);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const backBtn = () => (<BackStepButton label="Back To Customer Search" onClick={onPrevStep} />);

  const infoEntry = (label, value) => (
    <>
      {title(label)}
      {text(value)}
    </>
  );

  const customerInfoRow = () => (
    container({
      style: { justifyContent: 'flex-start' },
      children: [
        item({ flex: 5, children: [customerInfo()] }),
        item({ flex: 5, style: { padding: '0px 20px' }, children: [contactInfo()] }),
        item({ flex: 2, style: { width: '100%' }, children: [tciInfo()] }),
      ],
    })
  );

  const customerInfo = () => (
    <Card
      title="Customer Information"
      style={{ maxHeight: 300, height: '100%' }}
    >
      <>
        {container({
          style: { justifyContent: 'flex-start' },
          children: [
            item({ flex: 3, children: [infoEntry('Name', _.startCase((customer?.name).toLowerCase()))] }),
            item({ flex: 3, children: [infoEntry('Sold-To', customer?.sold_to)] }),
            item({ flex: 3, children: [infoEntry('FAS Location', `${customer?.sales_office} - ${(regions?.[customer?.sales_office] ?? {}).region_label ?? ''}`)] }),
            item({ flex: 3, children: [infoEntry('Distribution Channel', customer?.distribution_channel ?? '')] }),
          ],
        })}
        <CustomerInfo customer={customer} boldLabels noNotes flexValues={[6, 3, 3]} />
      </>
    </Card>
  );

  const tciInfo = () => <AssignedCustomerTciCard customer={customer} />;

  const contactInfo = () => <CustomerContactsList customer={customer} />;

  const actionsRow = () => container({
    style: { justifyContent: 'space-between' },
    children: [
      item({ flex: 'auto', children: [backBtn()] }),
      item({ flex: 'auto', children: [<CreateCustomerQuoteButton customer={customer} onCreated={(quoteData) => setReloadTable(quoteData)} />] }),
    ],
  });

  const tabView = () => <CustomerOrdersTabView customer={customer} reloadOrders={reloadTable} />;

  return (
    <>
      {actionsRow()}
      {customerInfoRow()}
      {tabView()}
    </>
  );
};

export default _.flow(
  connect(),
)(CustomerOrdersDashboardStep);
