/* eslint-disable max-len */
import React from 'react';
import { COLORS } from '../../utils/consts';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import ShapeFactory from '../Shapes/ShapeFactory';
import SimpleText from '../Text/SimpleText';

const UserAccountCard = ({
  title, children, minHeight, maxHeight,
}) => {
  const container = ({ child, ...rest }) => <Container {...(rest ?? {})}>{child}</Container>;
  const item = ({ child, ...rest }) => <ContainerItem {...(rest ?? {})}>{child}</ContainerItem>;

  const box = ({ child, style, ...rest }) => <ShapeFactory shape="rectangle" {...(rest ?? {})} width="100%" height="100%" style={{ ...(style ?? {}), border: `1px solid ${COLORS.CINTAS_LIGHT_GRAY}` }}>{child}</ShapeFactory>;

  const header = () => box({
    style: { backgroundColor: COLORS.CINTAS_BLUE, padding: '8px 20px' },
    child: <SimpleText txt={`${(title ?? '').toUpperCase()}`} fontSize={18} style={{ fontWeight: 'bold', color: COLORS.CINTAS_WHITE }} />,
  });

  const content = () => box({
    child: children,
    style: { minHeight, maxHeight },
  });

  return container({
    direction: 'column',
    spacing: 1,
    style: { width: '100%' },
    child: [
      item({ flex: 12, child: header() }),
      item({ flex: 12, child: content() }),
    ],
  });
};

export default UserAccountCard;
