/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Divider, InputAdornment } from '@material-ui/core';
import {
  change, Field, getFormValues, reduxForm,
} from 'redux-form';
import {
  COLORS, DEFAULT_CURRENCY, MATERIAL_STATUS, MATERIAL_TYPE,
} from '../../../../../utils/consts';
import NO_IMAGE_AVAILABLE from '../../../../../assets/images/no_image_available.png';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import AddonItem from '../EventDialogControl/AddonItem';
import { getPricing } from '../EventDialogControl/CourseSelect';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import { /* fetchPricing, */ saveAddOnsPrices } from '../../../../../redux/actions/pricing.actions';
import StyledButton from '../../../../Button/StyledButton';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import { materialCategoriesSelector } from '../../../../../redux/selectors/settings.selectors';
import MaterialCategoryTile from './MaterialCategoryTile';
// import SimpleText from '../../../../Text/SimpleText';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import { listingsSelector } from '../../../../../redux/selectors/sap.selectors';
import { materialsDataSelector } from '../../../../../redux/selectors/materials.selector';

const formName = 'CustomAddonsSelection';
const minListWidth = 500;
const minListHeight = 400;
const viewType = { CAT: 'cat', LIST: 'li' };
const CustomAddonsDialog = ({
  onAddCustomAddon,
  customer,
  addOnPricing,
  options,
  eventType,
  courseIdx,
  course,
  mode,
  query,
  curCategory,
  categoriesData,
  saveData, // function that is called to save the data retrieved. Data is passed to it as an arg in the same format as single objects in data variable below
  dataPath, // state path to data which is a list of objects [{ pricing: [ { pricing_obj }, ... ], addon: { addon_object } }, ...]
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => _.get(state, `form.${dataPath}`));
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState();
  const [currentPricing, setCurrentPricing] = React.useState({ amount: 0, currency: DEFAULT_CURRENCY });
  const usedCategoryIDs = _.uniq(options.map((a) => a.category ?? 'uncategorized'));
  const [view, setView] = React.useState(viewType.CAT);
  // console.log('TEST - categoriesData', categoriesData);
  // console.log('TEST - usedCategoryIDs', usedCategoryIDs);
  const categories = categoriesData.filter((cat) => usedCategoryIDs.includes(cat.id));
  const listingsData = useSelector(listingsSelector);
  // console.log('TEST - categories', categories);

  React.useEffect(async () => {
    try {
      if (data) {
        data.forEach((info) => {
          dispatch(saveAddOnsPrices(info?.addon?.code ?? '', info?.addon?.pricing ?? []));
        });
      }
      setLoading(false);
    } catch (e) { }
  }, []);

  React.useEffect(() => {
    checkUpdateView();
  }, [query]);

  const checkUpdateView = () => {
    if (_.isEmpty(query ?? '') && view !== viewType.CAT) {
      dispatch(change(formName, 'category', undefined));
      setView(viewType.CAT);
    } else if (!_.isEmpty(query ?? '') && view !== viewType.LIST) {
      setView(viewType.LIST);
    }
  };

  const handleBackToCategories = () => {
    setView(viewType.CAT);
    dispatch(change(formName, 'category', undefined));
    dispatch(change(formName, 'query', ''));
  };

  const onCategorySelected = (category) => {
    dispatch(change(formName, 'category', category));
    setView(viewType.LIST);
  };

  const onSelected = (addon, pricing) => {
    if (selected && addon.id === selected.id) return;
    setSelected(addon);
    setCurrentPricing(pricing);
  };

  const footer = () => (
    <Container style={{ justifyContent: 'end' }}>
      <ContainerItem>
        <StyledButton
          variant="contained"
          color="primary"
          buttonContent="ADD"
          disabled={!selected}
          handleButton={() => onAddCustomAddon(selected, currentPricing)}
          style={{ paddingLeft: 30, paddingRight: 30 }}
        />
      </ContainerItem>
    </Container>
  );

  const scrollView = ({ children }) => (
    <div style={{
      padding: 10, paddingTop: 0, height: minListHeight, width: minListWidth, overflow: 'scroll',
    }}
    >
      <Container
        spacing={0}
        style={{ justifyContent: 'center', padding: '20px 0px', overflow: 'scroll' }}
      >
        {children}
      </Container>
    </div>
  );

  const renderCategory = (category) => <MaterialCategoryTile category={category} onClick={() => onCategorySelected(category)} />;

  const renderOption = (addon, i) => {
    let price;
    let currency = DEFAULT_CURRENCY;
    if (data) {
      const { pricing } = (_.find(addOnPricing, { material: addon.code }) ?? {});
      const marketPricing = _.find(pricing, { Customer: '-1' });
      const customerPricing = _.find(pricing, { Customer: customer.sold_to });
      const selectedPricing = customerPricing || marketPricing || {};
      price = getPricing(selectedPricing, customer.distribution_channel);
      price = Number.isNaN(price) ? 0.0 : price;
      currency = selectedPricing.Curr ?? currency;
    }
    const preexisting = course.addons && _.find(course.addons, ({ id: addon.id }));
    const isSelected = selected && selected.id === addon.id;

    const addonItem = ({
      ...addon,
      courseidx: courseIdx,
      paticipants: preexisting ? preexisting.qty : course.participants,
      course: `${course.course.title}`,
      price,
      currency,
      noAvailableListing: !((_.find(listingsData, { Material: addon.code }) ?? {}).inlcuded ?? true),
    });

    return (
      <ContainerItem
        onClick={addonItem.noAvailableListing ? null : () => onSelected(addon, { amount: price, currency })}
        style={{ border: `1px solid ${isSelected ? COLORS.CINTAS_BLUE : COLORS.CINTAS_WHITE}` }}
      >
        <AddonItem
          eventType={eventType}
          key={`custom-addon${i}-${price}`}
          mode={mode}
          notEditable
          customer={customer}
          addonItem={addonItem}
          loadPrice={!price}
          onPriceLoaded={(pricing) => {
            if (saveData) {
              // if this is the last addon to load, send all the pricing data to the parents
              saveData({
                pricing,
                addon: addonItem,
              });
            }
          }}
          selected
          price={price}
          currency={currency}
        />
      </ContainerItem>
    );
  };

  // const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;
  const title = (txt, style, rest) => <FASFieldTitle title={(txt ?? '').toUpperCase()} style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, ...(style ?? {}) }} {...(rest ?? {})} />;

  const searchBar = () => (
    <Container style={{
      justifyContent: 'center',
      margin: 0,
      paddingTop: 10,
      paddingBottom: 10,
    }}
    >
      <ContainerItem flex={10}>
        <Field
          id="query"
          name="query"
          component={MDTextInputField}
          noErrorLabel
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          withEnterTrigger
          placeholder={`Search materials ${curCategory && curCategory.title ? `in '${(curCategory.title ?? '').toLowerCase()}' ` : ''}...`}
          size="small"
          type="text"
          variant="outlined"
          required
        />
      </ContainerItem>
    </Container>
  );

  const categoriesToolbar = () => title('Select a category');

  const listToolbar = () => (
    <Container spacing={0} style={{ justifyContent: 'space-between', width: '100%' }}>
      <StyledButton
        buttonContent="back"
        handleButton={handleBackToCategories}
        color="primary"
        variant="text"
        style={{ fontSize: 16 }}
        startIcon={<BackIcon style={{ fontSize: 16 }} />}
      />
      {curCategory && curCategory?.title && title(curCategory.title)}
    </Container>
  );

  const toolbar = () => (
    <Container style={{
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 40,
      padding: '0px 20px',
    }}
    >
      {view === viewType.CAT ? categoriesToolbar() : listToolbar()}
    </Container>
  );

  const spinner = () => <Spinner customStyle={{ margin: 0, paddingTop: '45%' }} />;
  const divider = (noMargin) => <Divider style={{ margin: 0, marginBottom: noMargin ? 0 : 12 }} />;

  return (
    <div style={{ height: minListHeight + (minListHeight / 2.5), width: '98%' }}>
      {
        loading
          ? spinner()
          : [
            searchBar(),
            divider(true),
            ...(!_.isEmpty(categories ?? []) || query ? [toolbar(), divider(true)] : []),
            scrollView({ children: (view === viewType.LIST || _.isEmpty(categories ?? [])) ? options.map(renderOption) : categories.map(renderCategory) }),
            divider(),
            footer(),
          ]
      }
    </div>
  );
};

export default _.flow([
  connect((state) => {
    const vals = (getFormValues(formName)(state) ?? {});
    const { category, query } = vals;
    const currQuery = (query ?? '').toLowerCase();
    let options = Object.values(materialsDataSelector(state)[MATERIAL_TYPE.ADD_ON]).filter((mat) => (mat.status !== MATERIAL_STATUS.HIDDEN) && ((mat.title ?? '').toLowerCase().includes(currQuery) || (mat.code ?? '').includes(currQuery)));

    if (category && category.id) {
      options = [...options].filter((opt) => (category.id === 'uncategorized' ? !(opt.category) : (opt.category === category.id)));
    }
    return ({
      options,
      addOnPricing: state.pricing.addons,
      categoriesData: [...(materialCategoriesSelector(state) ?? []), ({
        id: 'uncategorized',
        title: 'not-categorized',
        imgURL: NO_IMAGE_AVAILABLE,
      })],
      query: currQuery,
      curCategory: category,
    });
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {
      query: '',
    },
  }),
])(CustomAddonsDialog);
