/* eslint-disable import/prefer-default-export */
import { baseInstance } from './instances/baseInstance';

export const fetchContactsByCustomerId = (id) => (
  baseInstance.get(`/sap/contacts?sapcustomerid=${id}`)
);

export const fetchContact = (contactId, customerId) => (
  baseInstance.get(`/sap/contacts?sapcustomerid=${customerId}&sapcontactid=${contactId}`)
);
