import _ from 'lodash';
import { change, reduxForm, submit } from 'redux-form';
import { Autocomplete, TextField } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { createMuiTheme, makeStyles, Paper } from '@material-ui/core';
import { MuiThemeProvider } from 'material-ui/styles';
import React, { useState } from 'react';

import { COLORS } from '../../../utils/consts';
import StyledButton from '../../Button/StyledButton';

import * as ModalsActions from '../../../redux/actions/modals.actions';
import * as UsersActions from '../../../redux/actions/users.actions';
import Spinner from '../../SpinnerOverlay/Spinner';

const getMuiTheme = () => () => createMuiTheme(
  {
    overrides: {
      MUIDataTable: {
        root: {
          zIndex: 1,
        },
        paper: {
          zIndex: 1,
        },
        responsiveBase: {
          zIndex: 1,
        },
      },
      MUIDataTableBodyCell: {
        root: {
          padding: 8,
        },
      },
      MuiIconButton: {
        root: {
          color: COLORS.CINTAS_BLUE,
        },
      },
      MUIDataTableFilterList: {
        root: {
          paddingBottom: 10,
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: COLORS.CINTAS_WHITE,
          fontSize: 16,
          textTransform: 'uppercase',
        },
        fixedHeader: {
          backgroundColor: COLORS.CINTAS_BLUE,
          color: COLORS.CINTAS_WHITE,
        },
        toolButton: {
          color: COLORS.CINTAS_WHITE,
        },
        sortActive: {
          color: COLORS.CINTAS_WHITE,
        },
      },
      MuiTableSortLabel: {
        root: {
          color: COLORS.CINTAS_WHITE,
          // if you want to have icons visible permanently
          // '& $icon': {
          //   opacity: 1,
          //   color: COLORS.CINTAS_WHITE
          // },
          '&:hover': {
            color: COLORS.CINTAS_WHITE,

            '&& $icon': {
              opacity: 1,
              color: COLORS.CINTAS_WHITE,
            },
          },
          '&$active': {
            color: COLORS.CINTAS_WHITE,
            // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
            '&& $icon': {
              opacity: 1,
              color: COLORS.CINTAS_WHITE,
            },
          },
        },
      },
    },
  },
);

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0px',
      },
    },
  },
});

const ManageTCICerts = ({
  dispatch, handleSubmit, uid, onCertAdded,
}) => {
  const classes = useStyles();
  const certs = useSelector((state) => state.certifications.data);
  const form = useSelector((state) => state.form.TCICertsForm);
  const [submitting, setSubmitting] = useState(false);

  const options = _(certs)
    .filter((c) => c.options === 'certification')
    .sortBy('title')
    .value();

  const submitCertifications = async (values) => {
    try {
      setSubmitting(true);
      dispatch(submit('TCICertsForm'));
      const newCerts = _.map(values.certifications, (v) => v.id);
      await dispatch(UsersActions.updateUserById(uid, newCerts));
      await onCertAdded(uid, newCerts);
      dispatch(ModalsActions.showModal('UPDATE_CERTS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: { message: 'Certifications successfully updated' },
      }));
      dispatch(ModalsActions.hideModal('MANAGE_TCI_CERTS'));
    } catch (error) {
      const message = 'Error updating certifications. Please try again!';
      dispatch(ModalsActions.showModal('UPDATE_CERTS_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { message },
      }));
    } finally {
      setSubmitting(false);
    }
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20, width: 20, padding: 0, margin: 0, color: COLORS.CINTAS_WHITE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <form>
        {/* <FASTitle title="Location Settings" customStyle={{ marginTop: 0 }} /> */}
        <MuiThemeProvider theme={getMuiTheme()}>
          <Autocomplete
            style={{ width: 480, marginBottom: 24 }}
            multiple
            id="certifications"
            options={options || []}
            getOptionLabel={(option) => option.title}
            value={form?.values?.certifications || []}
            onChange={(event, values) => dispatch(change('TCICertsForm', 'certifications', values))}
            ChipProps={{
              size: 'small',
              color: 'primary',
            }}
            renderInput={(params) => (
              <TextField
                classes={{
                  root: classes.root,
                }}
                {...params}
                variant="outlined"
                placeholder="Certifications"
                size="small"
              />
            )}
          />
        </MuiThemeProvider>
        <StyledButton variant="contained" color="primary" buttonContent={submitting ? spinner() : 'SUBMIT'} type="submit" handleButton={handleSubmit(submitCertifications)} />
      </form>
    </Paper>
  );
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'TCICertsForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(ManageTCICerts);
