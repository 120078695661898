/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
// import DoughnutGraphCard from '../../components/Calendar/CalendarComponents/Cards/DoughnutGraphCard';
// import BarGraphCard from '../../components/Calendar/CalendarComponents/Cards/BarGraphCard';
// import LineGraphCard from '../../components/Calendar/CalendarComponents/Cards/LineGraphCard';
// import ArrowIndicatorCard from '../../components/Calendar/CalendarComponents/Cards/ArrowIndicatorCard';
import {
  change, getFormValues, reduxForm, submit,
} from 'redux-form';
import ScheduleCard from '../../components/Calendar/CalendarComponents/Cards/ScheduleCard';
import CalendarTable from '../../components/Calendar/CalendarTable';
import * as MaterialsActions from '../../redux/actions/materials.actions';
import * as LocationsActions from '../../redux/actions/locations.actions';
import * as CommissionLevelsActions from '../../redux/actions/commissionLevels.actions';
import * as OrdersActions from '../../redux/actions/orders.actions';
// import * as EventsActions from '../../redux/actions/events.actions';
// import * as CustomersActions from '../../redux/actions/customers.actions';
// import * as CustomersActions from '../../redux/actions/customers.actions';
import { hasUserAccessSelector, userInfoSelector } from '../../redux/selectors/user.selectors';
import { defaultView, ROLE_ACCESSES } from '../../utils/consts';
import * as UsersActions from '../../redux/actions/users.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { initialDataLoadedSelector } from '../../redux/selectors/utils.selectors';
import { fetchConfigs } from '../../redux/actions/settings.actions';
import { materialsDataSelector } from '../../redux/selectors/materials.selector';
import { allEventsListSelector } from '../../redux/selectors/events.selectors';
import { calculateCalendarDatesRange } from '../../utils/event.utils';
import { fetchNotificationsData } from '../../redux/actions/notifications.actions';
import { fetchTasksData } from '../../redux/actions/tasks.actions';

const formName = 'CalendarTable';

const CalendarView = ({
  events: { data: eventsData },
  materialsInfo,
  user,
  dispatch,
  loadingTciEvents,
  initialDataLoaded,
  selectedTci,
}) => {
  const [loading, setLoading] = useState(!initialDataLoaded);
  const [eventsPromise, setEventsPromise] = useState(undefined);
  const hasCreateAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));
  const viewsTcisCalendar = useSelector((state) => !hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));
  const isAdmin = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.advancedAdmin]));
  const hideUpcoming = useMediaQuery('(min-width:1280px)');

  useEffect(async () => {
    if (user?.role && !initialDataLoaded) {
      await Promise.all([
        await dispatch(MaterialsActions.fetchMaterials()),
        await dispatch(CommissionLevelsActions.fetchCommissionLevels()),
        await dispatch(LocationsActions.fetchLocations()),
        await dispatch(fetchNotificationsData(1, 1)),
        await dispatch(fetchTasksData()),
        (!isAdmin) && await dispatch(UsersActions.getTCIs()), // for ADMIN role, the TCIs are pulled from users.actions `fetchCurrentUser()`
        (!viewsTcisCalendar) && await dispatch(OrdersActions.fetchOrders()),
        await dispatch(fetchConfigs()),
        await dispatch(UtilsActions.setInitialDataLoaded(true)),
      ]);
      setLoading(false);
    }
    return () => { };
  }, [user?.role ?? '']);

  const abortPreviousEventsRequest = async () => {
    try {
      await eventsPromise?.abort();
    } catch (e) { }
  };

  const loadViewportEvents = async (date, v) => {
    try {
      setLoading(true);
      await abortPreviousEventsRequest();
      dispatch(change(formName, 'viewport', calculateCalendarDatesRange(date, v)));
      dispatch(change(formName, 'openCancelledEventsPopover', false));
      const promise = dispatch(OrdersActions.fetchOrders(true));
      setEventsPromise(promise);
      await promise;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={4} direction="row">
        {/* <Grid item lg={3} md={6} xs={3}>
            <DoughnutGraphCard />
          </Grid>
          <Grid item lg={3} md={6} xs={3}>
            <LineGraphCard />
          </Grid>
          <Grid item lg={3} md={6} xs={3}>
            <BarGraphCard />
          </Grid>
          <Grid item lg={3} md={6} xs={3}>
            <ArrowIndicatorCard />
          </Grid> */}
        <Grid item lg={3} md={6} xs={3}>
          {viewsTcisCalendar ? <></> : (hideUpcoming && (
            <ScheduleCard
              materialsInfo={materialsInfo}
            />
          ))}
        </Grid>
        <Grid item lg={viewsTcisCalendar ? 12 : 9} md={12} xs={12}>
          <CalendarTable
            disableEventCreation={(viewsTcisCalendar && (!selectedTci || !selectedTci?.uid)) || !hasCreateAccess}
            loading={loading || loadingTciEvents}
            loadViewportEvents={loadViewportEvents}
            events={eventsData}
            materialsInfo={materialsInfo}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  events: {
    data: allEventsListSelector(state),
  },
  materialsInfo: materialsDataSelector(state),
  settings: state.settings,
  loadingTciEvents: state.users?.data?.loadingTciEvents,
  selectedTci: state.users?.data?.selectedTci,
  initialDataLoaded: initialDataLoadedSelector(state),
  user: userInfoSelector(state) ?? {},
  view: getFormValues(formName)(state).view,
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
    initialValues: {
      event: {},
      view: defaultView,
      showRevenue: false,
      viewport: calculateCalendarDatesRange(Date.now(), defaultView),
    },
  }),
])(CalendarView);
