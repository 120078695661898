/* eslint-disable no-nested-ternary */
import React from 'react';
import { COLORS } from '../../utils/consts';
import Spinner from '../SpinnerOverlay/Spinner';
import StyledButton from './StyledButton';

const ViewMoreButton = ({
  onClick, loading, disabled, noMorePages,
}) => {
  const spinner = () => {
    const size = 20;
    return (
      <Spinner
        spinnerStyle={{
          height: size, width: size, padding: 0, margin: 0, color: COLORS.CINTAS_BLUE,
        }}
        customStyle={{
          maxHeight: size,
          maxWidth: size,
          margin: 0,
          padding: 0,
          marginTop: '20%',
          marginLeft: '45%',
        }}
      />
    );
  };

  return (
    <div style={{ textAlign: 'center', width: '100%', marginTop: 4 }}>
      <StyledButton
        variant="text"
        color="primary"
        style={{ fontSize: 12 }}
        disabled={loading || disabled || noMorePages}
        buttonContent={loading ? spinner() : (noMorePages ? 'end reached' : 'view more')}
        handleButton={onClick}
      />
    </div>
  );
};

export default ViewMoreButton;
