import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const MDSnackbarContent = (props) => {
  const { classes, onClose, message } = props;
  return (
    <SnackbarContent
      className={classes.snackbarContent}
      aria-describedby="client-snackbar"
      message={<b className={classes.alertMessage} id="message-id">{message}</b>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};

export default MDSnackbarContent;
