/* eslint-disable import/no-cycle */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import LocationsDefault from '../../../../components/Settings/Other/LocationsDefault';
import SettingsFrame from '../SettingsFrame';
import HolidaysDefault from '../../../../components/Settings/Other/HolidaysDefault';
import { fetchConfigs } from '../../../../redux/actions/settings.actions';
import Spinner from '../../../../components/SpinnerOverlay/Spinner';

const DefaultsSettingsView = ({
  match: { path },
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const loadConfigs = async () => {
    try {
      await dispatch(fetchConfigs());
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadConfigs();
  }, []);

  const spinner = () => <Spinner customStyle={{ marginTop: '10%' }} />;

  return (
    <SettingsFrame
      path={path}
    >
      {
        loading ? spinner() : [
          <LocationsDefault />,
          <HolidaysDefault />,
        ]
      }
    </SettingsFrame>
  );
};

export default _.flow([connect()])(DefaultsSettingsView);
