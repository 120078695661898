/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import {
  SAVE_UPCOMING_ORDERS, SET_ORDER_DELIVERY_STATUS, SAVE_ORDERS, UPDATE_UPCOMING_ORDER,
} from './actionTypes/orders';

import * as OrdersAPI from '../../api/orders.api';
import {
  fetchEvents, fetchTciEvents, resetTciEvents, fetchUpcomingEvents, fetchTciUpcomingEvents, resetTciUpcomingEvents, fetchCancelledEventsDailyCount,
} from './events.actions';
import { OK_STATUS_CODES, ROLE_ACCESSES } from '../../utils/consts';
import { hasUserAccessSelector, reportingTcisUids, userInfoSelector } from '../selectors/user.selectors';
import { customerZipToTzLabel } from '../../utils/dateUtils';
import { timezoneSelector } from '../selectors/utils.selectors';
import blankImage from '../../assets/images/blank_image.jpeg';
import { upcomingEventsDataSelector } from '../selectors/events.selectors';
import { buildOrderNotification, deleteScheduledNotifications, postOrderNotification } from './notifications.actions';
import { NOTIFICATION_TYPE } from '../../utils/consts/notifications.consts';
import { calculateOrderChanges } from '../../utils/event.utils';
import { buildNotificationDetails } from '../../utils/notification.utils';
import { postOrderTask } from './tasks.actions';
import { TASK_SUBTYPE, TASK_TYPE } from '../../utils/consts/tasks.consts';

export const saveUpcomingOrders = (payload) => ({
  type: SAVE_UPCOMING_ORDERS,
  payload,
});

export const saveOrders = (payload) => ({
  type: SAVE_ORDERS,
  payload,
});

export const setOrderDeliveryStatus = (orderId, delivery) => ({
  type: SET_ORDER_DELIVERY_STATUS,
  payload: { orderId, delivery },
});

export const updateUpcomingOrder = (payload) => ({
  type: UPDATE_UPCOMING_ORDER,
  payload,
});

export const fetchOrder = (orderId) => async (/* dispatch */) => {
  try {
    const response = await OrdersAPI.getOrderById(orderId);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const reassignOrder = (orderId, instructorId, secondaryTci, secondaryChanged, noStateRefresh, ignoreConflict) => async (dispatch, getState) => {
  try {
    const response = await OrdersAPI.reassignOrder({ orderId, instructorId, secondaryTci }, timezoneSelector(getState()), ignoreConflict);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      if (!noStateRefresh) {
        const reportingTci = getState().users?.data?.selectedTci;
        if (reportingTci) {
          await dispatch(fetchTciOrders(reportingTci.uid));
        } else {
          await dispatch(fetchOrders());
        }
      }
      if (secondaryChanged) {
        dispatch(postOrderNotification({
          notificationType: NOTIFICATION_TYPE.SECONDARY_ADDED,
          eventID: orderId,
          loadAsOrder: true,
        }));
      }
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateOrder = (payld, userTzCode, subcontractor, disableEmail, ignoreStateSave, ignoreConflict) => async (dispatch, getState) => {
  try {
    const payload = { ...payld };
    if (!payload.customertz || payload.customertz === '') {
      payload.customertz = customerZipToTzLabel(payload.customerObj?.postal_code, { defaultVal: userTzCode });
    }
    payload.subcontracted = Boolean(subcontractor);
    const originalOrder = await dispatch(fetchOrderSummary(payload.id));
    const response = await OrdersAPI.updateOrder(payload, true, timezoneSelector(getState()), ignoreConflict);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      if (!ignoreStateSave) {
        await dispatch(fetchOrders());
      }
      dispatch(postOrderNotification({
        notificationType: NOTIFICATION_TYPE.EDITED_EVENTS,
        eventID: payload?.id,
        loadAsOrder: true,
        getActionInfo: () => ({ changesLabels: calculateOrderChanges(originalOrder, payload) }),
      }));
      if (response.data.emailToken) return response.data;
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const cancelOrder = (orderId, eventId, payload, cost, noTask) => async (dispatch) => {
  try {
    const response = await OrdersAPI.cancelOrder(orderId, payload);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      await dispatch(fetchOrders());
      dispatch(postOrderNotification({
        notificationType: NOTIFICATION_TYPE.CANCELED_EVENT,
        eventID: eventId,
        getActionInfo: (o, e) => ({ fee: e?.cancellationFee }),
      }));
      if (payload?.isWaived && cost > 0 && !noTask) {
        dispatch(postOrderTask({
          taskType: TASK_TYPE.WAIVE_FEE,
          subType: TASK_SUBTYPE.CANCEL,
          eventID: eventId,
          getActionInfo: () => ({ fee: cost, reason: payload?.reason, notes: payload?.notes }),
        }));
      }
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const generateCancelledSalesOrder = (orderID, fee) => async () => {
  try {
    const response = await OrdersAPI.postCancelledSalesOrder({ orderID, fee });
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const rescheduleOrder = (orderId, payload, reason, notes, fee, isWaived, subcontractor, cost, originalTimes, noTask) => async (dispatch, getState) => {
  try {
    const response = await OrdersAPI.rescheduleOrder(orderId, payload, timezoneSelector(getState()), reason, notes, fee, isWaived, subcontractor);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      await dispatch(fetchOrders());
      dispatch(postOrderNotification({
        notificationType: NOTIFICATION_TYPE.RESCHEDULED_EVENT,
        eventID: orderId,
        loadAsOrder: true,
        getActionInfo: () => (originalTimes ?? {}),
      }));
      if (isWaived && cost > 0 && !noTask) {
        dispatch(postOrderTask({
          taskType: TASK_TYPE.WAIVE_FEE,
          subType: TASK_SUBTYPE.RESCHEDULE,
          eventID: orderId,
          loadAsOrder: true,
          getActionInfo: () => ({
            ...(originalTimes ?? {}),
            fee: cost,
            reason,
            notes,
          }),
        }));
      }
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateDeliveryStep = (orderId, step) => async () => {
  try {
    const response = await OrdersAPI.updateDeliveryStep(orderId, step);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateContact = (orderId, contact) => async () => {
  try {
    const response = await OrdersAPI.updateContact(orderId, contact.SAPContactID ?? '');
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const fetchConfirmationOrder = (orderId, anonymous) => async (dispatch) => {
  try {
    let response;
    if (anonymous) {
      response = await OrdersAPI.getConfirmationOrderById(orderId);
      if (response && response.data) {
        return response.data;
      }
    } else {
      const snapshotToken = await dispatch(getLastSnapshotToken(orderId));
      if (!snapshotToken) { throw new Error(); }
      return await dispatch(getSignatureSnapshot(orderId, snapshotToken));
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchOrderSummary = (orderId, updateState = false) => async (dispatch) => {
  try {
    const response = await OrdersAPI.getOrderSummaryById(orderId);
    if (response && response.data) {
      if (updateState) { dispatch(updateUpcomingOrder(response)); }
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const generateConfirmation = (orderId) => async () => {
  try {
    const response = await OrdersAPI.generateConfirmation(orderId);
    if (response && response.data) {
      return response.data;
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const deleteSnapshot = (orderId, snapshotToken) => async () => {
  try {
    const response = await OrdersAPI.deleteSnapshot(orderId, snapshotToken);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getLastSnapshotToken = (orderId) => async () => {
  try {
    const response = await OrdersAPI.getLastSnapshotToken(orderId);
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getSignatureSnapshot = (orderId, snapshotToken) => async () => {
  try {
    const response = await OrdersAPI.getSignatureSnapshot(orderId, snapshotToken);
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const confirmOrder = (orderId, formdata, summary) => async (dispatch) => {
  try {
    let notificationInfo;
    try {
      notificationInfo = await dispatch(buildOrderNotification({
        notificationType: NOTIFICATION_TYPE.CONFIRMATION_SIGN,
        orderId,
        loadAsOrder: true,
        preloadedOrderSummary: summary,
      }));
    } finally {
      if (notificationInfo) {
        formdata.append('notification', JSON.stringify(notificationInfo.notification));
        formdata.append('notificationEmailBody', buildNotificationDetails(notificationInfo.notification)?.join('\n\n'));
      }
    }
    const response = await OrdersAPI.confirmOrder(orderId, formdata);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updatePoNum = (orderId, poNum) => async () => {
  try {
    const response = await OrdersAPI.updatePoNum(orderId, ({ POnumber: `${poNum}` }));
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateNotes = (orderId, notes) => async () => {
  try {
    const response = await OrdersAPI.updateNotes(orderId, ({ notes }));
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

// const cancelPendingEventsPromises = () => async (dispatch, getState) => {
//   try {
//     await getState().events?.eventsLoadPromise?.abort();
//   } catch (e) {
//     return;
//   } finally {
//     dispatch(updateEventsLoadPromise(undefined));
//   }
// };

export const fetchOrders = (ignoreUpcoming) => async (dispatch, getState) => {
  const future = async () => {
    try {
      const selectedTci = getState().users?.data?.selectedTci;
      if (selectedTci) {
        return dispatch(fetchTciOrders(selectedTci.uid, ignoreUpcoming));
      }

      // await dispatch(cancelPendingEventsPromises());
      const { role } = userInfoSelector(getState());
      const canViewUpcoming = hasUserAccessSelector(getState(), [ROLE_ACCESSES.ownCalendar]) && !ignoreUpcoming;

      await Promise.all([
        await dispatch(fetchEvents()),
        ...(canViewUpcoming ? [await dispatch(fetchUpcomingEvents())] : []),
      ]);

      // dispatch(updateEventsLoadPromise(eventsFuture));
      // await eventsFuture;

      let orderSummaries = [];
      if (canViewUpcoming) {
        const upcomingOrders = _(Object.keys(upcomingEventsDataSelector(getState()))).uniq().filter((ord) => Boolean(ord)).value();
        try {
          orderSummaries = ((await OrdersAPI.getOrderSummariesById(upcomingOrders)) ?? { data: [] }).data;
        } catch (e) {
          orderSummaries = [];
        }
        dispatch(saveUpcomingOrders(orderSummaries));
      }
      dispatch(fetchCancelledEventsDailyCount());
      return orderSummaries;
    } catch (error) {
      throw error;
    }
  };

  return future();
};

export const fetchTciOrders = (id, ignoreUpcoming) => async (dispatch, getState) => {
  const future = async () => {
    try {
      const { role } = userInfoSelector(getState());
      const canViewUpcoming = hasUserAccessSelector(getState(), [ROLE_ACCESSES.ownCalendar]) && !ignoreUpcoming;
      await Promise.all([
        await dispatch(fetchTciEvents(id)),
        ...(canViewUpcoming ? [await dispatch(fetchTciUpcomingEvents(id))] : []),
      ]);

      let orderSummaries = [];
      if (canViewUpcoming) {
        const upcomingOrders = _(Object.keys(upcomingEventsDataSelector(getState()))).uniq().value();

        try {
          orderSummaries = ((await OrdersAPI.getOrderSummariesById(upcomingOrders)) ?? { data: [] }).data;
        } catch (e) {
          orderSummaries = [];
        }
        dispatch(saveUpcomingOrders(orderSummaries));
      }

      dispatch(fetchCancelledEventsDailyCount());
      return orderSummaries;
    } catch (error) {
      dispatch(saveUpcomingOrders([]));
      throw error;
    }
  };
  return future();
};

export const resetTciOrders = () => async (dispatch) => {
  try {
    await Promise.all([
      await dispatch(resetTciEvents()),
      await dispatch(resetTciUpcomingEvents()),
    ]);
    dispatch(saveUpcomingOrders([]));
    return true;
  } catch (error) {
    dispatch(saveUpcomingOrders([]));
    return false;
  }
};

export const fetchTempContactOrders = () => async (dispatch, getState) => {
  try {
    const hasOwnCalendar = hasUserAccessSelector(getState(), [ROLE_ACCESSES.ownCalendar]);
    const reportingTcis = hasOwnCalendar ? null : reportingTcisUids(getState());
    const data = [];

    if (!hasOwnCalendar) {
      const responses = await Promise.all(reportingTcis.map((tci) => OrdersAPI.getTempContactOrders([tci])));
      responses.forEach((r) => data.push(...(r.data ?? [])));
    } else {
      const response = await OrdersAPI.getTempContactOrders();
      if (response && response.data) {
        data.push(...response.data);
      }
    }
    await dispatch(saveOrders(data));
    return data;
  } catch (error) {
    throw error;
  }
};

export const getOrdersCSV = (payload) => async () => {
  try {
    const response = await OrdersAPI.requestOrdersCSV(payload);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchFilteredOrdersCount = (payload) => async () => {
  try {
    const response = await OrdersAPI.getFilteredOrdersCount(payload);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchFilteredOrders = (payload) => async () => {
  try {
    const response = await OrdersAPI.getFilteredOrders(payload);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return response.data ?? [];
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchOrdersSlow = (payload) => async () => {
  try {
    const response = await OrdersAPI.getOrdersSlow(payload);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const updateOrderDelivery = (orderId) => async (dispatch) => {
  try {
    const response = await OrdersAPI.startOrderDelivery(orderId);
    if (response && response.data && OK_STATUS_CODES.includes(response.status)) {
      dispatch(setOrderDeliveryStatus(orderId, true));
      dispatch(deleteScheduledNotifications(NOTIFICATION_TYPE.EVENT_NOT_STARTED, orderId));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const cancelOrderDelivery = (orderId) => async (dispatch) => {
  try {
    const response = await OrdersAPI.cancelOrderDelivery(orderId);
    if (response && response.data && OK_STATUS_CODES.includes(response.status)) {
      dispatch(setOrderDeliveryStatus(orderId, false));
      dispatch(postOrderNotification({
        notificationType: NOTIFICATION_TYPE.EVENT_NOT_STARTED,
        eventID: orderId,
        loadAsOrder: true,
      }));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const submitSignature = (orderId, signature, forceFlag) => async (dispatch) => {
  try {
    let sign = forceFlag ? null : signature;
    if (forceFlag) {
      const formData = new FormData();
      const blankImg = await (await fetch(blankImage)).blob();
      formData.append('deliverySignature', blankImg);
      formData.append('printedSignature', '---- ----');
      sign = formData;
    }
    const response = await OrdersAPI.uploadSignature(orderId, sign, forceFlag);
    if (response && response.data) {
      dispatch(postOrderNotification({
        notificationType: NOTIFICATION_TYPE.COMPLETED_EVENTS,
        eventID: orderId,
        loadAsOrder: true,
      }));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const sendCommunication = (orderId, contact, template, confirmationToken, body, confirmationEmail = true) => async (/* dispatch */) => {
  try {
    const { subject, id } = template;
    const response = await OrdersAPI.sendCommunication({
      toemail: process.env.REACT_APP_ENV === 'production' ? contact?.Email : (process.env.REACT_APP_ENV === 'release' ? 'trainingcentral@cintas.com' : 'no-reply@benimbl.com'),
      subject,
      customertext: body,
      orderID: orderId,
    }, id, confirmationToken, confirmationEmail);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const sendConfirmation = (token, orderId) => async (/* dispatch */) => {
  try {
    const response = await OrdersAPI.sendConfirmation(token, orderId);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const checkShouldRebook = (orderId) => async () => {
  try {
    const response = await OrdersAPI.shouldRebook(orderId);
    if (response && response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
