/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */

import _ from 'lodash';
import { ROLE_ACCESSES } from '../../utils/consts';
import { configurationsSelector } from './settings.selectors';

/* eslint-disable import/prefer-default-export */
export const userInfoSelector = (state) => {
  const role = (state.users?.data?.currentUser ?? {}).org_role;
  const data = ({
    auth: state.auth?.data ?? {},
    ...(state.users?.data?.currentUser ?? {}),
    role,
  });

  return data;
};

export const userAccessMatrixSelector = (state) => userInfoSelector(state)?.roleMatrix ?? {};
export const userAdminMatrixSelector = (state) => userAccessMatrixSelector(state).adminMatrix ?? {};
export const hasUserAccessSelector = (state, accessList, preloadedMatrix) => {
  // This function uses the role access matrix for the logged in user to check for access to specific accesses passed as args
  // It takes a list of ROLE_ACCESSES enum and return TRUE if the user has access to at least one of the list items, FALSE otherwise.
  const matrix = preloadedMatrix ?? userAccessMatrixSelector(state);
  return (accessList.findIndex((accs) => Boolean(matrix[accs])) !== -1);
};

export const hasAdminAccessSelector = (state, accessList, preloadedMatrix) => {
  // This function uses the admin access matrix for the logged in user to check for access to specific accesses passed as args
  // It takes a list of ADMIN_ACCESSES enum and return TRUE if the user has access to at least one of the list items, FALSE otherwise.
  // It returns false if the user is not an admin of any type.
  const adminType = adminTypeSelector(state, preloadedMatrix);
  if (!adminType) return false;
  const matrix = preloadedMatrix ? (preloadedMatrix.adminMatrix ?? {}) : userAdminMatrixSelector(state);
  return (accessList.findIndex((accs) => Boolean(matrix[adminType]?.[accs])) !== -1);
};

export const adminTypeSelector = (state, preloaded) => {
  const adminRoles = [ROLE_ACCESSES.advancedAdmin, ROLE_ACCESSES.basicAdmin];
  for (let i = 0; i < adminRoles.length; i++) {
    if (hasUserAccessSelector(state, [adminRoles[i]], preloaded)) {
      return adminRoles[i];
    }
  }

  return undefined;
};

export const roleMatrixForRoleSelector = (state, orgRole, securityRole) => {
  const matrixKey = `${securityRole}${!_.isEmpty(orgRole ?? '') ? `--${orgRole}` : ''}`;
  const matrix = configurationsSelector(state)?.rolesMatrix ?? {};

  return matrix[matrixKey] ?? {};
};

export const currentUserLocationsSelector = (state) => _.uniq([...((state.users?.data?.currentUser ?? {}).loc_ids ?? [])]);
export const currentUserAvailableLocationsSelector = (state) => {
  const adminType = adminTypeSelector(state);
  if (!adminType) {
    return currentUserLocationsSelector(state);
  }

  return reportingTcisAllLocations(state);
};

export const instructorZipcodesSelector = (state, uid) => {
  const tci = reportingTciById(state, uid);
  let zipcodes = [];
  if (tci) {
    zipcodes = _.uniq(_.flatten(Object.keys(tci.locations ?? {}).map((loc) => (tci.locations?.[loc]?.zip_codes ?? []))));
    return zipcodes;
  }

  const currentUser = userInfoSelector(state);
  if (currentUser.uid !== uid) return zipcodes;

  return _.uniq(currentUser.zip_codes ?? []);
};

export const instructorRoleSelector = (state, uid) => {
  const tci = reportingTciById(state, uid);
  let orgRole = '';
  let securityRole = '';

  if (tci) {
    orgRole = tci.org_role ?? '';
    securityRole = tci.agr_name ?? '';
    return {
      role: orgRole,
      roleMatrix: roleMatrixForRoleSelector(state, orgRole, securityRole),
    };
  }

  const currentUser = userInfoSelector(state);
  if (currentUser.uid !== uid) return { role: orgRole };

  orgRole = currentUser.role ?? '';
  securityRole = currentUser.agr_name ?? '';
  return {
    role: orgRole,
    displayRole: currentUser.displayRole,
    roleMatrix: roleMatrixForRoleSelector(state, orgRole, securityRole),
  };
};

export const selectedTciSelector = (state) => state?.users?.data?.selectedTci;

export const currentUserLocationsDataSelector = (state) => {
  const user = userInfoSelector(state);
  if (!user) return [];
  return (user.locs ?? []).map((locData) => ({
    id: locData.id, label: locData.label, zipcodes: user.zip_codes ?? [],
  }));
};

export const reportingTciLocations = (state, uid, preloaded) => {
  if (!uid) {
    return [];
  }
  const tcis = preloaded ?? reportingTcis(state).filter((t) => t.pernr === uid);
  return tcis.reduce((prev, tci) => [...prev, ...Object.keys(tci.locations ?? {}).map((locId) => ({ id: locId, label: tci.locations?.[locId]?.location_desc, zipcodes: tci.locations?.[locId]?.zip_codes }))], []);
};

export const reportingTcis = (state) => (state.users?.data?.reportingTcis ?? []);
export const reportingInstructorsByLocation = (state, locId) => reportingTcis(state).filter((tci) => tci.uid && Object.keys(tci.locations ?? {}).includes(locId));
export const reportingTciById = (state, uid) => reportingTcis(state).filter((tci) => tci.uid === uid)?.[0];
export const reportingTcisUids = (state) => _.uniq(reportingTcis(state).map((t) => t.uid)).filter((uid) => Boolean(uid));
export const reportingTcisAllLocations = (state) => _.uniq(
  _.flatten(
    reportingTcis(state).map(
      (t) => [...Object.keys(t.locations ?? {})],
    ),
  ),
);

export const reportingTciLocationsIds = (state, uid) => reportingTciLocations(state, uid).map((l) => l.id);

export const instructorIdSelector = (state) => instructorSelector(state)?.uid;

export const instructorSelector = (state) => {
  const selectedTci = state.users?.data?.selectedTci;
  const currentUser = state.users?.data?.currentUser;

  return selectedTci ?? currentUser;
};

export const tciEmailSelector = (state) => {
  const format = (email) => (email.toString() ?? '--');
  const tci = selectedTciSelector(state);
  if (tci) return format(tci.usrid_long);
  const user = userInfoSelector(state);
  return format(user.email);
};
