/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
// import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import TimezoneSelect from 'react-timezone-select';
import { Typography } from '@material-ui/core';
import { Views } from 'react-big-calendar';
import SwitchField from '../Forms/FormComponents/Switch/SwitchField';
import { COLORS, ROLE_ACCESSES } from '../../utils/consts';
import StyledButton from '../Button/StyledButton';
import * as UtilsActions from '../../redux/actions/utils.actions';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import TciZipcodeSelect from './TciZipcodeSelect';
import LoadingDataSpinner from '../SpinnerOverlay/LoadingDataSpinner';
// eslint-disable-next-line import/no-cycle
import LocationsCalendarTriggerButton from './LocationsCalendar/LocationsCalendarTriggerButton';
import { hasUserAccessSelector } from '../../redux/selectors/user.selectors';

const headerIconsSize = {
  width: 24,
  height: 26,
};

const OptionToolbar = (props) => {
  const {
    handleButton,
    dispatch,
    loading,
    disableEventCreation,
    view,
    utils: { tz: selectedTimezone },
  } = props;

  const hasOwnCalendar = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));
  const showReveueSwitch = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.showRevenue]) && (view === Views.MONTH || view === Views.DAY || view === Views.WEEK));
  const hasLocCalendarAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.locCalendar]));

  const addEventBtn = () => (
    <div>
      <StyledButton variant="contained" color="primary" buttonContent="ADD EVENT" handleButton={handleButton} startIcon={<AddIcon />} />
    </div>
  );

  const reveueSwitch = () => (
    <div style={{ margin: '0px 0px 0px 20px' }}>
      <Field
        name="showRevenue"
        id="showRevenue"
        component={SwitchField}
        label="Show Revenue"
      />
    </div>
  );

  const zipcodeFilter = () => (
    <TciZipcodeSelect />
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt) => <Typography variant="subtitle2">{txt}</Typography>;

  const locationCalendarBtn = () => (
    item({
      flex: 'auto',
      style: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: COLORS.CINTAS_BLUE,
        width: 40,
      },
      children: <LocationsCalendarTriggerButton
        dispatch={dispatch}
        iconSize={headerIconsSize.width}
        iconStyle={{ height: headerIconsSize.height }}
      />,
    })
  );

  return (
    container({
      spacing: 0,
      style: { justifyContent: 'start', alignItems: 'end', flexWrap: 'nowrap' },
      children: [
        (!hasOwnCalendar) ? item({
          flex: 3,
          style: { paddingLeft: 24 },
          children: [
            text('Postal Code'),
            zipcodeFilter(),
          ],
        }) : (hasLocCalendarAccess ? item({
          flex: 'auto',
          style: { zIndex: 100, marginRight: 8 },
          children: [locationCalendarBtn()],
        }) : <></>),
        item({
          flex: (!hasOwnCalendar) ? 9 : 12,
          style: { width: '100%' },
          children: container({
            style: { justifyContent: 'space-between', alignItems: 'end' },
            children: [
              item({
                flex: 7,
                style: { zIndex: 100 },
                children:
                  [
                    container({
                      style: { justifyContent: 'flex-start', alignItems: 'end' },
                      children: [
                        item({
                          flex: 4,
                          children: [
                            text('Timezone'),
                            <TimezoneSelect
                              value={selectedTimezone.value}
                              onChange={(tz) => dispatch(UtilsActions.setTimezone(tz))}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  backgroundColor: 'transparent',
                                  borderRadius: 0,
                                  borderColor: COLORS.CINTAS_BLUE,
                                }),
                              }}
                            />,
                          ],
                        }),
                        item({
                          flex: 8,
                          style: { justifyContent: 'flex-start', margin: 'auto', marginTop: 20 },
                          children: showReveueSwitch ? reveueSwitch() : <></>,
                        }),
                      ],
                    }),
                  ],
              }),
              item({
                flex: 'auto',
                children: (loading ? [<LoadingDataSpinner />] : [
                  !disableEventCreation && addEventBtn(),
                ]),
              }),
            ],
          }),
        }),
      ],
    })
  );
};

const mapStateToProps = (state) => ({
  utils: state.utils,
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'OptionToolbar',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
])(OptionToolbar);
