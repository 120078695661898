import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Box, Container, CssBaseline, Typography, withStyles, Avatar,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Copyright from '../../../components/Footer/Copyright';

export const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
});

const LoginWireFrame = ({ title, classes, renderChildren }) => (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      {renderChildren(classes)}
    </div>
    <Box mt={8}>
      <Copyright />
      {process.env.REACT_APP_ENV !== 'production' && (
        <Typography variant="subtitle2" align="center" gutterBottom style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
          {`${process.env.REACT_APP_ENV}/${process.env.REACT_APP_VERSION}`}
        </Typography>
      )}
    </Box>
  </Container>
);

export default _.flow([
  connect(),
  withStyles(styles),
  withRouter,
])(LoginWireFrame);
