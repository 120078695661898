/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React from 'react';
import EditingIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { Box } from '@material-ui/core';
import ShapeFactory from '../../../../Shapes/ShapeFactory';
import { COLORS } from '../../../../../utils/consts';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import StyledButton from '../../../../Button/StyledButton';

const defaultSize = 40;
const EditBoxButton = ({
  onClick, isEditing, borderRadius, isLoading, hideWhileEditing, size, noBorder, disableSave, saveButton, hideEditIcon,
}) => {
  const dimension = size ?? defaultSize;
  const spinnerSize = dimension * 0.5;
  const clickCallback = disableSave && isEditing ? null : onClick;
  const hide = isEditing && hideWhileEditing;

  const getIcon = () => {
    if (isLoading) return spinner();
    if (isEditing) return <CheckIcon style={{ color: disableSave ? COLORS.CINTAS_GRAY : COLORS.CINTAS_BLUE }} />;
    return !hideEditIcon && <EditingIcon color="primary" />;
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: spinnerSize, width: spinnerSize, padding: 0, margin: 0, color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: spinnerSize,
        maxWidth: spinnerSize,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const getButton = () => {
    if (isEditing && saveButton) {
      return (
        <StyledButton
          buttonContent="SAVE"
          color="primary"
          variant="contained"
          onClick={clickCallback}
        />
      );
    }
    return (
      <ShapeFactory
        onClick={clickCallback}
        shape="square"
        width={(dimension)}
        style={{
          border: !noBorder && `1px solid ${COLORS.CINTAS_GRAY}`,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius,
          cursor: 'pointer',
        }}
      >
        {getIcon()}
      </ShapeFactory>
    );
  };

  return (
    hide
      ? <Box height={dimension} width={dimension} />
      : getButton()
  );
};
export default EditBoxButton;
