import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  reduxForm,
  submit,
} from 'redux-form';

const formName = 'CoursePriceDialog';

const CoursePriceDialogContent = withStyles((theme) => ({
  root: {
    // minWidth: 400,
    // maxWidth: 400,
    // minHeight: 300,
    // maxHeight: 300,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      // minWidth: 50,
    },
  },
}))(MuiDialogContent);

const CoursePriceDialogWrapper = ({ children }) => (
  <CoursePriceDialogContent>
    {children}
  </CoursePriceDialogContent>
);

export default _.flow([
  connect(),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {},
    onSubmit: submit,
  }),
])(CoursePriceDialogWrapper);
