/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import { styled } from '@material-ui/styles';
import { COLORS } from '../../utils/consts/index';
import 'react-step-progress/dist/index.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import { showModal } from '../../redux/actions/modals.actions';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import StyledButton from '../Button/StyledButton';
import Spinner from '../SpinnerOverlay/Spinner';

const spinnerSize = 16;

const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...((() => {
    if (ownerState.error) {
      return ({
        backgroundColor: COLORS.CINTAS_WHITE,
      });
    }
    if (ownerState.completed || ownerState.active) {
      return ({
        backgroundColor: COLORS.CINTAS_BLUE,
      });
    }
    return ({
      backgroundColor: COLORS.CINTAS_GRAY,
    });
  })()),
}));

const errorIcon = () => (
  <WarningIcon style={{ color: COLORS.CINTAS_RED }} />
);

const checkIcon = () => (
  <CheckIcon />
);

const spinner = (error) => (
  <Spinner
    spinnerStyle={{ height: spinnerSize, width: spinnerSize, color: error ? COLORS.CINTAS_RED : 'white' }}
    customStyle={{
      maxHeight: spinnerSize,
      maxWidth: spinnerSize,
      marginTop: 4,
      marginBottom: 4,
    }}
  />
);

const ColorlibStepIcon = (loading) => (props) => {
  const {
    active, completed, error, icon, className,
  } = props;
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active, error }} className={className}>
      {loading
        ? spinner(error)
        : (error ? errorIcon() : (completed ? checkIcon() : icon))}
    </ColorlibStepIconRoot>
  );
};

/*
    @param props : {
        tabs: [String],
        tabsContent: [JSX.Element],
        tabsValidators: [() => bool],
    }
*/
const StepsView = ({
  tabs,
  tabsContent,
  tabsValidators,
  tabsActions,
  initialTab,
  dispatch,
  submitButtonLabel,
  nextButtonLabel,
  prevButtonLabel,
  onSubmit,
  // contentHeight,
  disableErrorLabel,
  onTabChange,
  // events,
}) => {
  const [currentStep, setCurrentStep] = React.useState(initialTab ?? 0);
  const [stepErrors, setStepErrors] = React.useState(tabs.map(() => false).reduce((red, v, i) => ({ ...red, [i]: v }), {}));
  const [loadingNext, setLoadingNext] = React.useState(false);
  // const hasSkillchecks = events && events.some((e) => e.courseObj?.type === MATERIAL_TYPE.SKILL_CHECK);

  const border = `1px solid ${COLORS.CINTAS_DARK_GRAY}`;

  const showWarningToast = (msg) => {
    dispatch(showModal('WARNING_ALERT', {
      modalType: 'WARNING_ALERT',
      modalProps: { message: msg },
    }));
  };

  const updateStep = async (stp, completed) => {
    let success = true;
    if (onTabChange) {
      try {
        setLoadingNext(true);
        success = (await onTabChange(stp)) ?? true;
        if (success) setCurrentStep(stp);
      } catch (error) {
        setLoadingNext(false);
        success = false;
      } finally {
        if (!completed) setLoadingNext(false);
      }
    } else {
      setCurrentStep(stp);
    }

    return success;
  };

  const handleStepClick = async (stepIdx, complete) => {
    const proceed = async (resetError) => {
      const currS = currentStep;
      const ok = await updateStep(stepIdx, complete);
      if (ok && resetError) {
        setStepErrors({ ...stepErrors, [currS]: false });
      }
      return ok;
    };

    const bigStepJump = Boolean(stepIdx > (currentStep + 1));

    if (stepIdx === currentStep || bigStepJump) {
      if (bigStepJump) {
        // cannot move forward more than ONE step at the time
        // therefore, do nothing and show error
        showWarningToast('You can only move forward to the next step');
      } else if (complete) {
        const finalValidate = tabsValidators[currentStep];
        setLoadingNext(true);
        const { valid, errorStep } = await finalValidate();
        if (valid) {
          await proceed(true);
          if (onSubmit) {
            const result = onSubmit();
            if (!result) {
              setLoadingNext(false);
              setStepErrors({ ...stepErrors, [currentStep]: true });
            }
          }
        } else {
          setLoadingNext(false);
          setStepErrors({ ...stepErrors, [(errorStep ?? currentStep)]: true });
        }
      }
    } else if (stepIdx < currentStep) {
      await proceed();
    } else if (stepIdx > currentStep) {
      if (tabsValidators && tabsValidators[currentStep]) {
        const validate = tabsValidators[currentStep];
        setLoadingNext(true);
        const result = await validate();
        if (result) {
          await proceed(true);
        } else {
          setLoadingNext(false);
          setStepErrors({ ...stepErrors, [currentStep]: true });
        }
      } else {
        await proceed();
      }
    }
  };

  const goToPreviousStep = () => {
    if (currentStep <= 0) return;
    handleStepClick(currentStep - 1);
  };

  const goToNextStep = async () => {
    if (currentStep >= ((tabs ?? []).length - 1)) {
      await handleStepClick(currentStep, true);
    } else {
      await handleStepClick(currentStep + 1);
    }
  };

  const stepErrorLabel = () => (
    <Typography variant="caption" color="error">
      Fix errors before proceeding
    </Typography>
  );

  const buildSteps = () => tabs.map((label, i) => {
    const labelProps = {};
    if (stepErrors[i]) {
      labelProps.optional = !disableErrorLabel && stepErrorLabel();
      labelProps.error = true;
    }
    const loading = loadingNext && (currentStep + 1) === i;

    return (
      <Step style={{ color: COLORS.CINTAS_BLUE }} onClick={() => handleStepClick(i)} key={label}>
        <StepLabel StepIconComponent={ColorlibStepIcon(loading)} {...labelProps}>{label}</StepLabel>
      </Step>
    );
  });

  const contentContainer = () => (
    <div style={{
      marginTop: 24,
      paddingBottom: 24,
      overflow: 'visible',
      height: '100%',
      borderBottom: border,
      borderTop: border,
    }}
    >
      {tabsContent[currentStep]}
    </div> ?? <></>
  );

  const btn = ({
    label, onClick, variant, disabled,
  }) => (
    <StyledButton
      style={{ marginTop: '5%', width: '100%' }}
      color="primary"
      variant={variant}
      disabled={disabled}
      handleButton={onClick}
      buttonContent={label}
    />
  );

  const actionContainer = ({ child, align }) => (
    <ContainerItem flex={3} style={{ textAlign: align }}>
      {child}
    </ContainerItem>
  );

  const actionButtons = () => (
    <Container style={{
      justifyContent: 'space-between',
      width: '100%',
      paddingBottom: 10,
      position: 'sticky',
      bottom: 0,
      backgroundColor: COLORS.CINTAS_WHITE,
      borderTop: `1px solid ${COLORS.CINTAS_LIGHT_GRAY}`,
    }}
    >
      {actionContainer({
        align: 'left',
        child: btn({
          onClick: goToPreviousStep,
          label: (prevButtonLabel ?? 'Previous Step').toUpperCase(),
          variant: 'outlined',
          disabled: currentStep === 0,
        }),
      })}
      {((tabsActions ?? [])[currentStep] ?? [])}
      {actionContainer({
        align: 'right',
        child: btn({
          onClick: goToNextStep,
          label: loadingNext ? spinner() : (
            currentStep === ((tabs ?? []).length - 1) ? (submitButtonLabel ?? 'Submit') : (nextButtonLabel ?? 'Next Step')
          ).toUpperCase(),
          variant: 'contained',
        }),
      })}
    </Container>
  );

  return (
    <>
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        style={{ paddingTop: 10 }}
      >
        {buildSteps()}
      </Stepper>
      {contentContainer()}
      {actionButtons()}
    </>
  );
};

export default StepsView;
