/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { connect, useSelector } from 'react-redux';
import {
  reduxForm, submit, getFormValues, change, initialize,
} from 'redux-form';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import EventDetailsDialogWrapper from './EventDetailsDialogWrapper';
import * as OrderActions from '../../../../redux/actions/orders.actions';
import * as ModalsActions from '../../../../redux/actions/modals.actions';
import * as SAPActions from '../../../../redux/actions/sap.actions';
import { orderCoursesSelector } from '../../../../redux/selectors/orders.selectors';
import Spinner from '../../../SpinnerOverlay/Spinner';
import TabView from '../../../TabView/TabView';
import EventOverview from './DialogSubcomponents/EventOverview';
import PartecipantsTab, { getNumRows } from './DialogSubcomponents/PartecipantsTab';
import CommunicationsTab from './DialogSubcomponents/Communication/CommunicationTab';
import SignatureTab from './DialogSubcomponents/SignatureTab';
import BillingSummaryTab from './DialogSubcomponents/BillingSummaryTab';
import {
  DEFAULT_TRUCK_NUMBER, MATERIAL_TYPE, TRUCK_NUM_VALIDATION_REGEX, SPECIAL_TRUCK_NUMS, BOOKING_MODE, EVENT_TYPE, CALENDAR_TYPE, SIGNATURE_PAD_WIDTH, SIGNATURE_PAD_HEIGHT, ROLE_ACCESSES,
} from '../../../../utils/consts';
import {
  hasUserAccessSelector, instructorIdSelector, selectedTciSelector, userInfoSelector,
} from '../../../../redux/selectors/user.selectors';
import StepsView from '../../../TabView/StepsView';
import ParticipantsMinDialog from './ParticipantsMinDialog';
import LegalText from '../../../../utils/consts/StaticText/LegalText';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import { participantsEditTimeExpired } from '../../../../redux/selectors/settings.selectors';
import { fetchSAPListings, resetSAPListings } from '../../../../redux/actions/sap.actions';
import { fetchPricing } from '../../../../redux/actions/pricing.actions';
import { getPricing } from '../EventDialog/EventDialogControl/CourseSelect';
import { listingsSelector } from '../../../../redux/selectors/sap.selectors';
import DeliveryStepValidationFailure from './DialogSubcomponents/DeliveryStepValidationFailure';
import RebookReasonDialog from '../RebookReasonDialog/RebookReasonDialog';
import EventDialog from '../EventDialog/EventDialog';
import { fetchContactsByCustomerId } from '../../../../redux/actions/contacts.actions';
import { updateOrderPayload } from '../../../../redux/payload_builders/orders.payload';
import { calendarTypeSelector } from '../../../../redux/selectors/calendar.selectors';
import { cleanRows } from '../EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import { materialsDataSelector } from '../../../../redux/selectors/materials.selector';
import { getOSEventInfo } from '../../../../routes/MainViews/EventSummaryView/EventSummaryView';
import { buildCourseUniqueId, calcMaxParticipants, showAddToCalendarPopup } from '../../../../utils/event.utils';

const tabNames = {
  OVERVIEW: 'OVERVIEW',
  PARTICIPANTS: 'PARTICIPANTS',
  BILLING_SUMMARY: 'BILLING SUMMARY',
  COMMUNICATION: 'COMMUNICATION',
  CUSTOMER_SIGNATURE: 'CUSTOMER SIGNATURE',
};
const dialogTabs = (delivery, hasEditAccess, cancelled) => (
  cancelled
    ? [tabNames.OVERVIEW]
    : [
      tabNames.OVERVIEW,
      ...(hasEditAccess ? [tabNames.PARTICIPANTS] : []),
      ...(delivery ? [tabNames.BILLING_SUMMARY] : []),
      ...(!delivery ? [tabNames.COMMUNICATION] : []),
      ...(delivery && hasEditAccess ? [tabNames.CUSTOMER_SIGNATURE] : []),
    ]);

export const formName = 'EventDetailsDialog';
export const partecipantsFieldNames = {
  course: 'course',
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  employeeId: 'employee_id',
  isPresent: 'present',
  isSelected: 'selected',
  isBeingEdited: 'isBeingEdited',
  partecipants: 'partecipants',
  index: 'index',
  id: 'id',
  eventId: 'eventId',
  location: 'location',
};

const signatureFieldNames = {
  printedName: 'printedSignature',
  otherName: 'otherName',
  poNumber: 'POnumber',
  truckNumber: 'truckNumber',
  participants: 'partecipants',
  billingSummary: 'billingSummary',
  participantFirst: partecipantsFieldNames.firstName,
  participantLast: partecipantsFieldNames.lastName,
  sigPad: 'signPad',
};

const partecipantsBackendFieldNames = {
  id: 'id',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  index: 'index',
  employeeID: 'employeeId',
  present: 'isPresent',
  eventid: 'eventId',
  location: 'location',
};

const courseFieldNames = {
  customerRoster: 'customerRoster',
};

const EventDetailsDialog = ({
  dispatch, orderId, values, history,
  modalName,
  utils: { tz: { value: tzValue, abbrev: tzCode } },
  subcontracted,
  materialsInfo,
  canEdit,
  hasDeliveryEditAccess,
  openedFromStartDelivery,
  tzOverride,
  hideRepeatBookingButton,
}) => {
  const selectedTimezone = tzOverride?.value ?? tzValue;
  const selectedTimezoneCode = tzOverride?.abbrev ?? tzCode;
  const [loading, setLoading] = useState(true);
  const [stepsViewKey, setStepsViewKey] = useState(0);
  const [order, setOrder] = useState({});
  const [currTab, setCurrTab] = useState();
  const selectedTci = useSelector(selectedTciSelector);
  const exclusionListings = useSelector(listingsSelector);
  const cancelled = order?.events?.filter((e) => e.cancelled).length === order?.events?.length; // order events are all cancelled if order is cancelled
  const defaultTruckNum = useSelector((state) => ((selectedTci || userInfoSelector(state)) ?? {}).defaultTruckNumber);
  const courses = useSelector((s) => orderCoursesSelector(s, orderId, order ? [...(order.events ?? [])] : []));
  const validationValues = React.useRef();
  const sigRef = React.useRef();
  const inDelivery = order?.delivery;
  const editParticipantsExpired = useSelector(
    participantsEditTimeExpired(
      order?.events && moment.utc(order?.events[order?.events.length - 1].endTime),
      order?.subcontracted || order?.events?.[0]?.subcontracted,
    ),
  );
  const listingsData = useSelector(listingsSelector);
  const sessionInstructor = useSelector((state) => {
    const calendarType = calendarTypeSelector(state);
    return (calendarType === CALENDAR_TYPE.tci) ? instructorIdSelector(state) : order?.instructor;
  });

  const hasEditAccess = canEdit || (hasDeliveryEditAccess && inDelivery);
  const drawSiganture = (signatureBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(signatureBlob);
    reader.onloadend = () => {
      sigRef?.current?.fromDataURL(reader.result, { width: SIGNATURE_PAD_WIDTH, height: SIGNATURE_PAD_HEIGHT });
    };
  };
  const getMaterialInfo = (id, type) => materialsInfo[type][id];
  const getWarehouseOverride = (item, type) => getMaterialInfo(item.id, type)?.warehouseOverride;
  const getTruckNumber = (item, type, fromDelivery) => {
    if (fromDelivery) {
      if (type === MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS) {
        return getWarehouseOverride(item, MATERIAL_TYPE.type) ? DEFAULT_TRUCK_NUMBER : (defaultTruckNum ?? item.truckNumber);
      }
      return (defaultTruckNum ?? item.truckNumber);
    } return (item.truckNumber ?? defaultTruckNum);
  };

  useEffect(() => {
    if (values && values.deliverySignature) {
      drawSiganture(values.deliverySignature);
    }
  }, [currTab, values]);

  validationValues.current = values;
  const contact = order
    ? (order.contact
      ? order.contact
      : (order.temporaryContact
        ? { ...order.temporaryContact, isTemporary: true }
        : {}))
    : {};

  const validateRebooking = async (ord) => {
    const rebookingInfo = await dispatch(OrderActions.checkShouldRebook(ord?.id ?? orderId));
    if (rebookingInfo?.rebook) {
      dispatch(ModalsActions.hideModal(modalName));
      if (ord.delivery) {
        await dispatch(OrderActions.cancelOrderDelivery(ord?.id ?? orderId));
      }
      showConfirmReebookDialog(rebookingInfo, ord);
    }
    return rebookingInfo;
  };

  const onSubmitRebookedOrder = async (finalValues) => {
    const onDone = (result) => {
      if (result) {
        dispatch(ModalsActions.hideModal('EDIT_EVENT_DIALOG'));
        cleanRows();
        if (finalValues.addExternalCalendar) {
          showAddToCalendarPopup(finalValues.order, dispatch);
        }
      }
      showToast(result, result ? 'Event rebooked successfully' : 'Error rebooking event');
    };
    const orderPayload = updateOrderPayload(finalValues, sessionInstructor, EVENT_TYPE.ON_SITE, selectedTimezone);
    const success = await dispatch(OrderActions.updateOrder(orderPayload, selectedTimezoneCode, Boolean(sessionInstructor?.subcontractor)));
    onDone(success);
  };

  const startRebookFlow = async (rebookingInfo, orderData) => {
    if (!rebookingInfo?.rebook) return;
    // setLoadingEdits(true);
    try {
      const materialsToRemove = (rebookingInfo.materials ?? []).map((mInfo) => mInfo.material);
      // parentIDs are in case one of the materials that changed went from a skillcheck to something else, in that case, we are removing the parent course too.
      const parentIDs = (orderData.events ?? []).filter((e) => materialsToRemove.includes(e.course) && e.parentEventID).map((e) => e.parentEventID);
      // childrenIDs are in case one of the materials that changed went from a course to something else, in that case, we are removing the child course too (skillcheck associated with it).
      const childrenIDs = (orderData.events ?? []).filter((e) => materialsToRemove.includes(e.course) && e.courseObj?.skillcheckID).map((e) => e.courseObj.skillcheckID);
      const adjustedEvents = (orderData.events ?? []).filter((e) => !parentIDs.includes(e.id) && !childrenIDs.includes(e.id) && !materialsToRemove.includes(e.course)).map((e) => {
        const { addOns, additional_participants } = e;
        let newaddOns = [...addOns];
        let newAdditionalParticipants = [...additional_participants];

        if (!_.isEmpty(addOns ?? [])) {
          newaddOns = addOns.filter((a) => !materialsToRemove.includes(a.id));
        }

        if (!_.isEmpty(additional_participants ?? [])) {
          newAdditionalParticipants = additional_participants.filter((a) => !materialsToRemove.includes(a.id));
        }

        return {
          ...e,
          addOns: newaddOns,
          additional_participants: newAdditionalParticipants,
        };
      });
      const ord = ({ ...(orderData ?? {}), events: [...adjustedEvents] });
      const fetchedContacts = await dispatch(fetchContactsByCustomerId(ord.customer));
      await dispatch(SAPActions.fetchSAPListings(ord.customerObj.sold_to, ord.customerObj.sales_organization, ord.customerObj.distribution_channel));
      const skillchecks = (ord.events ?? []).filter((e) => e.courseObj.type === MATERIAL_TYPE.SKILL_CHECK);
      const crses = (ord.events ?? []).filter((e) => e.courseObj.type !== MATERIAL_TYPE.SKILL_CHECK).map(
        (e) => {
          const { courseObj: { skillcheckID } } = e;
          const skillcheckObjs = skillcheckID ? skillchecks.filter((sk) => sk.courseObj.id === skillcheckID) : [];
          const skillcheckObj = skillcheckObjs.find((sk) => sk.parentEventID === e.id) ?? (skillcheckObjs.length ? skillcheckObjs[1] : undefined);
          const participants = Math.abs((e.participants_count ?? 0) - (e.additional_participants?.[0]?.qty ?? 0));

          return ({
            ...(e ?? {}),
            eventId: e.id,
            course: (e.courseObj ?? {}),
            participants,
            price: e.price?.makeDayDiscount ? (e.price?.amount ?? 0) + e.price?.makeDayDiscount : (e.price?.amount ?? 0),
            priceObj: e.price,
            addons: (e.addOns ?? []),
            additional_participants: (e.additional_participants ?? []),
            notes: (e.notes ?? ''),
            courseStartTime: moment.utc(e.startTime),
            courseEndTime: moment.utc(e.endTime),
            skillcheck: skillcheckObj ? { eventId: skillcheckObj.id ?? undefined, parentEventID: skillcheckObj.parentEventID, ...(skillcheckObj.courseObj ?? {}) } : undefined,
            skillcheckFull: skillcheckObj ? skillcheckObj?.participants_count === e.participants_count : undefined,
            skillcheckParticipants: skillcheckObj ? skillcheckObj.participants_count : undefined,
            skillcheckPrice: skillcheckObj ? (skillcheckObj?.price?.amount ?? 0) : undefined,
          });
        },
      );

      const desc = {
        eventType: EVENT_TYPE.ON_SITE,
        ...(_.isEmpty(crses) ? {} : { ...crses?.[0], price: crses?.[0].priceObj }),
      };
      const onSiteData = {
        desc,
        eventType: EVENT_TYPE.ON_SITE,
        order: orderData.id,
        date: moment.utc(`${orderData?.events?.[0].startTime}`).format('YYYY-MM-DD'),
        startTime: _.isEmpty(crses) ? undefined : moment.utc(crses?.[0]?.courseStartTime),
        endTime: _.isEmpty(crses) ? undefined : moment.utc(crses[crses.length - 1].courseEndTime),
        customer: orderData.customerObj,
        customerContact: fetchedContacts.find((c) => c.SAPContactID === ord.contactID),
        temporaryContact: ord.temporaryContact ? ({ ...ord.temporaryContact }) : undefined,
        notes: ord.notes,
        courses: _.isEmpty(crses) ? [{}] : crses,
      };

      await dispatch(initialize('AddEventDialog', onSiteData));

      const mdName = 'EDIT_EVENT_DIALOG';
      await dispatch(ModalsActions.showModal(mdName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          deleteText: 'delete',
          // fullWidth: true,
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'EDIT EVENT',
          draggable: true,
          nestedScrolling: true,
          content: <EventDialog
            modalName={mdName}
            onSubmit={onSubmitRebookedOrder}
            updatedEvent={{ desc }}
            mode={BOOKING_MODE.rebooking}
          />,
        },
      }));
    } finally {
      // setLoadingEdits(false);
    }
  };

  const showConfirmReebookDialog = (rebookingInfo, ord) => {
    const dialogName = 'CONFIRM_REBOOK';
    dispatch(ModalsActions.showModal(dialogName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        disableBackdropClick: true,
        maxWidth: 'md',
        confirmText: 'REBOOK',
        title: 'REBOOK REQUIRED',
        content: <RebookReasonDialog rebookingInfo={{ ...(rebookingInfo ?? {}), order: ord }} />,
        onConfirm: () => {
          startRebookFlow(rebookingInfo, ord);
          dispatch(ModalsActions.hideModal(dialogName));
        },
      },
    }));
  };

  const loadOrder = async (noLoading, withExclusionListings, checkRebooking, fromDelivery = openedFromStartDelivery) => {
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const ord = await dispatch(OrderActions.fetchOrderSummary(orderId, true));
      if (!ord) return;
      let materials = []; // set of material ids
      ord.events.forEach((e) => {
        if (e.additional_participants) {
          e.additional_participants.forEach((addPart) => materials.push(addPart.sap_material_number));
        }
      });
      materials = _.uniq([...materials]); // make it unique values
      const updated = {
        id: orderId,
        ...ord,
        events: (ord.events ?? []).map(
          (e) => ({
            ...e,
            title: e.courseObj?.title ?? '',
            addOns: (e.addOns ?? []).map((addon) => {
              const isAvailable = ((_.find(exclusionListings ?? [], { Material: addon?.sap_material_number }) ?? {}).inlcuded ?? true);
              return ({
                ...(addon ?? {}),
                required: (e.courseObj.addons.required ?? []).includes(addon?.id) && isAvailable,
                include: isAvailable, // include if available
                truckNumber: getTruckNumber(addon, null, fromDelivery),
              });
            }),
            additional_participants: (e.additional_participants ?? []).map((add) => {
              const isAvailable = ((_.find(exclusionListings ?? [], { Material: add?.sap_material_number }) ?? {}).inlcuded ?? true);
              return ({
                ...(add ?? {}),
                include: isAvailable, // include if available
                truckNumber: getTruckNumber(add, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS, fromDelivery),
              });
            }),
            truckNumber: getTruckNumber(e, null, fromDelivery),
          }),
        ),
      };
      if (checkRebooking) {
        const rebookingInfo = await validateRebooking(updated);
        if (rebookingInfo?.rebook) {
          if (openedFromStartDelivery) {
            showToast(false, 'Exited delivery', true);
          }
          return;
        }
      }
      setOrder(updated);
      if (!currTab && updated.delivery && !updated.deliverySignature) {
        setCurrTab(Number(updated.deliveryStep ?? '0'));
      }
      await preparePartecipantsForm([...updated.events], updated.delivery && !updated.deliverySignature);
      await prepareSignatureForm(updated);
      await dispatch(change(formName, 'billingSummary', { ...updated ?? {} }));
      if (withExclusionListings) {
        await dispatch(fetchSAPListings(ord?.customerObj?.sold_to, ord?.customerObj?.sales_organization, ord?.customerObj?.distribution_channel));
      }
    } catch (error) {
      const errMessage = 'Error Loading Event Details. Please contact an Admin';
      dispatch(ModalsActions.showModal('SHOW_EVENT_DETAILS_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { errMessage },
      }));
    } finally {
      if (!noLoading) {
        setLoading(false);
      }
    }
  };

  const registerPopupCloseListener = () => {
    // this is a callback for when the dialog gets dismissed by clicking the close icon in the top bar
    if (modalName) {
      dispatch(ModalsActions.registerModalClosingValidation(modalName, () => {
        if (overviewBeingEdited()) {
          return ({
            canClose: false,
            feedbackData: {
              modalType: 'WARNING_ALERT',
              modalProps: {
                message: 'You have unsaved changes',
              },
            },
          });
        }
        return { canClose: true };
      }));
    }
  };

  useEffect(async () => {
    await loadOrder(false, true, true);
    registerPopupCloseListener();
    return async () => {
      await dispatch(resetSAPListings());
    };
  }, []);

  const updateOrderDeliveryStep = async (newStep) => {
    const stepStr = `${newStep}`;
    await dispatch(OrderActions.updateDeliveryStep(orderId, stepStr));
    setOrder({ ...order, deliveryStep: stepStr });
  };

  const onStepValidationFailed = async (failedStep) => {
    await updateOrderDeliveryStep(failedStep ?? 0);
  };

  const prepareSignatureForm = async (ord) => {
    const value = ord.printedSignature ?? (ord.deliverySignature ? '' : `${ord.contact?.FirstName} ${ord.contact?.LastName}`);
    await dispatch(change(formName, signatureFieldNames.printedName, value));
  };

  const restoreOriginalOrder = (form, path) => {
    if (!order) return;
    dispatch(change(form, path, { ...order }));
    cleanRows();
    getOSEventInfo([...order.events], materialsInfo, null, true);
  };

  const mapPartecipantsFromSAP = (partecipants, event, isDelivery) => {
    const existentPs = [...(partecipants ?? [])].sort((a, b) => a.index - b.index).map(
      (p) => Object.fromEntries(
        Object.keys(p).map(
          (k) => [partecipantsFieldNames[partecipantsBackendFieldNames[k]], p[k]],
        ),
      ),
    );

    existentPs.forEach((p, i) => {
      if (!p[partecipantsFieldNames.firstName]) {
        existentPs[i] = { ...(existentPs[i] ?? {}), [partecipantsFieldNames.isBeingEdited]: isDelivery };
      }
    });

    const isSkillcheck = event.courseObj?.type === MATERIAL_TYPE.SKILL_CHECK;
    const numRows = event.courseObj ? getNumRows({ event }, isSkillcheck) : 0;

    while (existentPs.length < numRows) {
      existentPs.push({
        [partecipantsFieldNames.index]: existentPs.length,
        [partecipantsFieldNames.isBeingEdited]: isDelivery,
      });
    }

    return existentPs;
  };

  const preparePartecipantsForm = async (events, isDelivery) => {
    const partecipantsObj = events.reduce((reduced, event) => ({
      ...reduced,
      [buildCourseUniqueId({ ...(event.courseObj ?? {}), event: ({ ...(event ?? {}) }) })]: mapPartecipantsFromSAP(event.eventParticipants, event, isDelivery),
    }), {});
    await dispatch(change(formName, `${partecipantsFieldNames.partecipants}`, partecipantsObj));
  };

  const updateMaterialsParticipantsCount = async () => {
    let participantsForm = (validationValues?.current?.partecipants ?? {});
    participantsForm = Object.keys(participantsForm).reduce((red, uniqueKey) => ({
      ...red,
      [uniqueKey]: (participantsForm[uniqueKey] ?? []).filter((p) => Boolean(p) && p[signatureFieldNames.participantFirst] && p[signatureFieldNames.participantLast]),
    }), ({}));
    const events = validationValues?.current?.billingSummary?.events ?? [];
    const skillchecksIdxs = [];
    let prevParticipants = 0;
    const newOrder = {
      ...order,
      subcontracted: Boolean(sessionInstructor?.subcontractor),
      events: (await Promise.allSettled(events.map(async (e, x) => {
        const addPartInfo = e?.courseObj?.additionalParticipants ?? {};
        const addPartMat = materialsInfo[MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS][addPartInfo.id] ?? {};
        const hasAdditionalParticipantsMaterial = !_.isEmpty(addPartInfo) && ((_.find(listingsData, { Material: addPartMat?.code }) ?? {})?.inlcuded ?? true);
        const eventUniqueId = buildCourseUniqueId({ ...(e.courseObj ?? {}), event: ({ ...(e ?? {}) }) });
        const isSkillcheck = (e.courseObj?.type ?? '') === MATERIAL_TYPE.SKILL_CHECK;
        if (isSkillcheck) skillchecksIdxs.push(x);

        const totalParticipants = isSkillcheck ? prevParticipants : (participantsForm[eventUniqueId] ?? []).filter((p) => Object.values(p).filter((v) => Boolean(v)).length > 0).length;
        prevParticipants = totalParticipants;
        const numP = (totalParticipants > calcMaxParticipants(e.courseObj, !_.isEmpty(e.secondaryTci ?? '')) && hasAdditionalParticipantsMaterial) ? calcMaxParticipants(e.courseObj, !_.isEmpty(e.secondaryTci ?? '')) : totalParticipants;
        const numExtraP = totalParticipants - numP;
        const needAdditionalPsAdded = hasAdditionalParticipantsMaterial && (numExtraP > 0) && ((e.additional_participants ?? []).length === 0);
        let additionalPsPrice;
        if (needAdditionalPsAdded) {
          if (addPartMat && addPartMat.code) {
            const pricing = await dispatch(fetchPricing(order.customerObj, addPartMat.code, false, true));
            const pricingListing = _.find((pricing ?? []), { Customer: '-1' });
            const priceVal = getPricing(pricingListing, order.customerObj?.distribution_channel);
            additionalPsPrice = {
              ...(e.price ?? {}),
              amount: (priceVal ?? 0),
            };
          }
        }

        return ({
          ...e,
          subcontracted: Boolean(sessionInstructor?.subcontractor),
          truckNumber: e.truckNumber ?? defaultTruckNum,
          participants_count: isSkillcheck ? undefined : numP, // set skillchecks participants to undefined for billing summary
          addOns: (e.addOns ?? []).map((addon) => {
            const material = materialsInfo[MATERIAL_TYPE.ADD_ON][addon.id] ?? {};
            const notAvailable = _.isEmpty(material) || !((_.find(listingsData ?? [], { Material: (materialsInfo[MATERIAL_TYPE.ADD_ON][addon.id]?.code) }) ?? {}).inlcuded ?? true);
            const qty = () => {
              if (notAvailable) return 0;
              return material?.defaultParticipantsCount ? totalParticipants : 1;
            };
            return { ...addon, qty: qty(), truckNumber: addon.truckNumber ?? defaultTruckNum };
          }),
          // TODO: Test further with new nested conditional (numExtraP > 0)
          additional_participants: hasAdditionalParticipantsMaterial && !needAdditionalPsAdded
            ? (numExtraP > 0 ? (e.additional_participants ?? []).map((item) => ({
              ...item,
              qty: numExtraP,
              truckNumber: item.truckNumber ?? defaultTruckNum,
            })) : [])
            : (needAdditionalPsAdded ? [{
              ...addPartInfo,
              price: additionalPsPrice,
              qty: numExtraP,
              truckNumber: item.truckNumber ?? defaultTruckNum,
            }] : []),
        });
      }))).map((r) => r.value),
    };

    await dispatch(OrderActions.updateOrder({ ...newOrder }, selectedTimezoneCode, Boolean(newOrder?.subcontracted), true, true));
    await loadOrder(true);
    if (!_.isEmpty(skillchecksIdxs)) {
      dispatch(change(formName, 'editingBillSummary', true));
      skillchecksIdxs.forEach((idx) => {
        dispatch(change(formName, `billingSummary.events[${idx}].participants_count`, ''));
      });
    }
  };

  const saveDefaultTruckNumbers = async () => {
    const vls = validationValues?.current?.billingSummary;
    if (!vls || !defaultTruckNum) return;
    const updated = ({ ...vls });
    (values.events ?? []).forEach((e, x) => {
      if (_.isEmpty((e.truckNumber ?? '').trim())) {
        updated.events[x].truckNumber = defaultTruckNum;
      }
      (e.addOns ?? []).forEach((a, y) => {
        if (_.isEmpty((a.truckNumber ?? '').trim())) {
          updated.events[x].addOns[y].truckNumber = defaultTruckNum;
        }
      });

      (e.additional_participants ?? []).forEach((ap, j) => {
        if (_.isEmpty((ap.truckNumber ?? '').trim())) {
          const part = updated.events[x].additional_participants[j];
          updated.events[x].additional_participants[j].truckNumber = getWarehouseOverride(part, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS) ? DEFAULT_TRUCK_NUMBER : defaultTruckNum;
        }
      });
    });

    await dispatch(OrderActions.updateOrder({ ...updated }, selectedTimezoneCode, Boolean(sessionInstructor?.subcontractor), true, true));
    await loadOrder(true, false, false, false);
  };

  const reloadEvent = async (eventId, refreshParticipantsForm) => {
    const summary = await dispatch(OrderActions.fetchOrderSummary(orderId));
    const event = ((summary ?? {}).events ?? []).find((ev) => ev.id === eventId) ?? {};
    const course = courses.find((c) => c.id === event.course && c.event.id === event.id);
    if (event && order && course) {
      const newEvents = [...order.events.map((e) => ({ ...e }))];
      const i = newEvents.findIndex((e) => e.id === eventId);
      if (i !== -1) {
        newEvents[i] = ({ ...event, id: eventId });
        setOrder({ ...order, events: [...newEvents] });
        if (refreshParticipantsForm) {
          await dispatch(change(formName, `${partecipantsFieldNames.partecipants}.${buildCourseUniqueId(course)}`, mapPartecipantsFromSAP(event.eventParticipants, event, order.delivery && !order.deliverySignature)));
        }
      }
    }
  };

  const onRosterUpdated = (eventId, url) => {
    const event = (order?.events ?? []).find((ev) => ev.id === eventId);

    if (event) {
      setOrder({
        ...(order ?? {}),
        events: [...(order?.events ?? []).filter((ev) => ev.id !== eventId), { ...event, [courseFieldNames.customerRoster]: url }],
      });
    }
  };

  const onTabChange = (t) => setCurrTab(t);

  const finalizeStep = async (s, oldStep) => {
    const ts = dialogTabs(order.delivery, hasEditAccess, cancelled);
    if (s > currTab && ts[oldStep] === tabNames.PARTICIPANTS) {
      // if the participants validation has already ran and the user is clear to proceed to the next step
      await updateMaterialsParticipantsCount();
    }
    if (s > currTab && ts[oldStep] === tabNames.BILLING_SUMMARY && Boolean(defaultTruckNum)) {
      // if the billing summary validation has already ran and the user is clear to proceed to the next step
      await saveDefaultTruckNumbers();
    }

    if (s > currTab && ts[oldStep] === tabNames.OVERVIEW) {
      // if the overview validation has NOT ran yet and the user is going to proceed to the next step
      if (overviewBeingEdited()) {
        showUnsavedChangesWarning();
        return false; // false return value will tell StepView to not allow the step change
      }
    }

    if (s < currTab && ts[oldStep] === tabNames.PARTICIPANTS) {
      // if the participants validation has NOT ran yet and the user is going to PREVIOUS step
      if (areParticipantsBeingEdited()) {
        showUnsavedChangesWarning();
        return false; // false return value will tell StepView to not allow the step change
      }
    }

    if (s < currTab && ts[oldStep] === tabNames.BILLING_SUMMARY) {
      // if the Billing summary validation has NOT ran yet and the user is going to PREVIOUS step
      if (isBillingSummaryBeingEdited()) {
        showUnsavedChangesWarning();
        return false;
      }
    }

    return true;
  };

  const onDeliveryStepChanged = async (s, dontFinalize) => {
    const oldStep = currTab;
    const finalized = dontFinalize ? true : await finalizeStep(s, oldStep);
    if (finalized) {
      if (s > currTab && (!order?.deliveryStep || s > order?.deliveryStep)) {
        // if they are going forward on incomplete steps
        const newStep = `${s}`;
        updateOrderDeliveryStep(newStep);
      }
      onTabChange(s);
    }

    return finalized;
  };

  const overviewBeingEdited = () => validationValues.current.editingEventOverview;

  const overviewTabValidation = (hideFeedback, step) => {
    const isPoRequired = Boolean(order.customerObj?.PO_Required_Flag && order.customerObj?.PO_Required_Flag !== '');
    if (isPoRequired) {
      const po = (validationValues.current.billingSummary[signatureFieldNames.poNumber] ?? '').trim();
      if (!po) {
        if (!hideFeedback) {
          showToast(false, 'Please insert a PO Number for the order');
          onStepValidationFailed(step ?? currTab);
        }
        return false;
      }
      return true;
    }
    return true;
  };

  const showParticipantsMinDialog = (errorsInfo) => {
    const errors = Object.values(errorsInfo ?? {}) ?? [];
    const pModalName = 'PARTICIPANTS_MIN_DIALOG';
    dispatch(ModalsActions.showModal(pModalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'lg',
        confirmText: 'ok',
        title: 'MINIMUM PARTICIPANTS',
        content: <ParticipantsMinDialog data={errors} />,
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(pModalName));
        },
      },
    }));
  };

  const showUnsavedChangesWarning = () => {
    showToast(false, 'You have unsaved changes', true);
  };

  const areParticipantsBeingEdited = () => {
    const participants = validationValues.current[signatureFieldNames.participants] ?? {};
    const beingEdited = Object.values(participants).reduce(
      (red, val) => [...red, ...(val ?? [])],
      [],
    ).filter(
      (p) => p[partecipantsFieldNames.isBeingEdited]
        && (p[partecipantsFieldNames.firstName] || p[partecipantsFieldNames.lastName] || p[partecipantsFieldNames.email] || p[partecipantsFieldNames.employeeId]),
    );

    return !_.isEmpty(beingEdited);
  };

  const participantsTabValidation = (hideFeedback, step) => {
    const participants = validationValues.current[signatureFieldNames.participants] ?? {};
    const validateParticipantsMin = () => {
      const events = validationValues.current.billingSummary?.events ?? [];
      const flagged = {};
      events.forEach((e) => {
        const courseId = e.course;
        const pKey = Object.keys(participants).find((k) => k === `${courseId}-${e.startTime}:${e.endTime}`);
        if (_.isEmpty(pKey ?? '')) {
          return;
        }

        const numP = (participants[pKey] ?? []).filter((p) => Boolean(p) && p[signatureFieldNames.participantFirst] && p[signatureFieldNames.participantLast]).length;
        const min = e.courseObj?.participants?.min ?? 0;
        if (numP < min) {
          flagged[pKey] = {
            courseTitle: e.courseObj?.title ?? '',
            courseTime: moment.utc(e.startTime),
            min,
            num: numP,
          };
        }
      });

      return _.isEmpty(flagged) ? undefined : flagged;
    };

    // const beingEdited = Object.values(participants).reduce(
    //   (red, val) => [...red, ...(val ?? [])],
    //   [],
    // ).filter(
    //   (p) => p[partecipantsFieldNames.isBeingEdited]
    //     && (p[partecipantsFieldNames.firstName] || p[partecipantsFieldNames.lastName] || p[partecipantsFieldNames.email] || p[partecipantsFieldNames.employeeId]),
    // );

    if (areParticipantsBeingEdited()) {
      showUnsavedChangesWarning();
      onStepValidationFailed(step ?? currTab);
      return false;
    }
    const participantsErrors = validateParticipantsMin();
    if (participantsErrors) {
      if (!hideFeedback) {
        showParticipantsMinDialog(participantsErrors);
        onStepValidationFailed(step ?? currTab);
      }
      return false;
    }
    return true;
  };

  const validateTruckNumbers = (events, hideFeedback) => {
    let valid = true;

    const invalidNum = (num) => !num || (num !== DEFAULT_TRUCK_NUMBER && !TRUCK_NUM_VALIDATION_REGEX.test(num) && !SPECIAL_TRUCK_NUMS.includes(num));

    events.forEach((e) => {
      if (invalidNum(e.truckNumber)) {
        if (!hideFeedback) {
          showToast(false, `${e.title} has an invalid truck number`);
        }
        valid = false;
      }
      (e.addOns ?? []).forEach(
        (addOn) => {
          const notAvailable = _.isEmpty(addOn) || !((_.find(listingsData ?? [], { Material: (materialsInfo[MATERIAL_TYPE.ADD_ON][addOn.id]?.code) }) ?? {}).inlcuded ?? true);
          if (!notAvailable && addOn.include && invalidNum(addOn.truckNumber)) {
            if (!hideFeedback) {
              showToast(false, `${addOn.title} of ${e.title} has an invalid truck number`);
            }
            valid = false;
          }
        },
      );

      (e.additional_participants ?? []).forEach(
        (ap) => {
          if (ap && (Number(ap.qty ?? 0) > 0) && invalidNum(ap.truckNumber)) {
            if (!hideFeedback) {
              showToast(false, `${ap.title} of ${e.title} has an invalid truck number`);
            }
            valid = false;
          }
        },
      );
    });
    return valid;
  };

  const isBillingSummaryBeingEdited = () => validationValues.current.editingBillSummary;

  const billingSummaryTabValidation = async (hideFeedback, step) => {
    if (isBillingSummaryBeingEdited()) {
      showUnsavedChangesWarning();
      return false;
    }
    // if (defaultTruckNum) return true;
    await saveDefaultTruckNumbers();
    const summary = validationValues.current.billingSummary ?? {};
    const valid = validateTruckNumbers((summary.events ?? []), hideFeedback);
    if (!valid && !hideFeedback) {
      onStepValidationFailed(step ?? currTab);
    }
    return valid;
  };

  const getPrintedName = () => {
    let printedName = validationValues.current[signatureFieldNames.printedName];
    const useOther = (!(printedName ?? '').replace(`${undefined} ${undefined}`, '').trim() || !(printedName ?? '').trim()) && validationValues.current[signatureFieldNames.otherName];
    printedName = useOther ? validationValues.current[signatureFieldNames.otherName] : printedName;
    return printedName;
  };

  const getSigPad = () => sigRef?.current ?? {};

  const signatureTabValidation = async (hideFeedback, step) => {
    const printedName = getPrintedName();
    const sigPad = getSigPad();
    if (!(printedName ?? '').replace(`${undefined} ${undefined}`, '').trim() || !(printedName ?? '').trim()) {
      if (!hideFeedback) {
        showToast(false, 'Please provide a printed name for the signature');
        onStepValidationFailed(step ?? currTab);
      }
      return { valid: false, errorStep: step ?? currTab };
    }

    if (sigPad?.isEmpty()) {
      if (!hideFeedback) {
        showToast(false, 'Please draw a signature in the box above');
        onStepValidationFailed(step ?? currTab);
      }
      return { valid: false, errorStep: step ?? currTab };
    }

    if (!hideFeedback) {
      const { valid, errorStep } = await validateAllSteps();
      return { valid, errorStep };
    }

    return { valid: true };
  };

  const showPrevStepExpiredPopup = ({ step }) => {
    const MODAL_NAME = 'PREV_STEP_EXPIRED_ALERT';
    dispatch(ModalsActions.showModal(MODAL_NAME, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        confirmText: 'go to step',
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(MODAL_NAME));
          onDeliveryStepChanged(step, true);
          setStepsViewKey((stepsViewKey + 1));
        },
        cancelText: 'cancel',
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'INVALID STEP',
        content: <DeliveryStepValidationFailure
          step={step}
          steps={dialogTabs(order.delivery, hasEditAccess, cancelled)}
        />,
      },
    }));
  };

  const validateAllSteps = async () => {
    const validations = getValidationFunctions();
    for (let x = 0; x < validations.length; x++) {
      const stepValidation = validations[x];
      const validationRes = stepValidation && await stepValidation(true, x);
      const valid = (x === (validations.length - 1)) ? validationRes.valid : validationRes;
      if (!valid && (x !== (validations.length - 1))) { // if its not the final validation step (this step) and its not valid
        // if previous step isnt valid any longer;
        showPrevStepExpiredPopup({ step: x });
        return { valid: false, errorStep: x };
      }
    }
    return { valid: true };
  };

  const finalizeOrder = async () => {
    const sigPad = getSigPad();
    const printedName = getPrintedName();
    let result = false;

    try {
      const { valid } = await validateAllSteps();
      if (!valid) {
        return false;
      }
      const formData = new FormData();
      formData.append('printedSignature', printedName);
      sigPad.getTrimmedCanvas().toBlob(async (blob) => {
        formData.append('deliverySignature', blob);
        const url = await dispatch(OrderActions.submitSignature(orderId, formData));
        if (url) {
          loadOrder();
          dispatch(ModalsActions.hideModal('EVENT_DETAILS_DIALOG'));
          showToast(true, 'Course has been successfully finalized');
          result = true;
        }
      });
      return result;
    } catch (e) {
      return false;
    }
  };

  const showToast = (success, msg, warning) => {
    dispatch(ModalsActions.showModal('COMPLETE_ORDER_STATUS', {
      modalType: warning ? 'WARNING_ALERT' : (success ? 'SUCCESS_ALERT' : 'ERROR_ALERT'),
      modalProps: {
        message: msg ?? (success ? 'Step completed!' : 'You are not done with this step yet'),
      },
    }));
  };
  const spinner = () => <Spinner customStyle={{ marginTop: '30%' }} />;
  const text = (txt, padding) => <Typography style={{ textAlign: 'center', padding }}>{txt}</Typography>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const participantsTab = (withValidator) => (
    <PartecipantsTab
      key="PartecipantsTab"
      tzOverride={tzOverride}
      fieldNames={partecipantsFieldNames}
      backendFieldNames={partecipantsBackendFieldNames}
      isFullyEditable={(!order.delivery) && hasEditAccess}
      isComplete={((order && order.deliverySignature && !order.manual_invoice) && editParticipantsExpired) || !hasEditAccess || !hasDeliveryEditAccess}
      orderId={orderId}
      order={order}
      formName={formName}
      dispatch={dispatch}
      values={withValidator ? validationValues.current : values}
      onRosterUpdated={onRosterUpdated}
      reloadEvent={reloadEvent}
    />
  );

  const overviewTab = (withValidator) => (
    <EventOverview
      key="ovr"
      showOrderId
      alreadyLoaded
      showPoNumber
      allowTempContactSwitch
      formName={formName}
      orderFormPath="billingSummary"
      hideRepeatBookingButton={hideRepeatBookingButton}
      values={withValidator ? validationValues.current : values}
      tzOverride={tzOverride}
      showDeliveryExit={order.delivery}
      validateRebooking={validateRebooking}
      parentModal={modalName}
      order={order}
      contact={contact}
      dispatch={dispatch}
      reloadOrder={loadOrder}
      withConfirmationNavigation
      onPoUpdated={() => { }}
    />
  );

  const billingSummaryTab = (withValidator) => (
    <BillingSummaryTab
      key="BillingSummaryTab"
      reloadOrder={loadOrder}
      restoreOriginalOrder={restoreOriginalOrder}
      order={order}
      validateTruckNumbers={validateTruckNumbers}
      formName={formName}
      dispatch={dispatch}
      values={withValidator ? validationValues.current : values}
      history={history}
      selectedTimezone={selectedTimezone}
      selectedTimezoneCode={selectedTimezoneCode}
      tzOverride={tzOverride}
    />
  );

  const signatureTab = (withValidator) => (
    <SignatureTab
      key="SignatureTab"
      orderId={orderId}
      customerId={order.customer}
      order={order}
      values={withValidator ? validationValues.current : values}
      signatureImg={order.deliverySignature}
      tzOverride={tzOverride}
      dispatch={dispatch}
      refreshOrder={loadOrder}
      fieldNames={signatureFieldNames}
      formName={formName}
      setSigPad={(sp) => { sigRef.current = sp; }}
      getSigPad={getSigPad}
    />
  );

  const communicationsTab = () => (
    <CommunicationsTab
      key="CommunicationsTab"
      formName={formName}
      order={order}
      dispatch={dispatch}
      values={values}
      tzOverride={tzOverride}
      subcontracted={subcontracted}
      updateOrderCommunicationHistory={
        (newHistory) => setOrder({
          ...(order ?? {}),
          communication_history: [...(newHistory ?? [])],
        })
      }
    />
  );

  const getTabs = (withValidator) => {
    const includedTabs = dialogTabs(order?.delivery, hasEditAccess, cancelled);
    return [
      ...(includedTabs.includes(tabNames.OVERVIEW) ? [overviewTab(withValidator)] : []),
      ...(includedTabs.includes(tabNames.PARTICIPANTS) ? [participantsTab(withValidator)] : []),
      ...(includedTabs.includes(tabNames.COMMUNICATION) ? [communicationsTab()] : []),
      ...(includedTabs.includes(tabNames.BILLING_SUMMARY) ? [billingSummaryTab(withValidator)] : []),
      ...(includedTabs.includes(tabNames.CUSTOMER_SIGNATURE) ? [signatureTab(withValidator)] : []),
    ];
  };

  const getTabsActions = () => [
    [], // overview tab actions
    [], // participants tab actions
    [], // billing summary tab actions
    [item({ flex: 6, style: { paddingTop: '2%', textAlign: 'center', width: '100%' }, children: [text(LegalText.customerSignature.body)] })], // signature tab actions
  ];

  const getValidationFunctions = () => [
    overviewTabValidation,
    participantsTabValidation,
    billingSummaryTabValidation,
    signatureTabValidation,
  ];

  const tabView = () => (
    <TabView
      tabs={dialogTabs(order.delivery, hasEditAccess, cancelled)}
      initialTab={currTab}
      onTabChange={onTabChange}
      tabsContent={getTabs()}
    />
  );

  const stepsView = () => (
    <StepsView
      disableErrorLabel
      dispatch={dispatch}
      key={stepsViewKey}
      onSubmit={finalizeOrder}
      submitButtonLabel="Finalize Invoice"
      nextButtonLabel="Complete Step"
      contentHeight={512}
      tabs={dialogTabs(order.delivery, hasEditAccess, cancelled)}
      tabsContent={getTabs(true)}
      tabsActions={getTabsActions()}
      tabsValidators={getValidationFunctions()}
      onTabChange={onDeliveryStepChanged}
      initialTab={currTab}
      events={order.events}
    />
  );

  const layout = () => {
    const isComplete = hasDeliveryEditAccess ? order.deliverySignature : true;
    return (!inDelivery) || isComplete || cancelled ? tabView() : stepsView();
  };

  return (
    <>
      <EventDetailsDialogWrapper orderId={orderId} dispatch={dispatch}>
        {loading ? spinner() : layout()}
      </EventDetailsDialogWrapper>
    </>
  );
};

export default _.flow([
  connect((state) => {
    const values = getFormValues(formName)(state);
    const subcontracted = values?.subcontracted || values?.events?.[0]?.subcontracted;
    return ({
      values,
      utils: state.utils,
      materialsInfo: materialsDataSelector(state),
      subcontracted,
      canEdit: hasUserAccessSelector(state, [subcontracted ? ROLE_ACCESSES.subcontractorEvents : ROLE_ACCESSES.editEvent]),
      hasDeliveryEditAccess: hasUserAccessSelector(state, [ROLE_ACCESSES.canDeliver]),
    });
  }),
  withRouter,
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(EventDetailsDialog);
