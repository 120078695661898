/* eslint-disable max-len */
import _ from 'lodash';
import {
  IconButton,
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { getFormValues, initialize } from 'redux-form';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import NewMaterialModal from '../../Modals/SettingsModals/NewMaterialModal';

import {
  COLORS, MATERIAL_STATUS_DISPLAY, MATERIAL_STATUS, MATERIAL_TYPE_DISPLAY, MATERIAL_TYPE,
} from '../../../utils/consts';
import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';
import { materialSelector } from '../../../redux/selectors/materials.selector';
import { materialCategoriesSelector } from '../../../redux/selectors/settings.selectors';
import { removeLeadingZeros } from '../../../utils/helpers';

const searchOptions = {
  filter: true,
  filterType: 'dropdown',
  searchOpen: true,
  selectableRows: 'none',
  sortOrder: {
    name: 'Status',
    direction: 'desc',
  },
  downloadOptions: {
    filename: 'Materials.csv',
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: true,
    },
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    const printedData = [];

    data.map((obj) => {
      const retainedData = obj.data.slice(0, 26);

      // Handle base case for original unassociated material
      const baseData = [...retainedData, '', '', ''];

      printedData.push({ index: undefined, data: baseData });

      // Loop for each associated material
      _.forEach(obj.data[25], (assocMaterial) => {
        let tempData = [];
        switch (assocMaterial.matType) {
          case MATERIAL_TYPE_DISPLAY.ADDITIONAL_PARTICIPANTS:
            tempData = [...retainedData, '', '', '', '', '', '', removeLeadingZeros(assocMaterial.matID), assocMaterial.matDesc, assocMaterial.matParticipants];
            break;
          case MATERIAL_TYPE_DISPLAY.SKILL_CHECK:
            tempData = [...retainedData, '', '', '', '', removeLeadingZeros(assocMaterial.matID), assocMaterial.matDesc, '', '', ''];
            break;
          case 'Optional Addon':
            tempData = [...retainedData, '', '', removeLeadingZeros(assocMaterial.matID), assocMaterial.matDesc, '', '', '', '', ''];
            break;
          case 'Required Addon':
            tempData = [...retainedData, removeLeadingZeros(assocMaterial.matID), assocMaterial.matDesc, '', '', '', '', '', '', ''];
            break;
          default:
            tempData = [...retainedData, '', '', '', '', '', '', '', '', ''];
        }
        printedData.push({ index: undefined, data: tempData });
      });

      return -1;
    });

    const val = `${buildHead(columns)}${buildBody(printedData)}`.trim();

    return val;
  },
};

const columns = [
  'Sales Org',
  'Material #',
  'SAP Material Description',
  'Type',
  {
    name: 'Status',
    options: {
      customBodyRender: (value) => (value !== 'Awaiting' ? value : <OrangeWarningIcon />),
      filterType: 'multiselect',
      filterList: ['Awaiting', 'Customer-Facing', 'Hidden', 'Partners Only'],
    },
  },
  {
    name: 'TC Material Description',
    options: {
      display: true,
    },
  },
  {
    name: 'Subcontractor',
    options: {
      display: false,
    },
  },
  {
    name: 'Lead Time (days)',
    options: {
      display: false,
    },
  },
  {
    name: 'Waive Cost',
    options: {
      display: false,
    },
  },
  {
    name: 'Material Long Description',
    options: {
      display: false,
    },
  },
  {
    name: 'Minimum Participants',
    options: {
      display: false,
    },
  },
  {
    name: 'Maximum Participants',
    options: {
      display: false,
    },
  },
  // All hidden columns
  {
    name: 'Duration (Min)',
    options: {
      display: false,
    },
  },
  {
    name: 'Make it a Day Discount (%)',
    options: {
      display: false,
    },
  },
  {
    name: 'Certification Group',
    options: {
      display: false,
    },
  },
  {
    name: 'Recertification Group',
    options: {
      display: false,
    },
  },
  {
    name: 'Recertification Frequency (Months)',
    options: {
      display: false,
    },
  },
  {
    name: 'Family',
    options: {
      display: false,
    },
  },
  {
    name: 'Warehouse Override',
    options: {
      display: false,
    },
  },
  {
    name: 'Category Classification',
    options: {
      display: false,
    },
  },
  {
    name: 'Changed by Date',
    options: {
      display: false,
    },
  },
  {
    name: 'Image',
    options: {
      display: false,
    },
  },
  {
    name: 'Last Update by',
    options: {
      display: false,
    },
  },
  {
    name: 'Tied to participant count',
    options: {
      display: false,
    },
  },
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
  {
    name: 'AssociatedMaterials',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
  {
    name: 'Required Addons Material',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Required Addons Description',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Optional Addons Material',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Optional Addons Description',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Skill Check Material Number',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Skill Check Description',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Additional Participants Material #',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Additional Participants Description',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
  {
    name: 'Additional Participants Quantity',
    options: {
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: true,
    },
  },
];

const OrangeWarningIcon = withStyles({
  root: {
    color: COLORS.CINTAS_ORANGE,
  },
})(WarningIcon);

const MaterialsTable = (props) => {
  const {
    sapMaterials,
    materials,
    certifications,
    dispatch,
    classificationsData,
  } = props;
  const history = useHistory();
  const showNewMaterialModal = () => {
    dispatch(ModalsActions.showModal('NEW_MATERIAL_MODAL', {
      modalType: 'FAS_EVENT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        confirmText: 'confirm',
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'CONFIGURE MATERIAL',
        content: <NewMaterialModal />,
      },
    }));
  };
  const handleEditClick = ({ material, materialExists }) => {
    if (materialExists) {
      history.push(`/admin/materials/${material.Sales_Organization}/${material.Material}/${material.Deleted}`);
    } else {
      dispatch(initialize('CourseSettingsForm', {
        code: material.Material,
        salesOrg: material.Sales_Organization,
        status: MATERIAL_STATUS.HIDDEN,
        title: material.Material_Description,
      }));
      showNewMaterialModal();
    }
  };
  const editButton = (material, materialExists, isDeleted) => (
    <IconButton
      aria-label="edit"
      onClick={() => handleEditClick({ material, materialExists })}
      disabled={!materialExists && isDeleted}
    >
      <EditIcon />
    </IconButton>
  );

  const rows = useSelector((state) => _.map(sapMaterials, (material) => {
    const firestoreMaterial = _.find(
      materials,
      { code: material.Material, salesOrg: material.Sales_Organization },
    );
    const materialExists = !!firestoreMaterial;
    const isDeleted = material.Deleted;

    // Create new field associatedMaterials array of objects, [{matType: 'String', matDesc: 'String', matID: ###### }, {....}]
    // use this new field in the onDownload method in table options to render duplicate fields in the CSV

    let assocMaterials = [];
    // check for additional Participants
    if ((firestoreMaterial?.additionalParticipants ?? false) && (firestoreMaterial?.additionalParticipants.id)) {
      const addPartMaterial = materialSelector({
        state,
        type: MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS,
        id: firestoreMaterial?.additionalParticipants.id,
      });
      assocMaterials = [...assocMaterials, {
        matType: MATERIAL_TYPE_DISPLAY.ADDITIONAL_PARTICIPANTS,
        matDesc: addPartMaterial.title ?? 'BAD MATERIAL',
        matID: addPartMaterial.code ?? 'BAD MATERIAL',
        matParticipants: firestoreMaterial?.additionalParticipants.qty ?? 'Missing',
      }];
    }

    // check for skillcheck
    if (firestoreMaterial?.skillcheckID ?? false) {
      const skillcheckMaterial = materialSelector({
        state,
        type: MATERIAL_TYPE.SKILL_CHECK,
        id: firestoreMaterial.skillcheckID,
      });

      assocMaterials = [...assocMaterials, {
        matType: MATERIAL_TYPE_DISPLAY.SKILL_CHECK,
        matDesc: skillcheckMaterial.title ?? 'BAD MATERIAL',
        matID: skillcheckMaterial.code ?? 'BAD MATERIAL',
      }];
    }

    // check for required addons
    if (firestoreMaterial?.addons?.required ?? false) {
      const requiredAddons = firestoreMaterial.addons.required;
      const reqAddonMaterial = _.map(requiredAddons, (addon) => materialSelector({
        state,
        type: MATERIAL_TYPE.ADD_ON,
        id: addon,
      }));

      _.forEach(reqAddonMaterial, (addonMaterial) => {
        assocMaterials = [...assocMaterials, {
          matType: 'Required Addon',
          matDesc: addonMaterial.title ?? 'BAD MATERIAL',
          matID: addonMaterial.code ?? 'BAD MATERIAL',
        }];
      });
    }

    // check for optional addons
    if (firestoreMaterial?.addons?.optional ?? false) {
      const optionalAddons = firestoreMaterial.addons.optional;
      const optAddonMaterial = _.map(optionalAddons, (addon) => materialSelector({
        state,
        type: MATERIAL_TYPE.ADD_ON,
        id: addon,
      }));

      _.forEach(optAddonMaterial, (addonMaterial) => {
        assocMaterials = [...assocMaterials, {
          matType: 'Optional Addon',
          matDesc: addonMaterial.title ?? 'BAD MATERIAL',
          matID: addonMaterial.code ?? 'BAD MATERIAL',
        }];
      });
    }

    // Convert fields to display values
    const subcontractDisplay = firestoreMaterial?.subcontractor ?? false ? 'Yes' : 'No';
    const warehouseDisplay = firestoreMaterial?.warehouseOverride ?? false ? 'Yes' : 'No';
    const waiveCostDisplay = firestoreMaterial?.waiveCost ?? false ? 'Yes' : 'No';
    const statusDisplay = isDeleted ? 'Inactive' : MATERIAL_STATUS_DISPLAY[firestoreMaterial?.status] || 'Awaiting';
    const materialUpdateDate = (firestoreMaterial?.updatedmaterial ?? false) ? moment(firestoreMaterial.updatedmaterial).format('MM/DD/YYYY') : 'N/A';
    const materialUpdateBy = `${firestoreMaterial?.firstnameofwhoupdatedmaterial ?? ''} ${firestoreMaterial?.lastnameofwhoupdatedmaterial ?? ''}`;
    const tiedToParticipantsCountDisplay = (firestoreMaterial?.defaultParticipantsCount ?? false) ? 'Yes' : 'No';
    const certificationDisplay = firestoreMaterial?.certGroup
      ? _.find(
        certifications,
        { id: firestoreMaterial.certGroup },
      )?.title ?? 'Failed to Find Certification' : 'N/A';
    const recertificationDisplay = firestoreMaterial?.recertGroup
      ? _.find(
        certifications,
        { id: firestoreMaterial.recertGroup },
      )?.title ?? 'Failed to Find Recertification' : 'N/A';
    const familyDisplay = firestoreMaterial?.family
      ? _.find(
        certifications,
        { id: firestoreMaterial.family },
      )?.title ?? 'Failed to Find Family' : 'N/A';
    const classificationDisplay = firestoreMaterial?.category
      ? _.find(
        classificationsData,
        { id: firestoreMaterial?.category },
      )?.title ?? 'Failed to Find Classification' : 'N/A';

    return [
      material.Sales_Organization,
      material.Material?.replace(/^[0]+/g, ''),
      material.Material_Description ?? 'N/A',
      firestoreMaterial ? MATERIAL_TYPE_DISPLAY[firestoreMaterial.type] : 'N/A',
      statusDisplay,
      firestoreMaterial?.title ?? 'N/A',
      subcontractDisplay,
      firestoreMaterial ? firestoreMaterial.leadTime : 'N/A',
      waiveCostDisplay,
      firestoreMaterial ? firestoreMaterial.description : 'N/A',
      firestoreMaterial?.participants?.min ?? 'N/A',
      firestoreMaterial?.participants?.max ?? 'N/A',
      firestoreMaterial ? firestoreMaterial.duration : 'N/A',
      firestoreMaterial ? firestoreMaterial.discount : 'N/A',
      certificationDisplay,
      recertificationDisplay,
      firestoreMaterial ? firestoreMaterial.recertFreq : 'N/A',
      familyDisplay,
      warehouseDisplay,
      classificationDisplay,
      materialUpdateDate,
      firestoreMaterial?.image ?? 'N/A',
      materialUpdateBy,
      tiedToParticipantsCountDisplay,
      editButton(material, materialExists, isDeleted),
      assocMaterials,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
  }));

  return (
    <FASMUIDataTable
      data={rows}
      columns={columns}
      options={searchOptions}
    />
  );
};
const mapStateToProps = (state) => ({
  values: getFormValues('CourseDetailsForm')(state),
  classificationsData: materialCategoriesSelector(state),
});
export default _.flow([
  connect(mapStateToProps),
])(MaterialsTable);
