import { Paper } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import { useSelector } from 'react-redux';
import { timezoneSelector } from '../../../../../../redux/selectors/utils.selectors';
import { useStyles } from '../../../../../../routes/MainViews/EventSummaryView/EventSummaryView';
import { COLORS, DATE_TIME_YYYY_SHORT_FORMAT_HISTORY } from '../../../../../../utils/consts';
import Container from '../../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../../../Text/SimpleText';

const CommunicationHistoryTable = ({ data, wrapperStyle, hideTitle }) => {
  const tableClasses = useStyles();
  const tz = useSelector(timezoneSelector);

  const text = (txt, style, rest) => (
    <SimpleText
      txt={txt}
      {...(rest ?? {})}
      style={{
        textAlign: 'start',
        ...(style ?? {}),
      }}
    />
  );

  const entryItem = (label, value, breakLine) => (
    <ContainerItem flex={4}>
      {text(label, { color: COLORS.CINTAS_BLUE, fontWeight: 'bold' })}
      {text(value, { fontSize: 16, fontWeight: 'none' }, { lineBreak: breakLine ? '-' : undefined })}
    </ContainerItem>
  );

  const entry = (dataEntry) => (
    <Paper
      key={dataEntry.date}
      className={tableClasses.paper}
      elevation={2}
      style={{
        width: '100%',
        marginBottom: 20,
        padding: 0,
      }}
    >
      <Container style={{ justifyContent: 'space-between', padding: 14 }} spacing={0}>
        {entryItem(moment.utc(dataEntry.date).tz(tz).format(DATE_TIME_YYYY_SHORT_FORMAT_HISTORY), (dataEntry.subject ?? ''), true)}
        {entryItem('sent by', _.startCase(`${(dataEntry.FirstName ?? '---')} ${(dataEntry.LastName ?? '---')}`.toLowerCase()))}
        {entryItem('sent to', (dataEntry.sent_to ?? '---'))}
      </Container>
    </Paper>
  );

  const noHistory = () => (
    <ContainerItem flex={12}>
      {text('No communications have been sent yet.')}
    </ContainerItem>
  );

  return (
    <Container className={tableClasses.root} maxWidth="md" style={{ justifyContent: 'start', ...(wrapperStyle ?? {}) }}>
      <div style={{
        marginBottom: 20, color: COLORS.CINTAS_BLUE, fontWeight: 'bold', fontSize: 18,
      }}
      >
        {hideTitle ? '' : 'Communication History'}
      </div>
      {_.isEmpty(data) ? noHistory() : (data ?? []).map((dataEntry) => entry(dataEntry))}
    </Container>

  );
};

export default CommunicationHistoryTable;
