import _ from 'lodash';
import { change, Field } from 'redux-form';
import { MuiThemeProvider } from 'material-ui/styles';
import { useSelector, connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';

import { MATERIAL_TYPE, OEC_CUSTOMER } from '../../../../../utils/consts';
import CoursePrice from './CoursePrice';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import MDAutoComplete from '../../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import MDSlider from '../../../../Forms/FormComponents/MDSlider/MDSlider';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';

import * as PricingActions from '../../../../../redux/actions/pricing.actions';
import { materialsDataSelector } from '../../../../../redux/selectors/materials.selector';
import { calcMaxParticipants } from '../../../../../utils/event.utils';

const OpenEnrollmentCourseSelect = (props) => {
  const { name, dispatch } = props;
  const openEnrollmentMaterials = useSelector(materialsDataSelector);
  const openEnrollmentCourses = Object.values(openEnrollmentMaterials[MATERIAL_TYPE.COURSE]);
  const formValues = useSelector((state) => state.form.AddEventDialog.values);

  // Get initial max participants from state
  const initMaxParticipants = formValues?.openEnrollmentCourse?.course?.participants ?? 0;
  const isCourseSelected = formValues?.openEnrollmentCourse ?? false;

  const fetchPricing = async (value) => {
    const sapMaterialId = value.code;
    const pricing = await dispatch(PricingActions.fetchPricing(OEC_CUSTOMER, sapMaterialId));
    const coursePrice = _.find(pricing, { id: sapMaterialId });
    dispatch(change('AddEventDialog', 'openEnrollmentCourse.price', coursePrice.customer.amount));
    dispatch(change('AddEventDialog', 'participants', calcMaxParticipants(value, !_.isEmpty(formValues?.secondaryTci ?? ''))));
    dispatch(change('AddEventDialog', 'openEnrollmentCourse.currency', coursePrice.customer.currency));
  };

  return (
    <>
      <Grid item style={{ paddingBottom: '0px' }} xs={12}>
        <FASFieldTitle title="Course" />
      </Grid>
      <Grid item xs={12}>
        <Field
          options={openEnrollmentCourses}
          getLabel={(option) => `${option.code} - ${option.title}`}
          variant="outlined"
          placeholder="Select course"
          id="course"
          name={`${name}.course`}
          required
          component={MDAutoComplete}
          onValueChange={(value) => fetchPricing(value)}
          size="small"
          label="Course"
          popupIcon={<KeyboardArrowDownIcon color="primary" />}
          disableClearable
          noBorderRadius
        />
        <Grid item xs={12}>
          <Grid item xs={12}>
            <CoursePrice hideDivider member={name} i="" disabledCustomerPrice={false} />
          </Grid>
          <MuiThemeProvider>
            <Grid item xs={12} sm={12}>
              <FASFieldTitle title="Max Participants" />
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={7}>
                  <Field
                    variant="outlined"
                    placeholder="0"
                    id="coursemaxParticipantsSlider"
                    name="participants"
                    component={MDSlider}
                    label="Max Participants"
                    disabled={!isCourseSelected}
                    max={initMaxParticipants.max}
                    min={initMaxParticipants.min}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    variant="outlined"
                    placeholder="Maximum Participants"
                    id="coursemaxParticipantsTextInput"
                    name="participants"
                    component={MDTextInputField}
                    label="Max Participants"
                    type="number"
                    max={initMaxParticipants.max}
                    min={initMaxParticipants.min}
                    size="small"
                    noBorderRadius
                    disabled={!isCourseSelected}
                    disableClearable
                  />
                </Grid>
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default connect()(OpenEnrollmentCourseSelect);
