/* eslint-disable import/prefer-default-export */
import { baseInstance } from './instances/baseInstance';

export const fetchUserUnsolvedTasks = (userID, page, type) => (
  // baseInstance.get(`/getUnsolvedTasks?userID=${userID}`)
  baseInstance.get(`/getUnsolvedTasksV2?userID=${userID}&page=${page}${type ? `&type=${type}` : ''}`)
);

export const fetchUserSolvedTasks = (userID, page) => (
  baseInstance.get(`/getSolvedTasks?userID=${userID}&page=${page}`)
);

export const markTaskRead = (taskID) => (
  baseInstance.put(`/readTask?taskID=${taskID}`)
);

export const markAllTasksRead = (ids) => (
  baseInstance.post('/markAllTasksRead', { ids })
);

export const getTaskById = (taskID) => (
  baseInstance.get(`/tasks/${taskID}`)
);

export const markTaskResolved = (taskID, resolutionValue) => (
  baseInstance.put(`/resolveTask?taskID=${taskID}`, `${resolutionValue}`)
);

export const runConflictCheckForUsers = (payload) => (
  baseInstance.post('/usersConflictsCheck', payload)
);

export const generateOrderTask = (payload) => (
  baseInstance.post('/generateOrderTask', payload)
);
