/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Field, reduxForm, getFormValues, change,
} from 'redux-form';
import {
  Grid,
  MenuItem,
  InputAdornment,
  Button,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  COLORS,
  MATERIAL_STATUS,
  MATERIAL_STATUS_DISPLAY,
  MATERIAL_TYPE,
  DESC_MAX_LENGTH,
} from '../../../utils/consts';
import FASCheckboxField from '../CustomFormComponents/FASCheckboxField/FASCheckboxField';
import FASFieldTitle from '../CustomFormComponents/FASFieldTitle';
import FASImage from '../CustomFormComponents/FASImage';
import FASRadio from '../CustomFormComponents/FASRadio';
import FASSelectField from '../CustomFormComponents/FASSelectField';
import FASTitle from '../CustomFormComponents/FASTtle';
import MDRadioGroupField from '../FormComponents/MDRadioGroup/MDRadioGroupField';
import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';
import Spinner from '../../SpinnerOverlay/Spinner';

import * as MaterialsActions from '../../../redux/actions/materials.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import MDAutoComplete from '../FormComponents/MDAutoComplete/MDAutoCompleteField';
import UploadImageInput from './UploadImageInput';
import { materialCategoriesSelector } from '../../../redux/selectors/settings.selectors';
import { materialsDataSelector, materialsOfMultipleTypes } from '../../../redux/selectors/materials.selector';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0px',
      },
    },
  },
});

const validate = (values) => {
  const errors = {};
  let requiredFields = [];
  const integerFields = ['duration', 'leadtime', 'recertFrequency'];
  const initialFields = ['type', 'title', 'description'];

  // if type is hidden, ignore validation
  if (values.status === MATERIAL_STATUS.HIDDEN) {
    initialFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });

    if (values.materialInUse) {
      errors.status = 'This material is currently in use by a course. Status cannot be Hidden.';
    }
    return errors;
  }
  // ALL Other validation
  requiredFields = ['status', 'title', 'duration', 'discount', 'type', 'description'];
  if (values.subcontractor) {
    requiredFields = ['leadTime', ...requiredFields];
  }

  // Check required Fields
  requiredFields.forEach((field) => {
    if (!String(values[field])) { // converting to string, because discount can be '0' and it's a falsy value
      errors[field] = 'Required';
    }
  });

  // Check integer Fields
  integerFields.forEach((field) => {
    if (values[field] && !Number.isInteger(parseFloat(values[field]))) {
      errors[field] = 'Must be an Integer';
    }
  });

  // Check for type change on in-use material
  if (values.materialInUse) {
    errors.type = 'This material is currently in use by a course. Type cannot be changed.';
  }

  // Field specific Validation

  if (values.description && values.description.length > DESC_MAX_LENGTH) {
    errors.notes = `No more than ${DESC_MAX_LENGTH} characters`;
  }

  // minParticipants
  if (!values.participants?.min) {
    errors.participants = {};
    errors.participants.min = 'Required';
  } else {
    if (values.participants?.min < 0) {
      errors.participants = {};
      errors.participants.min = 'Must be 0 or greater';
    }
    if (!Number.isInteger(parseFloat(values.participants.min))) {
      errors.participants = {};
      errors.participants.min = 'Must be an integer';
    }
  }

  // maxParticipants
  if (!values.participants?.max) {
    errors.participants = {};
    errors.participants.max = 'Required';
  } else {
    if (values.participants?.max < 0) {
      errors.participants = {};
      errors.participants.max = 'Must be greater than 0';
    }
    if (parseInt(values.participants?.max, 10) <= parseInt(values.participants?.min, 10)) {
      errors.participants = {};
      errors.participants.max = 'Below Minimum Participants';
    }
    if (!Number.isInteger(parseFloat(values.participants.max))) {
      errors.participants = {};
      errors.participants.max = 'Must be an integer';
    }
  }

  // Duration
  if (values.duration < 0) {
    errors.duration = 'Must be greater than 0';
  }
  // Discount
  if (values.discount < 0 || values.discount > 100) {
    errors.discount = 'Must be between 0 and 100';
  }

  // RecertFrequency
  if (values.recertFreq < 0) {
    errors.recertFreq = 'Must be greater than 0';
  }

  // LeadTime >0
  if (values.leadTime < 0) {
    errors.leadTime = 'Must be greater than 0';
  }
  return errors;
};

const CourseSettingsForm = (props) => {
  const {
    curValues,
    certifications,
    materialCategories,
    dispatch,
    isInitial,
    nonCourseMaterials,
    courseMaterials,
    isDeleted,
  } = props;
  const [counter, setCounter] = useState(curValues?.description?.length);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [newImage, setNewImage] = useState();
  const classes = useStyles();

  React.useEffect(() => {
    setCounter(curValues?.description?.length || 0);
  }, [curValues?.description]);

  React.useEffect(() => {
    if (curValues?.type === MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS) {
      dispatch(change('CourseSettingsForm', 'warehouseOverride', true));
    }
  }, [curValues?.type]);

  // const uploadImage = async (image) => {
  //   setIsImageLoading(true);
  //   try {
  //     await dispatch(MaterialsActions.uploadImage(curValues.id, image));
  //   } catch (error) {
  //     dispatch(ModalsActions.showModal('UPLOAD_IMAGE_ERROR_MODAL', {
  //       modalType: 'ERROR_ALERT',
  //       modalProps: { message: 'Something happened while uploading the image. Please, try again!' },
  //     }));
  //   } finally {
  //     setIsImageLoading(false);
  //   }
  // };

  const uploadImage = async (event) => {
    await dispatch(change('CourseSettingsForm', 'imageObj', event.target.files[0]));
    await dispatch(change('CourseSettingsForm', 'image', URL.createObjectURL(event.target.files[0])));
  };

  // const handleImageChange = async (image) => {
  //   await dispatch(change('CourseSettingsForm', 'newImage', image));
  //   await dispatch(change('CourseSettingsForm', 'image', image));
  // };

  // Utility vars
  const isCourse = curValues?.type === MATERIAL_TYPE.COURSE;
  const isSkillCheck = curValues?.type === MATERIAL_TYPE.SKILL_CHECK;
  const isAddon = curValues?.type === MATERIAL_TYPE.ADD_ON;
  const isAdditionalParticipants = curValues?.type === MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS;
  const subcontract = curValues?.subcontractor;
  const curMaterialID = curValues?.id;

  // Control Variables for preventing in-use addons/skillchecks to be set as hidden
  const isUsingThisSkillcheck = _.find(
    courseMaterials,
    (course) => (course.skillcheckID === curMaterialID),
  );

  const isUsingThisAddon = _.find(
    courseMaterials,
    (course) => ((course?.addons?.required ?? []).concat(course?.addons?.optional ?? []).includes(curMaterialID)),
  );

  const materialInUse = Boolean(isUsingThisAddon || isUsingThisSkillcheck);

  // Handle status change
  React.useEffect(() => {
    dispatch(change('CourseSettingsForm', 'materialInUse', materialInUse));
  }, [curValues?.status]);

  // Handle material type change
  React.useEffect(() => {
    if (isUsingThisAddon && curValues?.type === MATERIAL_TYPE.ADD_ON) {
      dispatch(change('CourseSettingsForm', 'materialInUse', false));
    } else if (isUsingThisSkillcheck && curValues?.type === MATERIAL_TYPE.SKILL_CHECK) {
      dispatch(change('CourseSettingsForm', 'materialInUse', false));
    } else {
      dispatch(change('CourseSettingsForm', 'materialInUse', materialInUse));
    }
  }, [curValues?.type]);

  // Select Field Values
  const certMenuItems = _.filter(
    certifications,
    (cert) => (cert?.options === 'certification' && cert.enabled),
  );

  const recertMenuItems = _.filter(
    certifications,
    (cert) => (cert.options === 'recertification' && cert.enabled),
  );

  const familyMenuItems = _.filter(
    certifications,
    (cert) => (cert.options === 'family' && cert.enabled),
  );

  const skillCheckMenuItems = _.filter(
    nonCourseMaterials,
    (material) => material.type === MATERIAL_TYPE.SKILL_CHECK,
  );

  const additionalParticipantsMenuItems = _.filter(
    nonCourseMaterials,
    (material) => material.type === MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS,
  );

  // Autocomplete Field Values
  const addonMenuItems = _.filter(
    nonCourseMaterials,
    (material) => material.type === MATERIAL_TYPE.ADD_ON,
  );

  const initialRequiredAddons = _.map(
    curValues?.addons?.required, (value) => _.find(addonMenuItems, { id: value }),
  );

  const initialOptionalAddons = _.map(
    curValues?.addons?.optional, (value) => _.find(addonMenuItems, { id: value }),
  );

  const initialSkillCheck = _.find(nonCourseMaterials, { id: curValues?.skillcheckID });

  const initialAdditionalParticipants = _.find(nonCourseMaterials, { id: curValues?.additionalParticipants?.id });

  const categoryDropdown = () => (
    <Grid item sm={12} md={6} style={{ marginBottom: 22 }}>
      <FASFieldTitle title="Classification" />
      <Field
        variant="outlined"
        options={_.map(materialCategories, (item) => item.id)}
        getLabel={(option) => _.find(materialCategories, (item) => item.id === option)?.title}
        id="category"
        name="category"
        size="small"
        noBorderRadius
        component={MDAutoComplete}
        inputProps={{
          placeholder: 'Select Category',
        }}
        value={curValues.recertGroup}
      />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      {/* Container for shared fields */}
      {/* Shared nonImage */}
      <Grid item sm={12} md={isInitial ? 12 : 8} container spacing={3} alignItems="center" justify="flex-start">
        {/* Status/Type */}
        {!isInitial && (
          <Grid item sm={12} md={6}>
            <FASFieldTitle title="Status *" />
            <Field
              variant="outlined"
              placeholder="Select status"
              id="status"
              name="status"
              component={FASSelectField}
              displayEmpty
              defaultValue={{ label: 'Status', value: 'default' }}
              disabled={isDeleted === 'true'}
            >
              <MenuItem disabled value="">
                <span style={{ color: 'rgba(133,133,133)' }}>Select Status</span>
              </MenuItem>
              {_.map(MATERIAL_STATUS, (status) => (
                <MenuItem value={status} key={status}>{MATERIAL_STATUS_DISPLAY[status]}</MenuItem>
              ))}
            </Field>
          </Grid>
        )}
        <Grid item sm={12} md={6}>
          <FASFieldTitle title="Type *" />
          <Field id="type" variant="outlined" name="type" required component={MDRadioGroupField} row>
            <FormControlLabel value={MATERIAL_TYPE.COURSE} control={<FASRadio />} label="Course" />
            <FormControlLabel value={MATERIAL_TYPE.ADD_ON} control={<FASRadio />} label="Add-on" />
            <FormControlLabel value={MATERIAL_TYPE.SKILL_CHECK} control={<FASRadio />} label="Skill Check" />
            <FormControlLabel value={MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS} control={<FASRadio />} label="Additional Participants" />
          </Field>
        </Grid>
        {/* Title */}
        <Grid item sm={12} md={6}>
          <FASFieldTitle title="Title *" />
          <Field
            id="title"
            name="title"
            component={MDTextInputField}
            size="small"
            type="text"
            variant="outlined"
            required
            autoComplete="title"
            noBorderRadius
            placeholder="Title"
          />
        </Grid>
        {isAddon && !isInitial && (
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="0001 Warehouse" />
            <Field
              name="warehouseOverride"
              component={FASCheckboxField}
              label="Y/N"
            />
          </Grid>
        )}
        {isAddon && !isInitial && (
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Tied to participant count" />
            <Field
              name="defaultParticipantsCount"
              component={FASCheckboxField}
              label="Y/N"
            />
          </Grid>
        )}
        {(isCourse || isSkillCheck) && !isInitial && (
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Subcontractor" />
            <Field
              name="subcontractor"
              component={FASCheckboxField}
              label="Y/N"
            />
          </Grid>
        )}
        {(isCourse || isSkillCheck) && !isInitial && (
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Waive Cost" />
            <Field
              name="waiveCost"
              component={FASCheckboxField}
              label="Y/N"
            />
          </Grid>
        )}
        {(isCourse || isSkillCheck) && subcontract && (
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Lead Time *" />
            <Field
              variant="outlined"
              placeholder="Lead Time"
              id="leadTime"
              name="leadTime"
              component={MDTextInputField}
              type="number"
              min={0}
              size="small"
              noBorderRadius
              InputProps={{
                endAdornment: <InputAdornment position="end">days</InputAdornment>,
              }}
            />
          </Grid>
        )}
        {isAddon && categoryDropdown()}
        {/* description */}
        <Grid item sm={12}>
          <FASFieldTitle title="Description *" />
          <Field
            id="description"
            name="description"
            component={MDTextInputField}
            multiline
            rows={4}
            type="text"
            size="small"
            variant="outlined"
            placeholder="Please enter a description"
            required
            inputProps={{ maxLength: DESC_MAX_LENGTH }}
            helperText={`${counter}/${DESC_MAX_LENGTH}`}
            autoComplete="notes"
            noBorderRadius
          />
        </Grid>
      </Grid>

      {/* </Grid> */}
      {!isInitial && (
        <UploadImageInput
          loading={isImageLoading}
          onUpload={uploadImage}
        />
      )}
      {/* container for course-only fields */}
      {isCourse && !isInitial && (
        <Grid container item spacing={3} alignItems="center" justify="flex-start">
          <Grid item sm={12} md={12}>
            <FASTitle customStyle={{ marginBottom: 0 }} title="Course Options" />
          </Grid>
          {/* Participants */}
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Minimum Participants *" />
            <Field
              variant="outlined"
              placeholder="Minimum Participants"
              id="minParticipants"
              name="participants.min"
              component={MDTextInputField}
              type="number"
              min={0}
              size="small"
              noBorderRadius
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Maximum Participants *" />
            <Field
              variant="outlined"
              placeholder="Maximum Participants"
              id="participants.max"
              name="participants.max"
              component={MDTextInputField}
              type="number"
              min={1}
              size="small"
              noBorderRadius
            />
          </Grid>
          {/* duration - discount */}
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Duration *" />
            <Field
              id="duration"
              name="duration"
              component={MDTextInputField}
              size="small"
              type="number"
              variant="outlined"
              required
              autoComplete="duration"
              noBorderRadius
              placeholder="Duration"
              min={0}
              InputProps={{
                endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Make it a Day Discount *" />
            <Field
              id="discount"
              name="discount"
              component={MDTextInputField}
              size="small"
              type="number"
              variant="outlined"
              required
              autoComplete="discount"
              noBorderRadius
              placeholder="Discount"
              min={0}
              max={100}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6} md={3} style={{ marginBottom: 22 }}>
            <FASFieldTitle title="Certification Group" />
            <Field
              variant="outlined"
              options={_.map(certMenuItems, (item) => item.id)}
              getLabel={(option) => _.find(certMenuItems, (item) => item.id === option)?.title}
              id="certGroup"
              name="certGroup"
              size="small"
              noBorderRadius
              component={MDAutoComplete}
              inputProps={{
                placeholder: 'Select Certification Group',
              }}
              value={curValues.certGroup}
            />
          </Grid>
          {/* Recertification Group */}
          <Grid item sm={6} md={3} style={{ marginBottom: 22 }}>
            <FASFieldTitle title="Recertification Group" />
            <Field
              variant="outlined"
              options={_.map(recertMenuItems, (item) => item.id)}
              getLabel={(option) => _.find(recertMenuItems, (item) => item.id === option)?.title}
              id="recertGroup"
              name="recertGroup"
              size="small"
              noBorderRadius
              component={MDAutoComplete}
              inputProps={{
                placeholder: 'Select Recertification Group',
              }}
              value={curValues.recertGroup}
            />
          </Grid>
          {/* Recertification Frequency */}
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Recertification Frequency" />
            <Field
              variant="outlined"
              placeholder="Recertification Frequency"
              id="recertFreq"
              name="recertFreq"
              component={MDTextInputField}
              type="number"
              min={0}
              size="small"
              noBorderRadius
              InputProps={{
                endAdornment: <InputAdornment position="end">months</InputAdornment>,
              }}
            />
          </Grid>
          {/* Family */}
          <Grid item sm={6} md={3} style={{ marginBottom: 22 }}>
            <FASFieldTitle title="Family" />
            <Field
              variant="outlined"
              options={_.map(familyMenuItems, (item) => item.id)}
              getLabel={(option) => _.find(familyMenuItems, (item) => item.id === option)?.title}
              id="family"
              name="family"
              size="small"
              noBorderRadius
              component={MDAutoComplete}
              inputProps={{
                placeholder: 'Select Family',
              }}
              value={curValues.famimly}
            />
          </Grid>
          {/* Addons */}
          <Grid item sm={12} md={12} m={12}>
            <FASTitle customStyle={{ marginBottom: 0 }} title="Course Addons & Skill Checks" />
          </Grid>
          {/* Required */}
          <Grid item sm={12} md={6}>
            <FASFieldTitle title="Required Addons" />
            <Autocomplete
              multiple
              id="addons.required"
              options={_.filter(
                addonMenuItems,
                (a) => !_.includes(curValues?.addons?.optional, a.id),
              ) || []}
              getOptionLabel={(option) => `${option?.code?.toString().replace(/^[0]+/g, '')} - ${option?.title?.toString()}`}
              value={initialRequiredAddons || []}
              onChange={(event, values) => dispatch(change('CourseSettingsForm', 'addons.required', _.map(values, 'id')))}
              ChipProps={{
                size: 'small',
                color: 'primary',
              }}
              renderInput={(params) => (
                <TextField
                  classes={{
                    root: classes.root,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Required"
                  size="small"
                />
              )}
            />
          </Grid>
          {/* Optional Addons */}
          <Grid item sm={12} md={6}>
            <FASFieldTitle title="Optional Addons" />
            <Autocomplete
              multiple
              id="addons.optional"
              options={_.filter(
                addonMenuItems,
                (a) => !_.includes(curValues?.addons?.required, a.id),
              ) || []}
              getOptionLabel={(option) => `${option?.code?.toString().replace(/^[0]+/g, '')} - ${option?.title?.toString()}`}
              value={initialOptionalAddons || []}
              onChange={(event, values) => dispatch(change('CourseSettingsForm', 'addons.optional', _.map(values, 'id')))}
              ChipProps={{
                size: 'small',
                color: 'primary',
              }}
              renderInput={(params) => (
                <TextField
                  classes={{
                    root: classes.root,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Optional"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <FASFieldTitle title="Skill Check" />
            <Autocomplete
              id="skillcheckID"
              options={skillCheckMenuItems}
              value={initialSkillCheck || ''}
              getOptionLabel={(option) => (option?.code ? `${option?.code?.replace(/^[0]+/g, '')} - ${option?.title}` : '')}
              onChange={(event, value) => {
                dispatch(change('CourseSettingsForm', 'skillcheckID', value?.id ?? ''));
              }}
              renderInput={(params) => (
                <TextField
                  classes={{
                    root: classes.root,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Skill Check"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item sm={initialAdditionalParticipants ? 5 : 6}>
            <FASFieldTitle title="Additional Participants" />
            <Autocomplete
              id="additionalParticipants"
              options={additionalParticipantsMenuItems}
              value={initialAdditionalParticipants || ''}
              getOptionLabel={(option) => (option?.code ? `${option?.code?.replace(/^[0]+/g, '')} - ${option?.title}` : '')}
              onChange={(event, value) => {
                dispatch(change('CourseSettingsForm', 'additionalParticipants.id', value?.id ?? ''));
              }}
              renderInput={(params) => (
                <TextField
                  classes={{
                    root: classes.root,
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Additional Participants"
                  size="small"
                />
              )}
            />
          </Grid>
          {initialAdditionalParticipants && (
            <Grid item sm={1} style={{ height: 106 }}>
              <FASFieldTitle title="Quantity" />
              <Field
                variant="outlined"
                id="additionalParticipantsQty"
                name="additionalParticipants.qty"
                component={MDTextInputField}
                type="number"
                min={0}
                size="small"
                noBorderRadius
              />
            </Grid>
          )}
        </Grid>
      )}
      {isSkillCheck && !isInitial && (
        <Grid container item spacing={3} alignItems="center" justify="flex-start">
          <Grid item sm={12} md={12}>
            <FASTitle customStyle={{ marginBottom: 0 }} title="Skill Check Options" />
          </Grid>
          <Grid item sm={6} md={3}>
            <FASFieldTitle title="Duration *" />
            <Field
              id="duration"
              name="duration"
              component={MDTextInputField}
              size="small"
              type="number"
              variant="outlined"
              required
              autoComplete="duration"
              noBorderRadius
              placeholder="Duration"
              min={0}
              InputProps={{
                endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6} md={3} style={{ marginBottom: 22 }}>
            <FASFieldTitle title="Certification Group" />
            <Field
              variant="outlined"
              options={_.map(certMenuItems, (item) => item.id)}
              getLabel={(option) => _.find(certMenuItems, (item) => item.id === option)?.title}
              id="certGroup"
              name="certGroup"
              size="small"
              noBorderRadius
              component={MDAutoComplete}
              inputProps={{
                placeholder: 'Select Certification Group',
              }}
              value={curValues.certGroup}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default _.flow([
  connect((state) => {
    const curValues = getFormValues('CourseSettingsForm')(state);
    const certifications = state.certifications.data;
    const nonCourseMaterials = _.filter(
      materialsOfMultipleTypes({
        state,
        types: Object.values(MATERIAL_TYPE).filter((t) => t !== MATERIAL_TYPE.COURSE),
      }),
      (item) => item.status !== MATERIAL_STATUS.HIDDEN,
    );
    const courseMaterials = _.filter(
      Object.values(materialsDataSelector(state)[MATERIAL_TYPE.COURSE]),
      (item) => item.status !== MATERIAL_STATUS.HIDDEN,
    );
    return {
      curValues,
      certifications,
      nonCourseMaterials,
      courseMaterials,
      materialCategories: materialCategoriesSelector(state),
    };
  }),
  reduxForm({
    form: 'CourseSettingsForm',
    validate,
  }),
])(CourseSettingsForm);
