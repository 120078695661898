import _ from 'lodash';
import axios from 'axios';

import { getDefaultAPIBaseURL } from '../../utils/config';

export const baseInstance = axios.create({
  timeout: 60000,
  baseURL: getDefaultAPIBaseURL(),
});

export const setInstanceToken = (accessToken) => {
  baseInstance.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${accessToken}`;
      return config;
    },
  );
};

export const ejectRequestInterceptors = () => {
  _.each(
    _.keys(baseInstance.interceptors.request.handlers),
    (key) => baseInstance.interceptors.request.eject(key),
  );
};

export const ejectResponseInterceptors = () => {
  _.each(
    _.keys(baseInstance.interceptors.response.handlers),
    (key) => baseInstance.interceptors.response.eject(key),
  );
};

export const setResponseErrorInterceptor = (func) => {
  baseInstance.interceptors.response.use(
    (response) => response,
    (error) => func(error),
  );
};
