/* eslint-disable import/no-cycle */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import {
  Field, change, getFormValues, reduxForm, submit, getFormSyncErrors, arrayPush,
} from 'redux-form';
import {
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import AddIcon from '@material-ui/icons/Add';
import { orderCoursesSelector } from '../../../../../redux/selectors/orders.selectors';
import FASSelectField from '../../../../Forms/CustomFormComponents/FASSelectField';
import StyledButton from '../../../../Button/StyledButton';
import * as ModalsActions from '../../../../../redux/actions/modals.actions';
import * as EventActions from '../../../../../redux/actions/events.actions';
import {
  MATERIAL_TYPE, TIME_FORMAT, COLORS, DATE_PICKER_FOMAT, NOTES_DISPLAY_BORDER,
} from '../../../../../utils/consts';
import CustomerRoster from './CustomerRoster';
import FileImportDialog from '../../../../Modals/FileImportDialog';
import PartecipantRow from './ParticipantRow';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import DownloadParticipantsBtn from './DownloadParticipantsBtn';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import Container from '../../../../LayoutBuilders/Container';
import { partecipantsFieldNames } from '../EventDetailsDialog';
import DeleteConfirmAlert from '../../EventPopper/DeleteConfirmAlert';
import DownloadRosterPDFButton from '../../../../Button/DownloadRosterPDFButton';
import { timezoneSelector } from '../../../../../redux/selectors/utils.selectors';
import {
  buildCourseUniqueId, calcMaxAdditionalParticipants, calcMaxParticipants, getHigherAccountNumber, resetDeliverySignature,
} from '../../../../../utils/event.utils';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../../../../utils/consts/nationalAccount.consts';
import NationalAccountCourseDisplayHook from '../../../../NationalAccountDashboard/NationalAccountCourseDisplay';

const spinnerSize = 15;
const formName = 'EventDetailsDialog';

const layouts = {
  completedMultiCourse: 'completedMultiCourse',
  completedSingleCourse: 'completedSingleCourse',
  incompleteMultiCourse: 'incompleteMultiCourse',
  incompleteSingleCourse: 'incompleteSingleCourse',
  deleteMode: 'deleteMode',
};

const isParticipantEmpty = (p) => (
  !p[partecipantsFieldNames.firstName]
  && !p[partecipantsFieldNames.lastName]
  && !p[partecipantsFieldNames.email]
  && !p[partecipantsFieldNames.employeeId]
  && !p[partecipantsFieldNames.location]
);

const runValidation = (p, rosterConfig) => {
  if (!p) return undefined;
  const isEmpty = isParticipantEmpty(p);
  if (isEmpty) {
    return undefined;
  }
  const pErrors = {};
  if (!(p[partecipantsFieldNames.firstName] ?? '').trim()) {
    pErrors[partecipantsFieldNames.firstName] = 'Required';
  }
  if (!(p[partecipantsFieldNames.lastName] ?? '').trim()) {
    pErrors[partecipantsFieldNames.lastName] = 'Required';
  }
  if (rosterConfig?.emailRequired && !(p[partecipantsFieldNames.email] ?? '').trim()) {
    pErrors[partecipantsFieldNames.email] = 'Required';
  }
  if (rosterConfig?.employeeIdRequired && !(p[partecipantsFieldNames.employeeId] ?? '').trim()) {
    pErrors[partecipantsFieldNames.employeeId] = 'Required';
  }
  if (rosterConfig?.locRequired && !(p[partecipantsFieldNames.location] ?? '').trim()) {
    pErrors[partecipantsFieldNames.location] = 'Required';
  }

  return pErrors;
};

const validateParticipants = (values) => {
  const errors = { partecipants: {} };
  const { partecipants, billingSummary } = values;
  const courseKeys = Object.keys(partecipants ?? {});

  if (!courseKeys || !courseKeys.length) return errors;
  courseKeys.forEach((cKey) => {
    const ps = partecipants[cKey];
    errors.partecipants[cKey] = [];
    (ps ?? []).forEach((p) => errors.partecipants[cKey].push(runValidation(p, billingSummary?.nationalAccount?.rosterConfig)));
  });

  return errors;
};

export const getNumRows = (c) => (c
  ? Math.max(
    // max between the default number of rows and the actual entered number of participants
    Number(calcMaxParticipants(c.event?.courseObj, !_.isEmpty(c.event?.secondaryTci ?? ''), 0)) + Number(calcMaxAdditionalParticipants(c.event?.courseObj, !_.isEmpty(c.event?.secondaryTci ?? ''), 0)),
    Number((c.event?.eventParticipants ?? []).length),
  )
  : 0);

const PartecipantsTab = ({
  isFullyEditable,
  isComplete,
  orderId,
  order,
  values,
  dispatch,
  reloadEvent,
  onRosterUpdated,
  fieldNames,
  backendFieldNames,
  isValid,
  errors,
  tzOverride,
}) => {
  /*
           * @params courses: [Object]
           * @params currentCourse: Object
           * @params partecipants: [Object] list of only the inputed partecipants
           * @params values: Object with current values of redux form. Object keys are the fieldNames
          */
  const { events, nationalAccount } = order;
  const rosterConfig = nationalAccount?.rosterConfig;
  const courses = [
    ...useSelector((state) => orderCoursesSelector(state, orderId, events, true)),
  ];
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(courses && courses.length > 0 ? courses[0] : null);
  const [shouldActivateSave, setActivateSave] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [showDropdown, setShowDropdown] = useState(currentCourse && courses && courses.length > 1);
  const selectedTimezone = useSelector((state) => tzOverride?.value ?? timezoneSelector(state));
  const isInThePast = moment.utc(events?.[0]?.startTime).isBefore(moment.utc());
  const [partecipants, setParticipants] = useState([]);
  const [layout, setLayout] = useState(null);
  const [selecteds, setSelecteds] = useState([]);

  const isSkillcheck = currentCourse?.type === MATERIAL_TYPE.SKILL_CHECK;
  // console.log('TEST - currentCourse', currentCourse);

  const [numRows, setNumRows] = useState(getNumRows(currentCourse, isSkillcheck));
  // console.log('TEST - numRows', numRows);
  // const deleteMode = partecipants && partecipants.filter((p) => Boolean(p[fieldNames.isSelected])).length > 0;
  const natAccountRosterNotesState = NationalAccountCourseDisplayHook({ noteType: NATIONAL_ACCOUNT_NOTE_TYPE.ROSTER, account: getHigherAccountNumber(order?.customerObj) });

  const getLayout = () => {
    if (!isComplete && deleteMode) {
      return layouts.deleteMode;
    } if (isComplete && showDropdown) {
      return layouts.completedMultiCourse;
    } if (isComplete && !showDropdown) {
      return layouts.completedSingleCourse;
    } if (!isComplete && showDropdown) {
      return layouts.incompleteMultiCourse;
    }
    return layouts.incompleteSingleCourse;
  };

  useEffect(() => {
    setShowDropdown(currentCourse && courses && courses.length > 1);
  }, [currentCourse, courses]);

  useEffect(() => {
    setDeleteMode(!(selecteds.length === 0));
  }, [selecteds]);

  useEffect(() => {
    setLayout(getLayout());
  }, [deleteMode, isComplete, showDropdown]);

  const getPartecipants = (course) => {
    const alreadyHasPartecipants = Boolean(course && course.event.eventParticipants && course.event.eventParticipants.length > 0);
    const alreadyLoadedPartecipants = values.partecipants && values.partecipants[buildCourseUniqueId(course)];
    return alreadyLoadedPartecipants
      ? values.partecipants[buildCourseUniqueId(course)]
      : alreadyHasPartecipants
        ? (course.event.eventParticipants ?? []).sort((a, b) => a.index - b.index).map(
          (p) => Object.fromEntries(
            Object.keys(p).map(
              (k) => [fieldNames[backendFieldNames[k]], p[k]],
            ),
          ),
        )
        : [];
  };
  // Initial UseEffect
  useEffect(() => {
    // setup the fields initial values in redux form
    dispatch(change(formName, fieldNames.course, buildCourseUniqueId(currentCourse)));
    // dispatch(change(formName, `${fieldNames.partecipants}.${buildCourseUniqueId(currentCourse)}`, partecipants));
    return () => { };
  }, []);

  // UseEffect on values.course
  useEffect(() => {
    if (values) {
      const newCourse = courses.find((c) => buildCourseUniqueId(c) === values.course);
      setCurrentCourse(newCourse);
      setParticipants(getPartecipants(newCourse));
      setNumRows(getNumRows(newCourse, isSkillcheck));
    }
    return () => { };
  }, [values?.course]);

  // UseEffect on events
  useEffect(() => { }, [events]);

  const formatTime = (time) => moment.utc(time).tz(selectedTimezone).format(TIME_FORMAT).toLocaleUpperCase() ?? '';

  const saveImportedPartecipants = (data) => {
    const initialIdx = partecipants && partecipants.length ? partecipants.findIndex((p) => !runValidation(p, rosterConfig) || !_.isEmpty(runValidation(p, rosterConfig))) : 0;
    if (initialIdx === -1) return;
    const filledParticipants = (partecipants ?? []).slice(0, initialIdx);
    const pWithIds = data.map((p, i) => ({ index: initialIdx + i, [fieldNames.isPresent]: true, ...(p ?? {}) }));
    const newParticipants = [...(filledParticipants ?? []), ...(pWithIds ?? [])];

    while (newParticipants.length < numRows) {
      newParticipants.push({
        [fieldNames.index]: newParticipants.length,
        [partecipantsFieldNames.isBeingEdited]: !isFullyEditable && !isComplete,
      });
    }

    dispatch(change(
      formName,
      `${fieldNames.partecipants}.${buildCourseUniqueId(currentCourse)}`,
      [...newParticipants],
    ));

    resetDeliverySignature(formName, dispatch); // reset signature after participants change

    if (isFullyEditable) {
      setActivateSave(true);
    } else {
      const updatedValues = {
        ...values,
        partecipants: {
          ...(values.partecipants ?? []),
          [buildCourseUniqueId(currentCourse)]: [...newParticipants],
        },
      };

      handleSave({ updated: updatedValues });
    }
  };

  const handleSave = async ({ updated }) => {
    setSaving(true);
    try {
      await dispatch(submit(formName));
      if (!isValid) {
        throw Error();
      }
      const allPartecipants = ((updated ?? values).partecipants[buildCourseUniqueId(currentCourse)] ?? []).filter((p) => Boolean(p) && (runValidation(p) !== undefined) && _.isEmpty(runValidation(p, rosterConfig)));
      const toSave = [];
      for (let i = 0; i < allPartecipants.length; i++) {
        const p = allPartecipants[i];
        toSave.push(({ ...(p ?? {}), [fieldNames.index]: i, [fieldNames.isPresent]: true }));
      }
      await dispatch(EventActions.savePartecipants(currentCourse.event.id, toSave));
      resetDeliverySignature(formName, dispatch); // reset signature after participants change
      if (!isFullyEditable) {
        const currPsValues = ((updated ?? values).partecipants[buildCourseUniqueId(currentCourse)] ?? []);
        for (let x = 0; x < currPsValues.length; x++) {
          const pVal = currPsValues[x];
          if (_.isEmpty(runValidation(pVal, rosterConfig))) {
            dispatch(change(formName, `${fieldNames.partecipants}.${buildCourseUniqueId(currentCourse)}.${x}.${partecipantsFieldNames.isBeingEdited}`, false));
          }
        }
      }
      setParticipants(allPartecipants);
      reloadEvent(currentCourse.event.id, !isFullyEditable);
      activateSave(false);
    } catch (e) {

    } finally {
      setSaving(false);
    }
  };

  const handleAddRow = () => {
    dispatch(arrayPush(formName, `partecipants[${buildCourseUniqueId(currentCourse)}]`, {
      [fieldNames.index]: numRows.length,
      [partecipantsFieldNames.isBeingEdited]: true,
    }));
    setNumRows((numRows + 1));
  };

  const activateSave = (onOff) => {
    if (onOff !== shouldActivateSave) { setActivateSave(onOff); }
  };

  const handleImport = () => {
    const modalName = 'IMPORT_FILE_DIALOG';
    const initialIdx = partecipants && partecipants.length ? partecipants.findIndex((p) => !runValidation(p) || !_.isEmpty(runValidation(p, rosterConfig))) : 0;
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_EVENT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        maxWidth: 'lg',
        title: 'IMPORT CSV',
        content: <FileImportDialog
          dispatch={dispatch}
          modalName={modalName}
          onData={saveImportedPartecipants}
          message="Click here (or drag and drop) to upload a file that matches the template structure"
          maxRecords={initialIdx === -1 ? numRows : (numRows - initialIdx)}
          requiredHeaders={[fieldNames.firstName, fieldNames.lastName, fieldNames.email, fieldNames.employeeId]}
          templateSampleEntries={
            [{
              [fieldNames.firstName]: 'John',
              [fieldNames.lastName]: 'Smith',
              [fieldNames.employeeId]: '123456',
              [fieldNames.email]: 'john.smith@sample.com',
            }]
          }
        />,
      },
    }));
  };

  const handleMassDelete = async () => {
    const deleteParticipants = async () => {
      try {
        setDeleting(true);
        const toKeep = (values.partecipants[buildCourseUniqueId(currentCourse)] ?? []).filter((p) => !p[fieldNames.isSelected]);
        const updatedValues = {
          ...values,
          partecipants: {
            ...(values.partecipants ?? []),
            [buildCourseUniqueId(currentCourse)]: toKeep,
          },
        };
        await handleSave({ updated: updatedValues });
        dispatch(change(formName, `partecipants.${buildCourseUniqueId(currentCourse)}`, toKeep));
      } finally {
        resetDeliverySignature(formName, dispatch); // reset signature after participants change
        setDeleting(false);
      }
    };

    const modalName = 'DELETE_PARTI_CONF';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        confirmText: 'confirm',
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'DELETE PARTICIPANTS',
        content: <DeleteConfirmAlert itemTypeName="participant" />,
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(modalName));
          deleteParticipants();
        },
      },
    }));
  };

  const exitDeleteMode = () => {
    setSelecteds([]);
    const ps = (values.partecipants[buildCourseUniqueId(currentCourse)] ?? []);
    ps.forEach((p, i) => dispatch(change(formName, `partecipants.${buildCourseUniqueId(currentCourse)}[${i}]`,
      {
        ...(p ?? {}),
        [fieldNames.isSelected]: false,
      })));
  };

  // Components Builders:

  const row = ({ children, key, ...rest }) => (
    <Container key={key} {...(rest)}>
      {children}
    </Container>
  );

  const rowCell = ({
    child, flex, key, ...rest
  }) => (
    <ContainerItem key={key} flex={flex} {...(rest ?? {})}>
      {child}
    </ContainerItem>
  );

  const participantsNotes = () => (
    <div style={{ border: NOTES_DISPLAY_BORDER, padding: '0 8px 8px 8px', marginBottom: 10 }}>
      <FASFieldTitle title={natAccountRosterNotesState.label} style={{ fontWeight: 'bold', textAlign: 'left' }} />
      {natAccountRosterNotesState.noteComponent()}
    </div>
  );

  const headerRow = () => row({
    style: { justifyContent: showDropdown ? 'space-between' : 'flex-end' },
    spacing: 1,
    children: [
      showDropdown && layout !== layouts.completedSingleCourse ? rowCell({ key: 0, flex: 6, child: courseDropdown() }) : <></>,
      rowCell({
        key: 1,
        child: actions(),
        flex: 6,
      }),
      rowCell({
        key: 2, flex: 12, style: { marginLeft: 12 }, child: natAccountRosterNotesState.hasNotes ? participantsNotes() : <></>,
      }),
    ],
  });

  const courseDropdown = () => {
    const disable = (shouldActivateSave && !isComplete);
    const dropdown = () => (
      <Field
        variant="outlined"
        placeholder="Select Course"
        id={fieldNames.course}
        name={fieldNames.course}
        component={FASSelectField}
        displayEmpty
        disabled={disable}
        style={{ textAlign: 'start', borderRadius: 0, width: isComplete ? '100%' : '70%' }}
      >
        {menuItem()}
        {courses.map((e) => menuItem(buildCourseUniqueId(e), e.title, e.event.startTime))}
      </Field>
    );

    return disable ? (
      <Tooltip
        placement="top-start"
        title="save changes to enable"
      >
        {dropdown()}
      </Tooltip>
    ) : dropdown();
  };

  const menuItem = (courseId, courseName, startTime) => (courseId && courseName
    ? (
      <MenuItem key={courseId} value={courseId}>
        {courseName} - {formatTime(startTime)}
      </MenuItem>
    )
    : (
      <MenuItem disabled value="na" key="suheader">
        <span style={{ color: 'rgba(133,133,133)' }}>Select Course</span>
      </MenuItem>
    ));

  const spinner = () => (
    <Spinner
      spinnerStyle={{ height: spinnerSize, width: spinnerSize, color: 'white' }}
      customStyle={{
        maxHeight: spinnerSize,
        maxWidth: spinnerSize,
        marginTop: 5,
        marginBottom: 5,
      }}
    />
  );

  const downloadRosterPDFButton = useMemo(() => (
    <DownloadRosterPDFButton size={33} preloaded={order} />
  ), [order]);

  const downloadParticipantsCsvBtn = () => {
    const courseDate = moment.utc(currentCourse?.event?.startTime).tz(selectedTimezone);
    return (
      <DownloadParticipantsBtn
        participants={partecipants.map((p) => ({
          ...(p ?? {}),
          [fieldNames.isPresent]: isParticipantEmpty(p) ? '' : (p[fieldNames.isPresent] ? 'Yes' : 'No'),
          date: isParticipantEmpty(p) ? '' : courseDate.format(DATE_PICKER_FOMAT),
        }))}
        fileName={`${(currentCourse.title ?? '').toLowerCase().replaceAll(' ', '_')}_participants`}
        headers={[
          'date',
          fieldNames.firstName,
          fieldNames.lastName,
          fieldNames.email,
          fieldNames.employeeId,
          ...(!isFullyEditable ? [fieldNames.isPresent] : []),
        ]}
      />
    );
  };

  const clearSelectionCell = () => rowCell({
    key: 0,
    flex: 5,
    style: { paddingRight: 10 },
    child: buildBtn('Clear selection', exitDeleteMode, undefined, 'text'),
  });

  const deleteBtnCell = () => rowCell({ key: 1, flex: 4, child: buildBtn(deleting ? spinner() : 'DELETE', handleMassDelete, COLORS.CINTAS_RED) });

  const downloadRosterBtnCell = ({ marginRight, hide }) => rowCell({
    key: 0,
    flex: 1,
    style: { textAlign: 'end', marginRight },
    child: hide ? <></> : downloadRosterPDFButton,
  });

  const downloadRosterCsvBtnCell = () => rowCell({
    key: 1,
    flex: 1,
    style: { textAlign: 'end', marginRight: isFullyEditable ? 0 : 20 },
    child: downloadParticipantsCsvBtn(),
  });

  const importBtnCell = () => rowCell({
    key: 2, style: { marginRight: 10 }, flex: 4, child: buildBtn('IMPORT', handleImport),
  });

  const saveBtnCell = () => rowCell({ key: 3, flex: 4, child: buildBtn(saving ? spinner() : 'SAVE', shouldActivateSave ? handleSave : null) });

  const deleteModeCells = () => [
    clearSelectionCell(),
    deleteBtnCell(),
  ];

  const completedMultiCourseActionCells = () => [
    downloadRosterBtnCell({ marginRight: 20 }),
    downloadRosterCsvBtnCell(),
  ];

  const completedSingleCourseActionCells = () => [
    downloadRosterBtnCell({ marginRight: isFullyEditable ? 0 : 20 }),
    downloadRosterCsvBtnCell(),
  ];

  const incompleteCourseActionCells = () => [
    downloadRosterBtnCell({ marginRight: isFullyEditable ? 0 : 20, hide: !isInThePast }),
    downloadRosterCsvBtnCell(),
    importBtnCell(),
    saveBtnCell(),
  ];

  const getActions = () => {
    switch (layout) {
      case layouts.completedMultiCourse:
        return completedMultiCourseActionCells();
      case layouts.completedSingleCourse:
        return completedSingleCourseActionCells();
      case layouts.incompleteMultiCourse:
        return incompleteCourseActionCells();
      case layouts.incompleteSingleCourse:
        return incompleteCourseActionCells();
      case layouts.deleteMode:
        return deleteModeCells();
      default:
        return incompleteCourseActionCells();
    }
  };

  const actions = () => row({
    style: {
      justifyContent: (isFullyEditable && !deleteMode) && (layout !== layouts.completedMultiCourse) ? 'space-around' : ('flex-end'),
      alignItems: 'center',
    },
    children: getActions(),
    // (deleteMode && !isComplete) ? deleteModeCells() : [
    //   rowCell({
    //     key: 0,
    //     flex: 1,
    //     style: { textAlign: 'end', marginRight: (isFullyEditable && !(isComplete && showDropdown)) ? 0 : 20 },
    //     child: (isComplete || isInThePast) ? downloadRosterPDFButton : <></>,
    //   }),
    //   rowCell({
    //     key: 1,
    //     flex: 1,
    //     style: { textAlign: 'end', marginRight: isFullyEditable ? 0 : 20 },
    //     child: downloadParticipantsCsvBtn(),
    //   }),
    //   !isComplete && rowCell({
    //     key: 2, style: { marginRight: 10 }, flex: 4, child: buildBtn('IMPORT', handleImport),
    //   }),
    //   !isComplete && rowCell({ key: 3, flex: 4, child: buildBtn(saving ? spinner() : 'SAVE', shouldActivateSave ? handleSave : null) }),
    // ],
  });

  const buildBtn = (text, onClick, color, variant) => (
    <StyledButton
      disabled={!onClick}
      onClick={onClick}
      variant={variant ?? 'contained'}
      color="primary"
      buttonContent={text}
      style={{ width: '100%', backgroundColor: color }}
    />
  );

  const footerActions = () => {
    const footerHeight = 38;
    return row({
      style: { justifyContent: 'space-between', marginTop: 14 },
      spacing: 1,
      children: [
        !isSkillcheck && rowCell({
          key: 0,
          flex: 10,
          child: <CustomerRoster
            canEdit={!isComplete}
            maxHeight={footerHeight}
            event={currentCourse ? currentCourse.event : undefined}
            onRosterUploaded={onRosterUpdated}
            dispatch={dispatch}
          />,
        }),
        !isComplete && currentCourse?.type !== MATERIAL_TYPE.SKILL_CHECK && rowCell({
          key: 1, flex: 2, child: addRowBtn({ size: footerHeight }), style: { padding: 0 },
        }),
        (layout === layouts.completedSingleCourse) && rowCell({
          key: 2,
          flex: 'auto',
          style: { marginRight: 20 },
          child: downloadParticipantsCsvBtn(),
        }),
      ],
    });
  };

  const addRowBtn = ({ size }) => (
    <StyledButton
      onClick={handleAddRow}
      variant="contained"
      color="primary"
      style={{ width: size, height: size }}
      buttonContent={<AddIcon />}
    />
  );
  return (courses.length === 0 || !currentCourse) ? <></> : (
    <div style={{
      marginTop: 16, width: '98%',
    }}
    >
      {headerRow()}
      {(Array(numRows).fill(0, 0)).map((__, i) => (
        <PartecipantRow
          i={i}
          key={`${i}`}
          values={{ ...values }}
          isComplete={isComplete}
          isFullyEditable={isFullyEditable}
          fieldNames={fieldNames}
          formName={formName}
          errors={errors}
          repostParticipants={handleSave}
          onChange={!shouldActivateSave ? () => activateSave(true) : null}
          dispatch={dispatch}
          eventId={currentCourse?.event.id}
          courseId={buildCourseUniqueId(currentCourse)}
          rosterConfig={order?.nationalAccount?.rosterConfig}
          onSelected={(selected, b, c, name) => {
            if (selected) {
              setSelecteds((previous) => [...previous, name]);
            } else {
              setSelecteds((previous) => previous.filter((el) => el !== name));
            }
          }}
        />
      ))}
      {footerActions()}
    </div>
  );
};

export default _.flow([
  connect((state) => {
    const errors = (getFormSyncErrors(formName)(state) ?? {}).partecipants ?? {};
    const pErrors = Object.values(errors).reduce((r, eList) => [...r, ...(eList ?? [])], []);
    const allErrors = pErrors.map((pError) => Object.values(pError ?? {})).reduce((r, eList) => [...r, ...(eList ?? [])], []);
    return ({
      errors,
      isValid: _.isEmpty(allErrors),
      values: getFormValues(formName)(state),
    });
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    validate: validateParticipants,
  }),
])(PartecipantsTab);
