/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';

import * as LocationsActions from '../../../redux/actions/locations.actions';
import LocationsTable from '../../../components/Settings/Locations/LocationsTable';
import SettingsFrame from './SettingsFrame';

class LocationsView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(LocationsActions.fetchLocations());
  }

  render() {
    const { locations: { data: locationsData }, match: { path } } = this.props;
    return (
      <SettingsFrame
        path={path}
      >
        <LocationsTable locations={locationsData} />
      </SettingsFrame>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.locations,
});

export default _.flow([
  connect(mapStateToProps),
])(LocationsView);
