import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { COLORS } from '../../../utils/consts';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#000000',
    margin: (props) => (props.narrowMargin ? '5px 0px' : '10px 0px'),
  },
  optional: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'italic',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '22px',
    color: 'grey',
    margin: '20px 0px',
  },

  required: {
    // fontFamily: 'proxima-nova, sans-serif',
    // fontStyle: 'normal',
    // fontWeight: '500',
    // fontSize: '18px',
    // lineHeight: '22px',
    color: COLORS.CINTAS_RED,
    // margin: '20px 0px',
  },
}));

const FASFieldTitle = (props) => {
  const {
    title, required, optional, narrowMargin, ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <Typography className={classes.title} variant="h6" component="h2" {...rest}>
      {title}
      {' '}
      {optional && <span className={classes.optional}> (optional) </span>}
      {required && (
        <span className={classes.required}>
          {' '}
          *
        </span>
      )}
    </Typography>
  );
};

export default FASFieldTitle;
