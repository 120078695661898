import Immutable from 'seamless-immutable';

import {
  SAVE_MATERIALS,
  UPDATE_MATERIAL_IMAGE_LINK,
} from '../actions/actionTypes/materials';

const initialState = Immutable({
  data: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MATERIALS:
      return state.set('data', action.materials);
    case UPDATE_MATERIAL_IMAGE_LINK:
      return state.setIn(
        ['data', action.payload.type, action.payload.material],
        {
          ...(state.data[action.payload.type][action.payload.material] ?? {}),
          image: action.payload.link,
        },
      );
    default:
      return state;
  }
};
