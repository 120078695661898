/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getCommissionLevels = () => (
  baseInstance.get('/commissionlevels')
);

export const createCommissionLevels = (payload) => (
  baseInstance.post('/commissionlevels', payload)
);
