/* eslint-disable no-use-before-define */
import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import ExpansionTile from '../LayoutBuilders/ExpansionTile';

const CertTile = ({ entry }) => {
  const [expanded, setExpanded] = React.useState(false);
  const shouldAllowExpansion = false;

  const text = (txt) => (
    <Typography
      align="center"
      style={{
        fontFamily: 'proxima-nova',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
      }}
    >
      {txt}
    </Typography>
  );

  const labelColumn = (top) => (
    <>
      {text(_.startCase(top), { fontSize: 14, fontWeight: 'bold' })}
    </>
  );

  const buildEntry = () => <Box style={{ padding: '0px 12px', width: '100%' }}>{labelColumn(entry)}</Box>;

  return (
    <ExpansionTile
      header={buildEntry()}
      headerFlex="100%"
      noExpanding={!shouldAllowExpansion}
      style={{
        padding: 0, margin: 0, textAlign: 'start', wordWrap: 'break-word', wordBreak: 'break-all',
      }}
      onExpansionChange={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      <></>
    </ExpansionTile>
  );
};

export default CertTile;
