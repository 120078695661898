/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, initialize, reduxForm } from 'redux-form';
import MDAutoComplete from '../../../../components/Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import Card from '../../../../components/LayoutBuilders/Card';
import Container from '../../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../../components/LayoutBuilders/ContainerItem';
import SimpleText from '../../../../components/Text/SimpleText';
import { updateOrgRelationsSettings } from '../../../../redux/actions/settings.actions';
import { orgRolesRelationsSelector } from '../../../../redux/selectors/settings.selectors';
import { ORG_STRUCTURE_ROLES } from '../../../../utils/consts';
import CollapsableHeader from './CollapsableHeader';

const formName = 'OrgRolesRelationsForm';

const OrgRolesRelations = ({ initialState }) => {
  const dispatch = useDispatch();
  const opts = Object.keys(ORG_STRUCTURE_ROLES).sort();

  React.useEffect(() => {
    dispatch(initialize(formName, initialState));
  }, []);

  const onChangeMapping = async (role, val) => {
    dispatch(updateOrgRelationsSettings({ [role]: (val ?? '') }));
  };

  const card = ({ children, style, title }) => (
    <Card style={style} title={title}>{children}</Card>
  );

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => <SimpleText txt={txt} style={{ fontSize: rest?.fontSize }} {...(rest ?? {})} />;

  const boldText = (txt) => text(txt, {
    style: {
      fontWeight: 'bold',
      fontSize: 16,
    },
  });

  const header = () => container({
    style: { justifyContent: 'flex-start' },
    spacing: 1,
    children: [
      item({ flex: 1, children: [boldText('Role')] }),
      item({ flex: 2, children: [boldText('Manager Role')] }),
    ],
  });

  const dropdown = (settingKey) => {
    const fieldId = `${settingKey}`;
    return (
      <Field
        variant="outlined"
        name={fieldId}
        component={MDAutoComplete}
        displayEmpty
        options={opts}
        style={{ textAlign: 'start', borderRadius: 0 }}
        noErrorLabel
        onChange={(val) => onChangeMapping(settingKey, val)}
        inputProps={{
          style: { margin: 0, paddingTop: 0, height: 40 },
          size: 'small',
        }}
      />
    );
  };

  const table = () => card({
    style: { fontSize: 10 },
    children: [
      container({
        spacing: 1,
        children: [
          item({ flex: 12, children: [header()] }),
          ...opts.map((role) => item({
            flex: 12,
            children: container({
              style: { alignItems: 'center', minHeight: 40 },
              children: [
                item({ flex: 1, children: [text(ORG_STRUCTURE_ROLES[role], { fontSize: 16 })] }),
                item({
                  flex: 2,
                  children: [
                    (role !== ORG_STRUCTURE_ROLES.ADMIN) ? dropdown(role) : <p style={{ fontSize: 14 }}>N/A</p>,
                  ],
                }),
              ],
            }),
          })),
        ],
      }),
    ],
  });

  return (
    <CollapsableHeader title="ORG ROLES RELATIONS" actions={[]} boundActionToContent content={table()} />
  );
};

export default _.flow([
  connect((state) => ({
    initialState: orgRolesRelationsSelector(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(OrgRolesRelations);
