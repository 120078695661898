import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  reduxForm,
} from 'redux-form';

const PersonalCelendarDialogContent = withStyles((theme) => ({
  root: {
    maxWidth: 500,
    minHeight: 100,
    zIndex: 500,
    [theme.breakpoints.down('sm')]: {
      minWidth: 500,
      maxWidth: 500,
    },
  },
}))(MuiDialogContent);

const PersonalCelendarDialogWrapper = ({ children }) => (
  <PersonalCelendarDialogContent dividers>
    {children}
  </PersonalCelendarDialogContent>
);

export default _.flow([
  connect(),
  reduxForm({
    form: 'PersonalCelendarDialog',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {},
  }),
])(PersonalCelendarDialogWrapper);
