/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { Children } from 'react';
import { connect, useSelector } from 'react-redux';
import { Views } from 'react-big-calendar';
import { getFormValues } from 'redux-form';
import { Container, Typography } from '@material-ui/core';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { cancelledEventsDayCountSelector, dayTotalRevenueSelector } from '../../redux/selectors/events.selectors';
import { copyLocalDateToTimezone, isHoliday, whichHoliday } from '../../utils/dateUtils';

import { BlockIcon } from '../SVGIcons/SVGIcons';
import CancelledEventsHeader from './CancelledEventsHeader';
import { CALENDAR_TYPE, COLORS } from '../../utils/consts';
import { calendarTypeSelector } from '../../redux/selectors/calendar.selectors';
import { ccyFormat } from '../../utils/helpers';
import { holidaysSelector, showRevenueSelector } from '../../redux/selectors/settings.selectors';
import ContainerItem from '../LayoutBuilders/ContainerItem';

const cancelledOrdersHeaderViews = [Views.DAY, Views.WEEK];
const holidayLabelHeaderViews = [Views.DAY];

// ColoredDateCellWrapper controls the style of each date cell in week view
const CalendarCellWrapper = ({
  children, value, tz,
  formName,
  calendarType,
  showRevenue,
}) => {
  const calendarView = useSelector((state) => getFormValues(formName)(state).view ?? Views.WEEK);
  const holidays = useSelector(holidaysSelector);
  const dateString = value && copyLocalDateToTimezone(value, tz).startOf('day').format('YYYY-MM-DD');
  const cancelledEventsCount = useSelector((state) => cancelledEventsDayCountSelector(state, dateString));
  const showCancelledEvents = cancelledEventsCount?.cancelledEvents && cancelledOrdersHeaderViews.includes(calendarView) && calendarType === CALENDAR_TYPE.tci;
  const revenue = useSelector((state) => {
    if (!showRevenue) return 0;
    return dayTotalRevenueSelector(state, dateString, tz) ?? 0;
  });

  const showBlockedDayIcon = Boolean((value?.getDay() === 0) || isHoliday(value, holidays));

  const style = (chldr) => ({
    ...chldr.style,
    backgroundColor: showBlockedDayIcon ? '#F4F4F4' : 'white',
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '10px',
    display: 'flex',
    cursor: 'crosshair',
    justifyContent: 'space-between',
  });

  const container = ({ child, ...rest }) => <Container {...(rest ?? {})}>{child}</Container>;
  const item = ({ child, ...rest }) => <ContainerItem {...(rest ?? {})}>{child}</ContainerItem>;

  const footer = () => (
    <>
      <div style={{
        bottom: '2px',
        left: '5px',
        width: '100%',
        // display: 'inline-block',
        marginBottom: 0,
        position: 'absolute',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
      }}
      >
        {(showRevenue && revenue > 0 && cancelledOrdersHeaderViews.includes(calendarView)) ? <Typography style={{ color: COLORS.CINTAS_MEDIUM_GRAY, fontSize: 10 }}>{ccyFormat(revenue)}</Typography> : <></>}
        {showCancelledEvents ? <CancelledEventsHeader withLineBreak={calendarView !== Views.DAY} cancelledEventsInfo={cancelledEventsCount} formName={formName} /> : undefined}
      </div>
    </>
  );

  const holidayLabel = () => (<>{whichHoliday(value, holidays)}</>);

  const cellStyle = React.cloneElement(
    Children.only(children),
    { style: style(children) },
    <div style={{ marginTop: '5px', paddingLeft: '5px', marginRight: '5px', marginBottom: 0, position: 'relative', width: '100%' }}>
      {container({
        style: { margin: 0, padding: 0, display: 'flex', alignItems: 'start', justifyContent: 'flex-start', width: '100%' },
        spacing: 0,
        child: [
          item({ flex: 'auto', style: { margin: 0, padding: 0 }, child: showBlockedDayIcon && <BlockIcon /> }),
          item({ flex: 10, style: { marginLeft: 6, color: COLORS.CINTAS_RED, fontWeight: 'bold' }, child: (isHoliday(value, holidays) && holidayLabelHeaderViews.includes(calendarView)) && holidayLabel() }),
        ],
      })}
      {/* {value.getDay() === 6 && <UnblockIcon />} */}
      {footer()}
    </div>,
  );

  return cellStyle;
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
    calendarType: calendarTypeSelector(state),
    showRevenue: calendarTypeSelector(state) === CALENDAR_TYPE.location ? false : showRevenueSelector(state),
  })),
])(CalendarCellWrapper);
