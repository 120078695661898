/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { IconButton } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import XIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import StyledButton from '../../../../components/Button/StyledButton';
import FASCheckboxField from '../../../../components/Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import MDAutoComplete from '../../../../components/Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import Container from '../../../../components/LayoutBuilders/Container';
import FASMUIDataTable from '../../../../components/Settings/CustomMUIDataTable/FASMUIDataTable';
import {
  COLORS, ORG_STRUCTURE_ROLES, ROLE_ACCESSES, ROLE_ACCESSES_LABELS,
} from '../../../../utils/consts';
import Spinner from '../../../../components/SpinnerOverlay/Spinner';
import MDTextInputField from '../../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import CollapsableHeader from './CollapsableHeader';

const MatrixTable = ({
  titleText,
  matrix,
  onDeleteRole,
  formName, loading, saveChanges,
  noNewRole, noDelete, noRoleInput,
  excludeAppRole,
  uneditableRoles = [],
  uneditableRoleAccesses = {},
  columnsOverride,
  roles = ROLE_ACCESSES,
  formatRoleLabel,
}) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const defaultUserRoleMatrix = Object.keys(ROLE_ACCESSES_LABELS).reduce((red, col) => ({
    ...red,
    [col]: false,
    appRole: '',
  }), { role: 'NEW ROLE' });

  const onSave = async () => {
    try {
      setSaving(true);
      const success = await saveChanges?.call();
      if (success) {
        setEditing(false);
      }
    } finally {
      setSaving(false);
    }
  };

  const onAddRole = () => {
    setEditing(true);
    dispatch(change(formName, defaultUserRoleMatrix.role, defaultUserRoleMatrix));
  };

  const onAccessEdited = async (role, accessType, value) => {
    dispatch(change(formName, `${role}.${accessType}`, value));
  };

  const roleInput = (role) => (
    <Field
      name={`${role ?? ''}.role`}
      component={MDTextInputField}
      noHelperText
      noErrorLabel
      withEnterTrigger
      required
      type="text"
      size="small"
      margin="none"
      style={{ margin: 0, padding: 0 }}
    />
  );

  const appRoleInput = (role) => (
    <Field
      variant="outlined"
      name={`${role ?? ''}.appRole`}
      component={MDAutoComplete}
      displayEmpty
      options={Object.keys(ORG_STRUCTURE_ROLES)}
      style={{ textAlign: 'start', borderRadius: 0 }}
      noErrorLabel
      inputProps={{
        style: { margin: 0, padding: 0 },
        size: 'small',
      }}
    />
  );

  const checkBox = (role, accessType, value) => (
    <div style={{ textAlign: 'center' }}>
      <FASCheckboxField
        name={`${role}.${accessType}`}
        onChange={(val) => onAccessEdited(role, accessType, val)}
        value={value}
        hideErrorHelper
      />
    </div>
  );

  const accessLabel = (active) => (
    <div style={{ textAlign: 'center' }}>
      {active ? <CheckIcon style={{ color: COLORS.CINTAS_GREEN, margin: 'auto' }} /> : <XIcon style={{ color: COLORS.CINTAS_RED, margin: 'auto' }} />}
    </div>
  );

  const deleteAccessBtn = (role) => (
    <IconButton style={{ color: COLORS.CINTAS_RED }} onClick={() => onDeleteRole(role)}><DeleteIcon /></IconButton>
  );

  const rows = _.map(Object.keys(matrix), (role) => {
    const shouldEdit = editing && !uneditableRoles.includes(role);
    const roleLabel = matrix[role]?.role;

    return [
      shouldEdit && !noRoleInput ? roleInput(role) : (formatRoleLabel ? formatRoleLabel(roleLabel) : roleLabel),
      ...Object.keys(roles).filter((col) => !excludeAppRole || (col !== roles.appRole)).map((col) => {
        if (col === roles.appRole) {
          return shouldEdit ? appRoleInput(role) : (matrix[role]?.[roles.appRole] ?? '');
        }
        return ((shouldEdit && !(uneditableRoleAccesses[role] ?? []).includes(col)) ? checkBox(role, col, matrix[role][col]) : accessLabel(matrix[role][col]));
      }),
      ...(shouldEdit && !noDelete ? [deleteAccessBtn(role)] : []),
    ];
  });

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;

  const expansionTile = () => <CollapsableHeader title={titleText} actions={!loading && (editing ? [saveBtn()] : [editPenButton()])} boundActionToContent content={accessTable()} />;

  const saveBtn = () => (
    <StyledButton
      color="primary"
      variant="contained"
      buttonContent={saving ? spinner() : 'save'}
      handleButton={onSave}
      disabled={(Object.values(matrix).filter((val) => !val.role).length > 0) || (matrix[defaultUserRoleMatrix.role]?.role === defaultUserRoleMatrix.role)}
      style={{ margin: 4 }}
    />
  );

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 14, width: 14, padding: 0, margin: 0, color: COLORS.CINTAS_WHITE,
      }}
      customStyle={{
        maxHeight: 14,
        maxWidth: 14,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const addRoleBtn = () => container({
    style: { justifyContent: 'flex-start' },
    children: [
      <StyledButton
        color="primary"
        variant="contained"
        buttonContent="add role"
        startIcon={<AddIcon />}
        disabled={matrix[defaultUserRoleMatrix.role]}
        handleButton={onAddRole}
      />,
    ],
  });

  const editPenButton = () => (
    <IconButton
      color="primary"
      onClick={() => setEditing(true)}
    >
      <EditIcon />
    </IconButton>
  );

  const accessTable = () => (
    <FASMUIDataTable
      data={rows}
      columns={[
        ...(columnsOverride ?? ['Role', ...Object.values(ROLE_ACCESSES_LABELS).filter((label) => !excludeAppRole || (ROLE_ACCESSES_LABELS.appRole !== label))]),
        ...(editing && !noDelete ? [''] : []), // this is for the delete icon button
      ]}
      options={{
        pagination: false,
        rowsPerPageOptions: false,
        selectableRows: 'none',
        rowHover: false,
        download: false,
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        sort: false,
        sortOrder: {
          name: 'Role',
          direction: 'asc',
        },
        textLabels: {
          body: {
            noMatch: 'There are no roles yet',
          },
        },
      }}
      root={{
        padding: '5px 15px 5px',
        textAlign: 'center',
      }}
      bodyCell={{
        borderRight: `0.01em solid ${COLORS.CINTAS_BLUE}`,
        borderBottom: `0.01em solid ${COLORS.CINTAS_BLUE}`,
      }}
    />
  );

  return (
    <>
      {expansionTile()}
      {noNewRole ? <></> : addRoleBtn()}
    </>
  );
};

export default MatrixTable;
