import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core';
import { COLORS } from '../../../../utils/consts';

const SwitchField = ({
  input,
  label,
  disabled,
}) => {
  const StyledSwitch = withStyles({
    switchBase: {
      '&$checked': {
        color: COLORS.CINTAS_BLUE,
      },
      '&$checked + $track': {
        backgroundColor: COLORS.CINTAS_BLUE,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <FormControlLabel
      control={(
        <StyledSwitch
          checked={!!input.value}
          onChange={(event, value) => { input.onChange(value); input.onBlur(value); }}
          disabled={disabled}
        />
      )}
      label={label}
    />
  );
};
export default SwitchField;
