/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { change } from 'redux-form';
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Box, IconButton } from '@material-ui/core';
import WarningIcon from '@mui/icons-material/Warning';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  orderEventsSelector, orderCoursesSelector,
} from '../../../../../redux/selectors/orders.selectors';
import BoldTitle from '../../../../Text/BoldTitle';
import SimpleText from '../../../../Text/SimpleText';
import ShapeFactory from '../../../../Shapes/ShapeFactory';
import RowLayout from '../../../../LayoutBuilders/RowLayout';
import RowCell from '../../../../LayoutBuilders/RowCell';
import {
  COLORS, DATE_SHORT_FORMAT, DATE_TIME_YYYY_SHORT_FORMAT, DEFAULT_TRUCK_NUMBER, DEFAULT_SIGNATURE_DATE, TIME_FORMAT, ROLE_ACCESSES, SUBCONTRACTED_INSTRUCTOR_LABEL, DATE_TIME_ISO, NOTES_DISPLAY_BORDER,
} from '../../../../../utils/consts';
import { getBrowserTimezone, removeLeadingZeros, normalizeDate } from '../../../../../utils/helpers';
import StyledButton from '../../../../Button/StyledButton';
import DeleteConfirmAlert from '../../EventPopper/DeleteConfirmAlert';
import * as ModalsActions from '../../../../../redux/actions/modals.actions';
import * as OrdersActions from '../../../../../redux/actions/orders.actions';
import ContactSelectionDialog from './ContactSelectionDialog';
import EditableLabel from '../../../../Forms/FormComponents/ComplexLabels/EditableLabel';
import {
  adminTypeSelector, hasUserAccessSelector,
  selectedTciSelector, userInfoSelector,
} from '../../../../../redux/selectors/user.selectors';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import ConfirmationNavButton from '../../../../Button/ConfirmationNavButton';
import AddToCalendarBtn from '../../../../Button/AddToCalendarBtn';
import { timezoneNameSelector, timezoneSelector } from '../../../../../redux/selectors/utils.selectors';
import CancelledCourseIcon from '../../../CancelledCourseIcon';
import SwapInstructorButton from '../../../../Button/SwapInstructorButton';
import DuplicateEventButton from '../../../../Button/DuplicateEventButton';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../../../../utils/consts/nationalAccount.consts';
import { getHigherAccountNumber } from '../../../../../utils/event.utils';
import NationalAccountCourseDisplayHook from '../../../../NationalAccountDashboard/NationalAccountCourseDisplay';

const EventOverview = ({
  order, contact,
  hideCourses, hideStatus, hideNotes,
  isQuote,
  orderFormPath,
  values,
  hideInstructor,
  alreadyLoaded,
  showOrderId,
  showDeliveryExit,
  showPoNumber,
  allowTempContactSwitch,
  withConfirmationNavigation,
  dispatch,
  reloadOrder,
  isBilling,
  tz,
  tzName,
  tzOverride,
  customerFacing,
  onPoUpdated,
  validateRebooking,
  parentModal,
  hideRepeatBookingButton,
  formName,
}) => {
  /*
    * @params events: [Object] sorted by startTime
    * @params instructors: [String]
    * @params courses: [Object]
    * @params customer: Object
    * @params startDate: Date
    * @params endDate: Date
    */
  const editingFormPath = 'editingEventOverview';
  const poNumberFormField = 'POnumber';
  const notesFormField = 'notes';
  const defaultTimeZone = getBrowserTimezone();
  const timezone = localStorage.authenticated ? (tzOverride?.value ?? tz) : defaultTimeZone?.value;
  const timezoneName = localStorage.authenticated ? (tzOverride?.abbrev ?? tzName) : defaultTimeZone?.abbrev;
  const poNumberFormFieldId = `${orderFormPath}.${poNumberFormField}`;
  const notesFormFieldId = `${orderFormPath}.${notesFormField}`;
  const events = alreadyLoaded ? [...(order.events ?? [])] : [
    ...useSelector((state) => orderEventsSelector(state, order.id)),
  ];
  const selectedTimezone = useSelector(timezoneSelector);
  const bookableUser = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile, ROLE_ACCESSES.ownCalendar]));
  const currentUserTimezone = useSelector(!bookableUser ? selectedTciSelector : userInfoSelector)?.tz?.value ?? defaultTimeZone?.value;

  const cancelled = events && events.filter((e) => e.cancelled).length === events.length;
  const subcontracted = order?.subcontracted || order?.events?.[0]?.subcontracted;
  const instructors = subcontracted ? [SUBCONTRACTED_INSTRUCTOR_LABEL] : [
    `${events?.[0]?.instructor.firstName ?? ''} ${events?.[0]?.instructor.lastName ?? ''}`.toUpperCase(),
    ...(events?.[0]?.secondaryInstructor ? [`${events?.[0]?.secondaryInstructor.firstName ?? ''} ${events?.[0]?.secondaryInstructor.lastName ?? ''}`.toUpperCase()] : []),
  ];

  const canStartDelivery = useSelector((state) => (hasUserAccessSelector(state, [ROLE_ACCESSES.canDeliver]) && !cancelled));
  const canSwapInstructor = useSelector((state) => (hasUserAccessSelector(state, [ROLE_ACCESSES.swapInstructor]) && !cancelled));
  const canForceDelivery = useSelector((state) => (hasUserAccessSelector(state, [subcontracted ? ROLE_ACCESSES.subcontractorInvMan : ROLE_ACCESSES.canManuallyInvoice]) && !cancelled));
  const canBook = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));
  const courses = [
    ...useSelector((state) => orderCoursesSelector(state, order.id, events)),
  ];

  const customer = order?.customerObj ?? {};
  const startDate = isQuote
    ? (moment.utc(order?.creationDate))
    : ((events && events.length > 0) ? moment.utc(events[0].startTime) : moment().utc());
  const endDate = isQuote
    ? (moment.utc(order?.expirationDate))
    : ((events && events.length > 0) ? moment.utc(events.length > 1 ? events[events.length - 1].endTime : events[0].endTime) : moment().utc());
  const isSigned = order && order.signature;
  const creationDate = order?.created ? moment.utc(order.created.split('-')[0] === DEFAULT_TRUCK_NUMBER ? DEFAULT_SIGNATURE_DATE : order.created) : undefined;
  const updateDate = order?.updated && order?.updated.split('-')[0] !== DEFAULT_TRUCK_NUMBER && !moment.utc(order.updated).isSame(moment.utc(order.created)) ? moment.utc(order.updated) : undefined;
  const isManuallyInvoiced = order && order.manual_invoice;
  const inDelivery = order && order.delivery;
  const hasEditDeliveryAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.canDeliver]) && !cancelled);
  const hasUpdateAccess = useSelector((state) => (hasUserAccessSelector(state, [subcontracted ? ROLE_ACCESSES.subcontractorEvents : ROLE_ACCESSES.editEvent]) || (hasEditDeliveryAccess && inDelivery)) && !cancelled);
  const isAdvancedAdmin = useSelector((state) => adminTypeSelector(state) === ROLE_ACCESSES.advancedAdmin);
  const isComplete = (order && order.deliverySignature) || (inDelivery && !hasEditDeliveryAccess);

  const signatureDateDisplay = moment.utc((order?.signatureDate ?? DEFAULT_SIGNATURE_DATE)).tz(timezone).format(DATE_TIME_YYYY_SHORT_FORMAT);
  const natAccProgramNotesState = NationalAccountCourseDisplayHook({ noteType: NATIONAL_ACCOUNT_NOTE_TYPE.PROGRAM, account: getHigherAccountNumber(order?.customerObj) });
  const natAccPoNotesState = NationalAccountCourseDisplayHook({ noteType: NATIONAL_ACCOUNT_NOTE_TYPE.PO, account: getHigherAccountNumber(order?.customerObj) });

  const formatTime = (time) => normalizeDate(time, timezone).format(TIME_FORMAT).toLocaleUpperCase() ?? '';
  const shouldShowCourseTime = (course) => {
    if (!course || !course.event || !courses || courses.length === 0) return false;

    const sameTitleCourses = courses.filter((c) => c.title.toLowerCase() === course.title.toLowerCase());
    if (sameTitleCourses.length > 1) return true;

    return false;
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const itemComponent = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const updateEditing = (val) => {
    dispatch(change(formName, editingFormPath, val));
  };

  const onExitDelivery = async () => {
    await dispatch(OrdersActions.cancelOrderDelivery(order.id));
    const stepStr = `${0}`;
    await dispatch(OrdersActions.updateDeliveryStep(order.id, stepStr));
    if (reloadOrder) {
      reloadOrder();
    }
  };

  const onForceFinalization = async () => {
    const url = await dispatch(OrdersActions.submitSignature(order.id, null, true));
    const success = (url ?? '').includes('http');
    if (success) {
      reloadOrder();
    }
    showToast(success);
  };

  const showToast = (success) => {
    dispatch(ModalsActions.showModal('UPDATE_ORDER_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: success ? 'Order successfully updated!' : 'Something went wrong updating the order. Please, try again!',
      },
    }));
  };

  const onEditedPoNum = async (val) => {
    dispatch(OrdersActions.updatePoNum(order.id, val));
    if (onPoUpdated) {
      onPoUpdated(val);
    }
  };

  const onEditedNotes = async (val) => {
    dispatch(OrdersActions.updateNotes(order.id, val));
  };

  const onEditContact = async (newContact) => {
    if (contact.isTemporary) {
      await dispatch(OrdersActions.updateContact(order.id, newContact));
    }
    if (reloadOrder) {
      reloadOrder();
    }
  };

  const showTempContactSwapDialog = async () => {
    const modalName = 'TEMP_CONTACT_SWAP_ALERT';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'SELECT FINAL CONTACT',
        content: <ContactSelectionDialog
          modalName={modalName}
          customerId={order?.customer}
          onConfirm={onEditContact}
        />,
      },
    }));
  };

  const onRequestForceFinalization = () => {
    const modalName = 'FORCE_FINALIZATION_AERLT';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        hideCancel: false,
        confirmText: 'confirm',
        cancelText: 'cancel',
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'FORCE COURSE DELIVERY',
        content: <SimpleText style={{ padding: 20, fontSize: 18 }} txt="Are you sure you want to mark this event as completed?" />,
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(modalName));
          onForceFinalization();
        },
      },
    }));
  };

  const onRequestExitDelivery = () => {
    const modalName = 'DELETE_AERLT';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        confirmText: 'confirm',
        disableBackdropClick: true,
        maxWidth: 'md',
        title: 'EXIT COURSE DELIVERY',
        content: <DeleteConfirmAlert itemTypeName="delivery" action="exit" />,
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(modalName));
          onExitDelivery();
        },
      },
    }));
  };

  const startCourseHander = async (orderID) => {
    // console.log(courseIdx, orderId);
    /**
     * TODO:
     * When the button is pressed, the course goes into “delivery mode”. That means,
     * the participants roster switches to the present/not present visual
     * and the customer signature can be collected.
     * A course started flag will be saved in the event object
    */
    try {
      if (validateRebooking) {
        const rebookingInfo = await validateRebooking(order);
        if (rebookingInfo?.rebook) {
          if (parentModal) {
            await dispatch(ModalsActions.hideModal(parentModal));
          }
          return;
        }
      }

      await dispatch(OrdersActions.updateOrderDelivery(orderID));
      dispatch(ModalsActions.showModal('CREATE_EVENT_SUCCESS', {
        modalType: 'SUCCESS_ALERT',
        modalProps: { message: 'Event successfully started' },
      }));
    } catch (error) {
      dispatch(ModalsActions.showModal('DELIVERY_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: 'Something happened while moving to delivery. Please, try again!' },
      }));
    } finally {
      if (reloadOrder) {
        reloadOrder();
      }
    }
  };

  // Components builders:
  const row = ({
    children, withPadding = false, style, key, ...rest
  }) => (
    <RowLayout key={key} {...rest} style={{ paddingTop: (withPadding ? 14 : 0), ...(style ?? {}) }}>
      {children}
    </RowLayout>
  );

  const rowCell = ({
    child, flex, key, ...rest
  }) => (
    <RowCell key={key} flex={flex} {...(rest ?? {})}>{child}</RowCell>
  );

  const rowOneHeaders = () => row({
    withPadding: true,
    children: [
      rowCell({ key: 0, child: header(isQuote ? 'Creation Date' : 'Date/Time'), flex: (hideStatus && !isQuote) ? 7 : (isQuote ? 2 : 5) }),
      isQuote && rowCell({ key: 1, child: header('Expiration Date'), flex: 2 }),
      !isQuote && rowCell({ key: 2, child: header('Time Zone'), flex: 3 }),
      !hideStatus && !isQuote && rowCell({ key: 3, child: hideStatus ? <></> : header('Confirmation status'), flex: 4 }),
    ],
  });

  const rowTwoHeaders = () => row({
    withPadding: true,
    children: [
      !hideInstructor && rowCell({ key: 0, child: header('Instructor'), flex: hideCourses ? 2 : (showOrderId ? 4 : 6) }),
      !hideInstructor && rowCell({ key: 1, child: header('Created By'), flex: 4 }),
      hideCourses && !isQuote && courseTypeHeader(),
      showOrderId && rowCell({ key: 2, child: header('Order ID'), flex: 2 }),
    ],
  });

  const courseTypeHeader = () => rowCell({ key: 1, child: header('Course Type'), flex: 4 });

  const rowThreeHeaders = () => row({
    withPadding: true,
    children: [
      rowCell({ key: 0, child: header('Course'), flex: 4 }),
      courseTypeHeader(),
      natAccProgramNotesState.hasNotes && rowCell({
        key: 0,
        child: header(natAccProgramNotesState.label),
        flex: 4,
        style: { border: NOTES_DISPLAY_BORDER, borderBottom: 0, padding: '8px 0 8px 8px' },
      }),
    ],
  });

  const rowFourHeaders = () => row({
    withPadding: true,
    children: [
      rowCell({ key: 0, child: header('Customer'), flex: 4 }),
      rowCell({ key: 1, child: header('Address'), flex: 4 }),
      rowCell({ key: 2, child: header('Customer Contact'), flex: 4 }),
    ],
  });

  const rowFiveHeaders = () => row({
    withPadding: true,
    children: [
      rowCell({ key: 0, flex: 8 }),
      natAccPoNotesState.hasNotes && rowCell({
        key: 1,
        child: header(natAccPoNotesState.label),
        flex: 4,
        style: { border: NOTES_DISPLAY_BORDER, borderBottom: 0, padding: '8px 0 8px 8px' },
      }),
    ],
  });

  const rowSixHeaders = () => row({
    withPadding: true,
    children: [
      !hideNotes && rowCell({ key: 0, child: header('Notes'), flex: showPoNumber ? 8 : 12 }),
      showPoNumber && rowCell({ key: 0, child: header('PO #'), flex: 4 }),
    ],
  });

  const rowOne = () => {
    const eventTimeframe = startDate && endDate ? (isQuote ? endDate.format(DATE_SHORT_FORMAT) : `${formatTime(startDate)} to ${formatTime(endDate)}`) : '';
    return row({
      children: [
        rowCell({
          key: 0,
          flex: (hideStatus && !isQuote) ? 4 : 2,
          child: <>
            {text(startDate ? startDate.tz(timezone).format(DATE_SHORT_FORMAT) : '')}
            {withConfirmationNavigation && !cancelled && addToCalendarBtn()}
            {cancelled && cancelledLabel()}
          </>,
        }),
        rowCell({ key: 1, child: text(eventTimeframe), flex: (isQuote ? 2 : 3) }),
        !isQuote && rowCell({ key: 0, child: text(timezoneName), flex: 3 }),
        !hideStatus && rowCell({ key: 2, child: hideStatus ? <></> : confirmationStatus(), flex: 4 }),
      ],
    });
  };

  const rowTwo = () => {
    const instructorsStr = instructors && instructors.length > 0 ? instructors.join('\n') : '';
    return row({
      key: 0,
      children: [
        !hideInstructor && rowCell({ key: '0.0', child: !isComplete && !inDelivery && canSwapInstructor && !subcontracted ? instructorDisplay() : text(instructorsStr), flex: hideCourses ? 2 : (showOrderId ? 4 : 6) }),
        !hideInstructor && rowCell({
          key: '0.1',
          flex: 4,
          child: text(
            `${order?.createdBy.firstName ?? ''} ${order?.createdBy.lastName ?? ''}
            ${creationDate ? `\nCreated: ${creationDate.format(DATE_SHORT_FORMAT)}` : ''}${updateDate ? `\nUpdated: ${updateDate.format(DATE_SHORT_FORMAT)}` : ''}`,
            { withSubtitle: true, subtitleFontSize: 11 },
          ),
        }),
        hideCourses && !isQuote && courseType((courses[0]?.event?.eventType ?? (courses[0]?.eventType ?? ''))),
        showOrderId && rowCell({ key: '0.2', child: text(`${order?.cintasOrderID ?? ''}`), flex: 2 }),
      ],
    });
  };

  const courseRow = (course, i) => {
    const showType = i === 0;
    let courseLabel = (course.title ?? '');
    if (shouldShowCourseTime(course)) {
      courseLabel = `${courseLabel} ( ${formatTime(moment.utc(course.event.startTime))} - ${formatTime(moment.utc(course.event.endTime))} )`;
    }
    return row({
      key: i,
      children: [
        rowCell({
          key: 0,
          flex: 4,
          child: text(
            `${courseLabel}
            ${removeLeadingZeros(course.code)}`,
            { withSubtitle: true, subtitleFontSize: 12 },
          ),
        }),
        courseType(showType ? (course.event.eventType ?? '') : ''),
        showType && natAccProgramNotesState.hasNotes && rowCell({
          key: 0,
          flex: 4,
          style: { border: NOTES_DISPLAY_BORDER, borderTop: 0, padding: '0 0px 8px 8px' },
          child: natAccProgramNotesState.noteComponent(),
        }),
      ],
    });
  };

  const courseType = (type) => rowCell({ key: 1, child: text(type), flex: 4 });
  const getCustomerName = () => _.startCase(_.toLower(customer?.name ?? '')) ?? '';
  const getCustomerSoldTo = () => removeLeadingZeros(_.toLower(customer?.sold_to ?? ''));
  const getCustomerZREG = () => _.startCase(_.toLower(customer?.regional_customer ?? '')) ?? '';
  const getCustomerZNAT = () => _.startCase(_.toLower(customer?.national_customer ?? '')) ?? '';
  const getCustomerZLOC = () => _.startCase(_.toLower(customer?.Local_Customer ?? '')) ?? '';
  const getCustomerRoute = () => removeLeadingZeros(customer?.Route ?? '');

  const tempContactFeatures = () => row({
    spacing: 2,
    style: { marginTop: 1, justifyContent: 'start', textAlign: 'start' },
    children: [
      rowCell({
        flex: 'auto',
        child: text(customerFacing ? '' : 'TEMPORARY CONTACT', { style: { color: COLORS.CINTAS_RED, fontSize: 10 } }),
      }),
      allowTempContactSwitch && hasUpdateAccess && !isComplete && (
        <IconButton
          onClick={showTempContactSwapDialog}
          color="primary"
          style={{ fontSize: 10, padding: 0, margin: 0 }}
        >
          <PersonAddAlt1Icon />
        </IconButton>
      ),
    ],
  });

  const rowFour = () => {
    const address = contact ? `${customer?.street}\n${customer?.city}, ${customer?.region} ${customer?.postal_code}` : '';

    return row({
      children: [
        rowCell({
          key: 0,
          child: text(
            `${getCustomerName()}
            ${customer?.sold_to ? `\nSold-to: ${getCustomerSoldTo()}` : ''}
            ${customer?.Local_Customer ? `\nZLOC: ${getCustomerZLOC()}` : ''}
            ${customer?.regional_customer ? `\nZREG: ${getCustomerZREG()}` : ''}
            ${customer?.national_customer ? `\nZNAT: ${getCustomerZNAT()}` : ''}
            ${customer?.Route ? `\nRoute: ${getCustomerRoute()}` : ''}`,
            { withSubtitle: true, subtitleFontSize: 11 },
          ),
          flex: 4,
        }),
        rowCell({
          key: 1,
          child: text(address),
          flex: 4,
        }),
        rowCell({
          key: 2,
          child: [
            text(`${_.capitalize(contact?.FirstName ?? '')} ${_.capitalize(contact?.LastName ?? '')}\n${_.toLower(contact?.Email ?? '')}`),
            contact && contact.isTemporary && tempContactFeatures(),
          ],
          flex: 4,
        }),
      ],
    });
  };

  const rowFive = () => row({
    children: [
      rowCell({ key: 0, flex: 8 }),
      natAccPoNotesState.hasNotes && rowCell({
        key: 1,
        flex: 4,
        style: {
          textAlign: 'start', border: NOTES_DISPLAY_BORDER, borderTop: 0, padding: '0 0px 8px 8px',
        },
        child: natAccPoNotesState.noteComponent(),
      }),
    ],
  });

  const poNumberComponent = () => (
    <EditableLabel
      id={poNumberFormFieldId}
      path={poNumberFormField}
      values={values[orderFormPath]}
      placeholder="PO Number"
      initiallyEditing={values[editingFormPath]}
      disabled={Boolean(order.deliverySignature)}
      allowEditing={!isComplete && hasUpdateAccess}
      onEditingChanged={updateEditing}
      onSave={onEditedPoNum}
      saveButton
      inputFlex={8}
      charLimit={20}
      normalize={(v) => (v ?? '').replaceAll('#', '')}
    />
  );

  const notes = () => (
    <EditableLabel
      id={notesFormFieldId}
      path={notesFormField}
      type="textarea"
      spacing={3}
      textStyle={{
        overflow: 'wrap',
        fontSize: '1em',
        textOverflow: 'ellipsis',
        flexWrap: 'wrap',
        wordBreak: 'break-all',
        textAlign: 'start',
      }}
      inputFlex={8}
      labelFlex="auto"
      multiline
      multilineRows={5}
      values={values[orderFormPath]}
      placeholder="Notes"
      disabled={(Boolean(order.deliverySignature) && !isManuallyInvoiced)}
      allowEditing={(!isComplete || isManuallyInvoiced) && hasUpdateAccess}
      onSave={onEditedNotes}
    />
  );

  const rowSix = () => row({
    children: [
      !hideNotes && rowCell({
        key: 0,
        flex: 8,
        child: notes(),
      }),
      showPoNumber && rowCell({
        key: 1,
        flex: 4,
        style: { textAlign: 'start' },
        child: poNumberComponent(),
      }),
    ],
  });

  const confirmationStatus = () => row({
    style: { alignItems: 'start', paddingLeft: 10 },
    children: [
      rowCell({
        key: 0,
        style: {
          padding: isSigned ? 4 : 0, paddingTop: isSigned ? 5 : 2, textAlign: 'center', height: '100%',
        },
        child: confirmationDot(),
        flex: 1,
      }),
      rowCell({
        key: 1,
        style: { padding: 0 },
        child: <>
          {text(isSigned ? `Signed by ${order?.namewhoconfirm ?? '--'}` : 'Not Signed')}
          {isSigned ? text(signatureDateDisplay) : <></>}
        </>,
        flex: 10,
      }),
    ],
  });

  const confirmationDot = () => (
    isSigned
      ? <ShapeFactory shape="circle" color={isSigned ? COLORS.CINTAS_GREEN : COLORS.CINTAS_RED} width={10} height={10} />
      : <WarningIcon style={{ color: COLORS.CINTAS_RED, fontSize: 15 }} />
  );

  const header = (h) => <BoldTitle title={h} fontSize={15} />;

  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const instructorDisplay = () => itemComponent({
    flex: 12,
    children: container({
      spacing: 1,
      style: { justifyContent: 'start', alignItems: 'center' },
      children: [
        itemComponent({
          flex: 'auto',
          children: instructors.map((ins) => text(ins)),
        }),
        itemComponent({
          flex: 'auto',
          children: swapInstructorBtn(),
        }),
      ],
    }),
  });

  const buildButton = (label, onClick) => (
    <>
      <Box height={30} />
      <StyledButton variant="outlined" buttonContent={label} onClick={onClick} />
    </>
  );

  const exitDeliveryBtn = () => buildButton('EXIT DELIVERY', onRequestExitDelivery);

  const startDeliveryBtn = () => buildButton('START DELIVERY', () => startCourseHander(order.id));

  const forceFinalizationBtn = () => buildButton('INVOICE MANUALLY', onRequestForceFinalization);

  const swapInstructorBtn = () => <SwapInstructorButton order={order} />;

  const startTime = _(order?.events)
    .map((e) => e.startTime)
    .min();

  const sameTimezone = selectedTimezone === currentUserTimezone;
  const deliveryStartTime = (process.env.REACT_APP_ENV !== 'develop' && !isAdvancedAdmin) ? moment().format(DATE_TIME_ISO) > moment.utc(startTime).tz(selectedTimezone).subtract(1, 'hour').format(DATE_TIME_ISO) : true;

  const actions = () => (
    row({
      style: { justifyContent: 'center', alignItems: 'end', paddingBottom: 16 },
      spacing: 0,
      children: [
        !isComplete && canForceDelivery && !customerFacing && !isBilling && !cancelled && rowCell({
          flex: 'auto',
          style: { padding: 0, marginRight: 16 },
          child: forceFinalizationBtn(),
        }),
        rowCell({
          flex: 'auto',
          style: { padding: 0 },
          child: !isComplete && canStartDelivery && (showDeliveryExit ? exitDeliveryBtn()
            : !isBilling && !customerFacing && deliveryStartTime && sameTimezone && !subcontracted && startDeliveryBtn()),
        }),
        withConfirmationNavigation && confirmationNavBtn(),
        withConfirmationNavigation && !customerFacing && !inDelivery && canBook && !hideRepeatBookingButton && (
          <div style={{ position: 'absolute', right: 12 }}>
            <DuplicateEventButton order={order} />
          </div>
        ),
      ],
    })
  );

  const confirmationNavBtn = () => (
    rowCell({
      flex: 'auto',
      style: { padding: 0, marginLeft: 16 },
      child: <ConfirmationNavButton orderId={order?.id} />,
    })
  );

  const addToCalendarBtn = () => (
    rowCell({
      flex: 4,
      style: { padding: 0, paddingTop: 10 },
      child: <AddToCalendarBtn order={order} />,
    })
  );

  const cancelledLabel = () => row({
    style: { marginTop: 10, paddingLeft: 10 },
    children: [
      rowCell({
        flex: 2,
        style: { padding: 0, paddingTop: 2 },
        child: <CancelledCourseIcon />,
      }),
      rowCell({
        flex: 10,
        style: { padding: 0 },
        child: text('Cancelled', { style: { color: COLORS.CINTAS_RED, fontWeight: 'bold' } }),
      }),
    ],
  });

  return events.length === 0 ? <></> : (
    <div style={{ paddingLeft: 16, paddingRight: 16, width: '98%' }}>
      {rowOneHeaders()}
      {rowOne()}
      {rowTwoHeaders()}
      {rowTwo()}
      {!hideCourses && rowThreeHeaders()}
      {(!hideNotes || !hideCourses) && courses.map((e, i) => courseRow(e, i))}
      {rowFourHeaders()}
      {rowFour()}
      {!hideNotes && rowFiveHeaders()}
      {!hideNotes && rowFive()}
      {rowSixHeaders()}
      {rowSix()}
      {actions()}
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    tzName: timezoneNameSelector(state),
    tz: timezoneSelector(state),
  })),
])(EventOverview);
