/* eslint-disable max-len */
import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { browserName, browserVersion } from 'react-device-detect';

import * as baseInstance from '../../api/instances/baseInstance';
import * as Firebase from '../../utils/firebase';

import * as AuthActions from '../../redux/actions/auth.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as UsersActions from '../../redux/actions/users.actions';
import { getBrowserTimezone } from '../../utils/helpers';
import { COMPATIBLE_BROWSERS, ACCESS_MATRIX_VALIDATION } from '../../utils/consts';
import { showBrowserCompabilityPopup } from '../../utils/event.utils';

class AuthProvider extends Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setTimezone(getBrowserTimezone()));
    this.unsubscribe = Firebase.auth.onIdTokenChanged(async (userAuth) => {
      baseInstance.ejectRequestInterceptors();
      if (userAuth) {
        const token = await Firebase.auth.currentUser.getIdToken();
        baseInstance.setInstanceToken(token);
        dispatch(AuthActions.setUserAuth({
          ...userAuth.toJSON(),
          role: await Firebase.getUserRole(),
        }));
        try {
          await dispatch(UsersActions.fetchCurrentUser());
        } catch (e) {
          if (e?.code === ACCESS_MATRIX_VALIDATION.MISSING) {
            dispatch(AuthActions.logout());
          }
        }
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { children, dispatch } = this.props;

    const checkBrowserVersion = () => COMPATIBLE_BROWSERS.find((browser) => browser.name === browserName
      && Number(browser.version) <= Number(browserVersion));

    return (
      <>
        {children}
        {!checkBrowserVersion() && showBrowserCompabilityPopup(dispatch)}
      </>
    );
  }
}

export default _.flow([
  connect(),
])(AuthProvider);
