import Immutable from 'seamless-immutable';

import {
  SAVE_LOCATIONS, UPDATE_LOCATION, ADD_LOCATION,
} from '../actions/actionTypes/locations';

const initialState = Immutable({
  data: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOCATIONS:
      return state.set('data', action.locations);
    case UPDATE_LOCATION:
      return state.set('data', state.data.map((location) => ((location.id === action.location.id) ? action.location : location)));
    case ADD_LOCATION:
      return state.set('data', [...state.data, action.payload]);
    default:
      return state;
  }
};
