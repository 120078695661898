/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { change } from 'redux-form';
import {
  BELOW_MIN_PRICE_CL, EMAIL_VALIDATION_REGEX, ORDER_BLOCKED,
} from '../../utils/consts';
import { calcMaxParticipants } from '../../utils/event.utils';

// eslint-disable-next-line no-unused-vars
export const validateOpenEnrollment = (values, props, formName) => {
  // TODO
  return {};
};

export const validateTimeblock = (values, props, formName) => {
  const { dispatch } = props;
  const errors = _consolidateErrors({
    ..._checkRequiredFields(values, ['eventType', 'date', 'endDate', 'startTime', 'endTime', 'reason', 'blockType']),
  });
  _setDialogErrorHelperText(errors, dispatch, formName);
  return errors;
};

export const validateOnsite = (values, props, formName) => {
  const { dispatch } = props;
  const errors = _consolidateErrors({
    ..._checkCustomer(values),
    ..._checkTempContact(values),
    ..._checkCourses(values, dispatch, formName),
    ..._checkRequiredFields(values, ['eventType', 'date', 'startTime', 'customer']),
  });

  _setDialogErrorHelperText(errors, dispatch, formName);

  return errors;
};

export const validateQuote = (values, props, formName) => {
  const { dispatch } = props;
  const errors = _consolidateErrors({
    ..._checkCustomer(values),
    ..._checkTempContact(values),
    ..._checkCourses(values, dispatch, formName, true),
    ..._checkRequiredFields(values, ['eventType', 'customer']),
  });

  _setDialogErrorHelperText(errors, dispatch, formName);

  return errors;
};

/// HELPER FUNCTIONS

const _checkRequiredFields = (values, requiredFields) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
    if (values.date && moment.utc(values.date).isBefore(
      moment.utc(Date.now())
        .set('h', 0)
        .set('m', 0)
        .set('s', 0)
        .set('ms', 0),
    )) {
      errors.date = 'Cannot schedule and event in the past.';
    }
    if (values.date === undefined && values.startTime) {
      errors.startTime = 'Choose the date first';
    }
    if (values.date === undefined && values.endTime) {
      errors.endTime = 'Choose the date first';
    }
    if (!values.allDay && values.startTime?.isSameOrAfter(values.endTime)) {
      errors.endTime = 'End time must be greater than start time';
    }
    if (values.allDay && values.startTime > values.endTime) {
      errors.endTime = 'End time must be greater than start time';
    }
    if (values.endDate < values.date) {
      errors.endDate = 'End Date must be greater than start Date';
    }
    if (values.startTime && values.endTime && values.selectedDayEvents === true) {
      errors.startTime = 'Time Conflict';
    }
    if (values.allDay === true && values.selectedDayEvents === true) {
      errors.date = 'Time Conflict';
      errors.endDate = 'Time Conflict';
    }
  });

  return errors;
};

const _checkCustomer = (values) => {
  const errors = {};
  if (values.customer && (values.customer.customer_blocked_for_orders === ORDER_BLOCKED.denied)) {
    errors.customer = 'Customer is order blocked. Use time block reason “customer issue” to block time in the calendar';
  }

  const requiredStr = 'Required';
  if (!values.customerContact && !values.temporaryContact) {
    errors.customerContact = requiredStr;
    errors.temporaryContact = {};
    errors.temporaryContact.FirstName = requiredStr;
    errors.temporaryContact.LastName = requiredStr;
    errors.temporaryContact.Email = requiredStr;
  }
  return errors;
};

const _checkTempContact = (values) => {
  const errors = {};
  if (values.temporaryContact) {
    const requiredStr = 'Required';
    if (!values.temporaryContact.FirstName) {
      errors.temporaryContact = {};
      errors.temporaryContact.FirstName = requiredStr;
    }
    if (!values.temporaryContact.LastName) {
      errors.temporaryContact = {};
      errors.temporaryContact.LastName = requiredStr;
    }
    if (!values.temporaryContact.Email) {
      errors.temporaryContact = {};
      errors.temporaryContact.Email = requiredStr;
    }

    // regex validation for email and phone number
    if (values.temporaryContact.Email) {
      const result = EMAIL_VALIDATION_REGEX.exec(values.temporaryContact.Email);
      if (!result) {
        errors.temporaryContact = {};
        errors.temporaryContact.Email = 'Invalid Email Address';
      }
    }
  }

  return errors;
};

const _checkCoursesLength = (values, dispatch, formName) => {
  const errors = { courses: [] };

  if (!values.courses || !values.courses.length) {
    errors.courses = { _error: 'At least one course must be entered' };
  } else if (values.courses.length > 0 && values.startTime !== undefined && values.courses[0].course) {
    dispatch(change(formName, 'endTime', values.courses[values.courses.length - 1].courseEndTime));
  }
  return errors;
};

const _checkCourseAddons = (course) => {
  let addonsErrors = {};

  if (course && course.selectedAddons) {
    addonsErrors.selectedAddons = {};
    Object.keys(course.selectedAddons).forEach((key) => {
      const addon = course.selectedAddons[key];
      if (!addon) return;
      if (!addon.qty || addon.qty < 0) {
        addonsErrors.selectedAddons[key] = { qty: 'Invalid value' };
      }
    });
    if (Object.keys(addonsErrors.selectedAddons).length === 0) {
      addonsErrors = {};
    }
  }

  return addonsErrors;
};

const _checkCourseTime = (course, courseIndex, values) => {
  const courseTimeErrors = {};
  const courseStartTimeUTC = course.courseStartTime && moment.utc(course.courseStartTime);
  const courseEndTimeUCT = courseIndex !== 0 && moment.utc(values?.courses[courseIndex - 1]?.courseEndTime);
  if (course && course.courseEndTime && !courseStartTimeUTC) {
    courseTimeErrors.courseStartTime = 'Required';
  }
  if (courseIndex !== 0 && courseStartTimeUTC && courseStartTimeUTC < courseEndTimeUCT) {
    courseTimeErrors.courseStartTime = 'Error course start time';
  }
  return courseTimeErrors;
};

const _checkCourseParticipants = (course, hasSecondaryInstructor) => {
  const particCourseErrors = {};
  // Participants validation moved to here
  if (course && !course.participants) {
    particCourseErrors.participants = 'Required';
  }

  const courseMax = course.course
    && course.course.participants && calcMaxParticipants(course.course, hasSecondaryInstructor);

  if (course.participants > courseMax) {
    particCourseErrors.participants = 'Exceeds Maximum Participants';
  }
  if (course && course.participants < 1) {
    particCourseErrors.participants = 'Below Required Participants';
  }

  if (course && course.skillcheck) {
    if (!course.skillcheckParticipants && Number(course.skillcheckParticipants) !== 0) {
      particCourseErrors.skillcheckParticipants = 'Required';
    }
    if (course.skillcheckParticipants < 0) {
      particCourseErrors.skillcheckParticipants = 'Below Required Participants';
    }
    const skillcheckPrice = (course.skillcheckPrice?.customerPrice ?? false);
    const belowZero = skillcheckPrice && (skillcheckPrice < 0);
    if (belowZero) {
      particCourseErrors.course = 'Skill Check Price set below the minimum value allowed.';
    }
  }

  return particCourseErrors;
};

const _checkCoursePricing = (course) => {
  const courseErrors = {};
  if (course
    && course.customerPrice && course.commissionLevels
    && (course.customerPrice < BELOW_MIN_PRICE_CL.min)) {
    courseErrors.customerPrice = 'Below the minimum value allowed';
  }
  return courseErrors;
};

const _checkSingleCourse = (course, values, courseIndex, ignoreDatetime) => {
  let courseErrors = {};
  if (course && !course.course) {
    courseErrors.course = 'Required';
  } else {
    courseErrors = {
      ...((ignoreDatetime ? [] : _checkCourseTime(course, courseIndex, values))),
      ...(_checkCourseAddons(course)),
      ...(_checkCourseParticipants(course, !_.isEmpty(values?.secondaryTci ?? ''))),
      ...(_checkCoursePricing(course)),
    };
  }

  return courseErrors;
};

const _checkCourses = (values, dispatch, formName, ignoreDatetime) => {
  const errors = _checkCoursesLength(values, dispatch, formName);
  (values?.courses ?? []).forEach((course, courseIndex) => {
    errors.courses.push({});
    errors.courses[courseIndex] = _checkSingleCourse(course, values, courseIndex, ignoreDatetime);
  });

  return errors;
};

const _setDialogErrorHelperText = (errors, dispatch, formName) => {
  if (Object.keys(errors).length !== 0) {
    dispatch(change(formName, 'errorHelper', true));
  } else {
    dispatch(change(formName, 'errorHelper', false));
  }
};

const _consolidateErrors = (curErr) => {
  let errors = { ...curErr };
  const courseErrors = errors.courses;
  let hasCourseError = false;
  _.forEach(courseErrors, (course) => {
    if (Object.keys(course).length !== 0) {
      hasCourseError = true;
    }
  });

  if (!hasCourseError) {
    const { courses, ...otherErrors } = errors;
    errors = otherErrors;
  }

  return errors;
};
