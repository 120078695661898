/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  changeMultipleNotificationsToRead,
  fetchNotificationsData, fetchReadNotifications, fetchUnreadNotifications,
} from '../../../redux/actions/notifications.actions';
import { pastNotificationsSelector, unreadNotificationsSelector } from '../../../redux/selectors/notifications.tasks.selector';
import { COLORS } from '../../../utils/consts';
import MarkAllReadButton from '../../Button/MarkAllReadButton';
import ViewMoreButton from '../../Button/ViewMoreButton';
import Spinner from '../../SpinnerOverlay/Spinner';
import DrawerSection from '../DrawerSection';
import NotificationTile from './NotificationTile';

const maxNumRecordsPerPage = 20;

const NotificationsPanelContent = ({
  unread,
  past,
  dismissPanel,
}) => {
  const dispatch = useDispatch();
  const [currentPastPage, setPastPage] = React.useState(1);
  const [currentUnreadPage, setUnreadPage] = React.useState(1);
  const [loadingPastPage, setLoadingPastPage] = React.useState(false);
  const [loadingUnreadPage, setLoadingUnreadPage] = React.useState(false);
  const [loadingInitialData, setLoadingInitialData] = React.useState(false);
  const [markingRead, setMarkingRead] = React.useState(false);
  const pastPagesLoaded = Array(currentPastPage).fill(0).map((__, i) => i + 1);
  const unreadPagesLoaded = Array(currentUnreadPage).fill(0).map((__, i) => i + 1);
  const pastData = _.flatten((pastPagesLoaded ?? []).map((p) => (past?.[p] ?? [])));
  const unreadData = _.flatten((unreadPagesLoaded ?? []).map((p) => (unread?.[p] ?? []).map((n, i) => ({ ...(n ?? {}), page: p, pageIndex: i }))));
  const [noMorePastPages, setNoMorePastPages] = React.useState(false);
  const [noMoreUnreadPages, setNoMoreUnreadPages] = React.useState(false);
  const unreadNum = unreadData?.filter((n) => !n.read).length;

  const loadPage = async (page, isUnread) => {
    let success = false;
    try {
      if (isUnread) {
        setLoadingUnreadPage(true);
      } else {
        setLoadingPastPage(true);
      }
      const newPageData = await dispatch(isUnread ? fetchUnreadNotifications(page) : fetchReadNotifications(page));
      if (_.isEmpty(newPageData) || newPageData?.length < maxNumRecordsPerPage) {
        if (isUnread) { setNoMoreUnreadPages(true); } else { setNoMorePastPages(true); }
      }
      success = true;
    } catch (e) {
      success = false;
    } finally {
      if (isUnread) {
        setLoadingUnreadPage(false);
      } else {
        setLoadingPastPage(false);
      }
    }
    return success;
  };

  const loadInitialData = async (noFeedback) => {
    try {
      if (!noFeedback) setLoadingInitialData(true);
      const [{ value: unreadNotifications }, { value: readNotifications }] = await dispatch(fetchNotificationsData(1, 1, true));
      if (!readNotifications || _.isEmpty(readNotifications) || readNotifications?.length < maxNumRecordsPerPage) {
        setNoMorePastPages(true);
      }
      if (!unreadNotifications || _.isEmpty(unreadNotifications) || unreadNotifications?.length < maxNumRecordsPerPage) {
        setNoMoreUnreadPages(true);
      }
    } finally {
      if (!noFeedback) setLoadingInitialData(false);
    }
  };

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const onMarkAllRead = async () => {
    try {
      setMarkingRead(true);
      await dispatch(changeMultipleNotificationsToRead(unreadData.map((d) => d.notificationID)));
      await loadInitialData(true);
    } finally {
      setMarkingRead(false);
    }
  };

  const increasePage = async (isUnread) => {
    const newPage = (isUnread ? currentUnreadPage : currentPastPage) + 1;
    const pageLoaded = await loadPage(newPage, isUnread);
    if (pageLoaded) {
      if (isUnread) setUnreadPage(newPage);
      else setPastPage(newPage);
    }
  };

  const markAllReadButton = () => (
    <MarkAllReadButton onClick={onMarkAllRead} loading={markingRead} disabled={unreadNum === 0} />
  );

  const notificationsList = (notifications) => (
    <div>
      {notifications.map((n, i) => <NotificationTile notification={n} listIndex={n.pageIndex ?? i} dismissPanel={dismissPanel} />)}
    </div>
  );

  const unreadNotificationsSection = () => (
    <DrawerSection
      title={`${unreadNum} unread`}
      actions={[markAllReadButton()]}
      content={[
        notificationsList(unreadData),
        _.isEmpty(unreadData) && <></>,
        !_.isEmpty(unreadData) && viewMoreBtn(true),
      ]}
    />
  );

  const pastNotificationsSection = () => (
    <DrawerSection
      title="Past"
      content={[
        notificationsList(pastData),
        _.isEmpty(pastData) && noPastNotifications(),
        !_.isEmpty(pastData) && viewMoreBtn(),
      ]}
    />
  );

  const noPastNotifications = () => (
    <Typography
      style={{ color: COLORS.CINTAS_GRAY, fontSize: 12, textAlign: 'center' }}
    >
      Your past notifications will appear here
    </Typography>
  );

  const viewMoreBtn = (isUnread) => (
    <ViewMoreButton onClick={() => increasePage(isUnread)} loading={isUnread ? loadingUnreadPage : loadingPastPage} noMorePages={isUnread ? noMoreUnreadPages : noMorePastPages} />
  );

  const spinner = () => {
    const size = 30;
    return (
      <Spinner
        spinnerStyle={{
          height: size, width: size, padding: 0, margin: 0, color: COLORS.CINTAS_BLUE,
        }}
        customStyle={{
          maxHeight: size,
          maxWidth: size,
          margin: 0,
          padding: 0,
          marginTop: '20%',
          marginLeft: '45%',
        }}
      />
    );
  };

  return (
    <div>
      {
        loadingInitialData ? [spinner()] : [
          unreadNotificationsSection(),
          pastNotificationsSection(),
        ]
      }
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    unread: unreadNotificationsSelector(state),
    past: pastNotificationsSelector(state),
  })),
])(NotificationsPanelContent);
