/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment/moment';
import { DATE_PICKER_FOMAT, EVENT_TYPE } from '../../utils/consts';
import { materialsDataSelector } from './materials.selector';
import { calculateEventTotalPrice } from '../../utils/event.utils';

/* eslint-disable import/prefer-default-export */
export const eventsDataSelector = (state) => state.events?.data ?? {};
export const upcomingEventsDataSelector = (state) => state.events?.upcoming ?? {};
export const allEventsListSelector = (state) => _.flatten(_.flatten(Object.values(state.events?.data ?? {})).map((e) => Object.values(e))) ?? [];
export const allUpcomingEventsListSelector = (state) => _.flatten(_.flatten(Object.values(state.events?.upcoming ?? {})).map((e) => Object.values(e))) ?? [];
export const cancelledEventsCountSelector = (state) => state.events?.cancelledDailyCount ?? {};
export const cancelledEventsDayCountSelector = (state, date) => (state.events?.cancelledDailyCount ?? {})[date]; // date variable must be in format YYYY-MM-DD
export const eventsFromOrderSelector = (state, orderId) => Object.values((state.events?.data ?? {})[orderId] ?? {}) ?? [];
export const eventRevenueSelector = ({
  state, orderId, eventId, preloadedEvent,
}) => {
  const event = preloadedEvent ?? eventsDataSelector(state)[orderId]?.[eventId];
  if (!event) return 0.0;
  return calculateEventTotalPrice(
    event,
    [
      ...(event.addOns ?? []),
      ...(event.additional_participants ?? []),
    ],
    materialsDataSelector(state),
  );
};

export const cancelledEventsRevenueForDate = (state, date) => {
  const cancellationInfo = cancelledEventsDayCountSelector(state, date);
  if (!cancellationInfo?.cancelledEvents) return 0;

  const mapped = {};

  (cancellationInfo.cancelledIDs ?? []).forEach((eventInfo) => {
    if (!mapped[eventInfo.orderID]) {
      mapped[eventInfo.orderID] = eventInfo.fee ?? 0.0;
    }
  });

  return Object.values(mapped).reduce((red, fee) => red + fee, 0.0);
};

export const eventsRevenueForDate = (state, date, tz) => {
  const calendarEvents = allEventsListSelector(state).filter(
    (ev) => (
      (moment.utc(ev.startTime).tz(tz).format(DATE_PICKER_FOMAT) === date)
      && (ev.eventType === EVENT_TYPE.ON_SITE)
    ),
  );

  return calendarEvents.reduce(
    (red, ev) => red + eventRevenueSelector({ state, preloadedEvent: ev }),
    0.0,
  );
};

export const dayTotalRevenueSelector = (state, date, tz) => {
  const cancellationsRevenue = cancelledEventsRevenueForDate(state, date) ?? 0.0;
  const eventsRevenue = eventsRevenueForDate(state, date, tz) ?? 0.0;
  return cancellationsRevenue + eventsRevenue;
};
