/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { connect, useSelector } from 'react-redux';
import React from 'react';
import SimpleText from '../../Text/SimpleText';
import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';
import { ccyFormat, removeLeadingZeros } from '../../../utils/helpers';
import ConfirmationNavButton from '../../Button/ConfirmationNavButton';
import { ORDER_STATUS_DISPLAY, SUBCONTRACTED_INSTRUCTOR_LABEL } from '../../../utils/consts';
import DownloadRosterPDFButton from '../../Button/DownloadRosterPDFButton';
import { timezoneSelector, timezoneNameSelector } from '../../../redux/selectors/utils.selectors';

const columns = [
  {
    name: 'Region',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Location',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Instructor/TCI',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Customer',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Sold-To',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'PO',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Scheduled Amount',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Delivered Amount',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Start Date',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
      sortCompare: (order) => (obj1, obj2) => {
        const val1 = moment.utc(obj1.data, 'dddd, MMMM Do YYYY, h:mm a', true);
        const val2 = moment.utc(obj2.data, 'dddd, MMMM Do YYYY, h:mm a', true);
        const isAfter = val2.isAfter(val1);
        const sortVal = (isAfter ? 1 : -1) * (order === 'desc' ? 1 : -1);
        return sortVal;
      },
    },
  },
  {
    name: 'Start Time',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Time Zone',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  // {
  //   name: 'Contact',
  //   options: {
  //     display: true,
  //     filter: false,
  //     searchable: true,
  //     sort: true,
  //     viewColumns: true,
  //     download: false,
  //     sortCompare: (order) => (obj1, obj2) => {
  //       const val1 = obj1.rowData[10];
  //       const val2 = obj2.rowData[10];
  //       const orderVal = val1.localeCompare(val2) * (order === 'desc' ? 1 : -1);
  //       return orderVal;
  //     },
  //   },
  // },
  {
    name: 'Order Status',
    options: {
      display: true,
      filter: true,
      searchable: false,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Reason',
    options: {
      display: true,
      filter: true,
      searchable: false,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Route',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Contact First Name',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Contact Last Name',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Contact Phone',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: 'Contact Email',
    options: {
      display: true,
      filter: true,
      searchable: true,
      sort: true,
      viewColumns: true,
      download: true,
    },
  },
  {
    name: '',
    options: {
      display: true,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  }, // this is for the order confirmation button
  {
    name: '',
    options: {
      display: true,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  }, // this is for the roster PDF button
];

export const getStatusLabel = (order, requestingCancelled) => {
  if (order.manual_invoice) {
    return ORDER_STATUS_DISPLAY.MANUAL_INVOICE;
  }
  if ((order?.cancelled ?? false) || requestingCancelled || order?.rescheduledOrder) {
    return (order?.cancelled ?? false) ? ORDER_STATUS_DISPLAY.CANCELLED : ORDER_STATUS_DISPLAY.RESCHEDULED;
  }
  return order.deliverySignature ? ORDER_STATUS_DISPLAY.COMPLETED : (order.delivery ? ORDER_STATUS_DISPLAY.IN_PROGRESS : ORDER_STATUS_DISPLAY.NOT_STARTED);
};

export const makeStatusDisplay = (order, requestingCancelled, cancelNotes, rescheduled, simpleString) => {
  const label = getStatusLabel(order, requestingCancelled, order?.rescheduledOrder);
  let status = label;
  if (((order?.cancelled ?? false) || requestingCancelled || rescheduled) && !simpleString) {
    status = (
      <>
        <div style={{ fontWeight: 'bold' }}>{label}</div>
        {cancelNotes ? <div style={{ fontSize: 12 }}>{cancelNotes}</div> : <></>}
      </>
    );
  }
  return status;
};

export const makeContactDisplay = (contact, temp = false) => (
  <SimpleText
    withSubtitle
    subtitleFontSize={10}
    subtitleStyle={{ paddingLeft: 2 }}
    txt={`${temp ? 'Temporary - ' : ''}${contact?.name || 'Missing name'} \n ${(contact?.email || 'Missing email').toLowerCase()}`}
  />
);

const OrdersTable = ({
  regionLocationData, orders, isLoading, hasQueried, requestingCancelled,
}) => {
  const emptyTableMessage = hasQueried ? (isLoading ? 'Loading Orders. Please wait.' : 'Sorry, no matching records found') : '';
  const selectedTimezone = useSelector(timezoneSelector);
  const selectedTimezoneAbbrev = useSelector(timezoneNameSelector);

  const options = {
    filter: true,
    filterType: 'dropdown',
    customSearch: (searchQuery, currentRow) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (col && col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    selectableRows: 'none',
    sortOrder: {
      name: 'Start Date',
      direction: 'desc',
    },
    downloadOptions: {
      filename: 'Orders.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    textLabels: {
      body: {
        noMatch: emptyTableMessage,
      },
    },
  };

  const editButton = (order) => (
    <ConfirmationNavButton orderId={order.id} />
  );

  const downloadRosterButton = (id) => <DownloadRosterPDFButton orderId={id} />;

  const getCustomerName = (order) => {
    let customerName = 'Missing Customer';
    order?.events?.forEach((ev) => {
      customerName = ev.title || customerName;
    });
    return customerName;
  };

  const makeReasonDisplay = (order) => {
    if ((order?.cancelled ?? false) || (order?.rescheduledOrder ?? false)) {
      return order?.cancellationReason ?? 'Missing Reason';
    }
    return 'N/A';
  };

  const getDeliveredAmount = (order) => {
    // First check for cancellation fee
    if ((order.cancelled ?? false) || (order?.rescheduledOrder ?? false)) {
      return (order?.cancellationFee ?? false) ? order.cancellationFee : 0;
    }

    // If order hasn't been completed yet, return 0
    if (!order?.deliverySignature ?? false) {
      return 'N/A';
    }

    return -1;
  };

  const rows = _.map(orders, (order) => {
    const displayTime = order?.events?.[0]?.startTime ? moment.utc(order?.events?.[0]?.startTime).tz(selectedTimezone).format('hh:mm a') : 'Missing Time';
    const displayDate = order?.events?.[0]?.startTime ? moment.utc(order?.events?.[0]?.startTime).tz(selectedTimezone).format('MM/DD/YYYY') : 'Missing Date';
    const locationObject = _.find(regionLocationData, { loc_id: order?.locID ?? false });
    const regionDisplay = locationObject?.region_name ?? 'Missing Region';
    const locationDisplay = locationObject?.loc_name ?? 'Missing Location';
    const soldToDisplay = removeLeadingZeros(order?.customer) || 'Missing Customer';
    let instructorDisplay = order?.instructor ? `${order?.instructor?.firstName ?? ''} ${order?.instructor?.lastName}` : 'Missing Instructor Info';
    if (order?.events?.[0]?.instructorID?.startsWith('sub')) {
      // if the instructor is a subcontractor --> default to subcontractor instructor label (External Vendor)
      instructorDisplay = SUBCONTRACTED_INSTRUCTOR_LABEL.toUpperCase();
    }
    const orderStatus = makeStatusDisplay(order, requestingCancelled, undefined, undefined, true);
    const reasonDisplay = makeReasonDisplay(order);
    const customerName = getCustomerName(order);
    const contactObj = (order?.contact ?? false) || (order?.temporaryContact ?? {});
    const scheduledAmountDisplay = order?.revenue ?? 'Missing Revenue';
    const deliveredAmount = getDeliveredAmount(order);
    const deliveredAmountDisplay = deliveredAmount === -1 ? scheduledAmountDisplay : deliveredAmount === 'N/A' ? 'N/A' : ccyFormat(deliveredAmount);
    const contactNames = (contactObj?.name ?? 'Missing Missing').split(' ');

    return [
      regionDisplay,
      locationDisplay,
      instructorDisplay,
      customerName,
      soldToDisplay,
      order?.POnumber ?? 'N/A',
      ccyFormat(scheduledAmountDisplay),
      deliveredAmountDisplay,
      displayDate,
      displayTime,
      selectedTimezoneAbbrev,
      orderStatus,
      reasonDisplay,
      order?.route ?? 'Missing Route',
      contactNames[0] ?? 'Missing First Name',
      contactNames[1] ?? 'Missing Last Name',
      contactObj.Phones?.[0]?.Phone ?? 'Missing Phone',
      contactObj.email ?? 'Missing Email',
      order?.id ? editButton(order) : '',
      order?.id ? downloadRosterButton(order.id) : '',
    ];
  });

  return (
    <FASMUIDataTable
      data={rows}
      columns={columns}
      options={options}
      root={{
        padding: '5px 15px 5px',
        '&:nth-child(1)': {
          width: '15%',
        },
        '&:nth-child(2)': {
          width: '16%',
        },
        '&:nth-child(3)': {
          width: '16%',
        },
        '&:nth-child(4)': {
          width: '16%',
        },
        '&:nth-child(5)': {
          width: '16%',
        },
        '&:nth-child(6)': {
          width: '16%',
        },
        '&:nth-child(7)': {
          width: '5%',
        },
        '&:nth-child(8)': {
          width: '5%',
        },
      }}
    />
  );
};

export default _.flow([
  connect(),
])(OrdersTable);
