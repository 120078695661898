/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable no-fallthrough */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {
  fetchUserSolvedTasks, fetchUserUnsolvedTasks, generateOrderTask, getTaskById, markAllTasksRead, markTaskRead, markTaskResolved,
} from '../../api/tasks.api';
import { OK_STATUS_CODES } from '../../utils/consts';
import { TASK_RESOLUTIONS, TASK_TYPE } from '../../utils/consts/tasks.consts';
import { buildTaskBody } from '../../utils/tasks.utils';
import TasksManager from '../../utils/tasksManager';
import {
  checkTaskEnabledSelector, panelTypes, unsolvedTasksListSelector, unsolvedTasksSelector,
} from '../selectors/notifications.tasks.selector';
import { tasksConfigSelector } from '../selectors/settings.selectors';
import { userInfoSelector } from '../selectors/user.selectors';
import { timezoneDetailsSelector } from '../selectors/utils.selectors';
import {
  CLEAR_TASKS_DATA, CLEAR_UNSOLVED_TASKS_DATA, READ_TASK, RESOLVE_TASK, SAVE_TASKS_DATA, SAVE_UNSOLVED_TASKS,
} from './actionTypes/notifications.tasks';
import { fetchEventById } from './events.actions';
import { postOrderNotification, showNoticeDot } from './notifications.actions';
import { fetchOrderSummary, generateCancelledSalesOrder, reassignOrder } from './orders.actions';
import { getSupervisorUid } from './users.actions';
import { NOTIFICATION_TYPE } from '../../utils/consts/notifications.consts';

export const saveSolvedTasks = (page, data) => ({
  type: SAVE_TASKS_DATA,
  page,
  data,
});

const clearSolvedTasks = () => ({
  type: CLEAR_TASKS_DATA,
});

const clearUnsolvedTasks = (type) => ({
  type: CLEAR_UNSOLVED_TASKS_DATA,
  taskType: type,
});

const saveUnsolvedTasks = (type, page, data) => ({
  type: SAVE_UNSOLVED_TASKS,
  taskType: type,
  page,
  data,
});

const readTask = (type, page, index) => ({
  type: READ_TASK,
  taskType: type,
  page,
  index,
});

const resolveTask = (type, page, index, value) => ({
  type: RESOLVE_TASK,
  taskType: type,
  page,
  index,
  value,
});

export const fetchTaskById = (taskID) => async () => {
  try {
    const result = await getTaskById(taskID);
    if (result && result?.data) {
      return result.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const changeTaskToRead = (type, page, index, taskID) => async (dispatch, getState) => {
  try {
    const result = await markTaskRead(taskID);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(readTask(type, page, index));
      const unread = unsolvedTasksSelector(getState()).filter((n) => !n.read);
      if (_.isEmpty(unread)) {
        dispatch(showNoticeDot(panelTypes.task, false));
      }
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const changeMultipleTasksToRead = (taskIDs) => async (dispatch, getState) => {
  try {
    const result = await markAllTasksRead(taskIDs);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      await dispatch(fetchTasksData(null, true));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const changeTaskToResolved = (task, resolutionValue) => async (dispatch) => {
  try {
    let success = false;
    const { details: { orderID, fee } } = task ?? {};
    switch (task.type) {
      case TASK_TYPE.WAIVE_FEE:
        if (resolutionValue === TASK_RESOLUTIONS.WAIVE_FEE.ACCEPTED) {
          success = true;
          break;
        }
        success = await dispatch(generateCancelledSalesOrder(orderID, fee));
        break;
      case TASK_TYPE.MISSING_TRAINER:
      case TASK_TYPE.TERRITORY_CHANGE:
        const instructors = (resolutionValue ?? '').split('~');
        const getID = (str) => str?.split('::')?.[0];
        const instructorID = getID(instructors?.[0]);
        const secondaryTci = getID(instructors?.[1]);
        success = await dispatch(reassignOrder(orderID, instructorID, secondaryTci, false, true, true));
        break;
      default:
        success = true;
        break;
    }
    if (!success) throw Error();
    const result = await markTaskResolved(task.taskID, resolutionValue);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(resolveTask(task.type, task.page, task.i, resolutionValue));
      dispatch(postOrderNotification({
        notificationType: NOTIFICATION_TYPE.ESCALATED_EVENT,
        eventID: task?.details?.orderID,
        loadAsOrder: true,
        getActionInfo: () => ({ task, resolution: resolutionValue }),
      }));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const postOrderTask = ({
  taskType, subType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
}) => async (dispatch, getState) => {
  try {
    const isTaskEnabled = checkTaskEnabledSelector(getState(), taskType);
    if (!isTaskEnabled) {
      // dont do anything if the tasks configurations have this type of task disabled for inApp task
      return true;
    }
    const taskInfo = await dispatch(buildOrderTask({
      taskType, subType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
    }));
    if (!taskInfo) return false;
    const { receivers, task } = taskInfo;
    if (_.isEmpty(receivers)) return true;
    const response = await dispatch(createOrderTask(receivers, task));
    if (response && OK_STATUS_CODES.includes(response.stauts)) {
      return true;
    }
    return false;
  } catch (e) { return false; }
};

const createOrderTask = (receivers, task) => async () => {
  try {
    const emailBody = buildTaskBody(task) ?? '';
    const result = await generateOrderTask({ receivers, task, emailBody });
    const success = result && OK_STATUS_CODES.includes(result.status);

    return success;
  } catch (e) {
    return false;
  }
};

export const buildOrderTask = ({
  taskType, subType, eventID, getActionInfo, preloadedOrderSummary, loadAsOrder,
}) => async (dispatch, getState) => {
  // This function builds an order notification and its receivers
  // it returns an object with empty receivers if the notification type + order info do not meet criterias for the notification to be posted
  try {
    if ((!eventID || eventID === '') && (!loadAsOrder && !preloadedOrderSummary)) return undefined;
    let order;
    let event;
    if (loadAsOrder) {
      // fetch the order using the orderID or use the preloaded one
      order = preloadedOrderSummary ?? (await dispatch(fetchOrderSummary(eventID)));
      try {
        // get latest event-specific info (info not present in orderSummary)
        event = await dispatch(fetchEventById(order?.events?.[0]?.id));
      } catch (e) { event = {}; }
    } else {
      // get latest event-specific info (info not present in orderSummary)
      event = await dispatch(fetchEventById(eventID));
      // fetch the order linked to the event or use the preloaded one
      order = preloadedOrderSummary ?? (await dispatch(fetchOrderSummary(event.order)));
    }
    const { instructorID, createdBy } = event;
    const zip = (order?.customerObj?.postal_code ?? '').split('-')[0]; // zip used to calculate manager for TCI based on customer location
    const actionInfo = getActionInfo ? getActionInfo(order, event) : {};

    // create a task manager instance
    const tasksManager = new TasksManager({
      user: userInfoSelector(getState()),
      tz: timezoneDetailsSelector(getState()),
      taskConfigs: tasksConfigSelector(getState()),
    });
    // use the task manager to generate a task specific to the task type
    const task = tasksManager.phoenixOfType(taskType, subType).giveBirth(order, actionInfo);
    // use the task manager to calculate the receivers IDs according to the task type and task configurations
    const managerID = await dispatch(getSupervisorUid(instructorID, zip));
    const receivers = tasksManager.receiversOfOrderTaskType(taskType, managerID);
    return {
      task,
      receivers,
    };
  } catch (e) { return undefined; }
};

export const fetchTasksData = (page, cleanState) => async (dispatch, getState) => {
  try {
    const user = userInfoSelector(getState());
    if (!user) throw Error('No user logged-in');
    const results = await Promise.allSettled([
      dispatch(fetchUnsolvedTasks(page ?? 1, cleanState)),
      dispatch(fetchSolvedTasks(page ?? 1, cleanState)),
    ]);
    return results;
  } catch (e) {
    return false;
  }
};

const fetchUnsolvedTasks = (p, cleanState) => async (dispatch) => {
  try {
    return await Promise.allSettled(
      Object.values(TASK_TYPE).map(
        (type) => dispatch(fetchUnsolvedTasksofType(p ?? 1, type, cleanState)),
      ),
    );
  } catch (e) {
    throw e;
  }
};

export const fetchUnsolvedTasksofType = (p, type, cleanState) => async (dispatch, getState) => {
  try {
    const user = userInfoSelector(getState());
    if (!user) throw Error('No user logged-in');
    const page = (p ?? 1);
    const response = await fetchUserUnsolvedTasks(user.uid, page, type);
    const data = (response?.data ?? []);
    // .sort((a, b) => {
    //   if (!a.read && b.read) return -1;
    //   if (a.read && !b.read) return 1;
    //   return 0;
    // });
    if (cleanState) dispatch(clearUnsolvedTasks(type));
    dispatch(saveUnsolvedTasks(type, page, data));
    const unsolvedData = unsolvedTasksListSelector(getState());
    const unRead = unsolvedData.filter((t) => !t.read);
    dispatch(showNoticeDot(panelTypes.task, !_.isEmpty(unRead)));
    return data;
  } catch (e) {
    throw e;
    // for testing:
    // dispatch(saveUnsolvedTasks(type, page, mockedData.unsolved));
    // const unRead = mockedData.unsolved.filter((t) => !t.read);
    // dispatch(showNoticeDot(panelTypes.task, !_.isEmpty(unRead)));
    // return mockedData.unsolved;
  }
};

export const fetchSolvedTasks = (p, cleanState) => async (dispatch, getState) => {
  try {
    const user = userInfoSelector(getState());
    if (!user) throw Error('No user logged-in');
    const page = (p ?? 1);
    const response = await fetchUserSolvedTasks(user.uid, page);
    const data = response?.data ?? [];
    if (cleanState) dispatch(clearSolvedTasks());
    dispatch(saveSolvedTasks(page, data));
    return data;
  } catch (e) {
    throw e;
    // for testing:
    // await new Promise((resolve) => setTimeout(resolve, 1000)); // THIS IS FOR TESTING
    // if (cleanState) dispatch(clearSolvedTasks());
    // dispatch(saveSolvedTasks(p, mockedData.solved[p]));
    // return mockedData.solved[p];
  }
};

// const mockedData = {
//   unsolved: [
//     {
//       type: 'WAIVE_FEE',
//       subType: 'CANCEL',
//       resolved: false,
//       read: false,
//       creationDate: '2023-03-13T20:23:30Z',
//       expirationDate: '2023-04-12T20:23:30Z',
//       effectiveDate: '2023-03-13T20:23:30Z',
//       header: 'Waive Fee',
//       body: {
//         title: '1300 Lafayette East Coop',
//         datetime: '2023-03-09T21:00:00Z',
//         createdBy: 'Shawn Ashworth',
//         text: '',
//         tz: {
//           value: 'America/Los_Angeles',
//           label: '(GMT-8:00) Pacific Time',
//           offset: -8,
//           abbrev: 'PST',
//           altname: 'Pacific Standard Time',
//         },
//       },
//       details: {
//         description: '',
//         orderID: 'vjYU5vrTkFYKD1FZuUht',
//         fee: 681.49,
//         waiveReason: 'no-show',
//         waiveNotes: 'dwedwed',
//         location: {
//           city: 'Detroit, MI',
//           zip: '48207-2905',
//           locID: '045P',
//         },
//         contact: {
//           name: 'A A',
//           phone: '',
//           email: 'a@gmail.com',
//         },
//         customer: {
//           name: '1300 Lafayette East Coop',
//           customerID: '0010422829',
//           soldTo: '0010422829',
//         },
//         instructor: {
//           id: 'TpynvOgxVQdSDJDhF2BzFiRuYvy2',
//           firstName: 'ANTHONY',
//           lastName: 'JACKSON',
//         },
//       },
//     },
//     {
//       taskID: '3239u2rhf39ufh394r8u2fg234r2',
//       type: 'WAIVE_FEE',
//       subType: 'CANCEL', // optional field
//       assigneeID: 'ABCDEF',
//       expirationDate: moment.utc().add(30, 'days').toISOString(),
//       effectiveDate: moment.utc().toISOString(),
//       creationDate: moment.utc().toISOString(),
//       resolved: false, // defaults to false at creation
//       read: false,
//       resolutionValue: null, // setup by frontend to keep track of action taken
//       header: 'Waive Fee',
//       body: {
//         title: 'Evonik Corporation',
//         datetime: moment.utc().toISOString(),
//         createdBy: 'Emanuele Dalessandro',
//         text: null, // optional field
//         tz: {
//           abbrev: 'MDT',
//           altname: 'Mountain Daylight Time',
//           label: '(GMT-6:00) Mountain Time',
//           offset: -6,
//           value: 'America/Boise',
//         },
//       },
//       details: {
//         description: '',
//         orderID: 'pShpnqyIbhb1QitQl6gr',
//         fee: 30.50, // optional field
//         waiveReason: 'weather', // optional field
//         waiveNotes: 'waive notes test', // optional field
//         location: { // optional field
//           city: 'Murdock',
//           zip: '67111-8747',
//           locID: '045P',
//           territoryCode: '',
//         },
//         instructor: { // optional field
//           id: '30924u20394823094',
//           firstName: 'Emauele',
//           lastName: 'Dalessandro',
//         },
//         contact: { // optional field
//           contactID: '79299ra0-1ff9-11ed-861d-0920',
//           name: 'Jay Lario',
//           phone: '620297-3222',
//           email: 'jayfsklario@pixius.net',
//         },
//         customer: { // optional field
//           name: 'LARIO OIL & GAS CO.',
//           customerID: '8992099ra0-1ff9-11ed-861d-33',
//           soldTo: '0010668789',
//         },
//       },
//     },
//     {
//       taskID: '33r3r2fr3fg3g3gf',
//       type: 'TERRITORY_CHANGE',
//       // subType: 'CANCEL', // optional field
//       assigneeID: 'ABCDEF',
//       expirationDate: moment.utc().add(30, 'days').toISOString(),
//       effectiveDate: moment.utc().toISOString(),
//       creationDate: moment.utc().toISOString(),
//       resolved: false, // defaults to false at creation
//       read: true,
//       resolutionValue: null, // setup by frontend to keep track of action taken
//       header: 'Territory Change',
//       body: {
//         title: 'Evonik Corporation',
//         datetime: moment.utc().toISOString(),
//         createdBy: 'Emanuele Dalessandro',
//         text: null, // optional field
//         tz: {
//           abbrev: 'MDT',
//           altname: 'Mountain Daylight Time',
//           label: '(GMT-6:00) Mountain Time',
//           offset: -6,
//           value: 'America/Boise',
//         },
//       },
//       details: {
//         description: '',
//         orderID: 'pShpnqyIbhb1QitQl6gr',
//         fee: 30.50, // optional field
//         waiveReason: 'weather', // optional field
//         waiveNotes: 'waive notes test', // optional field
//         location: { // optional field
//           city: 'Murdock',
//           zip: '67111-8747',
//           locID: '045P',
//           territoryCode: '',
//         },
//         instructor: { // optional field
//           id: '30924u20394823094',
//           firstName: 'Emauele',
//           lastName: 'Dalessandro',
//         },
//         contact: { // optional field
//           contactID: '79299ra0-1ff9-11ed-861d-0920',
//           name: 'Jay Lario',
//           phone: '620297-3222',
//           email: 'jayfsklario@pixius.net',
//         },
//         customer: { // optional field
//           name: 'LARIO OIL & GAS CO.',
//           customerID: '8992099ra0-1ff9-11ed-861d-33',
//           soldTo: '0010668789',
//         },
//       },
//     },
//     {
//       taskID: '3239u2rhf39ufh394r8u3432',
//       type: 'MISSING_TRAINER',
//       // subType: 'CANCEL', // optional field
//       assigneeID: 'ABCDEF',
//       expirationDate: moment.utc().add(30, 'days').toISOString(),
//       effectiveDate: moment.utc().add(-20, 'days').toISOString(),
//       creationDate: moment.utc().add(-20, 'days').toISOString(),
//       resolved: false, // defaults to false at creation
//       read: true,
//       resolutionValue: null, // setup by frontend to keep track of action taken
//       header: 'Missing Trainer',
//       body: {
//         title: 'Evonik Corporation',
//         datetime: moment.utc().add(-20, 'days').toISOString(),
//         createdBy: 'Anthony Jackson',
//         text: null, // optional field
//         tz: {
//           abbrev: 'MDT',
//           altname: 'Mountain Daylight Time',
//           label: '(GMT-6:00) Mountain Time',
//           offset: -6,
//           value: 'America/Boise',
//         },
//       },
//       details: {
//         description: '',
//         orderID: 'pShpnqyIbhb1QitQl6gr',
//         fee: 30.50, // optional field
//         waiveReason: 'weather', // optional field
//         waiveNotes: 'waive notes test', // optional field
//         location: { // optional field
//           city: 'Murdock',
//           zip: '67111-8747',
//           locID: '045P',
//           territoryCode: '',
//         },
//         instructor: { // optional field
//           id: '30924u20394823094',
//           firstName: 'Anthony',
//           lastName: 'Jackson',
//         },
//         contact: { // optional field
//           contactID: '79299ra0-1ff9-11ed-861d-0920',
//           name: 'Jay Lario',
//           phone: '620297-3222',
//           email: 'jayfsklario@pixius.net',
//         },
//         customer: { // optional field
//           name: 'LARIO OIL & GAS CO.',
//           customerID: '8992099ra0-1ff9-11ed-861d-33',
//           soldTo: '0010668789',
//         },
//       },
//     },
//     {
//       taskID: '3239u2rhf39ufh394r8u3432',
//       type: 'MISSING_TRAINER',
//       // subType: 'CANCEL', // optional field
//       assigneeID: 'ABCDEF',
//       expirationDate: moment.utc().add(30, 'days').toISOString(),
//       effectiveDate: moment.utc().toISOString(),
//       creationDate: moment.utc().toISOString(),
//       resolved: false, // defaults to false at creation
//       read: false,
//       resolutionValue: null, // setup by frontend to keep track of action taken
//       header: 'Missing Trainer',
//       body: {
//         title: 'Evonik Corporation',
//         datetime: moment.utc().toISOString(),
//         createdBy: 'Anthony Jackson',
//         text: null, // optional field
//         tz: {
//           value: 'America/Los_Angeles',
//           label: '(GMT-8:00) Pacific Time',
//           offset: -8,
//           abbrev: 'PST',
//           altName: 'Pacific Standard Time',
//         },
//       },
//       details: {
//         description: '',
//         orderID: 'pShpnqyIbhb1QitQl6gr',
//         fee: 30.50, // optional field
//         waiveReason: 'weather', // optional field
//         waiveNotes: 'waive notes test', // optional field
//         location: { // optional field
//           city: 'Murdock',
//           zip: '67111-8747',
//           locID: '045P',
//           territoryCode: '',
//         },
//         instructor: { // optional field
//           id: '30924u20394823094',
//           firstName: 'Anthony',
//           lastName: 'Jackson',
//         },
//         contact: { // optional field
//           contactID: '79299ra0-1ff9-11ed-861d-0920',
//           name: 'Jay Lario',
//           phone: '620297-3222',
//           email: 'jayfsklario@pixius.net',
//         },
//         customer: { // optional field
//           name: 'LARIO OIL & GAS CO.',
//           customerID: '8992099ra0-1ff9-11ed-861d-33',
//           soldTo: '0010668789',
//         },
//       },
//     },
//   ],
//   solved: {
//     1: [
//       {
//         taskID: '33r3wefwfwefewfwefw',
//         type: 'TERRITORY_CHANGE',
//         // subType: 'CANCEL', // optional field
//         assigneeID: 'ABCDEF',
//         expirationDate: moment.utc().add(30, 'days').toISOString(),
//         effectiveDate: moment.utc().toISOString(),
//         creationDate: moment.utc().toISOString(),
//         resolved: true, // defaults to false at creation
//         read: true,
//         resolutionValue: '923481034710941::Anthony Jackson', // setup by frontend to keep track of action taken
//         header: 'Territory Change',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: moment.utc().toISOString(),
//           createdBy: 'Emanuele Dalessandro',
//           text: null, // optional field
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//         },
//         details: {
//           description: '',
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           fee: 30.50, // optional field
//           waiveReason: 'weather', // optional field
//           waiveNotes: 'waive notes test', // optional field
//           location: { // optional field
//             city: 'Murdock',
//             zip: '67111-8747',
//             locID: '045P',
//             territoryCode: '',
//           },
//           instructor: { // optional field
//             id: '30924u20394823094',
//             firstName: 'Emauele',
//             lastName: 'Dalessandro',
//           },
//           contact: { // optional field
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: { // optional field
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         taskID: '3239u2rhf39ufh394r8u2r33r3',
//         type: 'WAIVE_FEE',
//         // subType: 'CANCEL', // optional field
//         assigneeID: 'ABCDEF',
//         expirationDate: moment.utc().add(30, 'days').toISOString(),
//         effectiveDate: moment.utc().toISOString(),
//         creationDate: moment.utc().toISOString(),
//         resolved: true, // defaults to false at creation
//         read: true,
//         resolutionValue: 'ACCEPTED', // setup by frontend to keep track of action taken
//         header: 'Waive Fee',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: moment.utc().toISOString(),
//           createdBy: 'Emanuele Dalessandro',
//           text: null, // optional field
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//         },
//         details: {
//           description: '',
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           fee: 30.50, // optional field
//           waiveReason: 'weather', // optional field
//           waiveNotes: 'waive notes test', // optional field
//           location: { // optional field
//             city: 'Murdock',
//             zip: '67111-8747',
//             locID: '045P',
//             territoryCode: '',
//           },
//           instructor: { // optional field
//             id: '30924u20394823094',
//             firstName: 'Emauele',
//             lastName: 'Dalessandro',
//           },
//           contact: { // optional field
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: { // optional field
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         taskID: '33r32r12r12re23er23r',
//         type: 'TERRITORY_CHANGE',
//         // subType: 'CANCEL', // optional field
//         assigneeID: 'ABCDEF',
//         expirationDate: moment.utc().add(30, 'days').toISOString(),
//         effectiveDate: moment.utc().toISOString(),
//         creationDate: moment.utc().toISOString(),
//         resolved: true, // defaults to false at creation
//         read: true,
//         resolutionValue: '923481034710941::Anthony Jackson', // setup by frontend to keep track of action taken
//         header: 'Territory Change',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: moment.utc().toISOString(),
//           createdBy: 'Emanuele Dalessandro',
//           text: null, // optional field
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//         },
//         details: {
//           description: '',
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           fee: 30.50, // optional field
//           waiveReason: 'weather', // optional field
//           waiveNotes: 'waive notes test', // optional field
//           location: { // optional field
//             city: 'Murdock',
//             zip: '67111-8747',
//             locID: '045P',
//             territoryCode: '',
//           },
//           instructor: { // optional field
//             id: '30924u20394823094',
//             firstName: 'Emauele',
//             lastName: 'Dalessandro',
//           },
//           contact: { // optional field
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: { // optional field
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//       {
//         taskID: '33r32fr123414r324',
//         type: 'MISSING_TRAINER',
//         // subType: 'CANCEL', // optional field
//         assigneeID: 'ABCDEF',
//         expirationDate: moment.utc().add(30, 'days').toISOString(),
//         effectiveDate: moment.utc().toISOString(),
//         creationDate: moment.utc().toISOString(),
//         resolved: true, // defaults to false at creation
//         read: true,
//         resolutionValue: '923481034710941::Anthony Jackson', // setup by frontend to keep track of action taken
//         header: 'Missing Trainer',
//         body: {
//           title: 'Evonik Corporation',
//           datetime: moment.utc().toISOString(),
//           createdBy: 'Emanuele Dalessandro',
//           text: null, // optional field
//           tz: {
//             abbrev: 'MDT',
//             altname: 'Mountain Daylight Time',
//             label: '(GMT-6:00) Mountain Time',
//             offset: -6,
//             value: 'America/Boise',
//           },
//         },
//         details: {
//           description: '',
//           orderID: 'pShpnqyIbhb1QitQl6gr',
//           fee: 30.50, // optional field
//           waiveReason: 'weather', // optional field
//           waiveNotes: 'waive notes test', // optional field
//           location: { // optional field
//             city: 'Murdock',
//             zip: '67111-8747',
//             locID: '045P',
//             territoryCode: '',
//           },
//           instructor: { // optional field
//             id: '30924u20394823094',
//             firstName: 'Emauele',
//             lastName: 'Dalessandro',
//           },
//           contact: { // optional field
//             contactID: '79299ra0-1ff9-11ed-861d-0920',
//             name: 'Jay Lario',
//             phone: '620297-3222',
//             email: 'jayfsklario@pixius.net',
//           },
//           customer: { // optional field
//             name: 'LARIO OIL & GAS CO.',
//             customerID: '8992099ra0-1ff9-11ed-861d-33',
//             soldTo: '0010668789',
//           },
//         },
//       },
//     ],
//   },
// };
