import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  reduxForm,
  submit,
} from 'redux-form';

const EventDetailsDialogContent = withStyles((theme) => ({
  root: {
    minWidth: 950,
    maxWidth: 950,
    minHeight: 700,
    maxHeight: 700,
    overflow: 'visible',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      minWidth: 50,
    },
  },
}))(MuiDialogContent);

const EventDetailsDialogWrapper = ({ children }) => (
  <EventDetailsDialogContent>
    {children}
  </EventDetailsDialogContent>
);

export default _.flow([
  connect(),
  reduxForm({
    form: 'EventDetailsDialog',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {},
    onSubmit: submit,
  }),
])(EventDetailsDialogWrapper);
