import React from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { COLORS } from '../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cell: {
    width: '20%',
  },
}));

const getMuiTheme = (root, bodyCell) => () => createMuiTheme(
  {
    overrides: {
      MUIDataTable: {
        root: {
          zIndex: 1,
        },
        paper: {
          maxWidth: 'calc(100vw - 80px)',
          zIndex: 1,
        },
        responsiveBase: {
          zIndex: 1,
        },
      },
      MUIDataTableBodyCell: {
        root: {
          padding: 8,
        },
      },
      MuiTableCell: {
        root: bodyCell,
      },
      MuiIconButton: {
        root: {
          color: COLORS.CINTAS_BLUE,
        },
      },
      MUIDataTableFilterList: {
        root: {
          paddingBottom: 10,
        },
      },
      MUIDataTableHeadCell: {
        root,
        data: {
          color: COLORS.CINTAS_WHITE,
          fontSize: 16,
          textTransform: 'uppercase',
        },
        fixedHeader: {
          backgroundColor: COLORS.CINTAS_BLUE,
          color: COLORS.CINTAS_WHITE,
        },
        toolButton: {
          color: COLORS.CINTAS_WHITE,
        },
        sortActive: {
          color: COLORS.CINTAS_WHITE,
        },
      },
      MuiTableSortLabel: {
        root: {
          color: COLORS.CINTAS_WHITE,
          // if you want to have icons visible permanently
          // '& $icon': {
          //   opacity: 1,
          //   color: COLORS.CINTAS_WHITE
          // },
          '&:hover': {
            color: COLORS.CINTAS_WHITE,

            '&& $icon': {
              opacity: 1,
              color: COLORS.CINTAS_WHITE,
            },
          },
          '&$active': {
            color: COLORS.CINTAS_WHITE,
            // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
            '&& $icon': {
              opacity: 1,
              color: COLORS.CINTAS_WHITE,
            },
          },
        },
      },
    },
  },
);

const FASMUIDataTable = (props) => {
  const {
    data,
    columns,
    options,
    width,
    root,
    bodyCell,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}> */}
      <MuiThemeProvider theme={getMuiTheme(root, bodyCell)}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
          width={width || '100%'}
        />
      </MuiThemeProvider>
      {/* </Paper> */}
    </div>
  );
};

export default FASMUIDataTable;
