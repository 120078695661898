import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CollapseIcon from '@mui/icons-material/MinimizeRounded';
import DragHandle from '@mui/icons-material/DragIndicatorRounded';
import ExpandIcon from '@mui/icons-material/OpenInFullRounded';

const styles = (theme) => ({
  root: {
    margin: 0,
    background: '#012169',
    padding: theme.spacing(0),
    borderRadius: '0',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  header: {
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
    padding: theme.spacing(1),
    borderRadius: '0',
  },
});

const DialogHeader = withStyles(styles)((props) => {
  const {
    children, classes, onClose, draggable, ...other
  } = props;
  const { onCollapse, collapsed } = (draggable || {});

  const draggableHandle = () => (
    <IconButton aria-label="drag" className={classes.closeButton} id="alert-dialog-slide-title" style={{ float: 'right' }}>
      <DragHandle style={{ cursor: 'grab' }} />
    </IconButton>
  );

  const fullscreenIconButton = () => (
    <IconButton onClick={() => (onCollapse ? onCollapse(false) : undefined)} className={classes.closeButton} style={{ float: 'right', paddingRight: 4 }}>
      <ExpandIcon style={{ fontSize: 22 }} />
    </IconButton>
  );

  const minimizeScreenIconButton = () => (
    <IconButton onClick={() => (onCollapse ? onCollapse(true) : undefined)} className={classes.closeButton} style={{ float: 'right' }}>
      <CollapseIcon style={{ cursor: 'pointer' }} />
    </IconButton>
  );

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography className={classes.header} variant="h6">{children}</Typography>
      {draggable && !collapsed && minimizeScreenIconButton()}
      {draggable && collapsed && draggableHandle()}
      {draggable && collapsed && fullscreenIconButton()}
    </MuiDialogTitle>
  );
});

export default DialogHeader;
