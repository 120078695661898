/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Field, initialize, reduxForm,
} from 'redux-form';
import { MenuItem } from '@material-ui/core';
import { adminTypeSelector, userInfoSelector } from '../../redux/selectors/user.selectors';
import UserAccountCard from './UserAccountCard';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import Container from '../LayoutBuilders/Container';
import {
  NOTIFICATION_USER_SETTINGS, NOTIFICATION_USER_SETTING_TYPES,
  NOTIFICATION_USER_SETTINGS_DEFAULTS,
  NOTIFICATION_USER_SETTINGS_LABEL,
  NOTIFICATION_TYPE,
  NOTIFICATIONS_LABELS,
  NOTIFICATION_ADMIN_SETTINGS,
} from '../../utils/consts/notifications.consts';
import FASSelectField from '../Forms/CustomFormComponents/FASSelectField';
import SimpleText from '../Text/SimpleText';
import { updateCurrentUserNotificationsSettings } from '../../redux/actions/settings.actions';
import { ROLE_ACCESSES } from '../../utils/consts';
import { notificationsConfigSelector } from '../../redux/selectors/settings.selectors';

const formName = 'UserNotificationSettingsForm';

const UserNotificationSettings = ({
  minHeight, initialState, dispatch, isAdvancedAdmin, notificationsConfigs,
}) => {
  const isAdminRestricted = (type) => notificationsConfigs?.[type]?.userMaintained === NOTIFICATION_ADMIN_SETTINGS.userMaintained.ADMIN;

  const updateNotificationsSetting = (formVals) => {
    const payload = JSON.parse(
      JSON.stringify(formVals ?? {})
        .replaceAll('true', true)
        .replaceAll('false', false),
    );
    dispatch(updateCurrentUserNotificationsSettings(payload));
  };

  React.useEffect(() => {
    const initial = { ...(initialState ?? {}) };
    Object.keys(NOTIFICATION_TYPE).forEach((settingKey) => {
      if (!initial.hasOwnProperty(settingKey)) {
        initial[settingKey] = Object.keys(NOTIFICATION_USER_SETTINGS).reduce((red, settingProp) => ({ ...red, [settingProp]: NOTIFICATION_USER_SETTINGS_DEFAULTS[settingProp] }), {});
      }
    });
    /* initial = JSON.parse(
      JSON.stringify(initial ?? {})
        .replaceAll(true, `"${NOTIFICATION_USER_SETTINGS.notifyByEmail.YES}"`)
        .replaceAll(false, `"${NOTIFICATION_USER_SETTINGS.notifyByEmail.NO}"`),
    ); */
    dispatch(initialize(formName, initial));
    updateNotificationsSetting(initial);
    if (initialState && _.isEmpty(initialState)) {
      // user doesnt have a notifications settings object setup at all yet. Save the defaults to his account
      updateNotificationsSetting(initial);
    }
  }, []);

  const onChangeNotificationSetting = (settingKey, settingProp, value) => {
    updateNotificationsSetting({
      [settingKey]: {
        [settingProp]: value,
      },
    });
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const menuItem = (opt) => (
    <MenuItem key={opt.key} value={opt.value}>
      {opt.label}
    </MenuItem>
  );

  const dropdown = (settingKey, settingProp) => {
    const fieldId = `${settingKey}.${settingProp}`;
    const options = Object.keys(NOTIFICATION_USER_SETTINGS[settingProp]).map((optKey) => (
      { key: optKey, value: NOTIFICATION_USER_SETTINGS[settingProp][optKey], label: optKey }));
    return item({
      flex: 3,
      style: { height: '100%' },
      children:
        (NOTIFICATION_USER_SETTING_TYPES[settingKey][settingProp] ? (
          <Field
            variant="outlined"
            label={NOTIFICATION_USER_SETTINGS_LABEL[settingProp]}
            id={fieldId}
            name={fieldId}
            component={FASSelectField}
            displayEmpty
            style={{ textAlign: 'start', borderRadius: 0, width: '100%' }}
            onChange={(val) => onChangeNotificationSetting(settingKey, settingProp, val)}
          >
            {options.map((opt) => menuItem(opt))}
          </Field>
        )
          : <></>
        ),
    });
  };

  const settingKeyLabel = (key) => item({
    flex: 3,
    children: text(NOTIFICATIONS_LABELS[key] ?? '', {
      style: {
        fontWeight: 'bold',
        fontSize: 16,
      },
    }),
  });

  return (
    <div style={{ marginTop: 10 }}>
      <UserAccountCard title="Notification Settings" minHeight={minHeight}>
        {container({
          style: { padding: 20, paddingTop: 32 },
          children: [
            Object.keys(NOTIFICATION_TYPE).filter((type) => isAdvancedAdmin || !isAdminRestricted(type)).map((settingKey) => item({
              flex: 12,
              children: container({
                style: { justifyContent: 'flex-start', alignItems: 'center', height: '100%' },
                spacing: 2,
                children: [
                  settingKeyLabel(settingKey),
                  ...Object.keys(NOTIFICATION_USER_SETTINGS).map((settingProp) => dropdown(settingKey, settingProp)),
                ],
              }),
            })),
          ],
        })}
      </UserAccountCard>
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    initialState: userInfoSelector(state)?.notificationSettings,
    isAdvancedAdmin: adminTypeSelector(state) === ROLE_ACCESSES.advancedAdmin,
    notificationsConfigs: notificationsConfigSelector(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(UserNotificationSettings);
