import { NavLink, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import Permission from '../Permission/Permission';

const TopBarButton = (props) => {
  const {
    title,
    path,
    icon,
    index,
    whitelist,
    navOverride,
  } = props;

  const navLinkWrapper = (child) => (
    <NavLink
      exact
      to={path}
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
      }}
    >
      {child}
    </NavLink>
  );

  const iconBtn = () => (
    <div style={{ width: '100%' }}>
      <Tooltip title={title} arrow>
        <IconButton
          edge="end"
          color="primary"
          onClick={navOverride}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Permission whitelist={whitelist}>
      <div
        style={{ width: '100%' }}
        role="button"
        tabIndex={index}
      >
        {navOverride ? iconBtn() : navLinkWrapper(iconBtn())}
      </div>
    </Permission>
  );
};

export default withRouter(TopBarButton);
