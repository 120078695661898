/* eslint-disable react/jsx-no-bind */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React from 'react';

import ForgotPasswordForm from '../../../components/Forms/ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from '../../../components/Forms/LoginForm/LoginForm';

import * as AuthActions from '../../../redux/actions/auth.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import LoginWireFrame from './LoginWireFrame';
import SignUpText from '../../../utils/consts/StaticText/SignUpText';
import SimpleText from '../../../components/Text/SimpleText';
import PasswordForm from '../../../components/Forms/PasswordForm/PasswordForm';

class LoginView extends React.Component {
  constructor(props) {
    super(props);
    const { history } = props;
    if (localStorage.authenticated) {
      history.push('/home');
    }
  }

  componentDidMount() {
    // document.addEventListener('keydown', this.onLoginPressed, false);
  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.onLoginPressed, false);
  }

  // async onForgotPasswordSubmit(value) {
  //   const { dispatch } = this.props;
  //   dispatch(UtilsActions.setSpinnerVisible(true));
  //   try {
  //     await dispatch(AuthActions.forgotPassword(value));
  //     dispatch(ModalsActions.hideModal('FORGOT_PASSWORD_MODAL'));
  //     dispatch(ModalsActions.showModal('FORGOT_PASSWORD_SUCCESS_MODAL', {
  //       modalType: 'SUCCESS_ALERT',
  //       modalProps: {
  //         message: 'We\'ve sent you an email with the instructions on how to reset yout password.',
  //       },
  //     }));
  //   } catch (error) {
  //     let message = 'Something happened during the form submission. Please, try again!';
  //     if (error.response && error.response.status === 404) {
  //       message = 'Email not found! If you forgot your email, please contact the platform administrators.';
  //     }
  //     dispatch(ModalsActions.showModal('FORGOT_PASSWORD_ERROR_MODAL', {
  //       modalType: 'ERROR_ALERT',
  //       modalProps: { message },
  //     }));
  //   } finally {
  //     dispatch(UtilsActions.setSpinnerVisible(false));
  //   }
  // }

  async onLinkAzureID(email, password, credentials) {
    const { history, dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await AuthActions.linkAzureID(email, password, credentials);
      dispatch(ModalsActions.hideModal('PASSWORD_MODAL'));
      dispatch(ModalsActions.showModal('LINK_SUCCESS_ALERT', {
        modalType: 'SUCCESS_ALERT',
        modalProps: { message: 'Accounts linked successfully' },
      }));
      history.push('/home');
      return;
    } catch (error) {
      let message = 'Something happened during the form submission. Please, try again!';
      if (error.response && error.response.status === 404) {
        message = 'Password is incorrect. If you gorgot your account password, please contact the platform administrators.';
      }
      dispatch(ModalsActions.showModal('WRONG_PASSWORD_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  showPasswordModal(email, credentials) {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('PASSWORD_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        maxWidth: 'sm',
        hideCancel: true,
        title: 'Existing account found',
        bodyText: 'We found an existing account linked to this email, please enter your current FAS password to link the accounts together.',
        content: (
          <PasswordForm
            onSubmit={(values) => this.onLinkAzureID(email, values.password, credentials)}
          />
        ),
      },
    }));
  }

  async onLoginSubmit(values) {
    const { history, dispatch } = this.props;
    const { email, password } = values;
    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await dispatch(AuthActions.login(email, password));
      history.push('/home');
      return;
    } catch (error) {
      this.showSignInErrorDialog();
    } finally {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async signInWithAzureID() {
    const { history, dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await dispatch(AuthActions.signInWithAzureID());
      history.push('/home');
      return;
    } catch (error) {
      if (error.email && error.credential && error.code === 'auth/account-exists-with-different-credential') {
        this.showPasswordModal(error.email, error.credential);
      } else this.showSignInErrorDialog(true);
      // console.log(error);
    } finally {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  signUpAlert(withAzureID) {
    return (
      <div style={{
        maxWidth: 400,
        maxHeight: 200,
        padding: 24,
        textAlign: 'start',
      }}
      >
        <SimpleText
          txt={withAzureID ? 'Error while authenticating with Azure ID, make sure your user has access to Training Central and please try again.' : SignUpText.emailSent.body}
          color="black"
          style={{
            fontSize: 16,
            textAlign: 'start',
          }}
        />
      </div>
    );
  }

  showSignInErrorDialog(withAzureID = false) {
    const { dispatch } = this.props;
    const modalName = 'SIGNUP_MODAL';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: true,
        maxWidth: 'sm',
        title: withAzureID ? 'Login Error' : SignUpText.emailSent.header,
        confirmText: 'Okay',
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(modalName));
        },
        content: this.signUpAlert(withAzureID),
      },
    }));
  }

  showForgotPasswordModal() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal('FORGOT_PASSWORD_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        maxWidth: 'sm',
        hideCancel: true,
        title: 'Reset your password',
        bodyText: 'Enter the email you used to register, and we will send you and email to create a new password.',
        content: (
          <ForgotPasswordForm
            onConfirm={(email) => this.onForgotPasswordSubmit(email)}
          />
        ),
      },
    }));
  }

  render() {
    const { history } = this.props;
    return (
      <LoginWireFrame
        title="Sign In"
        renderChildren={(classes) => (
          <LoginForm
            classes={classes}
            history={history}
            showSignInErrorDialog={this.showSignInErrorDialog.bind(this)}
            onSubmit={(values) => this.onLoginSubmit(values)}
            onSignInWithAzureID={() => this.signInWithAzureID()}
          />
        )}
      />
    );
  }
}

export default _.flow([
  connect(),
  withRouter,
])(LoginView);
