import React from 'react';
import { COLORS } from '../../utils/consts';

const CancelledCourseIcon = ({
  size = 15,
}) => (
  <div style={{
    display: 'inline-block',
    fontSize: size * 0.72,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 'normal',
    height: size,
    width: size,
    color: COLORS.CINTAS_RED,
    border: `${size * 0.1}px solid ${COLORS.CINTAS_RED}`,
  }}
  >
    X
  </div>
);

export default CancelledCourseIcon;
