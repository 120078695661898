/* eslint-disable max-len */
import Immutable from 'seamless-immutable';

import {
  SAVE_USERS, RESET_USERS_STATE, SAVE_CURRENT_USER, SAVE_TCIS, SAVE_CURR_TCI, SET_LOADING_TCI_EVENTS,
} from '../actions/actionTypes/users';

const initialState = Immutable({
  data: {
    content: [],
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USERS:
      return state.setIn(['data', 'content'], action.users);
    case SAVE_CURRENT_USER:
      return state.setIn(['data', 'currentUser'], action.user);
    case SAVE_TCIS:
      return state.setIn(['data', 'reportingTcis'], action.tcis);
    case SAVE_CURR_TCI:
      return state.setIn(['data', 'selectedTci'], action.tci);
    case SET_LOADING_TCI_EVENTS:
      return state.setIn(['data', 'loadingTciEvents'], action.loading);
    case RESET_USERS_STATE:
      return initialState;
    default:
      return state;
  }
};
