/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import NationalAccountIcon from '@mui/icons-material/Language';
// import StoreFront from '@material-ui/icons/StorefrontRounded';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import _ from 'lodash';
import CustomerDashboardIcon from '../assets/images/CustomerDashboardIcon.svg';
import Calendar from './MainViews/CalendarView';
import NationalAccount from './MainViews/NationalAccountView';
import CertificationDetails from './MainViews/SettingsViews/CertificationDetailsView';
import Certifications from './MainViews/SettingsViews/CertificationsView';
import AddonClassifications from './MainViews/SettingsViews/AddonClassificationsView';
import LocationDetails from './MainViews/SettingsViews/LocationDetailsView';
import Locations from './MainViews/SettingsViews/LocationsView';
import MaterialDetails from './MainViews/SettingsViews/CourseDetailsView';
import Materials from './MainViews/SettingsViews/MaterialsView';
import OtherSettings from './MainViews/SettingsViews/OtherSettingsView';
import Orders from './MainViews/SettingsViews/OrdersView';
import Customers from './MainViews/CustomersDashboardView';
import AddonClassificationsDetails from './MainViews/SettingsViews/AddonClassificationsDetails';
import AccessMatrix from './MainViews/SettingsViews/AccessMatrix/AccessMatrixView';
import DefaultsSettingsView from './MainViews/SettingsViews/DefaultSettings/DefaultsSettingsView';
import { ADMIN_ACCESSES, ADMIN_ROLE_ACCESSES, ROLE_ACCESSES } from '../utils/consts';
import { adminTypeSelector, hasAdminAccessSelector, hasUserAccessSelector } from '../redux/selectors/user.selectors';
import DataLookUpView from './MainViews/SettingsViews/DataLookUpView';
import NotificationsAdminSettingsView from './MainViews/SettingsViews/NotificationsTasksAdminSettings/NotificationsTasksAdminSettingsView';

export const mainSections = [
  {
    title: 'Calendar',
    icon: <CalendarTodayIcon />,
    path: '/home',
    component: Calendar,
    exact: true,
    matrixAccesskeys: Object.values(ROLE_ACCESSES),
  },
  {
    title: 'Customer Dashboard',
    icon: <img style={{ height: 22, width: 20 }} src={CustomerDashboardIcon} alt="CustomerDashboardIcon" />,
    path: '/customersDashboard',
    component: Customers,
    matrixAccesskeys: [ROLE_ACCESSES.customerDashboard],
  },
  {
    title: 'National Accounts',
    icon: <NationalAccountIcon />,
    path: '/nationalAccount',
    component: NationalAccount,
    matrixAccesskeys: [ROLE_ACCESSES.viewNationalAccountPanel],
  },
  {
    title: 'Admin',
    icon: <SettingsIcon />,
    path: '/admin',
    component: Materials,
    matrixAccesskeys: [...ADMIN_ROLE_ACCESSES, ROLE_ACCESSES.viewOrdersPanel],
  },
];

// ADMIN role below -- Handle other roles in above sections.
const landingPagePaths = ['/'];

export const adminSections = (landingKey = 'orders') => [
  {
    key: 'orders',
    title: 'Admin | Orders',
    path: ['/orders', ...(landingKey && landingKey === 'orders' ? landingPagePaths : [])],
    component: Orders,
    matrixAccesskeys: [ROLE_ACCESSES.viewOrdersPanel],
  },
  {
    key: 'materials',
    title: 'Admin | Materials',
    path: ['/materials', ...(landingKey && landingKey === 'materials' ? landingPagePaths : [])],
    component: Materials,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.materials],
  },
  {
    key: 'training',
    title: 'Admin | Training Sites',
    path: ['/sites', ...(landingKey && landingKey === 'training' ? landingPagePaths : [])],
    component: Locations,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.createTrainingSites],
  },
  {
    key: 'certifications',
    title: 'Admin | Certifications',
    path: ['/certifications', ...(landingKey && landingKey === 'certifications' ? landingPagePaths : [])],
    component: Certifications,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.tciCert],
  },
  {
    key: 'addonsClassification',
    title: 'Admin | Add-on Classifications',
    path: ['/addonClassifications', ...(landingKey && landingKey === 'addonsClassification' ? landingPagePaths : [])],
    component: AddonClassifications,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.categories],
  },
  {
    key: 'addonsClassificationDetails',
    title: 'Admin | Add-on Classifications',
    path: ['/addonClassifications/:categoryID', ...(landingKey && landingKey === 'addonsClassificationDetails' ? landingPagePaths : [])],
    component: AddonClassificationsDetails,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.categories],
  },
  {
    key: 'matrix',
    title: 'Admin | Access Matrix',
    path: ['/accessMatrix', ...(landingKey && landingKey === 'matrix' ? landingPagePaths : [])],
    component: AccessMatrix,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.matrix],
  },
  {
    key: 'defaults',
    title: 'Admin | Defaults',
    path: ['/adminDefaults', ...(landingKey && landingKey === 'defaults' ? landingPagePaths : [])],
    component: DefaultsSettingsView,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.matrix],
  },
  {
    key: 'dataLookUp',
    title: 'Admin | Data Look Up',
    path: ['/dataLookUp', ...(landingKey && landingKey === 'dataLookUp' ? landingPagePaths : [])],
    component: DataLookUpView,
    matrixAccesskeys: [ROLE_ACCESSES.advancedAdmin],
  },
  {
    key: 'notificationAdminSettings',
    title: 'Admin | Notifications & Tasks Settings',
    path: ['/notificationSettings', ...(landingKey && landingKey === 'notificationAdminSettings' ? landingPagePaths : [])],
    component: NotificationsAdminSettingsView,
    matrixAccesskeys: [ROLE_ACCESSES.advancedAdmin],
  },
  {
    key: 'other',
    title: 'Admin | Other Settings',
    path: ['/other', ...(landingKey && landingKey === 'other' ? landingPagePaths : [])],
    component: OtherSettings,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
  },
  {
    key: 'location',
    title: 'Admin | Location Settings',
    path: ['/sites/:site', ...(landingKey && landingKey === 'location' ? landingPagePaths : [])],
    component: LocationDetails,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.createTrainingSites],
  },
  {
    key: 'materialsDetails',
    title: 'Admin | Material Settings',
    path: ['/materials/:salesOrg/:material/:isDeleted', ...(landingKey && landingKey === 'materialsDetails' ? landingPagePaths : [])],
    component: MaterialDetails,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.materials],
  },
  {
    key: 'certificationsSettings',
    title: 'Admin | Certification Settings',
    path: ['/certifications/:certification', ...(landingKey && landingKey === 'certificationsSettings' ? landingPagePaths : [])],
    component: CertificationDetails,
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.tciCert],
  },
];

export const findLandingAdminSection = (accessMatrix) => {
  if (Object.keys(accessMatrix ?? {}).length === 0) return undefined;
  const adminType = adminTypeSelector(null, accessMatrix);
  const shouldCheckAdminRole = (section) => adminType && !_.isEmpty(section.adminAccessKeys);
  const i = adminSections().findIndex((s) => (shouldCheckAdminRole(s) ? hasAdminAccessSelector(undefined, s.adminAccessKeys, accessMatrix) : hasUserAccessSelector(undefined, s.matrixAccesskeys, accessMatrix)));
  if (i === -1) return undefined;
  return adminSections()[i].key;
};
