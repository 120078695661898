/* eslint-disable no-case-declarations */
import _ from 'lodash';
import Immutable from 'seamless-immutable';

import {
  HIDE_MODAL,
  SHOW_MODAL,
  CLOSING_CALLBACK,
  REMOVE_CLOSING_CALLBACK,
} from '../actions/actionTypes/modals';

const initialState = Immutable({
  data: [],
  callbacks: {},
});

export const CLOSING_FEEDBACK_MODAL = 'CLOSING_FEEDBACK_MODAL';

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return state.set('data', state.data.concat([{ id: action.id, data: action.data }]));
    case HIDE_MODAL:
      let close = true;
      let closingInfo;
      if (state.callbacks?.[action.id]) {
        closingInfo = state.callbacks[action.id](state);
        close = closingInfo.canClose;
      }
      if (close) {
        return state.set('data', _.filter(state.data, (modal) => modal.id !== action.id)).setIn(['callbacks', action.id], undefined);
      }
      if (!close && closingInfo?.feedbackData) {
        return state.set('data', state.data.concat([{ id: CLOSING_FEEDBACK_MODAL, data: closingInfo.feedbackData }]));
      }
      return state;
    case CLOSING_CALLBACK:
      return state.setIn(['callbacks', action.id], action.callback);
    case REMOVE_CLOSING_CALLBACK:
      return state.setIn(['callbacks', action.id], undefined);
    default:
      return state;
  }
};
