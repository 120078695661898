import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { COLORS } from '../../../utils/consts';

const BaseEventWrapper = ({
  children, title, footer, showWarning,
}) => (
  <div>
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <span>
          {title}
        </span>
        <span>
          {showWarning && <WarningIcon sx={{ color: COLORS.CINTAS_RED, fontSize: 15 }} />}
        </span>
      </div>
      {children}
    </div>
    {footer}
  </div>
);

export default BaseEventWrapper;
