/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React from 'react';
import { change } from 'redux-form';
import { Box, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import OrderSummary from '../../../../OrderSummary/OrderSummary';
import { getOSEventInfo } from '../../../../../routes/MainViews/EventSummaryView/EventSummaryView';
import CartSummaryForm, { cleanRows } from '../../EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import {
  COLORS, EVENT_TYPE, ROLE_ACCESSES, // DEFAULT_TRUCK_NUMBER, TRUCK_NUM_VALIDATION_REGEX, SPECIAL_TRUCK_NUMS,
} from '../../../../../utils/consts';
import EditBoxButton from './EditBoxButton';
import { updateOrder } from '../../../../../redux/actions/orders.actions';
import { showModal } from '../../../../../redux/actions/modals.actions';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import StyledButton from '../../../../Button/StyledButton';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import { hasUserAccessSelector } from '../../../../../redux/selectors/user.selectors';
import { fetchPricingForMaterials } from '../../../../../redux/actions/pricing.actions';
import { materialsDataSelector } from '../../../../../redux/selectors/materials.selector';

const BillingSummaryTab = ({
  order, dispatch, reloadOrder, restoreOriginalOrder,
  history, selectedTimezone, selectedTimezoneCode, formName, values,
  validateTruckNumbers, tzOverride,
}) => {
  const formPath = 'billingSummary';
  const editingFormPath = 'editingBillSummary';
  const priceListingsFormPath = 'priceListings';
  const [saving, setSaving] = React.useState(false);
  const [editing, setEditing] = React.useState(values[editingFormPath]);
  const [preparingEdit, setPreparingEdit] = React.useState(false);
  const subcontracted = order?.subcontracted || order?.events?.[0]?.subcontracted;
  const canEdit = useSelector((state) => hasUserAccessSelector(state, [subcontracted ? ROLE_ACCESSES.subcontractorEvents : ROLE_ACCESSES.editEvent]));
  const hasEditDeliveryAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.canDeliver]));
  const isComplete = (order && (values.deliverySignature || order?.deliverySignature)) || (order.delivery && !hasEditDeliveryAccess);
  const hasUpdateAccess = (canEdit || (hasEditDeliveryAccess && order?.delivery)) && !isComplete;
  const isManuallyInvoiced = order && order.manual_invoice;
  const materialsData = useSelector(materialsDataSelector);

  React.useMemo(() => {
    if (order.events && order.events.length > 0
      && order.events[0].eventType === EVENT_TYPE.ON_SITE) {
      cleanRows();
      getOSEventInfo([...order.events], materialsData, selectedTimezone, true);
    }
  }, [order]);

  const updateEditing = (val) => {
    setEditing(val);
    dispatch(change(formName, editingFormPath, val));
  };

  const onEdit = async () => {
    if (preparingEdit) return;
    const loadedListings = Boolean((values[formPath].events[0] ?? {})[priceListingsFormPath]);
    if (loadedListings) { updateEditing(true); return; }
    try {
      // Pull pricing info for ALL the materials in order:
      setPreparingEdit(true);
      const events = (values[formPath].events ?? []);
      let materials = []; // set of material ids
      events.forEach((e) => {
        materials.push(e.courseObj.code);
        if (e.addOns) {
          e.addOns.forEach((addon) => materials.push(addon.sap_material_number));
        }
        if (e.additional_participants) {
          e.additional_participants.forEach((addPart) => materials.push(addPart.sap_material_number));
        }
      });

      materials = _.uniq([...materials]); // make it unique values

      const priceListings = await dispatch(fetchPricingForMaterials(order.customerObj, materials));

      events.forEach((e, i) => {
        const mat = e.courseObj.code;
        const listing = priceListings[mat];
        if (!listing) return;
        dispatch(change(formName, `${formPath}.events[${i}].${priceListingsFormPath}`, listing));
        if (e.addOns) {
          e.addOns.forEach((addon, j) => {
            const addOnmat = addon.sap_material_number;
            const addonListing = priceListings[addOnmat];
            if (!addonListing) return;
            dispatch(change(formName, `${formPath}.events[${i}].addOns[${j}].${priceListingsFormPath}`, addonListing));
          });
        }
        if (e.additional_participants) {
          e.additional_participants.forEach((addPart, j) => {
            const addPartmat = addPart.sap_material_number;
            const addPartListing = priceListings[addPartmat];
            if (!addPartListing) return;
            dispatch(change(formName, `${formPath}.events[${i}].additional_participants[${j}].${priceListingsFormPath}`, addPartListing));
          });
        }
      });

      updateEditing(true);
    } finally {
      setPreparingEdit(false);
    }
  };

  const onCancelEdits = () => {
    updateEditing(false);
    restoreOriginalOrder(formName, formPath);
  };

  const showToast = (success, msg) => {
    dispatch(showModal('UPDATE_ORDER_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: msg ?? (success ? 'Event successfully updated!' : 'Something went wrong updating the order. Please, try again!'),
      },
    }));
  };

  const validateSkillcheckParticipants = (events) => {
    let valid = true;

    events.forEach((e) => {
      if (!e.participants_count || e.participants_count === '') {
        showToast(false, 'Please enter all of the Skillcheck participants');
        valid = false;
      }
    });
    return valid;
  };

  const saveChanges = async () => {
    setSaving(true);
    const summary = ({ ...(values[formPath] ?? {}) });
    if (summary && summary.events) {
      if (!validateTruckNumbers([...summary.events]) || !validateSkillcheckParticipants([...summary.events])) {
        setSaving(false);
        return;
      }
      // Remove the addons the user decided to exclude and make sure editable items are in number form
      [...summary.events].forEach(
        (e, i) => {
          summary.events[i] = ({
            ...(e ?? {}),
            title: (e.courseObj?.title ?? ''),
            price: {
              ...(e.price ?? {}),
              amount: Number(e.price?.amount ?? 0),
            },
            participants_count: Math.abs(Number(e.participants_count ?? 0)),
            addOns: !e.addOns ? [] : e.addOns.filter((addon) => addon.include).map((addon) => ({
              ...(addon ?? {}),
              qty: Number(addon.qty ?? 0),
              price: {
                ...(addon.price ?? {}),
                amount: Number(addon.price?.amount ?? 0),
              },
            })),
          });
        },
      );
    }
    const success = await dispatch(updateOrder(summary, selectedTimezoneCode, Boolean(summary?.subcontracted), false, false, true));
    updateEditing(!success);
    setSaving(false);
    if (success) {
      reloadOrder(false, false, false, false);
    }
    showToast(success);
  };

  const divider = (color) => <Divider style={{ margin: '30px 0px', background: color }} />;

  const editBtn = () => (
    <div style={{
      width: '100%', display: 'flex', justifyContent: 'end', paddingRight: 30,
    }}
    >
      <EditBoxButton
        isLoading={preparingEdit}
        isEditing={editing}
        hideWhileEditing
        disableSave={isComplete && !isManuallyInvoiced}
        onClick={onEdit}
      />
    </div>
  );

  const btn = ({ label, onClick, variant }) => (
    <StyledButton
      buttonContent={label}
      color="primary"
      variant={variant ?? 'contained'}
      onClick={onClick}
    />
  );

  const spinner = () => {
    const size = 16;
    return (
      <Spinner
        margin={5}
        spinnerProps={{ color: COLORS.CINTAS_WHITE }}
        customStyle={{ maxHeight: size, maxWidth: size }}
        spinnerStyle={{ height: size, width: size }}
      />
    );
  };

  const footer = () => (
    <Container spacing={3} style={{ justifyContent: 'end', height: 60 }}>
      <ContainerItem flex="auto">
        {btn({ label: 'CANCEL', variant: 'outlined', onClick: onCancelEdits })}
      </ContainerItem>
      <ContainerItem flex="auto">
        {btn({ label: saving ? spinner() : 'SAVE', onClick: saving ? () => { } : saveChanges })}
      </ContainerItem>
    </Container>
  );

  return (
    <div style={{ paddingTop: 14, width: '98%' }}>
      {(!isComplete || isManuallyInvoiced) && hasUpdateAccess && editBtn()}
      <OrderSummary
        showOrderId
        showMaterialNumbers
        allowEditing={editing}
        formName={formName}
        order={order}
        history={history}
        dispatch={dispatch}
        isBilling
        tzOverride={tzOverride}
      />
      {divider()}
      <CartSummaryForm eventSummary hideTitle />
      <Box height={30} />
      {editing && footer()}
    </div>
  );
};

export default BillingSummaryTab;
