import _ from 'lodash';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import React from 'react';

import { useSelector } from 'react-redux';
import { mainSections, adminSections, findLandingAdminSection } from './sections';
import AppContainer from './navigation/AppContainer';
import AppLayout from './navigation/AppLayout';
import AppRoute from './navigation/AppRoute';
import AuthRoute from './navigation/AuthRoute';
import Login from './MainViews/Login/LoginView';
import SignUp from './MainViews/Login/SignUpView';
import EventSummary from './MainViews/EventSummaryView/EventSummaryView';
import PasswordReset from './MainViews/PasswordResetView';
import OrderConfirmationView from './MainViews/OrderConfirmationView';
import LegalText from '../utils/consts/StaticText/LegalText';
import TermsAndConditionsView from './MainViews/TermsAndConditionsView';
import UserProfileView from './MainViews/UserProfileView';
import { userAccessMatrixSelector } from '../redux/selectors/user.selectors';

const App = () => {
  const matrix = useSelector(userAccessMatrixSelector);
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <AppRoute
          path="/"
          exact
          layout={AppContainer}
          component={Login}
          title="Login"
        />
        <AuthRoute
          path="/register-user/:email"
          exact
          layout={AppContainer}
          component={SignUp}
          title="Sign Up"
        />
        {_.map(adminSections(findLandingAdminSection(matrix)), (section, index) => (
          <AuthRoute
            key={index}
            path={_.map(section.path, (path) => `/admin${path}`)}
            layout={AppLayout}
            component={section.component}
            title={section.title}
            whitelist={section.whitelist}
            exact
          />
        ))}
        {_.map(mainSections, (section, index) => (
          <AuthRoute
            key={index}
            path={section.path}
            layout={AppLayout}
            component={section.component}
            title={section.title}
            whitelist={section.whitelist}
            exact={section.exact}
          />
        ))}
        <AppRoute
          path="/calendar/:orderId"
          layout={AppLayout}
          component={EventSummary}
          title="Event Summary"
          maxWidth="lg"
        />
        <AuthRoute
          path="/password-reset"
          exact
          layout={AppContainer}
          component={PasswordReset}
          title="Password Reset"
        />
        <AppRoute
          path="/termsAndConditions"
          exact
          layout={AppContainer}
          component={TermsAndConditionsView}
          title={LegalText.termsAndConditions.title}
        />
        <AuthRoute
          path="/order/:orderId/confirm"
          exact
          layout={AppContainer}
          component={OrderConfirmationView}
          title="Order Confirmation"
        />
        <AuthRoute
          path="/CustomerQuotes/:customerId/Quotes/:quoteId/summary"
          exact
          layout={AppContainer}
          component={OrderConfirmationView}
          title="Order Confirmation"
        />
        <AuthRoute
          path="/myAccount"
          exact
          layout={AppLayout}
          component={UserProfileView}
          title="My Account"
        />
        {/* If no match for route, then redirect to / */}
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
