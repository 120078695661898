/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {
  change, Field, getFormValues, reduxForm,
} from 'redux-form';
import { Box } from '@material-ui/core';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../Text/SimpleText';
import { COLORS, DATE_TIME_LONG_FOMAT } from '../../../../utils/consts';
import MDAutoComplete from '../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import StyledButton from '../../../Button/StyledButton';
import { hideModal, showModal } from '../../../../redux/actions/modals.actions';
import { fetchOrderSummary, reassignOrder } from '../../../../redux/actions/orders.actions';
import Spinner from '../../../SpinnerOverlay/Spinner';
import { timezoneDetailsSelector } from '../../../../redux/selectors/utils.selectors';
import { checkUsersConflicts } from '../../../../redux/actions/users.actions';
import SecondaryTciSelection from '../EventDialog/EventDialogForm/FormComponent/SecondaryTciSelection';
import EditOrderButton from '../../../Button/EditOrderButton';
import { getNewDate } from '../../../../utils/dateUtils';
import CancelOrderButton from '../../../Button/CancelOrderButton';
import { buildSelectedEvent, prepareOnSiteEditingForm } from '../../../../utils/event.utils';

const formName = 'InstructorSwap';
const InstructorSwapDialogContent = withStyles((theme) => ({
  root: {
    minWidth: 830,
    // maxWidth: 400,
    minHeight: 300,
    // maxHeight: 300,
    textAlign: 'center',
    padding: '24px 18px',
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      // minWidth: 50,,
      // minWidth: 850,
    },
  },
}))(MuiDialogContent);

const InstructorSwapDialog = ({
  preloadedOrder, modalName, reportingTcis, input, secondaryInput, onDone, tzOverride, calendarTz, hideSecondary,
  allowCancel,
  allowEdit,
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState(preloadedOrder);
  const [orderDetails, setOrderDetails] = React.useState();
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [conflictingTcis, setConflictingTcis] = React.useState([]);
  const tzDetails = (tzOverride ?? calendarTz);
  const tz = tzDetails?.value;
  const tzLabel = tzDetails?.abbrev;
  const locationId = order.customerObj?.sales_office ?? '';
  const startTime = moment.utc((order.events ?? [{}])[0].startTime);
  const endTime = moment.utc((order.events ?? [{}])[(order.events ?? []).length - 1].endTime);
  const options = [...reportingTcis].filter((tci) => Boolean(tci.locations?.[locationId])).sort((a, b) => a.first_name.localeCompare(b.first_name));
  const locationLabel = options.length === 0 ? `${locationId}` : `${options[0]?.locations?.[locationId]?.location_desc ?? locationId}`;

  const checkConflicts = async () => {
    try {
      setLoading(true);
      const uids = options.map((opt) => opt.uid).filter((uid) => uid !== order.instructor && uid !== order?.secondaryTci);
      await refreshDetails(order);
      const conflicting = await dispatch(checkUsersConflicts({
        uids,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        defaultTz: tz,
      }));
      setConflictingTcis(conflicting ?? []);
    } finally {
      setLoading(false);
    }
  };

  const refreshDetails = async (ord) => {
    if (ord) {
      const details = await prepareOnSiteEditingForm({
        orderId: ord.id,
        preloaded: ord,
        initialData: {
          id: ord?.events?.[0]?.id,
          title: (ord?.events?.[0]?.title && _.startCase(_.toLower(ord?.events?.[0]?.title))) || 'On-Site Class',
          start: getNewDate(moment.tz(ord?.events?.[0]?.startTime, tz)),
          end: getNewDate(moment.tz(ord?.events?.[0]?.endTime, tz)),
          desc: ord?.events?.[0],
        },
        formName: 'AddEventDialog',
        dispatch,
        tz,
      });
      setOrderDetails(details);
    }
  };

  React.useEffect(() => {
    const initial = options.find((tci) => tci.uid === order.instructor);
    if (initial) {
      dispatch(change(formName, 'instructor', initial));
      dispatch(change(formName, 'secondaryTci', order?.secondaryTci));
    }
    checkConflicts();
  }, []);

  const reloadOrder = async () => {
    try {
      setLoading(true);
      const newOrder = await dispatch(fetchOrderSummary(order?.id));
      setOrder(newOrder);
      refreshDetails(newOrder);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    setSubmitting(true);
    const newUID = input.uid;
    const nameLabel = _.startCase(`${input.first_name} ${input.last_name}`.toLowerCase());
    const secondary = secondaryInput ? options.find((tci) => tci.uid === secondaryInput) : null;
    const secondaryLabel = secondary ? _.startCase(`${secondary.first_name} ${secondary.last_name}`.toLowerCase()) : null;
    const success = await dispatch(reassignOrder(
      order.id, newUID,
      secondaryInput,
      secondaryInput !== order?.secondaryTci,
    ));
    if (success) {
      dispatch(hideModal(modalName));
      if (onDone) {
        onDone(`${newUID}::${nameLabel}${secondaryLabel ? `~${secondaryInput}::${secondaryLabel}` : ''}`);
      }
    }
    showToast(success);
    setSubmitting(false);
  };

  const showToast = (success, message) => {
    dispatch(showModal('UPDATE_ORDER_STATUS', {
      modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
      modalProps: {
        message: message ?? (success ? 'Order reassigned!' : 'Something went wrong. Check for any time conflicts and try again!'),
      },
    }));
  };

  const getLabel = (option) => _.startCase(`${option.first_name ?? ''} ${option.last_name ?? ''}`.toLowerCase());

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, style) => <SimpleText txt={txt} style={{ textAlign: 'start', ...(style ?? {}) }} />;

  const labeledEntry = (lbl, value, inputProps) => (
    <>
      {labelDisplay(lbl)}
      {inputProps ? inputProps.input : text(value)}
    </>
  );

  const labelDisplay = (lbl) => text(lbl, { color: COLORS.CINTAS_BLUE, fontWeight: 'bold' });

  const disable = (opt) => !opt.uid || conflictingTcis.includes(opt.uid);

  const instructorSelect = () => (
    <Field
      options={options}
      variant="outlined"
      getLabel={getLabel}
      renderOption={(option) => text(getLabel(option), {
        color: disable(option) ? COLORS.CINTAS_RED : null,
        fontStyle: disable(option) ? 'italic' : 'normal',
      })}
      getOptionDisabled={disable}
      getValue={(option) => `${option.uid}`}
      id="instructor"
      name="instructor"
      required
      component={MDAutoComplete}
      inputProps={{
        placeholder: 'Instructor',
      }}
      size="small"
      // popupIcon={<KeyboardArrowDownIcon color="primary" />}
      noBorderRadius
    />
  );

  const secondaryInstructorSelect = () => (
    <SecondaryTciSelection
      noLabel
      noMarginLeft
      maxWidth="100%"
      location={order?.customerObj?.sales_office}
      conflictingIds={conflictingTcis}
      excludedIds={input ? [input?.uid] : []}
      initialValue={order?.events?.[0]?.secondaryInstructor && getLabel(order?.events?.[0]?.secondaryInstructor)}
    />
  );

  const submitBtn = () => (
    <StyledButton
      color="primary"
      variant="contained"
      buttonContent="Confirm"
      disabled={!input || submitting}
      onClick={handleConfirm}
      endIcon={submitting && spinner()}
      style={{ minWidth: 150 }}
    />
  );

  const spinner = (sz, margin) => {
    const size = sz ?? 20;
    return (
      <Spinner
        spinnerStyle={{
          height: size, width: size, padding: 0, margin: margin ?? 0, color: COLORS.CINTAS_BLUE,
        }}
        customStyle={{
          maxHeight: size,
          maxWidth: size,
          margin: margin ?? 0,
          padding: 0,
        }}
      />
    );
  };

  const editOrderButton = () => (
    <EditOrderButton
      orderId={order?.id}
      onEventEdited={() => {
        reloadOrder();
        checkConflicts();
      }}
      selectedEvent={buildSelectedEvent(order, tz)}
    // clickCallback={async () => {
    //   if (!input?.uid) {
    //     showToast(false, 'Please select an instructor before editing the course');
    //     return false;
    //   }
    //   await refreshDetails(order);
    //   return true;
    // }}
    />
  );

  const cancelOrderButton = () => (
    <CancelOrderButton
      orderId={order.id}
      curEvent={orderDetails}
      clickCallback={async () => {
        if (!input?.uid) {
          showToast(false, 'Please select an instructor before canceling the course');
          return false;
        }
        await refreshDetails(order);
        return true;
      }}
      onCancelled={() => {
        if (onDone) {
          onDone(`${input.uid}::${'CANCELLED'}`);
        }
        dispatch(hideModal(modalName));
      }}
    />
  );

  return (
    <InstructorSwapDialogContent>
      {
        container({
          spacing: 3,
          style: { justifyContent: 'space-between', minHeight: 100 },
          children: loading ? spinner(30, '10% 45%') : [
            item({
              flex: 6,
              children:
                [
                  labelDisplay('Start'),
                  container({
                    style: { alignItems: 'center' },
                    children: [
                      text(`${startTime.tz(tz).format(DATE_TIME_LONG_FOMAT)} ${tzLabel ?? ''}`),
                      allowEdit && editOrderButton(),
                    ],
                  }),
                ],
            }),
            item({ flex: 6, children: labeledEntry('Location', locationLabel) }),
            item({ flex: 6, children: labeledEntry('End', `${endTime.tz(tz).format(DATE_TIME_LONG_FOMAT)} ${tzLabel ?? ''}`) }),
            item({
              flex: 6,
              children: [
                labeledEntry('Primary Instructor', undefined, { input: instructorSelect() }),
                ...(!hideSecondary ? [
                  <Box height={10} />,
                  labeledEntry('Secondary Instructor', undefined, { input: secondaryInstructorSelect() }),
                ] : []),
              ],
            }),
            item({
              flex: 12,
              // style: { textAlign: 'end' },
              children: container({
                style: { justifyContent: 'space-between' },
                children: [
                  allowCancel ? cancelOrderButton() : <div />,
                  submitBtn(),
                ],
              }),
            }),
          ],
        })
      }
    </InstructorSwapDialogContent>
  );
};

export default _.flow([
  connect((state) => {
    const vals = (getFormValues(formName)(state) ?? {});
    return ({
      reportingTcis: state.users?.data?.reportingTcis ?? [],
      input: vals.instructor,
      secondaryInput: vals.secondaryTci,
      calendarTz: timezoneDetailsSelector(state),
    });
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: (values) => {
      const errors = {};
      if (!values.instructor) {
        errors.instructor = 'Required';
      }
      return errors;
    },
  }),
])(InstructorSwapDialog);
