import firebase from 'firebase';

const firebaseConfig = {
  develop: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  release: {
    apiKey: process.env.REACT_APP_RELEASE_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_RELEASE_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_RELEASE_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_RELEASE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_RELEASE_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_RELEASE_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_RELEASE_FIREBASE_MEASUREMENT_ID,
  },
  production: {
    apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
  },
  sandbox: {
    apiKey: process.env.REACT_APP_SANDBOX_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_SANDBOX_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_SANDBOX_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_SANDBOX_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_SANDBOX_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_SANDBOX_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_SANDBOX_FIREBASE_MEASUREMENT_ID,
  },
};

firebase.initializeApp(firebaseConfig[process.env.REACT_APP_ENV || 'develop']);

export const getUserRole = async () => {
  const { claims: { role } } = await firebase
    .auth()
    .currentUser
    .getIdTokenResult();

  return role;
};

export const getCurrentUser = () => firebase.auth()?.currentUser;
export const auth = firebase.auth();
export const provider = new firebase.auth.OAuthProvider('microsoft.com');
provider.setCustomParameters({
  // prompt: 'consent',
  tenant: process.env.REACT_APP_FIREBASE_TENANT_ID,
});

export const signInWithEmailPassword = async (email, password) => {
  try {
    const emailCredentials = firebase.auth.EmailAuthProvider.credential(email, password);
    const user = await auth.signInWithCredential(emailCredentials);
    return user;
  } catch (err) {
    throw err;
  }
};
