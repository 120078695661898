/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { change, Field, initialize } from 'redux-form';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import MDAutoComplete from '../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import StyledButton from '../../Button/StyledButton';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';

const getFilter = (filters) => filters.map((filter, idx) => ({
  label: filter,
  id: filter,
  backendField: filter,
  row: Math.floor(idx / 4),
  type: 'text',
}));

export const filtersFields = (filters) => [
  ...getFilter(filters ?? []),
  {
    label: 'Clear all', type: 'clear', row: Math.ceil(filters?.length / 4) + 1, hide_column: true,
  },
  {
    label: 'Apply', type: 'submit', row: Math.ceil(filters?.length / 4), hide_column: true,
  },
];

const CustomSearchFilters = ({
  handleApplyFilters, fields, dispatch, formName, getValue, getValueLabel, hideClearAll,
}) => {
  const [totalRows, setTotalRows] = useState(Math.ceil(fields.length / 4));
  const [currentFilters, setCurrentFilters] = useState(filtersFields(fields, totalRows).map((field) => ({
    ...field,
    applied: field.type === 'submit',
    fromField: field.id ? `filters.${field.id}` : undefined,
  })));

  useEffect(() => {
    const initialValues = filtersFields(fields).filter((fl) => Boolean(fl.initialValue));
    if (_.isEmpty(initialValues)) return;
    dispatch(initialize(formName, ({ filters: initialValues.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.initialValue }), {}) })));
    enableDisableApplyBtn(true);
  }, []);

  useEffect(() => {
    if (fields.length) {
      setCurrentFilters(filtersFields(fields).map((field) => ({
        ...field,
        applied: field.type === 'submit',
        fromField: field.id ? `filters.${field.id}` : undefined,
      })));
      setTotalRows(Math.ceil(fields.length / 4));
    }
  }, [fields]);

  const getHeaders = () => currentFilters.filter((f) => !f.hide_column);

  const apply = (targetId, targetValue) => {
    const updated = [...currentFilters.map(
      (f) => ({
        ...f,
        applied: f.type === 'submit'
          ? true
          : (
            targetId === f.fromField
              ? targetValue
              : getValueLabel(f)
          ),
      }),
    )];
    setCurrentFilters(updated);
    handleApplyFilters(updated);
  };

  const getFilterField = (filter) => {
    switch (filter.type) {
      case 'text':
        return textField({ id: filter.fromField, label: filter.label });
      case 'select':
        return selectField({
          id: filter.fromField,
          label: filter.label,
          options: (filter.options ?? []),
          disableClear: filter.disableClear,
          disable: filter.disableInput,
        });
      case 'submit':
        return btn({
          label: filter.label, style: { width: '48%', marginLeft: '52%' }, onTap: apply, disable: filter.applied,
        });
      case 'clear':
        return hideClearAll ? <></> : clearFiltersBtn();
      default:
        return null;
    }
  };

  const handleKeyPress = async (event, formPath) => {
    if (event.keyCode === 13) {
      event.target?.blur();
      apply(formPath, event.target?.value);
    }
  };

  const handleClearFilters = async () => {
    const headers = getHeaders();
    for (let i = 0; i < headers.length; i++) {
      await dispatch(change(formName, headers[i].fromField, undefined));
    }
    handleFieldChange();
  };

  const enableDisableApplyBtn = (enable) => {
    const btnIdx = currentFilters.findIndex((f) => f.type === 'submit');
    if (btnIdx === -1) return;
    if (currentFilters[btnIdx].applied === (!enable)) return;
    const updated = [...currentFilters.map((f) => ({ ...f }))];
    if (updated[btnIdx].applied !== (!enable)) {
      updated[btnIdx] = {
        ...updated[btnIdx],
        applied: (!enable),
      };
      setCurrentFilters([...updated]);
    }
  };

  const handleFieldChange = () => {
    enableDisableApplyBtn(true);
  };

  const textField = ({ id, label }) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      type="text"
      size="small"
      variant="outlined"
      placeholder={label}
      onHandleChange={handleFieldChange}
      onKeyDown={(event) => handleKeyPress(event, formName)}
      label={label}
      noBorderRadius
    />
  );

  const selectField = ({
    id, label, options, disableClear, disable,
  }) => (
    <Field
      options={options}
      id={id}
      name={id}
      component={MDAutoComplete}
      onChange={handleFieldChange}
      onKeyDown={handleKeyPress}
      disableClearable={disableClear}
      disabled={disable}
      type="text"
      size="small"
      variant="outlined"
      placeholder={label}
      label={label}
      getLabel={(option) => (option.label ?? '')}
      noBorderRadius
    />
  );

  const btn = ({
    label, disable, onTap, ...rest
  }) => (
    <StyledButton
      style={{ width: '100%' }}
      variant="contained"
      color="primary"
      disabled={disable}
      buttonContent={label}
      handleButton={onTap}
      {...(rest ?? {})}
    />
  );

  const clearFiltersBtn = () => (
    <StyledButton
      style={{ padding: 0, margin: 'auto', marginTop: 6 }}
      variant="text"
      color="primary"
      disabled={getHeaders().filter((f) => getValue(f)).length === 0}
      buttonContent="Clear All"
      handleButton={handleClearFilters}
    />
  );

  const filterRowsNumbers = [...Array(totalRows + 1).keys()].map(
    (rowNum) => currentFilters.filter((f) => f.row === rowNum),
  );
  const highestRowIntervals = ([...filterRowsNumbers].sort((a, b) => (a.length > b.length ? -1 : 1)))[0].length;

  return (
    <Container spacing={2}>
      {filterRowsNumbers.map(
        (filtersList, i) => {
          const stl = { paddingTop: 0, paddingBottom: 0 };
          const flex = Math.floor(12 / highestRowIntervals);
          const paddings = [];
          const inputs = filtersList.map(
            (filter, j, arr) => (
              <ContainerItem style={{ ...stl, ...(filter.style ?? {}) }} flex={filter.flex ?? Math.floor(12 / arr.length)}>
                {getFilterField(filter)}
              </ContainerItem>
            ),
          );
          if (i !== ([...Array(totalRows + 1).keys()] - 1)) {
            while ((inputs.length + paddings.length) < highestRowIntervals) {
              paddings.push(<ContainerItem style={stl} flex={flex} />);
            }
          }
          return [...inputs, ...paddings];
        },
      )}
    </Container>
  );
};

export default CustomSearchFilters;
