import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import ForgotPasswordForm from '../../../components/Forms/ForgotPasswordForm/ForgotPasswordForm';
import { hideModal, showModal } from '../../../redux/actions/modals.actions';
import { styles } from './LoginWireFrame';
import { setSpinnerVisible } from '../../../redux/actions/utils.actions';
import { forgotPassword } from '../../../redux/actions/auth.actions';

const ForgotPasswordBtn = ({ dispatch, classes }) => {
  const onForgotPasswordSubmit = async (value) => {
    dispatch(setSpinnerVisible(true));
    try {
      await dispatch(forgotPassword(value));
      dispatch(hideModal('FORGOT_PASSWORD_MODAL'));
      dispatch(showModal('FORGOT_PASSWORD_SUCCESS_MODAL', {
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: 'We\'ve sent you an email with the instructions on how to reset yout password.',
        },
      }));
    } catch (error) {
      let message = 'Something happened during the form submission. Please, try again!';
      if (error.response && error.response.status === 404) {
        message = 'Email not found! If you forgot your email, please contact the platform administrators.';
      }
      dispatch(showModal('FORGOT_PASSWORD_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message },
      }));
    } finally {
      dispatch(setSpinnerVisible(false));
    }
  };

  const showForgotPasswordModal = () => {
    dispatch(showModal('FORGOT_PASSWORD_MODAL', {
      modalType: 'MODAL_DIALOG',
      modalProps: {
        maxWidth: 'sm',
        hideCancel: true,
        title: 'Reset your password',
        bodyText: 'Enter the email you used to register, and we will send you and email to create a new password.',
        content: (
          <ForgotPasswordForm
            onConfirm={(email) => onForgotPasswordSubmit(email)}
          />
        ),
      },
    }));
  };

  return (
    <Grid container>
      <Grid item xs>
        <Typography className={classes.link} variant="body2" onClick={() => showForgotPasswordModal()}>
          Forgot password?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default _.flow([connect(), withStyles(styles)])(ForgotPasswordBtn);
