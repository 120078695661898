/* eslint-disable import/prefer-default-export */

import {
  SAVE_LOCATIONS,
  UPDATE_LOCATION,
  ADD_LOCATION,
} from './actionTypes/locations';

import * as LocationsAPI from '../../api/locations.api';

const saveLocations = (locations) => ({
  type: SAVE_LOCATIONS,
  locations,
});

const updateLocation = (location) => ({
  type: UPDATE_LOCATION,
  location,
});

const addLocation = (payload) => ({
  type: ADD_LOCATION,
  payload,
});

export const updateLocationById = (locationId, location) => async (dispatch) => {
  // set payload to modified location prop if needed
  const payload = {
    active: location?.active || false,
    name: location?.name,
    territory: location?.territory,
    address: {
      address1: location?.address?.address1,
      address2: location?.address?.address2,
      city: location?.address?.city,
      country: location?.address?.country,
      state: location?.address?.state,
      zip: location?.address?.zip,
    },
  };

  try {
    const response = await LocationsAPI.updateLocation(locationId, payload);
    if (response && response.data) {
      await dispatch(updateLocation(response.data));
      return response.data;
    }
  } catch (error) {
    throw error;
  }
  return payload;
};

export const fetchLocations = () => async (dispatch) => {
  try {
    const response = await LocationsAPI.getAllLocations();
    if (response && response.data) {
      dispatch(saveLocations(response.data));
      return response.data;
    }
    if (response && !response.data) {
      dispatch(saveLocations([]));
      return [];
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const createLocation = (values) => async (dispatch) => {
  // modify values from props and assign to payload
  const payload = {
    active: values?.active || false,
    name: values?.name,
    territory: values?.territory,
    address: {
      address1: values?.address?.address1,
      address2: values?.address?.address2,
      city: values?.address?.city,
      country: values?.address?.country,
      state: values?.address?.state,
      zip: values?.address?.zip,
    },
  };

  try {
    const response = await LocationsAPI.createLocation(payload);
    if (response && response.data) {
      await dispatch(addLocation(response.data));
      return response.data;
    }
  } catch (error) {
    throw error;
  }
  return payload;
};
