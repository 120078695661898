/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { getFormValues } from 'redux-form';
import { CALENDAR_TYPE, defaultView } from '../../utils/consts';
import { calculateCalendarDatesRange } from '../../utils/event.utils';

export const viewportSelector = (state, formName) => getFormValues(formName ?? 'CalendarTable')(state).viewport ?? calculateCalendarDatesRange(Date.now(), defaultView);
export const openCancelledEventsPopoverSelector = (state, formName = 'CalendarTable') => getFormValues(formName)(state).openCancelledEventsPopover;
export const locationsViewportSelector = (state) => getFormValues('LocationsCalendar')(state).viewport ?? calculateCalendarDatesRange(Date.now(), defaultView);
export const locationCalendarSelection = (state) => (getFormValues('LocationsCalendar')(state) ?? {}).location;

export const calendarTypeSelector = (state) => {
  try {
    const locationCal = getFormValues('LocationsCalendar')(state);
    if (locationCal) return CALENDAR_TYPE.location;
    return CALENDAR_TYPE.tci;
  } catch (e) {
    return CALENDAR_TYPE.tci;
  }
};
