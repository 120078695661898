import React from 'react';
import { COLORS } from '../../utils/consts';
import Spinner from '../SpinnerOverlay/Spinner';
import StyledButton from './StyledButton';

const MarkAllReadButton = ({ onClick, loading, disabled }) => {
  const spinner = () => {
    const size = 10;
    return (
      <Spinner
        spinnerStyle={{
          height: size, width: size, padding: 0, margin: 0, color: COLORS.CINTAS_BLUE,
        }}
        customStyle={{
          maxHeight: size,
          maxWidth: size,
          margin: 0,
          padding: 0,
          marginTop: 0,
          marginLeft: 0,
        }}
      />
    );
  };

  return (
    <StyledButton
      handleButton={onClick}
      disableCaps
      variant="outlined"
      buttonContent="Mark all as read"
      startIcon={loading ? spinner() : null}
      disabled={disabled}
      style={{ padding: '2px 20px', borderRadius: 4, fontWeight: 'bold' }}
    />
  );
};

export default MarkAllReadButton;
