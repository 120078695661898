/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import {
  Box,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  reduxForm, submit, getFormValues, Field, change,
} from 'redux-form';
import EventSummaryForm from '../../EventSummaryForm';
import { showTwoDecimal } from '../../../../../../../utils/helpers';
import CancelOrderAlertWrapper from './CancelOrderAlertWrapper';
import Container from '../../../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../../../LayoutBuilders/ContainerItem';
import { COLORS, NOTES_DISPLAY_BORDER } from '../../../../../../../utils/consts';
import StyledButton from '../../../../../../Button/StyledButton';
import { hideModal } from '../../../../../../../redux/actions/modals.actions';
import FASCheckboxField from '../../../../../../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import Spinner from '../../../../../../SpinnerOverlay/Spinner';
import * as ModalsActions from '../../../../../../../redux/actions/modals.actions';
import FASSelectField from '../../../../../../Forms/CustomFormComponents/FASSelectField';
import { cancellationReasonsSelector } from '../../../../../../../redux/selectors/settings.selectors';
import MDTextInputField from '../../../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import FASFieldTitle from '../../../../../../Forms/CustomFormComponents/FASFieldTitle';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../../../../../../utils/consts/nationalAccount.consts';
import NationalAccountCourseDisplayHook from '../../../../../../NationalAccountDashboard/NationalAccountCourseDisplay';

const formName = 'FeeDialog';

const CancelOrderAlert = (props) => {
  const {
    cancelCost,
    curEvent,
    isReschedule,
    modalName,
    confirmText,
    isSigned,
    onSubmit,
    isWaived,
    waiveReason,
    waiveNotes,
    hasExcludedMaterial,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const waiveReasonOptions = useSelector(cancellationReasonsSelector);
  const hasHigherLevelAccount = (curEvent?.customer ?? false) && (curEvent?.customer?.local_customer || curEvent?.customer?.regional_customer || curEvent?.customer?.national_customer);
  const disableWaivingCheckbox = (!hasHigherLevelAccount && !isSigned) || hasExcludedMaterial;
  const forcedWaive = disableWaivingCheckbox;
  const forcedWaiveReason = forcedWaive ? (!isSigned ? 'Course confirmation not signed' : 'Cancellation fee not listed') : null;
  const natAccCourseNoteState = NationalAccountCourseDisplayHook({ noteType: NATIONAL_ACCOUNT_NOTE_TYPE.COURSE, customer: curEvent.customer });

  React.useEffect(async () => {
    if (!isSigned || hasExcludedMaterial) {
      await dispatch(change(formName, 'isWaived', true));
    }
  }, []);

  const dismissDialog = () => dispatch(hideModal(modalName));

  const spacer = () => <Box height="8%" />;
  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => (
    <Typography
      {...(rest ?? {})}
    >
      {txt}
    </Typography>
  );

  const btn = ({
    label, disabled, onClick, ...rest
  }) => (
    <StyledButton
      variant="contained"
      buttonContent={label}
      handleButton={onClick}
      disabled={disabled}
      {...(rest ?? {})}
    />
  );

  const cancellationCost = () => (
    <div>
      {text(`${isReschedule ? 'Reschedule' : 'Cancellation'} Cost`, { style: { textAlign: 'center' } })}
      {spacer()}
      {text(`$${showTwoDecimal(isWaived ? 0.0 : cancelCost)}`, {
        style: {
          textAlign: 'center', fontWeight: 'bold', fontSize: 30, minHeight: 100, paddingTop: '10%',
        },
      })}
      {spacer()}
    </div>
  );

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20, width: 20, padding: 0, margin: 0, color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const footer = () => container({
    style: {
      justifyContent: 'end', marginTop: 10, // height: 15, // position: 'absolute', bottom: 12, right: 24, width: '90%',
    },
    children: [
      btn({
        label: 'BACK',
        onClick: dismissDialog,
        variant: 'outlined',
        disabled: loading,
        style: { padding: '5px 50px' },
      }),
      <Box width="5%" />,
      btn({
        label: confirmText,
        color: 'primary',
        style: { padding: '5px 20px' },
        endIcon: loading && spinner(),
        disabled: loading || (isWaived && !waiveReason && !disableWaivingCheckbox),
        onClick: async () => {
          setLoading(true);
          if (onSubmit) await onSubmit(Boolean(isWaived), forcedWaive ? forcedWaiveReason : waiveReason, waiveNotes, forcedWaive);
          setLoading(false);
          dismissDialog();
        },
      }),
    ],
  });

  const waivingCheckbox = () => (
    <Field
      name="isWaived"
      component={FASCheckboxField}
      label="Waive fee"
      labelPlacement="end"
      disabled={disableWaivingCheckbox}
      onChange={(value) => {
        if (!value) {
          dispatch(change(formName, 'waiveReason', undefined));
          dispatch(change(formName, 'waiveNotes', undefined));
        }
        if (hasHigherLevelAccount && !isSigned && !value) {
          dispatch(ModalsActions.showModal('HIGHER_LEVEL_ACCOUNT_WARNING', {
            modalType: 'WARNING_ALERT',
            modalProps: {
              message: 'Customer has not signed course confirmation. Proceed with cancel / reschedule fee only if national agreement in place',
            },
          }));
        }
      }}
    />
  );

  const menuItem = (reason) => (
    <MenuItem key={reason} value={reason}>
      {_.startCase((reason ?? '').toLowerCase())}
    </MenuItem>
  );

  const waiveReasonDropdown = () => (
    <Field
      variant="outlined"
      label="Waive Reason"
      id="waiveReason"
      name="waiveReason"
      component={FASSelectField}
      displayEmpty
      style={{ textAlign: 'start', borderRadius: 0, width: '100%' }}
    >
      {waiveReasonOptions.map((reason) => menuItem(reason))}
    </Field>
  );

  const waiveNotesInput = () => (
    <Field
      id="waiveNotes"
      name="waiveNotes"
      component={MDTextInputField}
      multiline
      rows={4}
      noErrorLabel
      label="Waive Notes"
      size="small"
      type="text"
      variant="outlined"
    />
  );

  const courseConfirmationNote = () => (
    <div style={{ border: NOTES_DISPLAY_BORDER, padding: '0 8px 8px 8px', marginTop: 10 }}>
      <FASFieldTitle title={natAccCourseNoteState.label} style={{ fontWeight: 'bold', textAlign: 'left' }} />
      {natAccCourseNoteState.noteComponent()}
    </div>
  );

  return (
    <CancelOrderAlertWrapper>
      {
        container({
          spacing: 1,
          style: { padding: 5, minHeight: 360 },
          children: [
            item({
              flex: 7,
              style: {
                justifyContent: 'center',
                textAlign: 'center',
                alignContent: 'center',
                paddingRight: 12,
                height: '100%',
                borderRight: `1px solid ${COLORS.CINTAS_GRAY}`,
              },
              children: [
                cancellationCost(),
                (cancelCost > 0 ? waivingCheckbox() : <></>),
                (isWaived && !disableWaivingCheckbox ? waiveReasonDropdown() : <></>),
                (isWaived && !disableWaivingCheckbox ? waiveNotesInput() : <></>),
                spacer(),
                natAccCourseNoteState.hasNotes && courseConfirmationNote(),
              ],
            }),
            item({
              flex: 5,
              style: { paddingLeft: 12, height: '100%', textAlign: 'left' },
              children: <EventSummaryForm activeStep={1} curEvent={curEvent} />,
            }),
          ],
        })
      }
      {text(`WARNING: This event will not be retrievable after ${isReschedule ? 'rescheduling' : 'cancelling'}. A SAP sales order will be generated and the customer will be billed the shown amount.`, {
        style: {
          fontSize: 10, marginTop: 10, // position: 'absolute', bottom: 60, left: 24, right: 24,
        },
      })}
      {footer()}
    </CancelOrderAlertWrapper>
  );
};

export default _.flow([
  connect((state) => ({
    isWaived: (getFormValues(formName)(state) ?? {}).isWaived,
    waiveReason: (getFormValues(formName)(state) ?? {}).waiveReason,
    waiveNotes: (getFormValues(formName)(state) ?? {}).waiveNotes,
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: () => { },
    onSubmit: submit,
    initialValues: {
      isWaived: false,
      waiveReason: undefined,
      waiveNotes: undefined,
    },
  }),
])(CancelOrderAlert);
