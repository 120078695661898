/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import * as yaml from 'js-yaml';
import moment from './moment';
// import zipToTzMap from '../assets/mappings/zipToTz';
import zipToTzLongMap from '../assets/mappings/zipToTzLong';
import { DATE_PICKER_FOMAT } from './consts';

export const currentTimezone = moment.tz.guess();
/**
 * This will create a 'moment' object that *is* moment.tz(), and automatically use the
 * 'timezone' used when you called 'getMoment()'
 */
export const getMoment = (timezone = currentTimezone) => {
  const m = (...args) => moment.tz(...args, timezone);
  m.localeData = moment.localeData;
  return m;
};

export const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * 'datetime' is a JS Date object
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const convertDateTimeToDate = (datetime, tzMoment) => new Date(tzMoment(datetime).format());
// sets Date using ISO 8601 format

// *not* using this
export const convertDateToDateTime = (date, timezone) => {
  const m = moment.tz(date, timezone);
  return moment.tz(
    {
      year: m.year(),
      month: m.month(),
      date: m.date(),
      hour: m.hour(),
      minute: m.minute(),
    },
    timezone,
  );
};

/**
 * 'hour' is an integer from 0 - 23 specifying the hour to set on the Date
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getTimeAsDate = (hour, tzMoment) => {
  if (hour < 0 || hour > 23) {
    throw Error(
      `*${hour}* is an invalid 'hour' value, which must be between 0 and 23.`,
    );
  }
  const m = tzMoment('1970-01-01');
  return new Date(
    m
      .hour(hour)
      .minute(0)
      .format(),
  );
};

/*
 * 'now' is your 'getNow' method
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getNow = (now, tzMoment) => convertDateTimeToDate(now(), tzMoment);

export const dateRangeHeaderFormat = ({ start, end }, culture, localizer) => `${localizer.format(start, 'ddd, MM/DD/YY', culture)} - ${localizer.format(
  end,
  'ddd, MM/DD/YY',
  culture,
)}`;

export const normalizeDates = (
  events,
  startField = 'start',
  endField = 'end',
) => events.map((event) => ({
  ...event,
  [startField]: new Date(event[startField]),
  [endField]: new Date(event[endField]),
}));

export const customerZipToTzLabel = (customerZip, { defaultVal }) => {
  try {
    if (!customerZip) throw Error('Invalid ZIP');
    const value = (customerZip.split('-')[0]).replace(/\s/g, '');
    if (!Number.isInteger(Number(value)) || value.length !== 5) {
      throw new Error('Invalid format or ZIP length');
    }
    const zipsMapping = yaml.load(zipToTzLongMap);

    const tzs = Object.keys(zipsMapping);
    for (let i = 0; i < tzs.length; i++) {
      const tz = tzs[i];
      const found = zipsMapping[tz].find((e) => `${e}` === value);
      if (found) {
        return tz;
      }
    }
    throw new Error('Not found');
  } catch (e) {
    return defaultVal;
  }
};

export const copyLocalDateToTimezone = (date, tz) => {
  const formatedDate = moment.tz(date, tz);
  formatedDate.set({
    year: date?.getFullYear(),
    month: date?.getMonth(),
    date: date?.getDate(),
    h: date?.getHours(),
    m: date?.getMinutes(),
  });

  return formatedDate;
};

export const getNewDate = (date, hour, minute) => (
  new Date(
    date.year(),
    date.month(),
    date.date(),
    hour ?? date.hour(),
    minute ?? date.minute(),
    0,
  )
);

export const getNewDateFromString = (dateStr) => {
  if (!dateStr) return new Date();
  const parts = dateStr.split('-');
  if (parts.length < 3) return new Date();
  return new Date(
    parts[0],
    parts[1] - 1,
    parts[2],
  );
};

export const getDayEvents = (date, events, selectedTimezone) => ((!events) ? [] : events.filter((ev) => {
  const dayMoment = moment.tz(date, selectedTimezone);
  const startMoment = ev.startTime
    ? moment.utc(ev.startTime).tz(selectedTimezone)
    : undefined;
  const endMoment = ev.endTime
    ? moment.utc(ev.endTime).tz(selectedTimezone)
    : undefined;
  return startMoment
    && endMoment
    && (
      (
        (dayMoment.month() === startMoment.month())
        && (dayMoment.date() === startMoment.date())
        && (dayMoment.year() === startMoment.year())
      )
      || (
        (dayMoment.month() === endMoment.month())
        && (dayMoment.date() === endMoment.date())
        && (dayMoment.year() === endMoment.year())
      ) || dayMoment.isBetween(startMoment, endMoment, [])
    );
}));

export const getIntervalDatesEvents = (startDate, endDate, events, selectedTimezone) => ((!events) ? [] : events.filter((ev) => {
  const startDateMoment = moment.utc(startDate).tz(selectedTimezone).startOf('day');
  const endDateMoment = moment.utc(endDate).tz(selectedTimezone).endOf('day');
  const startMoment = ev.startTime
    ? moment.utc(ev.startTime).tz(selectedTimezone)
    : undefined;
  const endMoment = ev.endTime
    ? moment.utc(ev.endTime).tz(selectedTimezone)
    : undefined;
  return startMoment
    && endMoment
    && startMoment.isBetween(startDateMoment, endDateMoment, []);
}));

export const getDaysBetweenDates = (startDate, endDate, format) => {
  const now = startDate.clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(format));
    now.add(1, 'days');
  }
  return dates;
};

export const getAllDayEventsForDate = (date, events, selectedTimezone) => {
  const dayMoment = copyLocalDateToTimezone(date, selectedTimezone);
  return (!events) ? [] : events.filter((ev) => Boolean(ev?.allDay)).filter((ev) => {
    const startMoment = ev.startTime ? copyLocalDateToTimezone(getNewDate(moment.utc(ev.startTime)), selectedTimezone) : undefined;
    const endMoment = ev.endTime ? copyLocalDateToTimezone(getNewDate(moment.utc(ev.endTime), 23, 59), selectedTimezone) : undefined;
    return startMoment
      && endMoment
      && (
        (
          (dayMoment.month() === startMoment.month())
          && (dayMoment.date() === startMoment.date())
          && (dayMoment.year() === startMoment.year())
        )
        || (
          (dayMoment.month() === endMoment.month())
          && (dayMoment.date() === endMoment.date())
          && (dayMoment.year() === endMoment.year())
        ) || dayMoment.isBetween(startMoment, endMoment, [])
      );
  });
};

export const formatDuration = (dur, unit = 'minutes') => {
  const duration = moment.duration(dur, unit);
  const hour = moment.utc(duration.asMilliseconds()).get('h');
  const min = moment.utc(duration.asMilliseconds()).get('m');
  if (min === 0 && hour === 0) return '0m';
  return `${hour > 0 ? `${hour} hour${hour > 1 ? 's' : ''}${min > 0 ? ' and ' : ''}` : ''}${min > 0 ? `${min} minute${min > 1 ? 's' : ''}` : ''}`;
};

export const timezoneAbbrevFromLabel = (tzLabel, dateMoment) => moment.tz.zone(tzLabel)?.abbr(dateMoment._tzm);

export const isHoliday = (date, holidays) => (holidays ?? {}).hasOwnProperty(moment.utc(date).format(DATE_PICKER_FOMAT));
export const whichHoliday = (date, holidays) => (isHoliday(date, holidays) ? (holidays[moment.utc(date).format(DATE_PICKER_FOMAT)]?.label ?? '') : '');

const DateUtils = {
  copyLocalDateToTimezone,
  getNewDate,
  getNewDateFromString,
  getDayEvents,
  getAllDayEventsForDate,
  customerZipToTzLabel,
  formatDuration,
  timezoneAbbrevFromLabel,
  isHoliday,
  whichHoliday,
};

export default DateUtils;
