/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  Divider,
  IconButton, Popover,
} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { COLORS } from '../../utils/consts';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../utils/consts/nationalAccount.consts';
import FASTitle from '../Forms/CustomFormComponents/FASTtle';
import NationalAccountCourseDisplayHook from './NationalAccountCourseDisplay';

const NationalAccountNotesPopover = ({
  size, noBackground, noFloat, account, style,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const natAccNotesStates = Object.values(NATIONAL_ACCOUNT_NOTE_TYPE).reduce((prev, type) => ({
    ...prev,
    [type]: NationalAccountCourseDisplayHook({
      noteType: type,
      account: account?.id,
    }),
  }), {});

  const handleOpen = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const noteDisplay = (noteType, noDivider) => (
    <>
      <div style={{ paddingLeft: 18, paddingRight: 18 }}>
        <FASTitle title={(natAccNotesStates[noteType]?.label ?? '').toUpperCase()} customStyle={{ fontSize: 14, margin: '4px 0px' }} />
        {natAccNotesStates[noteType]?.noteComponent()}
      </div>
      {!noDivider ? [<Box height={5} />, <Divider />] : <></>}
      <Box height={5} />
    </>
  );

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleOpen}
        style={{
          padding: 2,
          marginRight: 5,
          backgroundColor: noBackground ? null : COLORS.CINTAS_BLUE,
          float: noFloat ? null : 'right',
          ...(style ?? {}),
        }}
      >
        <InfoIcon style={{ fontSize: size ?? 22, margin: 6, color: noBackground ? COLORS.CINTAS_BLUE : COLORS.CINTAS_WHITE }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{
          paddingTop: 10, paddingBottom: 6, height: 480, width: 250, overflowY: 'auto',
        }}
        >
          {Object.values(NATIONAL_ACCOUNT_NOTE_TYPE).map((type, i, lst) => (
            noteDisplay(type, i === (lst.length - 1))
          ))}
        </div>
      </Popover>
    </>
  );
};

export default NationalAccountNotesPopover;
