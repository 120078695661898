/* eslint-disable max-len */
import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import ListAltRounded from '@mui/icons-material/ListAltRounded';
import { COLORS } from '../../utils/consts';

const ConfirmationNavButton = ({
  orderId, size, padding, margin, noBackground, iconOverride, quote, customerId, noFloat, tooltip,
}) => {
  const navToConfirmation = () => {
    if (orderId) {
      const orderUrl = `/order/${orderId}/confirm?ronly=true`;
      const quoteUrl = `/CustomerQuotes/${customerId}/Quotes/${orderId}/summary?usrv=true`;
      window.open(
        quote ? quoteUrl : orderUrl,
        '_blank',
      );
    }
  };

  return (
    <Tooltip title={tooltip ?? ''}>
      <IconButton
        onClick={navToConfirmation}
        color="primary"
        style={{
          padding: padding ?? 2,
          margin: margin ?? 0,
          backgroundColor: noBackground ? null : COLORS.CINTAS_BLUE,
          float: noFloat ? null : 'right',
        }}
      >
        {iconOverride ?? <ListAltRounded style={{ fontSize: size ?? 22, margin: 6, color: noBackground ? COLORS.CINTAS_BLUE : COLORS.CINTAS_WHITE }} />}
      </IconButton>
    </Tooltip>
  );
};

export default ConfirmationNavButton;
