/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable object-property-newline */
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
// import MUIDataTable from 'mui-datatables';
import {
  reduxForm,
  getFormValues,
  change,
} from 'redux-form';
import { Typography /* Chip, Card */ } from '@material-ui/core';
import { COLORS, DEFAULT_ROUTE, DEFAULT_ROUTE_REGEX /* ORDER_BLOCKED */ } from '../../../../../../../utils/consts/index';
import ContainerItem from '../../../../../../LayoutBuilders/ContainerItem';
import Container from '../../../../../../LayoutBuilders/Container';
import ExpansionTile from '../../../../../../LayoutBuilders/ExpansionTile';
import Spinner from '../../../../../../SpinnerOverlay/Spinner';
// import FASTitle from '../../../../../../Forms/CustomFormComponents/FASTtle';
import { removeLeadingZeros } from '../../../../../../../utils/helpers';
import CustomersTable from './CustomersTable';
import CustomerSearchFilters, { fieldsNames, filtersFields } from './CustomerSearchFilters';

const contentHeight = 620;
const tableBodyHeight = 460;
const padding = 18;

const columns = filtersFields().filter((f) => !f.hide_column).map((h) => h.label);

const validate = (values) => {
  const errors = {};

  if (values.selected) {
    errors.selected = 'Select a customer';
  }

  return errors;
};

export const createDisplayData = (customer) => {
  const { name, Route, sold_to, ...otherFields } = customer;
  const hasDefault = DEFAULT_ROUTE_REGEX.exec(Route);

  return {
    name: _.startCase(_.trim(name).toLowerCase()).toUpperCase(),
    Route: hasDefault ? '' : Route,
    sold_to: removeLeadingZeros(sold_to),
    ...otherFields,
  };
};

const CustomerAdvancedSearch = ({ values, customers, onSubmit, initialSelection, dispatch, formName }) => {
  // const expandFilters = true;
  const [relevantCustomers, setRelevantCustomers] = React.useState(customers);
  const [customerSelection, setCustomerSelection] = React.useState(initialSelection);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  // const getSubheader = () => currentFilters.filter(
  //   (f) => Boolean(f.applied) && f.type !== 'submit' && f.type !== 'clear',
  // ).map((f) => ({ value: f.applied, filter: f })).filter((v) => Boolean(v)).reduce(
  //   (r, { value, filter }, i, arr) => `${r}${filter.label}: ${value}${i === (arr.length - 1) ? '' : ', '}`,
  //   '',
  // );

  const calcRows = (data, filters) => {
    if (!loading) {
      setLoading(true);
    }
    const newData = data.map((c) => createDisplayData(c));
    setRows(
      newData.map(
        (c) => (filters ?? filtersFields()).map(
          (h) => c[h.id] ?? '',
        ),
      ),
    );
    setLoading(false);
  };

  React.useEffect(() => {
    calcRows(relevantCustomers);
  }, []);

  const getValueLabel = (field) => (field.id ?? []).length > 0
    ? (field.type === 'select'
      ? ((values?.filters ?? {})[field.id[0]] ?? {}).label
      : ((values?.filters ?? {})[field.id[0]] ?? null))
    : undefined;

  const getValue = (field) => field.id
    ? ((values?.filters ?? {})[field.id] ?? undefined)
    : undefined;

  const updateSelection = (val) => {
    dispatch(change(formName, 'selected', val));
    setCustomerSelection(val);
  };

  const handleRowClick = (__, rowState) => {
    const selection = rowState.length > 0 ? { ...(relevantCustomers[rowState[0].dataIndex] ?? {}), rowIndex: rowState[0].dataIndex } : initialSelection;
    updateSelection(selection);
    return selection;
  };

  const onDoubleClick = (dataIndex) => {
    const selection = handleRowClick(null, [{ dataIndex }]);
    onSubmit({ selected: selection });
  };

  const recalcRelevantCustomers = (filters) => {
    const filtersToApply = filters.filter((f) => f.applied && (f.type !== 'submit') && (f.type !== 'clear'));
    const relevant = customers.filter((c) => {
      let result = true;
      for (let i = 0; i < filtersToApply.length; i++) {
        const filter = filtersToApply[i];
        const value = (filter.type === 'select' ? (getValue(filter) ?? {}).key : (filter.applied ?? getValue(filter))) ?? '';
        let query = value.toLowerCase().trim();
        if (filter.id === fieldsNames.route && 'not routed'.includes(query)) {
          query = DEFAULT_ROUTE.toLowerCase().trim();
        }
        result = (filter.filteringFields ?? filter.id).some((__, idx) => {
          const customerVal = c[(filter.filteringFields ?? filter.id)[idx]];
          if (!customerVal && query) {
            return false;
          }
          return (customerVal ?? '').toLowerCase().trim().includes(query);
        });
        if (!result) break;
      }
      return result;
    });

    setRelevantCustomers(relevant);
    calcRows(relevant, filters);
  };

  const handleApplyFilters = (filters) => {
    updateSelection(undefined);
    recalcRelevantCustomers(filters);
  };

  const customersTable = () => (
    <CustomersTable
      key="table-customers"
      initialSorting={filtersFields()[0].label}
      onClick={handleRowClick}
      onDoubleClick={onDoubleClick}
      columns={columns}
      rows={rows}
      tableBodyHeight={tableBodyHeight}
      contentHeight={contentHeight}
      customers={relevantCustomers}
      customerSelection={customerSelection}
    />
  );

  const noResults = () => <Typography>No results found</Typography>;
  const filtersHeader = () => <div style={{ width: '100%', paddingLeft: 18 }}>Filters</div>;

  return (
    <ContainerItem flex={12} style={{ padding: 0, margin: 0 }}>
      <ExpansionTile
        header={filtersHeader()}
        headerFlex="10%"
        expanded
        noExpanding
      >
        <CustomerSearchFilters
          formName={formName}
          getValue={getValue}
          getValueLabel={getValueLabel}
          dispatch={dispatch}
          handleApplyFilters={handleApplyFilters}
        />
      </ExpansionTile>
      <Container spacing={0} style={{ height: contentHeight, overflow: 'auto', margin: 0, borderBottom: `1px solid ${COLORS.CINTAS_DARK_GRAY}` }}>
        <ContainerItem flex={12} style={{ padding }}>
          {loading ? <Spinner /> : ((relevantCustomers.length > 0) ? customersTable() : noResults())}
        </ContainerItem>
      </Container>
    </ContainerItem>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues('CustomerAdvancedSearch')(state),
  })),
  reduxForm({
    form: 'CustomerAdvancedSearch',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncBlurFields: [],
    initialValues: {
      filters: {},
    },
    validate,
  }),
])(CustomerAdvancedSearch);
