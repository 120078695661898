/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Field, change, getFormValues, reduxForm,
} from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { adminTypeSelector } from '../../redux/selectors/user.selectors';
import { ROLE_ACCESSES } from '../../utils/consts';
import { ROSTER_CONFIGS, ROSTER_CONFIGS_HEADERS } from '../../utils/consts/nationalAccount.consts';
import { YES_NO } from '../../utils/consts/notifications.consts';
import SwitchField from '../Forms/FormComponents/Switch/SwitchField';
import { showModal } from '../../redux/actions/modals.actions';
import { editRosterConfig } from '../../redux/actions/nationalAccount.actions';

const formName = 'NationalAccountForm';
const rosterFieldId = 'rosterConfig';

const NationalAccountRosterConfig = ({
  rosterConfig, canEdit, account, configs,
}) => {
  const dispatch = useDispatch();

  const showToast = (msg) => {
    dispatch(showModal('ROSTER_CONGIF_ERROR', {
      modalType: 'ERROR_ALERT',
      modalProps: {
        message: msg ?? '',
      },
    }));
  };

  const rows = Object.keys(ROSTER_CONFIGS).map((configKey) => ({ key: configKey, label: ROSTER_CONFIGS_HEADERS[configKey], value: ((rosterConfig?.[configKey]) === YES_NO.YES) }));

  const onEditConfigValue = async (configKey, val) => {
    const newVal = val === YES_NO.YES;
    try {
      const editedNote = await editRosterConfig(account, configKey, newVal);
      if (editedNote) dispatch(change(formName, rosterFieldId, editedNote[rosterFieldId]));
      else {
        await dispatch(change(formName, rosterFieldId, configs));
        throw new Error();
      }
    } catch (e) {
      showToast('Error while updating roster config. Please try again.');
    }
  };

  const switchField = (field, value) => (
    <Field
      id={`${rosterFieldId}.${field}`}
      name={`${rosterFieldId}.${field}`}
      label={value && 'Required'}
      component={SwitchField}
      onChange={(newValue) => onEditConfigValue(field, newValue)}
      disabled={!canEdit}
    />
  );

  const rosterField = (row) => (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="subtitle1" style={{ paddingTop: 8 }}>
          {row?.label}
        </Typography>
      </Grid>
      <Grid item>
        {switchField(row?.key, row?.value)}
      </Grid>
    </Grid>
  );

  const rosterConfigFields = () => _.map(rows, (row) => (rosterField(row)));

  return (
    <>
      {rosterConfigFields()}
    </>
  );
};

export default _.flow([
  connect((state) => ({
    rosterCongfig: getFormValues(formName)(state)?.[rosterFieldId] ?? {},
    canEdit: adminTypeSelector(state) === ROLE_ACCESSES.advancedAdmin,
  })),
  reduxForm({
    form: formName,
  }),
])(NationalAccountRosterConfig);
