/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import BoldTitle from '../../../../Text/BoldTitle';
import StyledButton from '../../../../Button/StyledButton';
import { COLORS } from '../../../../../utils/consts';
import DeleteConfirmAlert from '../../EventPopper/DeleteConfirmAlert';
import * as ModalsActions from '../../../../../redux/actions/modals.actions';
import * as EventActions from '../../../../../redux/actions/events.actions';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import SimpleText from '../../../../Text/SimpleText';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import Container from '../../../../LayoutBuilders/Container';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    display: 'flex',
    // backgroundColor: 'yellow',
  },
  actionsRow: {
    width: '100%',
    height: ({ maxHeight }) => maxHeight ?? '100%',
    display: 'table-cell',
    alignContent: 'start',
    verticalAlign: 'space-between',
    // backgroundColor: 'yellow',
  },
  fileInput: {
    display: 'none',
  },
});

const CustomerRoster = ({
  event, maxHeight, dispatch, onRosterUploaded, canEdit,
}) => {
  const classes = useStyles({ maxHeight });
  const [hasRoster, setHasRoster] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const fileInputRef = useRef(null);
  const maxActionsHeight = maxHeight - 20;
  const iconSize = maxHeight + 5;
  const [uploading, setUploading] = useState(false);

  React.useEffect(() => {
    setHasRoster(Boolean(event.customerRoster));
    if (hasRoster) {
      setUploadedFile(undefined);
    }
  }, [event.customerRoster]);

  const receiveUploadedFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];
    // save the file in SAP in the event object
    const formData = new FormData();
    formData.append('customerRoster', file);
    setUploading(true);
    const url = await dispatch(EventActions.saveRoster(event.id, formData));
    setUploading(false);
    if (!url) return;
    // Update the state
    setUploadedFile(file);
    setHasRoster(true);
    onRosterUploaded(event.id, url);
  };

  const onRequestUpload = () => fileInputRef.current.click();

  const handleReplaceRoster = () => {
    const modalName = 'DELETE_AERLT';
    dispatch(ModalsActions.showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: false,
        confirmText: 'confirm',
        disableBackdropClick: true,
        maxWidth: 'lg',
        title: 'DELETE ROSTER',
        content: <DeleteConfirmAlert itemTypeName="Roster" />,
        onConfirm: () => {
          dispatch(ModalsActions.hideModal(modalName));
          setUploadedFile(undefined);
          setHasRoster(false);
          onRequestUpload();
        },
      },
    }));
  };

  const handleViewRoster = () => {
    if (!uploadedFile && hasRoster) {
      window.open(event.customerRoster);
    } else if (uploadedFile) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const pdfWindow = window.open('');
        pdfWindow.document.write(
          `<iframe width='100%' height='100%' src='${reader.result}'></iframe>`,
        );
      }, false);
      reader.readAsDataURL(uploadedFile);
    }
  };

  // Components Builders:
  const row = ({ children, ...params }) => (
    <Container {...(params ?? {})}>{children}</Container>
    // <RowLayout spacing={0} key={key} {...params}>
    //   {children}
    // </RowLayout>
  );

  const rowCell = ({
    child, ...params
  }) => <ContainerItem {...(params ?? {})}>{child}</ContainerItem>;

  // <RowCell spacing={0} key={key} flex={flex} {...params}>{child}</RowCell>;

  const fileIcon = () => <FilePresentRoundedIcon style={{ fontSize: iconSize, marginLeft: 0, color: COLORS.CINTAS_BLUE }} />;
  const boldText = (txt) => <BoldTitle title={txt} />;
  const textBtn = (label, onClick, child, key) => (
    <StyledButton
      key={key}
      variant="text"
      handleButton={onClick}
      buttonContent={label}
      style={{ maxHeight: maxActionsHeight }}
    >
      {child}
    </StyledButton>
  );

  const pdfFileInput = () => (
    <input key="pdf-input-hidden" ref={fileInputRef} type="file" accept=".pdf,.PDF" className={classes.fileInput} onChange={receiveUploadedFile} />
  );

  const spinner = () => (
    <Spinner
      margin={5}
      spinnerProps={{ color: 'primary' }}
      customStyle={{ maxHeight: 10, maxWidth: 10 }}
      spinnerStyle={{ height: 10, width: 10 }}
    />
  );

  const uploadingMessage = () => row({
    style: { justifyContent: 'flex-start' },
    children: [
      spinner(),
      <SimpleText txt="UPLOADING..." />,
    ],
  });

  const actionsRow = () => row({
    style: { justifyContent: 'start' },
    children: [
      pdfFileInput(),
      ...(uploading
        ? [uploadingMessage()]
        : [
          hasRoster ? textBtn('VIEW', handleViewRoster, 0) : <></>,
          canEdit
            ? (hasRoster
              ? textBtn('REPLACE', handleReplaceRoster, 1)
              : textBtn('UPLOAD', onRequestUpload, 2))
            : (hasRoster ? <></> : <>NO FILE UPLOADED</>),
        ]
      ),
    ],
  });

  const rosterPreview = () => (
    (
      <div className={classes.actionsRow}>
        {boldText('Customer Roster')}
        {actionsRow()}
      </div>
    )
  );

  return (
    <div className={classes.container}>
      {row({
        style: { justifyContent: 'flex-start' },
        children: [
          rowCell({
            key: 0, flex: 1, child: fileIcon(), style: { textAlign: 'start' },
          }),
          rowCell({
            key: 1, flex: 9, child: rosterPreview(), style: { textAlign: 'start' },
          }),
        ],
      })}
    </div>
  );
};

export default CustomerRoster;
