/* eslint-disable max-len */
import {
  Paper,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Draggable from 'react-draggable';
import Slide from '@material-ui/core/Slide';
import DialogHeader from '../DialogHeader/DialogHeader';
import StyledButton from '../Button/StyledButton';
import { cleanRows } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';

const styles = () => ({
  innerContainer: {
    // padding: 15,
    paddingTop: 0,
  },
  actionsContainer: {
    margin: 15,
    padding: 0,
  },
  bodyText: {
    margin: 0,
    fontWeight: '100',
    fontSize: 18,
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class FASEventDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDragging: false, isCollapsed: false };
    this.PaperComponent = this.PaperComponent.bind(this);
  }

  onClose() {
    const { onCancel, hideModal, onClosePopup } = this.props;
    if (onClosePopup) {
      onClosePopup();
    }
    if (onCancel) {
      onCancel();
    } else {
      hideModal();
    }
    cleanRows();
  }

  onConfirm() {
    const { onConfirm, hideModal } = this.props;
    if (onConfirm) {
      onConfirm();
    } else {
      hideModal();
    }
  }

  PaperComponent(props) {
    const { isCollapsed } = this.state;
    return (
      <Draggable
        handle="#alert-dialog-slide-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        onDrag={() => this.setState({ isDragging: true, isCollapsed })}
        onStop={() => this.setState({ isDragging: false, isCollapsed })}
        position={!(this.state?.isCollapsed) ? { x: 0, y: 0 } : undefined}
        bounds={{
          top: -400, bottom: 400, left: -700, right: 700,
        }}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  render() {
    const {
      title,
      bodyText,
      fullWidth,
      fullScreen,
      cancelText,
      content,
      confirmText,
      onConfirm,
      draggable,
      bodyTextStyle,
      hideCancel,
      classes,
      disableBackdropClick,
      maxWidth,
      nestedScrolling = false,
    } = this.props;
    const dragging = (this.state?.isDragging);
    return (
      <Dialog
        PaperProps={{
          style: {
            borderRadius: '0px',
          },
        }}
        style={{ zIndex: 500, cursor: dragging ? 'grab' : null }}
        open
        disableBackdropClick={disableBackdropClick}
        fullWidth={fullWidth}
        TransitionComponent={Transition}
        maxWidth={maxWidth || 'lg'}
        fullScreen={fullScreen}
        keepMounted
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperComponent={this.PaperComponent}
        disableEnforceFocus
      >
        <div style={{ position: 'relative', overflow: nestedScrolling ? 'hidden' : 'auto' }}>
          <DialogHeader
            onClose={() => this.onClose()}
            draggable={
              draggable ? {
                collapsed: this.state.isCollapsed,
                onCollapse: (collapse) => this.setState({ isCollapsed: collapse, isDragging: dragging }),
              } : false
            }
          >
            {title}
          </DialogHeader>
          <div className={classes.innerContainer} style={{ display: this.state.isCollapsed ? 'none' : 'block' }}>
            <div id="alert-dialog-slide-description">
              <h5 className={classes.bodyText} style={{ ...bodyTextStyle }}>{bodyText || ''}</h5>
              {content || ''}
            </div>
          </div>
          {(!hideCancel || onConfirm) && (
            <DialogActions className={classes.actionsContainer}>
              {!hideCancel && (

                <StyledButton
                  variant="outlined"
                  width="160px"
                  handleButton={() => this.onClose()}
                  buttonContent={cancelText || 'Back'}
                />

              )}
              {onConfirm && (
                <StyledButton
                  variant="contained"
                  color="primary"
                  width="160px"
                  handleButton={() => this.onConfirm()}
                  buttonContent={confirmText || 'Confirm'}
                />
              )}

            </DialogActions>
          )}
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FASEventDialog);
