/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { Divider } from '@material-ui/core';
import React from 'react';
import ArrowRight from '@material-ui/icons/ChevronRight';
import _ from 'lodash';
import FASFieldTitle from '../../../../components/Forms/CustomFormComponents/FASFieldTitle';
import Card from '../../../../components/LayoutBuilders/Card';
import Container from '../../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../../components/LayoutBuilders/ContainerItem';
import { COLORS } from '../../../../utils/consts';

const MatrixRoleCard = ({
  roleCategory,
  categoryRoles,
  notEditableRoles = [],
  onOpenRole,
  collapsed,
}) => {
  const uneditableBorder = `1px solid ${COLORS.CINTAS_GRAY}`;

  const onSeeRole = (roleKey, notEditable) => {
    onOpenRole({
      roleKey,
      accessMatrix: categoryRoles[roleKey],
      notEditable,
    });
  };

  const title = (txt, color, fontSize, margin) => (
    <FASFieldTitle
      title={txt}
      style={{
        fontWeight: 'bold',
        color: color ?? COLORS.CINTAS_BLUE,
        fontSize: fontSize ?? 14,
        textAlign: 'start',
        padding: 0,
        margin: margin ?? 0,
      }}
    />
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const rolesCards = () => (
    <>
      {Object.keys(categoryRoles).map((roleKey) => {
        const disable = notEditableRoles.includes(roleKey);
        return (
          <Card
            onClick={() => onSeeRole(roleKey, disable)}
            style={{
              marginBottom: 12,
              cursor: 'pointer',
              border: disable ? uneditableBorder : null,
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 2,
              paddingBottom: 3,
            }}
          >
            {container({
              style: { justifyContent: 'space-between', alignItems: 'center' },
              children: [
                item({
                  flex: 'auto',
                  children: [
                    title(roleCategory, 'black', 12),
                    title(_.isEmpty(categoryRoles[roleKey]?.appRole ?? '') ? 'no org' : categoryRoles[roleKey]?.appRole, categoryRoles[roleKey]?.appRole ? 'red' : COLORS.CINTAS_GRAY, 12),
                  ],
                }),
                item({ flex: 'auto', children: [chevronIcon(disable)] }),
              ],
            })}
          </Card>
        );
      })}
    </>
  );

  const chevronIcon = (disable) => <ArrowRight style={{ color: disable ? COLORS.CINTAS_GRAY : COLORS.CINTAS_BLUE }} />;

  const categoryLabel = () => title(roleCategory, null, null, '2px 4px');

  const collapsedView = () => [
    container({
      style: { justifyContent: 'space-between' },
      children: [
        item({
          flex: 'auto',
          children: [categoryLabel()],
        }),
        item({ flex: 'auto', children: [chevronIcon(notEditableRoles.includes(roleCategory))] }),
      ],
    }),
  ];

  const fullView = () => [
    categoryLabel(),
    <Divider height={1} style={{ marginBottom: 10 }} />,
    rolesCards(),
  ];

  return (
    <Card
      style={{
        padding: 16,
        height: collapsed ? 64 : 250,
        minWidth: collapsed ? 200 : 300,
        overflowY: 'auto',
        cursor: collapsed ? 'pointer' : null,
        border: collapsed && notEditableRoles.includes(roleCategory) ? uneditableBorder : null,
      }}
      onClick={collapsed ? () => onSeeRole(roleCategory, notEditableRoles.includes(roleCategory)) : null}
    >
      {collapsed ? collapsedView() : fullView()}
    </Card>
  );
};

export default MatrixRoleCard;
