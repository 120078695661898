/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getTciByZip } from '../../redux/actions/users.actions';
import { useStyles } from '../../routes/MainViews/EventSummaryView/EventSummaryView';
import { COLORS } from '../../utils/consts';
import FASFieldTitle from '../Forms/CustomFormComponents/FASFieldTitle';
import Card from '../LayoutBuilders/Card';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import Spinner from '../SpinnerOverlay/Spinner';
import SimpleText from '../Text/SimpleText';

const AssignedCustomerTciCard = ({ customer }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const [tciInfo, setTciInfo] = React.useState();

  const loadTciInfo = async () => {
    try {
      setLoading(true);
      const { postal_code } = customer;
      if (!postal_code) throw Error('no_postal_code');
      const info = await dispatch(getTciByZip(postal_code.split('-')[0]));
      setTciInfo(info);
    } catch {
      setTciInfo({});
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadTciInfo();
    return () => { };
  }, []);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, style, align) => (<SimpleText txt={txt} style={{ ...(style ?? {}) }} divStyle={{ display: 'flex', justifyContent: align }} align={align} textAlign={align} />);

  const card = ({ children, style, title }) => (
    <Card style={style} title={title}>{children}</Card>
  );

  const spinner = () => <Spinner customStyle={{ margin: '10%' }} />;

  return container({
    direction: 'column',
    style: { maxHeight: 300 },
    children: [
      card({
        title: 'Assigned TCI',
        style: {
          minHeight: 130, maxHeight: 130, height: '100%', overflowY: 'auto', padding: 14,
        },
        children: (loading ? [spinner()] : [
          text(tciInfo?.name ?? 'Open', { fontWeight: 'bold' }),
          text(tciInfo?.email ?? ''),
        ]),
      }),
      <Box height={24} />,
      card({
        title: 'Assigned ISR',
        style: {
          minHeight: 130, maxHeight: 130, height: '100%', overflowY: 'auto', padding: 14,
        },
        children: loading ? [spinner()] : [
          (loading ? [spinner()] : [
            text(tciInfo?.manager?.name ?? 'Open', { fontWeight: 'bold' }),
            text(tciInfo?.manager?.email ?? ''),
          ]),
        ],
      }),
    ],
  });
};

export default AssignedCustomerTciCard;
